import { Theme } from './theme';

export const CMGTheme = {
  name: 'CMG (default)',
  colors: {
    typography: {
      primary: '#0C1E2C',
      secondary: '#083B60',
      subtle: '#5F7584',
    },
    action: {
      primary: '#1B4C73',
      secondary: '#ABBDCA',
      pressed: '#17456A',
      disabled: '#8AA2B5',
    },
    background: {
      solid: {
        primary: '#FFFFFF',
        secondary: '#F4FAFB',
        overlay: 'rgba(12, 30, 44, 0.6)',
        dark: '#17344B',
      },
    },
    states: {
      info: {
        primary: '#1B4C73',
        secondary: '#ABBDCA',
      },
      error: {
        primary: '#CC1E12',
        secondary: '#FAE6E6',
      },
      success: {
        primary: '#518112',
        secondary: '#F3FFED',
      },
      warning: {
        primary: '#B74E02',
        secondary: '#FDF0E3',
      },
    },
    elements: {
      divider: '#DEE7EE',
    },
    marketing: {
      primary: '#98AF64',
    },
  },
  effects: {
    dropShadow: {
      shadowColor: 'rgba(0, 0, 0, 0.27)',
      default: {
        shadowColor: '#000',
        shadowOffset: {
          width: '0px',
          height: '3px',
        },
        shadowRadius: '14px',
        shadowOpacity: 0.21,
      },
      modal: '0px 10px -3px 0px rgba(15, 57, 90, 0.1)',
    },
  },
} as Theme;
