import { observer } from 'mobx-react-lite';
import ClinicalNotesAutoFillField from '../ClinicalNotesAutoFillFields';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { useCallback, useEffect, useMemo } from 'react';
import { localClinicalNotesStore } from '../../../stores/localPersistentClinicalNotes.store';
import { useBilling } from '../../../hooks/useBilling';
import { get } from 'lodash';

interface IProps {
  noteId: string;
  sectionId: string;
  type?: string | null;
  title?: string;
  sortOrder?: number;
  uiSection?: string | null;
  billingTextValue?: string;
  localStoreEnabled: boolean;
  updateCallback: () => void;
}

export const BillingField = observer(
  ({
    noteId,
    sectionId,
    title,
    sortOrder,
    uiSection,
    type,
    billingTextValue,
    localStoreEnabled,
    updateCallback,
  }: IProps) => {
    const fullPageRefresh = clinicalNoteStore.refreshing;
    const externalEncounterId = clinicalNoteStore.clinicalNote?.encounterId;
    const billing = clinicalNoteRefreshableAutofillStore.billing;
    const encounterIdentifier = get(
      clinicalNoteStore,
      'clinicalNote.encounter.encounterIdentifier'
    );
    delete encounterIdentifier?.__typename;

    const { isLoading, refreshBilling } = useBilling(
      externalEncounterId,
      encounterIdentifier
    );

    const textValue = useMemo(() => {
      let returnValue: string | null | undefined = null;

      if (localStoreEnabled) {
        returnValue = localClinicalNotesStore.getSectionByIds(noteId, sectionId)
          ?.text;
      }

      return returnValue || billingTextValue;
    }, [billingTextValue, localStoreEnabled, noteId, sectionId]);

    const onChange = useCallback(
      (newValue: string) => {
        clinicalNoteStore.setSectionValue(
          sectionId,
          newValue,
          sortOrder,
          type,
          uiSection
        );
        updateCallback();
      },
      [sectionId, sortOrder, type, uiSection, updateCallback]
    );

    useEffect(() => {
      if (fullPageRefresh) {
        refreshBilling();
      }
    }, [fullPageRefresh, refreshBilling]);

    return (
      <ClinicalNotesAutoFillField
        id={sectionId}
        showTopAction
        isRefreshLoading={isLoading || fullPageRefresh}
        onChange={onChange}
        title={title}
        value={textValue}
        autofillData={billing?.items}
        refreshCallback={refreshBilling}
        autofillBottomText={billing?.refreshDateString}
        autofillError={billing?.didError}
        uiSection={uiSection ?? sectionId}
      />
    );
  }
);
