export enum UISectionIds {
  Medications = 'medications',
  Exam = 'exam',
  Vitals = 'vitals',
  Billing = 'billing',
  Prescriptions = 'prescriptions',
  PastMedicalHistory = 'past medical history',
  PastSurgicalHistory = 'past surgical history',
  Orders = 'orders',
  Assessments = 'assessments',
  Immunization = 'immunizations',
  Allergies = 'allergies',
  SocialHistory = 'social history',
  FamilyHistory = 'family history',
  AdvancedDirective = 'advanced directive',
  ChiefComplaint = 'chief complaint',
  HistoryOfPresentIllness = 'history of present illness',
  Screening = 'screening',
  ReviewOfSystems = 'review of systems',
  Results = 'results',
  Plan = 'plan',
}
