import { OnDataOptions, useSubscription } from '@apollo/client';
import { clinicalNoteStore } from '../stores/clinicalNote.store';
import {
  ClinicalNoteUpdatedVersionResponse,
  clinicalNoteUpdatedVersionSubscription,
  ClinicalNoteUpdatedVersionVariables,
} from '../services/graphql/clinicalNotesUpdatedVersionSubscription';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../config/launchdarkly';

export const onData = (
  options: OnDataOptions<ClinicalNoteUpdatedVersionResponse>
) => {
  const latestClinicalNoteUpdate =
    options?.data?.data?.clinicalNoteVersionUpdated;

  if (
    clinicalNoteStore.isSaving ||
    isNil(latestClinicalNoteUpdate) ||
    latestClinicalNoteUpdate.version === clinicalNoteStore.clinicalNote?.version
  ) {
    return;
  }

  clinicalNoteStore.setHasNewVersion();
  clinicalNoteStore.setNewVersionInfo(latestClinicalNoteUpdate);
  clinicalNoteStore.setAutosaveEnabled(false);
};

export const useClinicalNoteUpdatedSubscription = (
  id: string,
  version: number
) => {
  const ldClient = useLDClient();
  const inactivityAware = ldClient?.variation(
    FEATURE_FLAGS.CLINICAL_NOTE_UPDATED_VERSION,
    false
  );

  const onDataWithFF = useCallback(() => {
    if (inactivityAware) {
      return onData;
    }
    return () => {};
  }, [inactivityAware]);

  return useSubscription<
    ClinicalNoteUpdatedVersionResponse,
    ClinicalNoteUpdatedVersionVariables
  >(clinicalNoteUpdatedVersionSubscription, {
    variables: {
      id,
      version,
    },
    shouldResubscribe: true,
    onData: onDataWithFF(),
  });
};
