'use client';

import { FC } from 'react';
import { ExternalLink } from 'lucide-react';
import clsx from 'clsx';

type ExternalLinkButtonProps = {
  link: string;
  title: string;
  testId: string;
  buttonStyle: 'with-border' | 'without-border';
};

export const ExternalLinkButton: FC<ExternalLinkButtonProps> = ({
  link,
  title,
  testId,
  buttonStyle = 'with-border',
}) => {
  const handleClick = () => {
    window.open(link, '_blank');
  };

  const wrapperClassName = clsx(
    'font-500 mr-2 flex cursor-pointer items-center py-2 text-xs text-[#94a3b8] hover:opacity-60',
    {
      'rounded-md  border px-4': buttonStyle === 'with-border',
    }
  );

  return (
    <div
      className={wrapperClassName}
      onClick={handleClick}
      data-testid={testId}
    >
      {title}
      <ExternalLink size={16} className='mb-0.5 ml-2' />
    </div>
  );
};
