import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '../AkidoUI/IconButton';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';
import { useCopyPreviousNote } from '../../hooks/useCopyPreviousNote';
import { TestID } from '../../constants/testIds';
import { ActionsModal } from '../Modal/ActionsModal';
import { formatDate } from '../../utilities/formatting';
import { ErrorModal } from '../Modal/ErrorModal';
import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';
import { Link } from '../AkidoUI';

interface CopyPreviousEncounterButtonProps {
  styles?: {
    buttonContainer?: React.CSSProperties;
  };
}

export const CopyPreviousEncounterButton = observer(
  ({ styles }: CopyPreviousEncounterButtonProps) => {
    const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const previousNoteId = clinicalNoteStore.previousAkidoClinicalNoteId;
    const [previousNote, setPreviousNote] = useState<
      ClinicalNoteModel | undefined
    >();

    const { copyPreviousNote, getPreviousNote, error } =
      useCopyPreviousNote(previousNoteId);

    const fetchPreviousNote = useCallback(async () => {
      const result = await getPreviousNote();
      setPreviousNote(result);
    }, [getPreviousNote]);

    useEffect(() => {
      if (previousNoteId) {
        fetchPreviousNote();
      }
    }, [previousNoteId, fetchPreviousNote]);

    useEffect(() => {
      if (error) {
        setIsErrorModalVisible(true);
      }
    }, [error]);

    return (
      <>
        <IconButton
          testId={TestID.ClinicalNote.CopyPreviousEncounterButton}
          icon={<CopyIcon />}
          callback={() => {
            setIsCopyModalVisible(true);
          }}
          styles={styles}
          disabled={!previousNoteId || !previousNote}
          title='Copy previous encounter'
        />
        <ActionsModal
          testId={TestID.ClinicalNote.CopyClinicalNoteModal}
          title='Copy Previous Encounter Note'
          isVisible={isCopyModalVisible}
          onAccept={() => {
            setIsCopyModalVisible(false);
            copyPreviousNote(previousNote);
          }}
          onDecline={() => setIsCopyModalVisible(false)}
        >
          Copying previous note from{' '}
          <Link
            testId={TestID.ClinicalNote.CopyPreviousEncounterLink}
            to={`/clinical-notes/${previousNoteId}/preview`}
            target='_blank'
            rel='noreferrer'
          >
            {formatDate(previousNote?.encounter?.dateOfEncounter || '', false)}
          </Link>{' '}
          will overwrite all current content added to this note. Select confirm
          to continue.
        </ActionsModal>
        <ErrorModal
          testId={TestID.ClinicalNote.CopyPreviousErrorModal}
          title='Error'
          isVisible={isErrorModalVisible}
          onClose={() => {
            setIsErrorModalVisible(false);
          }}
          errorMessage='We could not copy the previous encounter note at this time. Please try again. If the issue persists contact support.'
        />
      </>
    );
  }
);
