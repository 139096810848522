import { makeAutoObservable } from 'mobx';
import { ClinicalNoteSaveStatus } from '../types/clinical-notes';

export class ClinicalEditorPageState {
  shouldBlockNavigation: boolean;
  isAutoSaving: boolean;
  saveStatus: ClinicalNoteSaveStatus;
  autoSaveTimeout: NodeJS.Timeout | undefined;
  hasBeenModified: boolean;
  previousTitle: string;

  constructor() {
    makeAutoObservable(this);

    this.shouldBlockNavigation = false;
    this.isAutoSaving = false;
    this.saveStatus = ClinicalNoteSaveStatus.isIdle;
    this.autoSaveTimeout = undefined;
    this.hasBeenModified = false;
    this.previousTitle = '';
  }

  setShouldBlockNavigation(newState: boolean) {
    this.shouldBlockNavigation = newState;
  }

  setIsAutoSaving(newState: boolean) {
    this.isAutoSaving = newState;
  }

  setSaveStatus(newStatus: ClinicalNoteSaveStatus) {
    this.saveStatus = newStatus;
  }

  setAutoSaveTimeout(newTimeout: NodeJS.Timeout | undefined) {
    this.autoSaveTimeout = newTimeout;
  }

  clearAutoSaveTimeoutInterval() {
    clearInterval(this.autoSaveTimeout);
    this.setAutoSaveTimeout(undefined);
  }

  clearAutoSaveTimeout() {
    clearTimeout(this.autoSaveTimeout);
    this.setAutoSaveTimeout(undefined);
  }

  setHasBeenModified(newState: boolean) {
    this.hasBeenModified = newState;
  }

  setHasBeenModifiedToTrue() {
    if (!this.hasBeenModified) {
      this.hasBeenModified = true;
    }
  }

  setPreviousTitle(newTitle: string) {
    this.previousTitle = newTitle;
  }

  reset() {
    this.shouldBlockNavigation = false;
    this.isAutoSaving = false;
    this.saveStatus = ClinicalNoteSaveStatus.isIdle;
    this.autoSaveTimeout = undefined;
    this.hasBeenModified = false;
    this.previousTitle = '';
  }

  get hasSaveError() {
    return this.saveStatus === ClinicalNoteSaveStatus.hasSaveError;
  }

  get isSaving() {
    return this.saveStatus === ClinicalNoteSaveStatus.isSaving;
  }

  get hasSaved() {
    return this.saveStatus === ClinicalNoteSaveStatus.hasSaved;
  }
}

export const clinicalEditorPageState = new ClinicalEditorPageState();
