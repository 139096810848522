import { CSSProperties, FC } from 'react';
import styled from 'styled-components';

const Container = styled.aside<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border-left: ${(props) => (props.border ? '1px solid #dcdfe3' : null)};
  margin-left: ${(props) => (props.border ? '30px' : null)};
  overflow: auto;
  min-width: 460px;
  max-width: 460px;
`;

interface ClinicalNotePreviewAsideProps {
  border?: boolean;
  style?: CSSProperties;
  children?: React.ReactNode;
}

export const ClinicalNotePreviewAside: FC<ClinicalNotePreviewAsideProps> = ({
  border,
  style,
  children,
}) => {
  return (
    <Container border={border} style={style}>
      {children}
    </Container>
  );
};
