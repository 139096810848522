import { useEffect, useRef } from 'react';

export const useInterval = (
  callback: () => void,
  delay: number,
  isActive: boolean
) => {
  const intervalRef = useRef<number | undefined>(undefined);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (isActive) {
      const tick = () => savedCallback.current();
      if (typeof delay === 'number') {
        intervalRef.current = window.setInterval(tick, delay);
        return () => window.clearInterval(intervalRef.current);
      }
    }
  }, [delay, isActive]);

  return intervalRef;
};
