import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import {
  ClinicalNoteBillingDisplayLabels,
  GetBillingByExternalIdReturn,
} from '../services/graphql/billing';

import { clinicalNoteRefreshableAutofillStore } from '../stores/clinicalNoteRefreshableAutofill.store';
import { AkidoIdentifierArg } from '@akido/provider-desktop-bffe-types';

export interface BillingVariables {
  externalId?: string;
  resourceIdentifier?: AkidoIdentifierArg;
}

export const useBilling = (
  externalId: string | null | undefined,
  resourceIdentifier?: AkidoIdentifierArg
) => {
  const [fetchBilling, { loading: isLoading }] = useLazyQuery<
    GetBillingByExternalIdReturn,
    BillingVariables
  >(ClinicalNoteBillingDisplayLabels, { fetchPolicy: 'network-only' });

  const retrieveBilling = useCallback(async () => {
    if (!externalId && !resourceIdentifier) {
      return;
    }

    const variables = externalId
      ? { externalId: externalId }
      : { encounterIdentifier: resourceIdentifier };

    const { data, error } = await fetchBilling({
      variables,
    });

    if (error !== undefined) {
      clinicalNoteRefreshableAutofillStore.setBillingDidError();
    }

    const displayLabels = data?.getFhirEncounter?.billing?.displayLabels;

    if (Array.isArray(displayLabels) && displayLabels.length > 0) {
      clinicalNoteRefreshableAutofillStore.setBilling(displayLabels);
    }
  }, [externalId, fetchBilling, resourceIdentifier]);

  return { isLoading, refreshBilling: retrieveBilling };
};
