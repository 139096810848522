import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Theme, DefaultTheme } from '../Themes';

export type LoadingSpinnerProps = {
  /**
   * the size of the spinner.
   */
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  /**
   * the type of the spinner
   */
  type?: 'ring' | 'line';
  /**
   * Theme, passed using styled-components.
   */
  theme?: Theme;
  /**
   * Test id for the component.
   */
  testId?: string;
  /**
   * Styles for the container element
   */
  containerStyles?: any;
  /**
   * Styles for the spinner ring element
   */
  spinnerStyles?: any;
};

function getSize(props: LoadingSpinnerProps) {
  switch (props.size) {
    case 'xsmall':
      return '.05rem';
    case 'small':
      return '.25rem';
    case 'medium':
      return '.75rem';
    case 'large':
      return '1.25rem';
    default:
      return '1rem';
  }
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div<LoadingSpinnerProps>`
  display: block;
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
`;

const SpinnerRing = styled.div<LoadingSpinnerProps>`
  font-size: ${(props) => getSize(props)};
  position: relative;
  height: calc(${(props) => getSize(props)} * 11);
  width: calc(${(props) => getSize(props)} * 11);
  max-height: calc(${(props) => getSize(props)} * 11);
  max-width: calc(${(props) => getSize(props)} * 11);
  box-sizing: border-box;
  border-radius: 50%;
  margin: 0.1rem auto;
  background: conic-gradient(
    from 90deg at 50% 50%,
    ${(props) => props.theme?.colors?.background?.solid?.primary} 0deg,
    ${(props) => props.theme?.colors?.background?.solid?.dark} 360deg
  );
  transform: translateZ(0);
  animation: 1.5s linear infinite;
  animation-name: ${rotate};
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    background: ${(props: LoadingSpinnerProps) =>
      props.theme?.colors?.background?.solid?.primary};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const SpinnerLine = styled.img<LoadingSpinnerProps>`
  height: calc(${(props) => getSize(props)} * 11);
  width: calc(${(props) => getSize(props)} * 11);
  max-height: calc(${(props) => getSize(props)} * 11);
  max-width: calc(${(props) => getSize(props)} * 11);
  margin: 0.1rem auto;
`;

export function LoadingSpinner({
  size,
  testId,
  theme,
  containerStyles,
  spinnerStyles,
  type,
}: LoadingSpinnerProps) {
  const renderSpinner = () => {
    switch (type) {
      case 'ring':
        return <SpinnerRing style={spinnerStyles} size={size} theme={theme} />;
      case 'line':
        return (
          <SpinnerLine
            style={spinnerStyles}
            size={size}
            theme={theme}
            src={require('./LineSpinner.gif')}
          />
        ); // eslint-disable-line global-require
      default:
        return <SpinnerRing style={spinnerStyles} size={size} theme={theme} />;
    }
  };
  return (
    <SpinnerContainer style={containerStyles} size={size} data-testid={testId}>
      {renderSpinner()}
    </SpinnerContainer>
  );
}

LoadingSpinner.defaultProps = {
  theme: DefaultTheme,
  size: 'medium',
  type: 'ring',
};
