import { useEffect, useRef } from 'react';

export const useHasLoaded = () => {
  const hasLoaded = useRef(false);

  useEffect(() => {
    if (!hasLoaded.current) {
      hasLoaded.current = true;
    }
  }, []);

  return hasLoaded.current;
};
