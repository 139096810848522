import { observer } from 'mobx-react-lite';
import { transcriptionDataStore } from '../../stores/transcriptionData.store';
import { Select } from './Select';
import { SelectOption } from './Select/types';

interface StatusSelectProps {
  onChange?: (select: SelectOption, action: string) => void;
  selectedOption?: string | undefined;
}

export const StatusSelect = observer((props: StatusSelectProps) => {
  const { onChange, selectedOption } = props;

  const statuses = transcriptionDataStore.statuses.map((status) => ({
    value: status.id,
    label: status.label,
  }));

  return (
    <Select
      label='Status'
      name='status-filter-select'
      placeholder='Select Status'
      options={statuses}
      onChange={onChange}
      selectedOption={selectedOption}
    />
  );
});
