import { cloneDeep } from 'lodash';
import React, { ReactElement, useCallback, useMemo, useRef } from 'react';
import { CSSObject } from 'styled-components';
import {
  DataTableData,
  DataTableHeaderContent,
  DataTableProps,
  OnHeaderClickFunc,
  ReactComponent,
  RenderRowFunc,
} from './DataTable.types';
import {
  defaultCellStyle,
  defaultHeaderCellStyle,
  defaultRowStyle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TablePagination,
  LoadingBar,
} from './TableElements';

const defaultRenderCell = (
  value: string | number | ReactComponent | undefined,
  rowId: string,
  idx: number
) => {
  const cellKey = `${rowId}_${idx}`;
  if (typeof value === 'function') {
    const component = value() as ReactElement;
    const Cell = React.cloneElement(component, {
      style: {
        ...defaultCellStyle,
        ...(component?.props?.style ?? {}),
      },
    });

    return (
      <TableCell {...(idx === 0 ? {} : { 'data-private': true })} key={cellKey}>
        {Cell}
      </TableCell>
    );
  }

  // Generate key from the row id and the index position in that row
  return (
    <TableCell {...(idx === 0 ? {} : { 'data-private': true })} key={cellKey}>
      {value}
    </TableCell>
  );
};

const defaultRenderHeaderCell = (
  header: DataTableHeaderContent,
  id: string,
  onHeaderClick: OnHeaderClickFunc | undefined,
  sortIconComponent: ReactComponent | undefined
) => {
  // Apply the default styles and then allow for overridden styles to take over
  if (typeof header.value === 'function') {
    const component = header.value() as ReactElement;
    return React.cloneElement(component, {
      id,
      key: id,
      style: {
        ...defaultHeaderCellStyle,
        ...(component?.props?.style ?? {}),
      },
    });
  }

  const sortIcon = sortIconComponent || (
    <img
      src={require('./defaultTableSortIcon.svg').default}
      alt='table sort icon'
    />
  ); // eslint-disable-line global-require

  return (
    <TableHeaderCell
      onClick={() => {
        if (typeof onHeaderClick === 'function' && header.sortable) {
          onHeaderClick(id, header.value);
        }
      }}
      key={id}
    >
      <>
        {header.value}
        {header.sortable ? sortIcon : null}
      </>
    </TableHeaderCell>
  );
};

const defaultRenderTableRow: RenderRowFunc = (
  rowData,
  idx,
  rows,
  style,
  onClick
) => {
  const rowValues = Object.values(rowData.value);

  const onClickCallback = () => {
    if (typeof onClick === 'function') {
      onClick(rowData.id, idx);
    }
  };

  return (
    <TableRow key={rowData.id} style={style} onClick={onClickCallback}>
      {rowValues.map((value, cellIdx) =>
        defaultRenderCell(value, rowData.id, cellIdx)
      )}
    </TableRow>
  );
};

const DefaultRenderTableHeaders = (
  headers: DataTableData['headers'],
  style: CSSObject,
  onHeaderClick: OnHeaderClickFunc | undefined,
  sortIconComponent: ReactComponent | undefined
) => {
  const headerComponents = useMemo(
    () =>
      Object.entries(headers).map(([id, value]) =>
        defaultRenderHeaderCell(value, id, onHeaderClick, sortIconComponent)
      ),
    [headers, onHeaderClick, sortIconComponent]
  );

  if (typeof headers === 'function') {
    const component = headers() as ReactElement;
    return React.cloneElement(component, {
      style: {
        ...style,
        ...(component?.props?.style ?? {}),
      },
    });
  }

  return <TableRow style={style}>{headerComponents}</TableRow>;
};

const renderPaginationFooter = (props: DataTableProps) => {
  if (!props.pagination) {
    return null;
  }

  const pageSize = props.pageSize || 10;
  const totalCount = props.totalCount || props?.data?.rows?.length || 0;
  const selectedPage = props.selectedPage || 1;
  const columnCount = Object.keys(props?.data?.headers)?.length || 0;

  return (
    <TablePagination
      pageSize={pageSize}
      totalCount={totalCount}
      selectedPage={selectedPage}
      onPageClick={props.onPageClick}
      columnCount={columnCount}
    />
  );
};

export function DataTable(props: DataTableProps) {
  const {
    data,
    renderTableRow,
    renderHeaderRow,
    styles,
    onRowClick,
    selectedRowsCallback,
    onHeaderClick,
    sortIconComponent,
    pageSize,
    pagination,
    totalCount,
    selectedPage,
    onPageClick,
    isLoading,
    testID,
  } = props;

  const rows = useMemo(() => data?.rows ?? [], [data.rows]);

  const renderRow = useMemo(
    () => renderTableRow ?? defaultRenderTableRow,
    [renderTableRow]
  );

  const renderHeaders = useMemo(
    () => renderHeaderRow ?? DefaultRenderTableHeaders,
    [renderHeaderRow]
  );

  const selectedRows = useRef({});

  const onRowClicked = useCallback(
    (rowId: string, idx: number) => {
      const copySelectedRows = cloneDeep(selectedRows.current);
      // @ts-ignore: needs to be fixed
      if (selectedRows.current?.[rowId]) {
        // @ts-ignore: needs to be fixed
        delete copySelectedRows?.[rowId];
      } else {
        // @ts-ignore: needs to be fixed
        copySelectedRows[`${rowId}`] = { rowId, idx };
      }

      selectedRows.current = copySelectedRows;

      if (typeof onRowClick === 'function') {
        onRowClick(rowId, idx);
      }

      if (typeof selectedRowsCallback === 'function') {
        selectedRowsCallback(selectedRows.current);
      }
    },
    [selectedRows, selectedRowsCallback, onRowClick]
  );

  const renderedRows = useMemo(() => {
    const style = styles?.tableRow ?? defaultRowStyle;

    return rows.map((row, idx, rowArr) => {
      const rowCopy = { ...row };
      if (isLoading) {
        Object.keys(row.value).forEach((key) => {
          rowCopy.value[key] = () => <LoadingBar />;
        });
      }
      return renderRow(rowCopy, idx, rowArr, style, onRowClicked);
    });
  }, [rows, renderRow, styles?.tableRow, isLoading, onRowClicked]);

  const headerStyles = styles?.tableHeaderRow ?? {};

  return (
    <TableContainer style={styles?.tableContainer} data-testid={testID}>
      <Table style={styles?.table}>
        <TableHeader style={styles?.tableHead}>
          {renderHeaders(
            data.headers,
            headerStyles,
            onHeaderClick,
            sortIconComponent
          )}
        </TableHeader>
        <TableBody style={styles?.tableBody}>{renderedRows}</TableBody>
        {renderPaginationFooter({
          pageSize,
          pagination,
          totalCount,
          data,
          selectedPage,
          onPageClick,
        })}
      </Table>
    </TableContainer>
  );
}
