import { ApolloError } from '@apollo/client';
import { makeAutoObservable } from 'mobx';
import { ClinicalNoteFieldValue } from '../types/clinical-notes';
import { TemplateModel } from '@akido/provider-desktop-bffe-types';

export class ClinicalNoteTemplatesStore {
  templates: TemplateModel[];
  areTemplatesLoading: boolean;
  templateErrors?: ApolloError;
  hasLoadedTemplates: boolean;
  template: TemplateModel | null;

  constructor() {
    makeAutoObservable(this);

    this.templates = [];
    this.areTemplatesLoading = false;
    this.templateErrors = undefined;
    this.hasLoadedTemplates = false;
    this.template = null;
  }

  reset() {
    this.templates = [];
    this.areTemplatesLoading = false;
    this.templateErrors = undefined;
    this.hasLoadedTemplates = false;
  }

  setTemplates(templates: TemplateModel[] | undefined) {
    if (Array.isArray(templates)) {
      this.templates = templates;
    }

    this.areTemplatesLoading = false;
    this.hasLoadedTemplates = true;
  }

  getTemplate(templateId: string) {
    if (this.templates.length > 0) {
      return this.templates.find((template) => template.id === templateId);
    }
  }

  setTemplateErrors(error: ApolloError) {
    this.templateErrors = error;
  }

  clearTemplateErrors() {
    this.templateErrors = undefined;
  }

  startLoadingTemplates() {
    // TODO: replace with a single variable:
    this.areTemplatesLoading = true;
    this.hasLoadedTemplates = false;
  }

  getTemplateById(templateId: string): TemplateModel | null {
    let foundTemplate: TemplateModel | null = null;

    for (const template of this.templates) {
      if (template.id === templateId) {
        foundTemplate = template;
      }
    }

    return foundTemplate;
  }

  getTemplateSortOrderHash(templateId: string): Record<string, number> | null {
    const template = this.getTemplateById(templateId);
    if (template !== null) {
      let sortOrderHash: Record<string, any> = {};
      for (const section of template.sections) {
        sortOrderHash[section.id] = section.sortOrder;
      }
      return sortOrderHash;
    }
    return null;
  }

  sortFieldsPerTemplate(
    templateId: string,
    dataFields: ClinicalNoteFieldValue[]
  ): ClinicalNoteFieldValue[] {
    const sortOrderHash = this.getTemplateSortOrderHash(templateId);
    if (sortOrderHash !== null) {
      const returnDataFields: ClinicalNoteFieldValue[] = [];

      for (const field of dataFields) {
        if (field.id !== undefined) {
          const sortOrder = sortOrderHash[field.id];
          returnDataFields.push({ ...field, sortOrder: sortOrder });
        }
      }
      return returnDataFields;
    }

    return dataFields;
  }
}

export const clinicalNoteTemplatesStore = new ClinicalNoteTemplatesStore();
