import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';
import { NoteStatusBadge } from './NoteStatusBadge';
import { ClinicalNoteStatus } from '../../types/clinical-notes';

const TitleWrapper = styled.div`
  padding: 10px;
  align-self: center;
  display: flex;
  align-items: center;
`;

const TitleText = styled.div`
  margin-left: 40px;
  border: none;
  background-color: transparent;
  color: #333737;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 0.88rem;
  line-height: 32px;
  letter-spacing: 0.1px;
`;

export const PreviewNoteTitle = observer(() => {
  const { clinicalNote, editableNoteTitle } = clinicalNoteStore;

  return (
    <TitleWrapper>
      <TitleText>{editableNoteTitle}</TitleText>
      <NoteStatusBadge status={clinicalNote?.status as ClinicalNoteStatus} />
    </TitleWrapper>
  );
});
