import { IconWrapper } from './PageHeader';
import { UserIcon } from '../AkidoUI/Icon/UserIcon';
import { Menu } from './Menu';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useKeyPress } from '../../hooks/useKeyPress';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import styled from 'styled-components';
import { TestID } from '../../constants/testIds';

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

interface UserMenuProps {
  shouldAutoSaveOnLogout: boolean;
  styles?: {
    container?: CSSProperties;
  };
}

export function UserMenu(props: UserMenuProps) {
  const { shouldAutoSaveOnLogout, styles } = props;
  const [isUserMenuVisible, setUserMenuVisible] = useState(false);

  const menuRef = useRef<HTMLDivElement | null>(null);

  const toggleUserMenu = useCallback(() => {
    setUserMenuVisible((prev) => !prev);
  }, []);

  const closeUserMenu = useCallback(() => {
    setUserMenuVisible(false);
  }, []);

  const isPressed = useKeyPress('Escape');
  useEffect(() => {
    if (isPressed) {
      closeUserMenu();
    }
  }, [isPressed, closeUserMenu]);

  useOnClickOutside(menuRef, closeUserMenu);

  return (
    <Container style={styles?.container} data-testid={TestID.Header.UserMenu}>
      <IconWrapper onClick={toggleUserMenu}>
        <UserIcon width={18.6} height={18.6} />
      </IconWrapper>
      <Menu
        ref={menuRef}
        visible={isUserMenuVisible}
        shouldAutoSaveOnLogout={shouldAutoSaveOnLogout}
        closeMenu={closeUserMenu}
      />
    </Container>
  );
}
