import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClinicalNoteURL } from '../../../utilities/formatting';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { clinicalEditorPageState } from '../../../stores/clinicalEditorPageState.store';
import { ClinicalNoteSaveStatus } from '../../../types/clinical-notes';
import { useBlockNavigation } from '../../../hooks/useBlockNavigation';
import { RoutePaths } from '../../../constants/routes';
import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';

interface IProps {
  clinicalNoteData: { id: string; signedAt?: string } | null;
  saveNote: (arg0: boolean) => Promise<any>;
  akidoNoteId: string | undefined;
  clinicalNote: ClinicalNoteModel | undefined;
  isNoteSigned: boolean;
}
export const useClinicalNoteEditorPageNavigation = ({
  clinicalNoteData,
  saveNote,
  akidoNoteId,
  clinicalNote,
  isNoteSigned,
}: IProps) => {
  const navigate = useNavigate();

  const clinicalNoteSavingExternally = clinicalNoteStore.savingExternally;
  const shouldBlockNavigation = clinicalEditorPageState.shouldBlockNavigation;

  const [isBlocking, confirmNavigation] = useBlockNavigation(
    shouldBlockNavigation
  );

  useEffect(() => {
    // Navigates from external id to akido id
    if (clinicalNoteData) {
      const akidoNoteUrl = getClinicalNoteURL(clinicalNoteData);
      navigate(akidoNoteUrl);
    }
  }, [clinicalNoteData, navigate]);

  useEffect(() => {
    // Unblocks navigation when saving
    if (clinicalNoteSavingExternally) {
      clinicalEditorPageState.setShouldBlockNavigation(false);
      clinicalEditorPageState.setSaveStatus(ClinicalNoteSaveStatus.isSaving);
    }
  }, [clinicalNoteSavingExternally]);

  const saveBeforeNavigation = async () => {
    await saveNote(true);
    confirmNavigation();
  };

  useEffect(() => {
    // Saves when navigating out
    if (isBlocking) {
      saveBeforeNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlocking]);

  useEffect(() => {
    // Navigates user to preview if they are not authorized to edit the note
    if (akidoNoteId && clinicalNote && !clinicalNote.canSignNote) {
      if (shouldBlockNavigation) {
        clinicalEditorPageState.setShouldBlockNavigation(false);
      }
      navigate(`${RoutePaths.ClinicalNotePreview.replace(':id', akidoNoteId)}`);
    }
  }, [akidoNoteId, shouldBlockNavigation, navigate, clinicalNote]);

  useEffect(() => {
    if (isNoteSigned && akidoNoteId) {
      navigate(`${RoutePaths.ClinicalNotePreview.replace(':id', akidoNoteId)}`);
    }
  }, [akidoNoteId, isNoteSigned, navigate]);
};
