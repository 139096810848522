import styled from 'styled-components';
import userIcon from '../../../assets/icons/defaultUserIcon.svg';
import { TestID } from '../../../constants/testIds';
import { PatientInfoLabel } from '../../ClinicalNotePreview/ClinicalNotePreviewPage/PatientInfoLabel';
import { formatPatientAgeAndDOB } from '../../../utilities/patientInfoFormatter';
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';
import { isNil } from 'lodash';

export const PatientIconImageContainer = styled.img({
  overflow: 'hidden',
  marginRight: '10px',
});

type PatientInfoCardProps = {
  isLoading: boolean;
  givenName: string;
  familyName: string;
  birthDate: string | undefined;
  patientAge: number | undefined;
  referringPractitionerFullName?: string;
  accountNumber?: string;
  dateOfService?: string;
};

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'start',
  margin: '20px',
  flex: 1,
});

const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #E0E0E0',
  borderRadius: '24px',
  backgroundColor: '#FFFFFF99',
  padding: '22px 27px 22px 34px',
  flex: 1,
});

const TitleRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '10px',
});

const LeftTitle = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const DOBContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const CardTitle = styled.div({
  fontWeight: 600,
  fontSize: '0.872rem',
});

const Divider = styled.span({
  color: '#CFCFCF',
  fontSize: '0.872rem',
  margin: '0 10px',
});

const CardContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const PatientInfoCard = ({
  isLoading,
  givenName,
  familyName,
  birthDate,
  patientAge,
  referringPractitionerFullName,
  accountNumber,
  dateOfService,
}: PatientInfoCardProps) => {
  const formattedAgeAndDOB = formatPatientAgeAndDOB(birthDate, patientAge);

  if (isLoading || !givenName) return null;

  return (
    <Container data-testid='PatientDemographicsCard'>
      <Card>
        <TitleRow>
          <LeftTitle>
            <PatientIconImageContainer
              src={userIcon}
              alt='patient demographics'
            />
            <CardTitle data-private>
              {givenName} {familyName}
            </CardTitle>
          </LeftTitle>
        </TitleRow>
        <CardContainer
          data-public
          data-testid={TestID.ClinicalNote.PatientInfoContainer}
        >
          <DOBContainer>
            <PatientInfoLabel
              data-private
              header='DOB'
              value={formattedAgeAndDOB}
            />
            <ConditionalRender shouldRender={!isNil(accountNumber)}>
              <>
                <Divider>|</Divider>
                <PatientInfoLabel
                  data-public
                  header='Acct #'
                  value={accountNumber}
                />
              </>
            </ConditionalRender>
          </DOBContainer>
          <PatientInfoLabel
            data-public
            header={'Date of Service'}
            value={dateOfService ?? ''}
            containerStyles={{
              paddingBottom: 0,
            }}
          />
          <PatientInfoLabel
            data-public
            header='Referring Provider'
            value={referringPractitionerFullName}
          />
        </CardContainer>
      </Card>
    </Container>
  );
};
