export function ChevronIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='13'
      height='8'
      viewBox='0 0 13 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.73836 0.304687L6.36092 4.72333L10.9835 0.304687L12.4035 1.66501L6.36092 7.45362L0.318359 1.66501L1.73836 0.304687Z'
        fill={props.color || '#0078D8'}
      />
    </svg>
  );
}
