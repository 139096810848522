import { FC } from 'react';
import { ExternalLinkButton } from './ExternalLinkButton';
import { FEATURE_FLAGS } from '../../config/launchdarkly';
import FeatureFlaggedConditionalRender from '../FeatureFlaggedConditionalRender/FeatureFlaggedConditionalRender';
import { CapellaRefillIntegration } from './CapellaRefillIntegration';
import { generateFullCapellaURL } from '../CapellaIntegration/generateFullCapellaURL';
import { useQuery } from '@apollo/client';
import {
  GetCapellaUrlQuery,
  GetCapellaUrlResponse,
} from '../../services/graphql/capellaUrl';

export const CapellaERXIntegration: FC = () => {
  const { data } = useQuery<GetCapellaUrlResponse>(GetCapellaUrlQuery);

  const fullRefillURL = generateFullCapellaURL(
    'Refill',
    data?.credentials?.capellaConfig?.url ?? ''
  );

  const fullRxPendingURL = generateFullCapellaURL(
    'RxPending',
    data?.credentials?.capellaConfig?.url ?? ''
  );

  return (
    <div className='mr-2 flex items-center gap-2 rounded-md border px-4'>
      <FeatureFlaggedConditionalRender
        featureFlag={FEATURE_FLAGS.CAPELLA_REFILL_PAGE_IFRAME}
      >
        <CapellaRefillIntegration />
      </FeatureFlaggedConditionalRender>
      <ExternalLinkButton
        link={fullRefillURL}
        title='Capella Refill'
        testId='RefillButton'
        buttonStyle='without-border'
      />
      <ExternalLinkButton
        link={fullRxPendingURL}
        title='Capella Rx'
        testId='RxButton'
        buttonStyle='without-border'
      />
    </div>
  );
};
