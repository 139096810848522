import authStore from '../../stores/auth.store';
import styled from 'styled-components';

const Name = styled.span`
  font-size: 0.9rem;
  color: #0078d8;
`;
export function UserName() {
  const name = authStore?.authInfo?.given_name ?? '';
  const familyName = authStore?.authInfo?.family_name ?? '';
  const fullName = `${name}${familyName ? ` ${familyName}` : ''}`;
  return <Name>{fullName}</Name>;
}
