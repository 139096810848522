import { observer } from 'mobx-react-lite';
import { RefreshButton } from '../../routes/ClinicalNotesEditor/RefreshButton';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';

export const TopRefreshClinicalNoteButton = observer(() => {
  const handleRefresh = () => {
    clinicalNoteStore.setRefreshing(true);
  };

  return (
    <RefreshButton
      isLoading={clinicalNoteStore.refreshing}
      onRefreshClicked={handleRefresh}
    />
  );
});
