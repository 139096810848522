import {
  Link as LinkReactDom,
  LinkProps as LinkPropsReactDom,
} from 'react-router-dom';

export type LinkProps = LinkPropsReactDom & {
  testId?: string;
};

export function Link({ testId, className, ...props }: LinkProps) {
  return (
    <LinkReactDom
      {...props}
      data-testid={testId}
      className={`font-bold text-[#3376d1] decoration-[none] duration-150 hover:text-[#408fff] ${
        className ?? ''
      }`}
    />
  );
}
