import { gql } from '@apollo/client';

export const CreateTranscriptionScribeNote = gql`
  mutation CreateScribeNote(
    $transcriptionId: String!
    $note: String!
    $dateAdded: String
  ) {
    createScribeNote(
      transcriptionId: $transcriptionId
      note: $note
      dateAdded: $dateAdded
    ) {
      createdAt
      updatedAt
      scribeNoteId
      note
      dateAdded
      transcriptionId
    }
  }
`;
