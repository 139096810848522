import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { scribeNoteModalStore } from '../../../stores/scribeNoteModal.store';
import { truncateUUID } from '../../../utilities/helpers';
import { ModalCloseButton } from '../../../components/Modal/ModalCloseButton';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const TitleText = styled.span`
  font-size: 1.3rem;
  font-weight: 450;
  margin-left: 25px;
`;

export const CreateScribeNoteModalHeader = observer(() => {
  return (
    <Header>
      <ModalCloseButton onClick={() => scribeNoteModalStore.hideModal()} />
      <TitleText>
        Add Note to {truncateUUID(scribeNoteModalStore.encounterId)}
      </TitleText>
    </Header>
  );
});
