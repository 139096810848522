import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../config/launchdarkly';
import { clinicalNoteStore } from '../stores/clinicalNote.store';
import { AI_TEMPLATE_NAME } from '../constants/templates';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { RoutePaths } from '../constants/routes';
import { useEffect } from 'react';

export const useAINotePage = () => {
  const ldClient = useLDClient();
  const navigate = useNavigate();
  const shouldOpenAINotePage = ldClient?.variation(
    FEATURE_FLAGS.AI_NOTE_PAGE,
    false
  );

  const templateName = clinicalNoteStore?.templateName;

  const params = useParams();
  const match = useMatch({ path: 'ai-clinical-note/:id' });

  useEffect(() => {
    if (templateName === AI_TEMPLATE_NAME && shouldOpenAINotePage) {
      const path = `${RoutePaths.AIClinicalNote}/${params?.id}`;
      navigate(path);
    }
  }, [navigate, params.id, shouldOpenAINotePage, templateName]);

  useEffect(() => {
    if (match && !shouldOpenAINotePage) {
      const path = `${RoutePaths.ClinicalNotes}/${params?.id}`;
      navigate(path);
    }
  }, [templateName, match, shouldOpenAINotePage, navigate, params?.id]);
};
