import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { useBilling } from '../../../hooks/useBilling';
import { ClinicalNotePreviewColors } from '../../../constants/colors';
import { RefreshButton } from '../../ClinicalNotesEditor/RefreshButton';
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';
import { isEmpty } from 'lodash';

const Container = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
  color: ${ClinicalNotePreviewColors.HEADER_TEXT};
`;

const NotesText = styled.div`
  font-size: 0.775rem;
  margin-top: 10px;
  color: ${ClinicalNotePreviewColors.TEXT};
  white-space: pre-wrap;
`;

const NotesHeader = styled.span({
  fontSize: '0.775rem',
  fontWeight: 600,
});

const Separator = styled.div`
  background-color: #f2f2f2;
  height: 1px;
`;

const BillingListItemsContainer = styled.ul`
  display: flex;
  margin: 20px 10px;
  flex-direction: column;
  font-size: 0.775rem;
  color: ${ClinicalNotePreviewColors.TEXT};
  padding: 0;
`;

const BillingListItem = styled.li`
  margin: 5px 0;
  padding: 0;
`;

interface BillingPreviewSectionProps {
  isVisible: boolean;
}

export const BillingPreviewSection: FC<BillingPreviewSectionProps> = (
  props
) => {
  const { isVisible } = props;

  const ldClient = useLDClient();

  const externalEncounterId = clinicalNoteStore.clinicalNote?.encounterId;
  const billingReadOnlyData = clinicalNoteRefreshableAutofillStore.billing;
  const billingNote = clinicalNoteStore.clinicalNote?.billingNote;

  const { isLoading: isBillingDataLoading, refreshBilling } =
    useBilling(externalEncounterId);

  useEffect(() => {
    refreshBilling();
  }, [refreshBilling]);

  const billingStyle = ldClient?.variation(FEATURE_FLAGS.NEW_ADDENDUM_UI)
    ? {
        height: 'unset',
        padding: '30px',
      }
    : {};

  const renderBillingLines = (textList: string[] | null) => {
    if (!textList) return null;

    return textList.map((field, i) => (
      <BillingListItem key={`billing_${field}_${i}`}>{field}</BillingListItem>
    ));
  };

  if (!isVisible) return <div />;

  return (
    <Container
      data-testid={TestID.ClinicalNote.PreviewBilling}
      style={billingStyle}
    >
      <HeaderContainer>
        <Title>Billing</Title>
        <RefreshButton
          isLoading={isBillingDataLoading}
          onRefreshClicked={refreshBilling}
        />
      </HeaderContainer>
      <Separator />
      <>
        <BillingListItemsContainer>
          {renderBillingLines(billingReadOnlyData?.items ?? [])}
        </BillingListItemsContainer>
        <ConditionalRender shouldRender={!isEmpty(billingNote)}>
          <NotesHeader>Notes</NotesHeader>
          <NotesText>{billingNote}</NotesText>
        </ConditionalRender>
      </>
    </Container>
  );
};
