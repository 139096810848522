import sortBy from 'lodash/sortBy';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { clinicalNoteTemplatesStore } from '../../../stores/clinicalNoteTemplates.store';
import { ClinicalNoteFieldValue } from '../../../types/clinical-notes';

export function getFields(
  sectionValues: Record<string, ClinicalNoteFieldValue>,
  categoryToDisplay: any
) {
  let dataFields = [];

  for (const sectionId in sectionValues) {
    const field = sectionValues[sectionId];
    if (field.category === categoryToDisplay) {
      dataFields.push(field);
    }
  }

  if (dataFields[0]?.sortOrder === undefined) {
    dataFields = clinicalNoteTemplatesStore.sortFieldsPerTemplate(
      clinicalNoteStore.templateId,
      dataFields
    );
  }

  return sortBy(dataFields, ['sortOrder']);
}
