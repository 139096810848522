import { useEffect, useState } from 'react';

const useInactivity = (countdownStart: number) => {
  const [timeLeft, setTimeLeft] = useState<number>(countdownStart);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    const resetTimeout = () => {
      setTimeLeft(countdownStart);
    };

    const events = [
      'load',
      'mousedown',
      'click',
      'scroll',
      'keypress',
      // in case they logged through mobile
      'touchstart',
      'touchend',
    ];
    events.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });
    return () => {
      clearInterval(myInterval);
      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });
    };
  });
  return timeLeft;
};

export default useInactivity;
