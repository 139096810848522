import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { PatientWithHistoriesSectionsGraphQLResult } from '../../../types/patient';
import {
  GetPatientParams,
  PatientWithClinicalSections,
} from '../../../services/graphql/patientWithClinicalData';

import { PastHistoryPatientFieldParsing } from '../../../utilities/PastHistoryPatientFieldParsing';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { createClinicalNoteListItemsBlockFromDisplayLabels } from '../../../utilities/formatting';
import { AllergyHelpers } from '../../../utilities/allergyHelpers';
import { get } from 'lodash';
import { isStringWithLength } from '../../../utilities/helpers';
import { PatientTypes } from '../../../services/graphql/commonTypes';

export const usePatientClinicalData = (
  patientId: string | undefined,
  externalId: string | null | undefined
) => {
  const ldClient = useLDClient();
  const {
    loading: isLoading,
    data,
    error,
  } = useQuery<PatientWithHistoriesSectionsGraphQLResult, GetPatientParams>(
    PatientWithClinicalSections,
    {
      variables: {
        patientId: {
          value: patientId ?? '',
          type: PatientTypes.FHIR_PATIENT_ID.toString(),
        },
        externalId: externalId ?? '',
        externalEncounterId: externalId ?? '',
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'standby',
      skip: !isStringWithLength(externalId) || !isStringWithLength(patientId),
    }
  );

  const shouldUseImmunizationsFromBffe = ldClient?.variation(
    FEATURE_FLAGS.IMMUNIZATIONS_FROM_BFFE,
    false
  );

  const autofillForTextSections = useMemo(() => {
    const encounterLevelData = data?.getFhirEncounter;

    const graphqlPastMedicalHistory = data?.patient?.pastMedicalHistory?.list;
    const pastMedicalHistory =
      PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
        graphqlPastMedicalHistory
      );
    const graphqlPastSurgicalHistory = data?.patient?.pastSurgicalHistory?.list;
    const pastSurgicalHistory =
      PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
        graphqlPastSurgicalHistory
      );
    const graphqlSocialHistory = data?.patient?.socialHistory?.list;
    const socialHistory =
      PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
        graphqlSocialHistory
      );
    const graphqlFamilyHistory = data?.patient?.familyHistory?.list;
    const familyHistory =
      PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
        graphqlFamilyHistory
      );
    const graphqlMedications = data?.patient?.medications?.list;
    const medications =
      PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
        graphqlMedications
      );

    const graphqlAssessments = encounterLevelData?.assessments?.list;
    if (graphqlAssessments && externalId) {
      clinicalNoteRefreshableAutofillStore.setAssessments(graphqlAssessments);
    } else if (error) {
      clinicalNoteRefreshableAutofillStore.setAssessmentsDidError();
    }

    const graphqlBilling = encounterLevelData?.billing?.displayLabels;
    if (graphqlBilling && externalId) {
      clinicalNoteRefreshableAutofillStore.setBilling(graphqlBilling);
    } else if (error) {
      clinicalNoteRefreshableAutofillStore.setBillingDidError();
    }

    const graphqlVitals = encounterLevelData?.vitals?.displayLabels;
    if (graphqlVitals && externalId) {
      clinicalNoteRefreshableAutofillStore.setVitals(graphqlVitals);
    } else if (error) {
      clinicalNoteRefreshableAutofillStore.setVitalsDidError();
    }

    const graphqlOrdersResult = encounterLevelData?.orders.list;
    if (graphqlOrdersResult && externalId) {
      clinicalNoteRefreshableAutofillStore.setOrders(graphqlOrdersResult);
    } else if (error) {
      clinicalNoteRefreshableAutofillStore.setOrdersDidError();
    }

    const drugAllergiesStringBlock =
      createClinicalNoteListItemsBlockFromDisplayLabels(
        get(data, 'patient.allergies.drugAllergies') ?? []
      );

    const nonDrugAllergiesStringBlock =
      createClinicalNoteListItemsBlockFromDisplayLabels(
        get(data, 'patient.allergies.nonDrugAllergies') ?? []
      );

    const allergies = AllergyHelpers.buildFieldString(
      '',
      drugAllergiesStringBlock,
      nonDrugAllergiesStringBlock
    );

    const immunizations = shouldUseImmunizationsFromBffe
      ? createClinicalNoteListItemsBlockFromDisplayLabels(
          get(data, 'patient.immunizations.list') ?? []
        )
      : '';

    const graphqlPrescriptions = data?.patient?.prescriptions?.list;
    if (graphqlPrescriptions && externalId) {
      clinicalNoteRefreshableAutofillStore.setPrescriptions(
        graphqlPrescriptions
      );
    } else if (error) {
      clinicalNoteRefreshableAutofillStore.setPrescriptionsDidError();
    }

    return {
      pastMedicalHistory,
      pastSurgicalHistory,
      socialHistory,
      familyHistory,
      allergies,
      immunizations,
      medications,
    };
  }, [data, error, externalId, shouldUseImmunizationsFromBffe]);

  return {
    isClinicalDataLoading: isLoading,
    isClinicalDataLoaded: !isLoading && data?.getFhirEncounter,
    autofillForTextSections,
  };
};
