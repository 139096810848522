import {
  isSameYear,
  setMonth,
  setYear,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { DateTime } from 'luxon';

export function generateDropdownMonths(fromDate: Date, toDate: Date) {
  const dropdownMonths: Date[] = [];
  if (isSameYear(fromDate, toDate)) {
    // only display the months included in the range
    const date = startOfMonth(fromDate);
    for (let month = fromDate.getMonth(); month <= toDate.getMonth(); month++) {
      dropdownMonths.push(setMonth(date, month));
    }
  } else {
    // display all the 12 months
    const date = startOfMonth(new Date()); // Any date should be OK, as we just need the year
    for (let month = 0; month <= 11; month++) {
      dropdownMonths.push(setMonth(date, month));
    }
  }
  return dropdownMonths;
}

export function generateDropdownYears(fromYear: number, toYear: number) {
  const years: Date[] = [];
  for (let year = fromYear; year <= toYear; year++) {
    years.push(setYear(startOfYear(new Date()), year));
  }
  return years;
}

export const buildReturnDate = (inputDate: Date, inputTime: Date): Date => {
  const date = DateTime.fromJSDate(inputDate);
  const time = DateTime.fromJSDate(inputTime);

  const returnDate = date.set({
    hour: time.hour,
    minute: time.minute,
    second: time.second,
    millisecond: time.millisecond,
  });

  return returnDate.toJSDate();
};

export const buildReturnTime = (inputTime: Date, amPm: 'AM' | 'PM'): Date => {
  const time = DateTime.fromJSDate(inputTime);
  const hour = (time.hour % 12) + (amPm === 'PM' ? 12 : 0);

  const returnTime = time.set({
    hour,
  });

  return returnTime.toJSDate();
};
