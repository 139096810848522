import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { DefaultTheme, Theme } from '../Themes';

const CardWrapper = styled.div<CardProps>`
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme?.colors?.elements?.divider};
  margin: 10px;
  width: calc(100% - 20px); // 20px is the margin of the card
  min-width: calc(100% - 20px); // 20px is the margin of the card
  min-height: 100px;
  box-shadow: ${(props) => {
    const dropShadow = props.theme?.effects?.dropShadow?.default;

    if (!dropShadow) return 'none';

    return `
      ${dropShadow.shadowOffset?.width}
      ${dropShadow.shadowOffset?.height}
      ${dropShadow.shadowRadius}
      ${props.theme?.effects?.dropShadow?.shadowColor}
    `;
  }};
`;

const CardTitle = styled.div<CardProps>`
  font-size: 22px;
  color: ${(props) => props.theme?.colors?.text?.primary};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.elements?.divider};
  padding: 20px;
`;

const CardBody = styled.div<CardProps>`
  padding: ${({ noPadding }) => (noPadding ? 0 : '.5rem 1rem')};
  font-size: 20px;
`;

export interface CardProps {
  /**
   * Content to render inside the card
   */
  children?: React.ReactNode;
  /*
   * theme passed from styled-components.
   * Not required if using a provider.
   */
  theme?: Theme;
  /**
   * Should it include inner padding
   */
  noPadding?: boolean;
  /**
   * Card title
   */
  title?: string;
  wrapperStyle?: CSSObject;

  style?: CSSObject;
}

export function Card({
  children,
  title,
  noPadding,
  theme,
  style,
  wrapperStyle,
}: CardProps) {
  return (
    <CardWrapper style={wrapperStyle} theme={theme}>
      {title && <CardTitle theme={theme}>{title}</CardTitle>}
      <CardBody style={style} noPadding={noPadding}>
        {children}
      </CardBody>
    </CardWrapper>
  );
}

Card.defaultProps = {
  theme: DefaultTheme,
  title: '',
  noPadding: false,
};
