import { FC } from 'react';
import { Tab } from '@headlessui/react';
import { TabButton } from '../TabButton/TabButton';
import { SummaryPanel } from '../TabPanels/SummaryPanel';
import { TranscriptPanel } from '../TabPanels/TranscriptPanel';

export interface TabControllerProps {}

export const TabController: FC<TabControllerProps> = () => {
  return (
    <Tab.Group>
      <Tab.List className='flex flex-row space-x-2 px-9 py-6'>
        <Tab>{() => <TabButton label='Transcript' />}</Tab>
        <Tab>{() => <TabButton label='Summary' />}</Tab>
      </Tab.List>
      <Tab.Panels className='h-full'>
        <TranscriptPanel />
        <SummaryPanel />
      </Tab.Panels>
    </Tab.Group>
  );
};
