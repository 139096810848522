export enum EncounterAnalyticsEventName {
  ViewEncounterNote = 'view_encounter_note',
  ViewScribeNote = 'view_scribe_note',
  CreateScribeNote = 'create_scribe_note',
  NoteStatusCompleted = 'note_status_completed',
  NoteStatusInProgress = 'note_status_in_progress',
}

export enum ClinicalNoteAnalyticsEventName {
  StartNote = 'Start Note',
  SaveNote = 'Save Note',
  PreviewNote = 'Preview Note',
  SignNote = 'Sign Note',
  AddAddendum = 'Add Addendum',
  SignAddendum = 'Sign Addendum',
}

export enum ClinicalAINoteAnalyticsEventName {
  GenerateSummary = 'Generate Summary',
  AINoteEdited = 'AI Note Edited',
}

export const AnalyticsScreenNameMapping: Record<string, string> = {
  noteEditor: 'Note Editor',
  unsignedNote: 'Unsigned Note',
  signedNote: 'Signed Note',
  billingInformation: 'Billing Info',
  transcriptions: 'Encounter List',
  login: 'Login',
  'transcriptions/details': 'Encounter Detail',
};
