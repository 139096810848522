import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { transcriptionFilterStore } from '../../stores/transcriptionFilter.store';

const ClearFiltersButton = styled.button`
  color: #3892c3;
  font-weight: 550;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-top: 20px;
  align-self: flex-end;
  cursor: pointer;
`;

interface ClearFiltersProps {
  onClick?: () => void;
}

export const ClearFilters: FC<ClearFiltersProps> = (props) => {
  const { onClick } = props;

  const _onClick = useCallback(() => {
    if (onClick) {
      onClick();
    }

    transcriptionFilterStore.clear();
  }, [onClick]);
  return (
    <ClearFiltersButton onClick={_onClick}>Clear Filters</ClearFiltersButton>
  );
};
