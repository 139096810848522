import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from 'styled-components';
import authStore from '../../../stores/auth.store';

interface ScribeNoteModalSaveProps {
  saveCallback: () => void;
}

const Button = styled.button`
  cursor: pointer;
  padding: 8px 24px;
  margin-top: 30px;
  border-radius: 16px;
  background-color: #1b4c73;
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;
  width: 238px;
  align-self: center;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
`;

export const CreateScribeNoteModalSave: FC<ScribeNoteModalSaveProps> = observer(
  ({ saveCallback }) => {
    const canCreateScribeNote = authStore.authorizations.canCreateScribeNotes;

    return canCreateScribeNote ? (
      <Button data-testid='ScribeNoteModalSaveButton' onClick={saveCallback}>
        Save
      </Button>
    ) : null;
  }
);
