import { observer } from 'mobx-react-lite';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { localClinicalNotesStore } from '../../../stores/localPersistentClinicalNotes.store';
import {
  CategoryVariant,
  ClinicalNoteFieldValue,
} from '../../../types/clinical-notes';
import ClinicalNotesField from '../ClinicalNotesField';

const encounterTitleContainerStyles = {
  width: '80%',
  margin: '0px 28px 16px',
};

const sideSectionsTitleContainerStyles = {
  width: '80%',
  margin: '0px 35px 16px',
};

export const SectionReadonlyAutofill = observer(
  ({
    categoryToDisplay,
    showTitle = true,
    updateCallback,
    field,
    noteId,
  }: {
    categoryToDisplay: CategoryVariant;
    noteId: string;
    showTitle?: boolean;
    updateCallback: () => void;
    field: ClinicalNoteFieldValue;
    containerStyles?: Record<string, unknown>;
  }) => {
    const sectionId = field?.sectionId ?? field?.id ?? '';
    const key = `field_${sectionId}`;
    const localStoreEnabled = useFeatureFlag(
      FEATURE_FLAGS.LOCAL_STORE_CLINICAL_NOTES,
      false
    );
    let localNoteSection = undefined;
    if (localStoreEnabled) {
      localNoteSection = localClinicalNotesStore.getSectionByIds(
        noteId,
        sectionId
      );
    }
    const value = localNoteSection?.text || field.text;

    const title = showTitle ? field.title : null;
    const isEncounterCategory = categoryToDisplay === CategoryVariant.encounter;
    const fieldIsEncounter = field.category === CategoryVariant.encounter;

    const outlineColor = !isEncounterCategory ? '#F6F8FA' : 'transparent';
    const titleContainerStyles = fieldIsEncounter
      ? encounterTitleContainerStyles
      : sideSectionsTitleContainerStyles;

    const bottomDividerStyles = !fieldIsEncounter
      ? {
          width: '95%',
          alignSelf: 'center',
          backgroundColor: '#E0E0E0',
        }
      : {};

    const onHandleChange = (newValue: string) => {
      clinicalNoteStore.setSectionValue(
        sectionId,
        newValue,
        field.sortOrder,
        field.type,
        field.uiSection,
        field.requestId
      );
      updateCallback();
    };

    return (
      <ClinicalNotesField
        id={sectionId}
        key={key}
        title={title ?? ''}
        value={value}
        onChange={onHandleChange}
        titleContainerStyles={titleContainerStyles}
        outlineColor={outlineColor}
        showTopDivider={fieldIsEncounter}
        bottomDividerStyles={bottomDividerStyles}
        uiSection={field.uiSection ?? sectionId}
      />
    );
  }
);
