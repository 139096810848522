import { keyBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DragonSpeechToText } from '../../../components/SpeechToText/DragonSpeechToText';
import authStore from '../../../stores/auth.store';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { clinicalNoteTemplatesStore } from '../../../stores/clinicalNoteTemplates.store';
import { CategoryVariant } from '../../../types/clinical-notes';
import { createDropdownInterfacesFromClinicalNoteTemplates } from '../../../utilities/helpers';
import { ChangeTemplateModal } from '../ChangeTemplateModal';
import { ClinicalNoteHeader } from '../ClinicalNoteHeader';
import {
  CustomDropdown,
  DEFAULT_DROPDOWN_ELEMENT,
  DropdownElement,
} from '../CustomDropdown';
import { SectionFields } from './SectionFields';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { TranscribeControl } from '../../../components/TranscribeControl/TranscribeControl';

const CenterSectionDiv = styled.div({
  display: 'flex',
  width: '45.379%',
  flexDirection: 'column',
  textAlign: 'center',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  borderTop: '1px solid #D0D7E4',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const CenterSectionTitleContainer = styled.div({
  display: 'flex',
  margin: '20px 20px 20px 28px',
  fontWeight: 'bold',
  maxHeight: '100px',
});

interface CenterSectionProps {
  noteId: string;
  clinicalNote: any;
  isContentVisible: boolean;
  handleHasBeenModified: () => void;
}

export const CenterSection = observer(
  ({
    noteId,
    clinicalNote,
    handleHasBeenModified,
    isContentVisible,
  }: CenterSectionProps) => {
    const currentTemplateId = clinicalNoteStore.templateId;
    const clinicalNoteTemplates = clinicalNoteTemplatesStore.templates;

    const [isTemplateChangeModalVisible, setIsTemplateChangeModalVisible] =
      useState(false);
    const [unsavedTemplate, setUnsavedTemplate] =
      useState<DropdownElement | null>(null);

    const clinicalNoteTemplateDropdownItems = useMemo(() => {
      if (clinicalNoteTemplates.length > 0) {
        return createDropdownInterfacesFromClinicalNoteTemplates(
          clinicalNoteTemplates
        );
      }
      return [];
    }, [clinicalNoteTemplates]);

    const handleTemplateChange = (newTemplate: DropdownElement) => {
      if (newTemplate.value !== currentTemplateId) {
        setUnsavedTemplate(newTemplate);
        setIsTemplateChangeModalVisible(true);
      }
    };

    const isAiEnabled = useFeatureFlag(FEATURE_FLAGS.AI_TRANSCRIPTION, false);

    const setDropdownTemplateFromSavedTemplate = useCallback(
      (newTemplateId: string) => {
        if (clinicalNoteTemplateDropdownItems.length > 0) {
          const templateItem = clinicalNoteTemplateDropdownItems.find(
            (item) => item.value === newTemplateId
          );
          if (templateItem) {
            clinicalNoteStore.setTemplateId(templateItem.value);
            clinicalNoteStore.setTemplateName(templateItem.label);
          }
        }
      },
      [clinicalNoteTemplateDropdownItems]
    );

    useEffect(() => {
      if (clinicalNote && clinicalNote.content.templateId) {
        clinicalNoteStore.setTemplateId(clinicalNote.content.templateId);
        setDropdownTemplateFromSavedTemplate(clinicalNote.content.templateId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinicalNote]);

    useEffect(() => {
      // Sets the templates dropdown list and starting template
      if (clinicalNoteTemplates.length > 0) {
        if (!currentTemplateId) {
          clinicalNoteStore.setTemplateId(
            clinicalNoteTemplateDropdownItems[0].value,
            false
          );
        }
      }
    }, [
      clinicalNoteTemplateDropdownItems,
      clinicalNoteTemplates,
      currentTemplateId,
    ]);

    const onAcceptChangeTemplateModal = useCallback(() => {
      /* istanbul ignore next */
      if (unsavedTemplate !== null) {
        clinicalNoteStore.setTemplateId(unsavedTemplate.value);
        const hashedTemplates = keyBy(clinicalNoteTemplates, 'id');
        const sections = hashedTemplates[unsavedTemplate.value]?.sections;

        if (Array.isArray(sections)) {
          clinicalNoteStore.setFieldsAndDefaultValues(noteId, sections);
        }
      }

      setUnsavedTemplate(null);
      setIsTemplateChangeModalVisible(false);
    }, [clinicalNoteTemplates, noteId, unsavedTemplate]);

    // We're initially not displaying the S2T when moving from external encounter ID
    // TODO: after we fix the flow (e.g. no display: none for the content) we should safely remove this option
    const isS2TVisible = isContentVisible;

    const templateDropdownSelectedValue = useMemo(() => {
      return (
        clinicalNoteTemplateDropdownItems.find(
          (dropdownItem: DropdownElement) =>
            dropdownItem.value === currentTemplateId
        ) || DEFAULT_DROPDOWN_ELEMENT
      );
    }, [clinicalNoteTemplateDropdownItems, currentTemplateId]);

    return (
      <CenterSectionDiv>
        <ChangeTemplateModal
          isVisible={isTemplateChangeModalVisible}
          onAcceptClose={onAcceptChangeTemplateModal}
          onDeclineClose={() => {
            setUnsavedTemplate(null);
            setIsTemplateChangeModalVisible(false);
          }}
        />
        <CenterSectionTitleContainer>
          <CustomDropdown
            dropdownContainerStyles={{
              margin: 0,
              border: '1px solid #D0D7E4',
              borderRadius: 8,
              width: '50%',
              display: 'flex',
              height: '45px',
            }}
            HeaderComponent={({ label, isOpen }) => {
              return (
                <ClinicalNoteHeader
                  label={label}
                  isOpen={isOpen}
                  testId={currentTemplateId}
                />
              );
            }}
            listStyles={{
              backgroundColor: '#FFF',
              color: '#083B60',
              border: '1px solid #D0D7E4',
              borderRadius: 8,
              width: '100%',
              marginTop: '40px',
            }}
            onChange={(newTemplate: DropdownElement) => {
              handleTemplateChange(newTemplate);
            }}
            options={clinicalNoteTemplateDropdownItems}
            selectedDropdownItem={templateDropdownSelectedValue}
          />

          {isS2TVisible ? (
            <>
              {isAiEnabled ? (
                <TranscribeControl />
              ) : (
                <DragonSpeechToText
                  userId={authStore.authInfo?.preferred_username}
                  styles={{ container: { marginLeft: '18px' } }}
                />
              )}
            </>
          ) : null}
        </CenterSectionTitleContainer>
        <SectionFields
          noteId={noteId}
          categoryToDisplay={CategoryVariant.encounter}
          updateCallback={handleHasBeenModified}
          template={clinicalNoteTemplatesStore.getTemplate(
            clinicalNoteStore.templateId
          )}
        />
      </CenterSectionDiv>
    );
  }
);
