import React, { useEffect, ReactNode, CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';

export type ModalProps = {
  /**
   * Content to be rendered inside the modal
   */
  children?: ReactNode;
  /**
   * Specify if the modal should be visible.
   */
  isVisible?: boolean;
  /**
   * a callback to be called when the modal is closed.
   */
  onClose?: () => void;
  /**
   * Configure the spring transition animations.
   */
  transitionConfig?: any;
  /**
   * A CSS style object to be applied to the modal container (BG overlay).
   */
  containerStyles?: CSSProperties;
  /**
   * A CSS style object to be applied to the modal content box.
   */
  contentStyles?: CSSProperties;
};

const ModalContainer = styled(animated.div)`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(12, 30, 44, 0.6);
`;

const ModalContent = styled(animated.div)`
  width: 80%;
  height: 80%;
  border: 1px solid #d8dce3;
  border-radius: 3px;
  background-color: #fdfdfe;
  transition: all 0.3s ease-in-out;
  cursor: default;
`;

/* istanbul ignore next */
export function Modal({
  children,
  isVisible = false,
  /* istanbul ignore next */
  transitionConfig = {},
  /* istanbul ignore next */
  onClose = () => {},
  /* istanbul ignore next */
  containerStyles = {},
  /* istanbul ignore next */
  contentStyles = {},
}: ModalProps) {
  const modalTransitions = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 300,
      ...(transitionConfig?.config || {}),
    },
    ...transitionConfig,
  });

  useEffect(() => {
    if (!isVisible) return () => {};

    function keyListener(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  }, [isVisible, onClose]);

  return modalTransitions((animationValues, showModal) => {
    if (!showModal) {
      return null;
    }

    const componentContent = (
      <ModalContainer
        role='dialog'
        aria-modal='true'
        data-testid='modal-container'
        style={{
          ...containerStyles,
          ...animationValues,
        }}
        onClick={onClose}
      >
        <ModalContent
          data-testid='modal-content'
          style={contentStyles}
          onClick={(event) => event.stopPropagation()}
        >
          {children}
        </ModalContent>
      </ModalContainer>
    );

    return createPortal(componentContent, document.body);
  });
}

Modal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  containerStyles: {},
  contentStyles: {},
  transitionConfig: {},
};
