import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import {
  getTranscriptionData,
  GetTranscriptionDataResponse,
} from '../../../services/graphql/transcriptionData';
import {
  Status,
  transcriptionDataStore,
} from '../../../stores/transcriptionData.store';

export type TranscriptionDataResponse = {
  transcriptionData: {
    statuses: Status[];
  };
};

export const useTranscriptionData = () => {
  const { data, error, loading } =
    useQuery<GetTranscriptionDataResponse>(getTranscriptionData);

  useEffect(() => {
    if (data) {
      transcriptionDataStore.setStatuses(
        data?.transcriptionData?.statuses ?? []
      );
      transcriptionDataStore.setFilterablePractitioner(
        data?.transcriptionData?.filterablePractitioners ?? []
      );
    }
  }, [data]);

  return { error, loading };
};
