import styled from 'styled-components';
import akidoLogo from '../../../assets/images/akido_logo.png';
import { UserMenu } from '../UserMenu';
import { CapellaMyQButton } from '../CapellaMyQButton';
import { CapellaERXIntegration } from '../CapellaERXIntegration';
import { MyTasksLink } from '../MyTasksLink';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { RoutePaths } from '../../../constants/routes';
import { REACT_APP_AKIDO_CHART_URL } from '../../../config/environment';
import { Link } from 'react-router-dom';

const TopHeader = styled.div`
  padding: 15px 0px 15px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 280px;
`;

export function PageHeaderV2(props: { shouldAutoSaveOnLogout: boolean }) {
  const ldClient = useLDClient();
  const myTasksEnabled = ldClient?.variation(
    FEATURE_FLAGS.MY_TASKS_LINKS,
    false
  );

  //set the margin to the original offset if my tasks is disabled
  const myQButtonMargin = myTasksEnabled ? 'mr-2' : 'mr-[90px]';

  const shouldRedirectToAkidoChart = ldClient?.variation(
    FEATURE_FLAGS.REDIRECT_TO_AKIDO_CHART,
    false
  );

  const canViewPatientLink = ldClient?.variation(
    FEATURE_FLAGS.PATIENT_SEARCH_PAGE,
    false
  );

  const redirectHost = shouldRedirectToAkidoChart
    ? REACT_APP_AKIDO_CHART_URL
    : '';

  return (
    <TopHeader>
      <div className='mr-6 md:flex'>
        <img
          className='mr-12 flex items-center space-x-2'
          src={akidoLogo}
          alt='Akido logo'
        />
        <nav className='flex items-center gap-6 text-sm'>
          <Link
            data-testid='schedule-nav'
            to={RoutePaths.Schedule}
            className='font-weight-500 text-gray-500 transition-colors hover:text-gray-700'
          >
            Schedule
          </Link>
          {canViewPatientLink && (
            <a
              data-testid='patients-nav'
              href={`${redirectHost}/patients`}
              className='font-weight-500 text-gray-500 transition-colors hover:text-gray-700'
            >
              Patients
            </a>
          )}
        </nav>
      </div>
      <RightHeader>
        <CapellaERXIntegration />
        <CapellaMyQButton className={myQButtonMargin} />
        <MyTasksLink />
        <UserMenu
          shouldAutoSaveOnLogout={props.shouldAutoSaveOnLogout}
          styles={{ container: { marginRight: '25px' } }}
        />
      </RightHeader>
    </TopHeader>
  );
}
