import { CSSProperties, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { createGlobalStyle } from 'styled-components';
import {
  DRAGON_STATE,
  dragonSettingsStore,
} from '../../stores/dragonSettings.store';
import authStore from '../../stores/auth.store';
import { useDragonSecretsData } from './hooks/useDragonSecrets';

declare global {
  interface Window {
    NUSA_embedSpeechBar: () => void;
    NUSA_configure: () => void;
    NUSA_isConfigured: boolean;
    NUSA_userId: string;
    NUSA_applicationName: string;
    NUSA_initializeVuiForm: () => void;
    NUSA_reinitializeVuiForm: () => void;
    NUSA_closeVuiForm?: () => void;
    NUSA_onRecordingStarted?: () => void;
    NUSA_onRecordingStopped?: () => void;
    NUSA_onProcessingStarted?: () => void;
    NUSA_onProcessingFinished?: (result: any[]) => void;
    NUSA_onProcessingStartedForElement?: (element: any) => void;
    NUSA_onProcessingFinishedForElement?: (element: any) => void;
    NUSA_onCommandRecognized?: (
      cmdId: string,
      spokenPhrase: string,
      content: string,
      placeholderIds: string[],
      placeholderValues: string[]
    ) => void;
    NUSA_getNUSALocation: () => string;
    NUSA_compareVersions: (version1: string, version2: string) => number;
    NUSA_isButtonDeviceSupported: () => boolean;
    NUSA_getBrowserVersion: (browser: string) => string | null;
    NUSA_getIOSVersion: () => string | null;
    NUSA_isHTML5Browser: () => boolean;
    NUSA_useAudioWorklet: () => boolean;
    NUSA_startRecording: () => void;
    NUSA_stopRecording: () => void;
  }
}

createGlobalStyle`
  #NUSAI_CommandBar {
    background-image: block;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

type DragonInitParams = {
  organizationToken: string;
  userId: string;
  partnerGUID: string;
  onInitFinished: () => void;
};

type DragonSpeechToTextProps = {
  userId: string;
  styles?: {
    container?: CSSProperties;
  };
};

const speechToTextScript =
  'https://speechanywhere.nuancehdp.com/Mainline/scripts/Nuance.SpeechAnywhere.js';

function embedSpeechBar() {
  // @ts-ignore
  window.NUSA_embedSpeechBar(document.getElementById('speechBarContainer'));
}

export function initSpeechToTextScript({
  userId,
  organizationToken,
  partnerGUID,
  onInitFinished,
}: DragonInitParams) {
  function NUSA_configure() {
    if (window.NUSA_isConfigured) {
      return;
    }
    document.cookie = `NUSA_Guids=${organizationToken}/${partnerGUID}`;
    window.NUSA_userId = userId;
    window.NUSA_applicationName = 'dragon_rn_poc';
    embedSpeechBar();

    window.NUSA_isConfigured = true;

    onInitFinished();
  }

  window.NUSA_configure = NUSA_configure;

  const script = document.createElement('script');

  script.type = 'text/javascript';

  script.src = speechToTextScript;

  document.getElementsByTagName('head')[0].appendChild(script);
}

export function showSpeechToText() {
  if (typeof window?.NUSA_initializeVuiForm === 'function') {
    embedSpeechBar();
    window.NUSA_initializeVuiForm();
  } else {
    console.error('window.NUSA_initializeVuiForm has not been initialized');
  }
}

export function reInitSpeechToText() {
  if (typeof window?.NUSA_reinitializeVuiForm === 'function') {
    window.NUSA_reinitializeVuiForm();
  } else {
    console.error('window.NUSA_reinitializeVuiForm has not been initialized');
  }
}

export function removeSpeechToTextUI() {
  if (typeof window?.NUSA_closeVuiForm === 'function') {
    window.NUSA_closeVuiForm();
  } else {
    console.error('window.NUSA_closeVuiForm has not been initialized');
  }
}

export const DragonSpeechToText = observer(
  ({ userId, styles }: DragonSpeechToTextProps) => {
    const { isLoading: isSecretsListLoading } = useDragonSecretsData();

    const { organizationToken, partnerGUID } =
      dragonSettingsStore.dragonSecrets;
    const { state } = dragonSettingsStore;
    const isAllowedToUseDragon = authStore.authorizations?.canUseSpeechToText;

    useEffect(() => {
      const isInitRequired =
        state === DRAGON_STATE.NOT_INITIALIZED &&
        isAllowedToUseDragon &&
        organizationToken;

      if (isInitRequired) {
        initSpeechToTextScript({
          organizationToken,
          partnerGUID,
          userId,
          onInitFinished: () => {
            dragonSettingsStore.setActive();
          },
        });
      }
    }, [organizationToken, userId, partnerGUID, state, isAllowedToUseDragon]);

    useEffect(() => {
      window.NUSA_onProcessingStartedForElement = (element: HTMLElement) => {
        element?.dispatchEvent?.(new Event('input'));
      };

      window.NUSA_onProcessingFinishedForElement = (element: HTMLElement) => {
        element?.dispatchEvent?.(new Event('input'));
      };

      return () => {
        window.NUSA_onProcessingStartedForElement = undefined;
      };
    }, []);

    useEffect(() => {
      if (state === DRAGON_STATE.INACTIVE && isAllowedToUseDragon) {
        showSpeechToText();
        dragonSettingsStore.setActive();
      }
    }, [state, isAllowedToUseDragon, isSecretsListLoading]);

    useEffect(() => {
      return function cleanup() {
        if (state === DRAGON_STATE.ACTIVE) {
          removeSpeechToTextUI();
          dragonSettingsStore.setInactive();
        }
      };
    }, [state]);

    if (isSecretsListLoading || !isAllowedToUseDragon) return null;

    return (
      <Container style={styles?.container}>
        <div id='speechBarContainer' data-testid='speechBarContainer' />
      </Container>
    );
  }
);
