export const {
  REACT_APP_PROVIDER_DESKTOP_BFFE_HOST,
  REACT_APP_LD_CLIENT_ID,
  REACT_APP_SESSION_TIMEOUT_MINUTES,
  REACT_APP_FIREBASE_CONFIG,
  REACT_APP_ENV,
  REACT_APP_SENTRY_DSN,
  REACT_APP_VERSION,
  REACT_APP_SCHEDULE_POLLING_PERIOD_MINUTES,
  REACT_APP_PROVIDER_DESKTOP_BFFE_SUBSCRIPTION_URL,
  REACT_APP_TRANSCRIPTION_WS_URL,
  REACT_APP_LOGROCKET_APP_ID,
  REACT_APP_AKIDO_CHART_URL,
  REACT_APP_DD_RUM_APPLICATION_ID,
  REACT_APP_DD_RUM_CLIENT_TOKEN,
  REACT_APP_DD_RUM_SERVICE,
} = process.env;
