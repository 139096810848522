import React, { FC, forwardRef } from 'react';
import styled from 'styled-components';
import authStore from '../../stores/auth.store';
import { SignOut } from './SignOut';
import { UserName } from '../UserName/UserName';

interface MenuProps {
  visible?: boolean;
  shouldAutoSaveOnLogout?: boolean;
  ref?: React.ForwardedRef<HTMLDivElement>;
  closeMenu: () => void;
}

const Container = styled.div`
  position: absolute;
  right: 50%;
  background-color: white;
  border-radius: 10px;
  z-index: 10;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 6px 8px;
  border: solid 1px #d0d7e4;
  min-width: 185px;
`;

const Specialty = styled.span`
  font-size: 0.75rem;
  color: #5f7584;
  margin: 2px 0 0 0;
`;

const Divider = styled.div`
  background-color: #f1f1f1;
  width: calc(100% + 16px);
  margin-left: -8px;
  height: 1px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 12px 8px;
`;

export const Menu: FC<MenuProps> = forwardRef<HTMLDivElement, MenuProps>(
  (props, ref) => {
    const { visible, shouldAutoSaveOnLogout, closeMenu } = props;
    const specialty = authStore?.authInfo?.specialty ?? '';

    if (!visible) {
      return null;
    }

    return (
      <Container ref={ref}>
        <UserInfo>
          <UserName />
          <Specialty>{specialty}</Specialty>
        </UserInfo>
        <Divider />
        <SignOut
          shouldAutoSaveOnLogout={shouldAutoSaveOnLogout}
          closeMenu={closeMenu}
        />
      </Container>
    );
  }
);
