import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from 'styled-components';
import { editScribeNoteModalStore } from '../../../stores/editScribeNoteModal.store';
import { ModalCloseButton } from '../../../components/Modal/ModalCloseButton';
import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';

interface EditScribeNoteBackButtonProps {
  onClick?: () => void;
}

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 19px;
`;

export const EditScribeNoteBackButton: FC<EditScribeNoteBackButtonProps> =
  observer((props) => {
    const { onClick } = props;

    if (editScribeNoteModalStore.isEditMode) {
      return (
        <Button
          onClick={() => {
            editScribeNoteModalStore.cancelEdit();
          }}
        >
          <BackArrow
            style={{ paddingTop: 4 }}
            height={12}
            width={12}
            fill='#1B4C73'
          />
        </Button>
      );
    }

    return <ModalCloseButton onClick={onClick} />;
  });
