import { useEffect, useRef, useState } from 'react';
import { REACT_APP_TRANSCRIPTION_WS_URL } from '../../../config/environment';
import { getFrequencyData } from './getAudioData';

export const useMicrophone = (
  recordingId?: string,
  url = REACT_APP_TRANSCRIPTION_WS_URL,
  chunkLength = 5000
) => {
  // Refs
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioStream = useRef<MediaStream | null>(null);
  const audioAnalyser = useRef<AnalyserNode | null>();
  const ws = useRef<WebSocket>();
  // States
  const [mediaRecorderState, setMediaRecorderState] =
    useState<RecordingState>();
  const [hasStopped, setHasStopped] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isWSReady, setWSReady] = useState<boolean>(false);

  const sendMessage = (event: string, data: any) => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ event, data }));
    }
  };

  const stop = () => {
    if (mediaRecorder.current?.state === 'recording') {
      mediaRecorder.current.stop();

      audioStream.current?.getTracks().forEach((track) => track.stop());
      setMediaRecorderState(mediaRecorder.current.state);
    }
  };

  useEffect(() => {
    if (ws.current && ws.current.readyState !== WebSocket.CLOSED) {
      return;
    }

    if (!url) {
      console.error('Transcriptions URL is blank');
      return;
    }

    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      setWSReady(true);
    };

    ws.current.onclose = () => {
      setWSReady(false);
      setHasStopped(true);
    };

    ws.current.onerror = (e) => {
      console.error(e);
      setWSReady(false);
      setHasStopped(true);
    };
  }, [url]);

  const getMicrophonePermission = async () => {
    if (mediaRecorder.current && !hasStopped) return;

    try {
      audioStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      // Create audio context for analysing volume
      const context = new AudioContext();

      audioAnalyser.current = context.createAnalyser();
      audioAnalyser.current.fftSize = 2048;

      const source = context.createMediaStreamSource(audioStream.current);
      source.connect(audioAnalyser.current);

      // Create MediaRecorder for capturing audio from microphone
      mediaRecorder.current = new MediaRecorder(audioStream.current, {
        mimeType: 'audio/webm;codecs=opus',
        audioBitsPerSecond: 16000,
      });

      mediaRecorder.current?.addEventListener('dataavailable', async (e) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.data);
        reader.onloadend = function () {
          sendMessage('transcribe-audio', {
            contents: reader.result,
            id: recordingId,
            requestId: e.timecode,
          });
        };
      });

      mediaRecorder.current?.addEventListener('stop', () => {
        setHasStopped(true);
      });
      mediaRecorder.current.start(chunkLength);
    } catch (err: unknown) {
      setError((err as DOMException).message);
    } finally {
      // Need to reset this state in order to allow the recorder to be recreated
      // when hitting start again
      setHasStopped(false);
      setMediaRecorderState(mediaRecorder.current?.state);
    }
  };

  return {
    error,
    start: getMicrophonePermission,
    stop,
    state: mediaRecorderState,
    ready: isWSReady,
    getFrequencyData: () => getFrequencyData(audioAnalyser.current),
    ws: ws.current,
    mediaRecorder: mediaRecorder.current,
  };
};
