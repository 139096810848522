import { useCallback } from 'react';
import {
  AINoteEditedProperties,
  ClinicalNoteAnalyticsProperties,
  EncounterAnalyticsProperties,
  GenerateSummaryProperties,
  ScreenViewProperties,
} from '../types/analyticsTypes';
import {
  ClinicalAINoteAnalyticsEventName,
  ClinicalNoteAnalyticsEventName,
  EncounterAnalyticsEventName,
} from '../constants/analytics';
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { TranscriptionStatus } from '../types/transcription-statuses';
import { clinicalNoteStore } from '../stores/clinicalNote.store';
import { isEmpty } from 'lodash';

export const useAnalytics = () => {
  const analytics = getAnalytics();

  const setUserPropertiesOnFirebase = useCallback(
    async ({
      userId,
      specialty,
      role,
    }: {
      userId?: string;
      specialty?: string;
      role?: string;
    }) => {
      setUserId(analytics, userId ?? 'UNKNOWN');
      setUserProperties(analytics, {
        specialty: specialty ?? 'UNKNOWN',
        role: role ?? 'UNKNOWN',
        akido_uid: userId ?? 'UNKNOWN',
      });
    },
    [analytics]
  );

  const sendEncounterAnalyticsEvent = useCallback(
    (
      eventName: EncounterAnalyticsEventName,
      properties?: EncounterAnalyticsProperties
    ) => {
      logEvent(analytics, eventName, {
        ...properties,
        date_time_stamp: new Date().toISOString(),
      });
    },
    [analytics]
  );

  const encounterId = clinicalNoteStore.clinicalNote?.encounterId;
  const akidoNoteId = clinicalNoteStore.clinicalNote?.id;

  const sendAINoteEditedEvent = useCallback(() => {
    const properties: AINoteEditedProperties = {
      akido_eid: akidoNoteId,
      capella_eid: encounterId,
      capella_note_id: encounterId,
      date_time_stamp: new Date().toISOString(),
    };

    logEvent(
      analytics,
      ClinicalAINoteAnalyticsEventName.AINoteEdited,
      properties
    );
  }, [analytics, encounterId, akidoNoteId]);

  const noteTemplateName = clinicalNoteStore.templateName;
  const sendClinicalNoteAnalyticsEvent = useCallback(
    (
      eventName: ClinicalNoteAnalyticsEventName,
      properties?: ClinicalNoteAnalyticsProperties
    ) => {
      const clinicalNoteAnalyticsProperties: ClinicalNoteAnalyticsProperties = {
        ...properties,
        date_time_stamp: new Date().toISOString(),
        capella_eid: encounterId,
      };

      if (noteTemplateName && !isEmpty(noteTemplateName)) {
        clinicalNoteAnalyticsProperties.note_template = noteTemplateName;
      }

      if (clinicalNoteStore.aiSummaryEdited) {
        sendAINoteEditedEvent();
      }

      logEvent(analytics, eventName, clinicalNoteAnalyticsProperties);
    },
    [analytics, noteTemplateName, sendAINoteEditedEvent, encounterId]
  );

  const sendScreenEvent = useCallback(
    (properties?: ScreenViewProperties) => {
      logEvent(analytics, 'screen_view', properties);
    },
    [analytics]
  );

  const sendNoteStatusUpdateEvent = useCallback(
    (newStatus: string, transcriptionId: string) => {
      switch (newStatus) {
        case TranscriptionStatus.COMPLETED:
          sendEncounterAnalyticsEvent(
            EncounterAnalyticsEventName.NoteStatusCompleted,
            {
              transcription_id: transcriptionId,
            }
          );
          break;
        case TranscriptionStatus.IN_PROGRESS:
          sendEncounterAnalyticsEvent(
            EncounterAnalyticsEventName.NoteStatusInProgress,
            {
              transcription_id: transcriptionId,
            }
          );
          break;
      }
    },
    [sendEncounterAnalyticsEvent]
  );

  const sendGenerateSummaryEvent = useCallback(
    (properties: GenerateSummaryProperties) => {
      logEvent(analytics, ClinicalAINoteAnalyticsEventName.GenerateSummary, {
        ...properties,
        date_time_stamp: new Date().toISOString(),
      });
    },
    [analytics]
  );

  return {
    setUserPropertiesOnFirebase,
    sendEncounterAnalyticsEvent,
    sendClinicalNoteAnalyticsEvent,
    sendNoteStatusUpdateEvent,
    sendScreenEvent,
    sendGenerateSummaryEvent,
    sendAINoteEditedEvent,
  };
};
