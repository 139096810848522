export class ZendeskHelpers {
  static applyZendeskChanges = (...args: any) => {
    if (typeof window?.zE?.apply === 'function') {
      window.zE.apply(null, args);
    } else {
      console.warn('Zendesk is not initialized yet');
    }
  };

  static waitForElement = (documentToCheck: Document, selector: string) => {
    return new Promise((resolve) => {
      if (documentToCheck.querySelector(selector)) {
        return resolve(documentToCheck.querySelector(selector));
      }

      const observer = new MutationObserver((_mutations) => {
        if (documentToCheck.querySelector(selector)) {
          resolve(documentToCheck.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(documentToCheck.body, {
        childList: true,
        subtree: true,
      });

      setTimeout(resolve, 500);
    });
  };

  static async moveZendeskToHeaderPosition(zendeskDocument: Document | null) {
    if (zendeskDocument) {
      const button = (await ZendeskHelpers.waitForElement(
        zendeskDocument,
        '[data-testid="launcher"]'
      )) as HTMLElement;
      const buttonLabel = (await ZendeskHelpers.waitForElement(
        zendeskDocument,
        '[data-testid="launcher-label"]'
      )) as HTMLElement;
      button.style.width = '80';
      button.style.maxWidth = '80';
      buttonLabel.style.font = 'Inter';
      buttonLabel.style.fontSize = '.775rem';
    }
  }

  static async resetZendeskWidgetButton(zendeskDocument: Document | null) {
    if (zendeskDocument) {
      const button = (await ZendeskHelpers.waitForElement(
        zendeskDocument,
        '[data-testid="launcher"]'
      )) as HTMLElement;
      const buttonLabel = (await ZendeskHelpers.waitForElement(
        zendeskDocument,
        '[data-testid="launcher-label"]'
      )) as HTMLElement;
      button.style.width = '108.35';
      button.style.maxWidth = '100%';
      buttonLabel.style.font = 'Inter';
      buttonLabel.style.fontSize = '1rem';
    }
  }
}
