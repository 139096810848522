import * as Sentry from '@sentry/react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { getUserInfo, oktaAuth } from '../../config/okta';
import { RoutePaths } from '../../constants/routes';
import { LoadingSpinner } from '../AkidoUI/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { REACT_APP_ENV } from '../../config/environment';
import { initLogRocket } from '../../services/logrocket/initLogRocket';
import { shouldRecordSession } from '../../services/logrocket/helper';

export function Authenticated({ children }: { children: ReactElement }) {
  const location = useLocation();
  const { setUserPropertiesOnFirebase } = useAnalytics();
  const ldClient = useLDClient();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const ssoAuthentication = useCallback(async () => {
    const isOktaAuthenticated = await oktaAuth.isAuthenticated();

    setIsAuthenticated(isOktaAuthenticated);
    if (!isOktaAuthenticated && location.pathname !== RoutePaths.Login) {
      oktaAuth.signInWithRedirect({
        originalUri: window.location.href,
      });
    }

    const { specialty, userId, role, groups, email, givenName, familyName } =
      await getUserInfo();

    setUserPropertiesOnFirebase({
      userId: userId,
      specialty: specialty,
      role: role,
    });

    Sentry.addBreadcrumb({
      category: 'auth',
      message: `Authenticated user ${email}`,
      level: 'info',
    });

    Sentry.setUser({ email });

    if (ldClient?.identify) {
      // The result of this promise is all the feature flags, which we don't need here
      void ldClient.identify({
        kind: 'user',
        key: userId,
        email,
        groups,
      });
    }

    if (userId && email) {
      if (shouldRecordSession(email)) {
        initLogRocket();

        LogRocket.identify(userId, {
          name: `${givenName} ${familyName} (${REACT_APP_ENV})`,
          email: email,
        });
      }
    }
  }, [ldClient, location.pathname, setUserPropertiesOnFirebase]);

  useEffect(() => {
    ssoAuthentication();
  }, [ssoAuthentication]);

  if (!isAuthenticated) {
    return <LoadingSpinner />;
  }

  return children;
}
