import { gql } from '@apollo/client';

export const PatientVitalsDisplayLabels = gql`
  query PatientVitalsDisplayLabels(
    $externalId: String
    $encounterIdentifier: AkidoIdentifierArg
  ) {
    getFhirEncounter(
      externalId: $externalId
      encounterIdentifier: $encounterIdentifier
    ) {
      vitals {
        displayLabels
      }
    }
  }
`;
