import { useMemo } from 'react';
import { ClinicalNoteFieldValue } from '../../types/clinical-notes';
import {
  ClinicalNoteFieldTitleTextStyle,
  ClinicalNotePreviewField,
  ClinicalNotePreviewFieldTitleLeftStyles,
} from './ClinicalNotePreviewPage/ClinicalNotePreviewField';
import {
  cleanUpAutofilledData,
  sectionHasData,
} from '../../utilities/autofillHelper';

interface IProps {
  clinicalNoteSections: ClinicalNoteFieldValue[];
  leftSideDisplay?: boolean;
  showTitle?: boolean;
}

export function ClinicalNotePreviewFieldArray({
  clinicalNoteSections,
  leftSideDisplay = false,
  showTitle = true,
}: IProps) {
  const FieldArray = useMemo(() => {
    const sectionComponents = [];

    for (const sectionId in clinicalNoteSections) {
      const section = clinicalNoteSections[sectionId];
      const sectionTitle = section.title;
      const sectionText = section.text;
      const sectionAutofill = cleanUpAutofilledData(section.autofillData);

      if (sectionTitle && sectionHasData(section)) {
        let titleTextStyle: any = { ...ClinicalNoteFieldTitleTextStyle };
        if (leftSideDisplay) {
          titleTextStyle = {
            ...titleTextStyle,
            ...ClinicalNotePreviewFieldTitleLeftStyles,
          };
        }
        sectionComponents.push(
          <ClinicalNotePreviewField
            key={sectionId}
            title={showTitle ? sectionTitle : ''}
            fieldValue={sectionText}
            fieldAutofillValue={sectionAutofill}
            titleTextStyle={titleTextStyle}
            shouldShowDivider={false}
          />
        );
      }
    }

    return sectionComponents;
  }, [clinicalNoteSections, leftSideDisplay, showTitle]);
  return <>{FieldArray}</>;
}
