import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { clinicalNoteTemplatesStore } from '../stores/clinicalNoteTemplates.store';
import { QueryClinicalNoteTemplatesArgs } from '@akido/provider-desktop-bffe-types';
import {
  ClinicalNoteTemplates,
  ClinicalNoteTemplatesGraphQlReturnObject,
} from '../services/graphql/clinicalNoteTemplates';

export const useFetchClinicalNoteTemplate = (
  options?: QueryClinicalNoteTemplatesArgs
) => {
  const [loadTemplateInformation, { error: fetchTemplatesError }] =
    useLazyQuery<ClinicalNoteTemplatesGraphQlReturnObject>(
      ClinicalNoteTemplates
    );

  useEffect(() => {
    clinicalNoteTemplatesStore.clearTemplateErrors();
    if (fetchTemplatesError !== undefined) {
      clinicalNoteTemplatesStore.setTemplateErrors(fetchTemplatesError);
    }
  }, [fetchTemplatesError]);

  const fetchClinicalNoteTemplates = useCallback(async () => {
    clinicalNoteTemplatesStore.startLoadingTemplates();

    const result = await loadTemplateInformation({
      variables: {
        screen: options?.screen,
        resourceType: options?.resourceType,
      },
    });
    const templates = result.data?.clinicalNoteTemplates.templates;

    clinicalNoteTemplatesStore.setTemplates(templates);
  }, [loadTemplateInformation, options?.screen, options?.resourceType]);

  useEffect(() => {
    fetchClinicalNoteTemplates();
  }, [fetchClinicalNoteTemplates]);
};
