import {
  LanguageCode,
  StartMedicalStreamTranscriptionCommand,
  StartMedicalStreamTranscriptionCommandInput,
  TranscribeStreamingClient,
} from '@aws-sdk/client-transcribe-streaming';
import { AWSCredentials, MessageDataType, RecordingProperties } from '../types';
import pEvent from 'p-event';
import { pcmEncode } from './pcm-encode';
import { joinWords } from './join-words';

export interface StartStreamingArgs {
  handleTranscribeOutput: (
    data: string,
    partial: boolean,
    transcriptionClient: TranscribeStreamingClient,
    mediaRecorder: AudioWorkletNode
  ) => void;
  currentCredentials: AWSCredentials;
  sampleRateHz: number;
  language: LanguageCode;
  specialty?: StartMedicalStreamTranscriptionCommandInput['Specialty'];
  type?: StartMedicalStreamTranscriptionCommandInput['Type'];
}

const findAndFocusTranscribeElement = () => {
  // This method is intended to set the first focusable element capable of receiving
  // output from the transcription service.

  if (window.AkidoTranscribe?.focusedElement) return;
  const elements = document.querySelectorAll(
    '[data-akido-transcribe-input="true"]'
  );

  if (elements.length === 0) return;
  const element = elements[0] as HTMLElement;
  element.focus();

  window.AkidoTranscribe = {
    focusedElement: element,
  };
};

export const startStreaming = async (args: StartStreamingArgs) => {
  const {
    handleTranscribeOutput,
    currentCredentials,
    sampleRateHz,
    language,
    specialty = 'PRIMARYCARE',
    type = 'CONVERSATION',
  } = args;
  const audioContext = new window.AudioContext();

  // This is what causes the annoying "Allow microphone access" prompts to pop up
  const stream: MediaStream = await window.navigator.mediaDevices.getUserMedia({
    video: false,
    audio: true,
  });

  const microphoneStream = audioContext.createMediaStreamSource(stream);

  const recordingProps: RecordingProperties = {
    numberOfChannels: 1,
    sampleRate: audioContext.sampleRate,
    maxFrameCount: audioContext.sampleRate * 0.1,
  };

  try {
    // Here we're registering the recording-process.js file to be run by the audio worklet.
    // Without this, we would not be doing any recording or transcription
    await audioContext.audioWorklet.addModule(
      '/worklets/recording-processor.js'
    );
  } catch (error) {
    console.log(`Add module error ${error}`);
  }

  // This is the audio worklet node that will be used to record the audio, it is referencing the name
  // we gave when we registered the recording-process.js file--check the last line of recording-processor.js to
  // see the registration call
  const mediaRecorder = new AudioWorkletNode(
    audioContext,
    'recording-processor',
    {
      processorOptions: recordingProps,
    }
  );

  mediaRecorder.port.postMessage({
    message: 'UPDATE_RECORDING_STATE',
    setRecording: true,
  });

  microphoneStream.connect(mediaRecorder);
  mediaRecorder.port.onmessageerror = (error) => {
    console.log(`Error receiving message from worklet ${error}`);
  };

  // p-event here is converting the message events from the audio worklet into
  // an AsyncIterable. This is what we can consume to get the audio data and
  // send it to the transcription service --- which requires an AsyncIterator.
  // This could be rolled by hand if we need to get this dependency out of here.
  const audioDataIterator = pEvent.iterator<
    'message',
    MessageEvent<MessageDataType>
  >(mediaRecorder.port, 'message');

  // We're using an async generator to send the audio data to the transcription service
  // after encoding it as 16 bit PCM and storing it in an unsigned 8 bit array. The data
  // will be stored in a lossless way in this case, with each 16 bit int taking up two
  // spots in the array using little endian format (set in the pcmEncode function). Amazon
  // Transcribe is set up to handle this format of audio data.
  const getAudioStream = async function* () {
    for await (const chunk of audioDataIterator) {
      if (chunk.data.message === 'SHARE_RECORDING_BUFFER') {
        const pcmEncodedBuffer = pcmEncode(chunk.data.buffer[0]);
        const audioData = new Uint8Array(pcmEncodedBuffer);

        yield {
          AudioEvent: {
            AudioChunk: audioData,
          },
        };
      }
    }
  };

  const transcribeClient = new TranscribeStreamingClient({
    region: 'us-west-2',
    credentials: currentCredentials,
  });

  const command = new StartMedicalStreamTranscriptionCommand({
    LanguageCode: language,
    MediaEncoding: 'pcm',
    Specialty: specialty,
    Type: type,
    MediaSampleRateHertz: sampleRateHz,
    AudioStream: getAudioStream(),
  });

  const data = await transcribeClient.send(command);

  findAndFocusTranscribeElement();
  console.log('Transcribe session established ', data.SessionId);

  if (data.TranscriptResultStream) {
    for await (const event of data.TranscriptResultStream) {
      for (const result of event?.TranscriptEvent?.Transcript?.Results ?? []) {
        const words = result?.Alternatives?.[0]?.Items ?? [];

        const sentence = joinWords(words);

        if (sentence) {
          handleTranscribeOutput(
            sentence,
            result.IsPartial || false,
            transcribeClient,
            mediaRecorder
          );
        }
      }
    }
  }
};
