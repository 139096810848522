import styled from 'styled-components';
import { FC } from 'react';
import { LoadingSpinner } from '../../../components';
import { TestID } from '../../../constants/testIds';

const refreshIcon = require('../../../assets/icons/reloadIcon.svg').default;

interface AINoteRefreshButtonProps {
  isLoading: boolean;
  onRefreshClicked: () => void;
}

const ReloadContainer = styled.img`
  overflow: hidden;
`;

const LoadingContainer = styled.div`
  display: flex;
  background-color: #0078d8;
  padding: 4px;
  border-radius: 50%;
`;

const RefreshActionButton = styled.button`
  display: flex;
  background: none;
  border: none;
  align-items: center;
`;

export const AINoteRefreshButton: FC<AINoteRefreshButtonProps> = ({
  isLoading,
  onRefreshClicked,
}) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner
          testId={
            TestID.AIClinicalNote.AIClinicalNoteHeaderButtons
              .RefreshButtonLoading
          }
          containerStyles={{ display: 'flex', alignItems: 'center' }}
          size='xsmall'
          type='line'
        />
      </LoadingContainer>
    );
  }

  return (
    <RefreshActionButton
      onClick={onRefreshClicked}
      data-testid={
        TestID.AIClinicalNote.AIClinicalNoteHeaderButtons.RefreshButtonIdle
      }
    >
      <ReloadContainer src={refreshIcon} alt='refresh button' />
    </RefreshActionButton>
  );
};
