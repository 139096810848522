import { gql } from '@apollo/client';
import { AkidoIdentifier } from '@akido/provider-desktop-bffe-types';

export type GetPatientParams = {
  patientId: AkidoIdentifier;
  externalId: string;
  externalEncounterId: string; // TODO: are externalId and externalEncounterId the same thing? this needs to be refactored
};

export const PatientWithClinicalSections = gql`
  query PatientWithClinicalSections(
    $patientId: AkidoIdentifierArg!
    $externalId: String
    $externalEncounterId: String!
  ) {
    patient(patientId: $patientId) {
      pastMedicalHistory {
        list {
          displayLabel
        }
      }
      pastSurgicalHistory {
        list {
          displayLabel
        }
      }
      socialHistory {
        list {
          displayLabel
        }
      }
      familyHistory {
        list {
          displayLabel
        }
      }
      allergies {
        drugAllergies {
          displayLabel
        }
        nonDrugAllergies {
          displayLabel
        }
      }
      immunizations {
        list {
          displayLabel
        }
      }
      prescriptions(externalEncounterId: $externalEncounterId) {
        list {
          displayLabel
        }
      }
      medications(externalEncounterId: $externalEncounterId) {
        list {
          displayLabel
          endDate
          startDate
        }
      }
    }
    getFhirEncounter(externalId: $externalId) {
      assessments {
        list {
          displayLabel
        }
      }
      vitals {
        displayLabels
      }
      billing {
        displayLabels
      }
      orders {
        list {
          displayLabel
        }
      }
    }
  }
`;
