import { observer } from 'mobx-react-lite';
import { ClinicalNoteFieldValue } from '../../../types/clinical-notes';
import ClinicalNotesAutoFillField from '../ClinicalNotesAutoFillFields';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import React, { useEffect } from 'react';
import { usePatientPrescriptions } from '../hooks/usePatientPrescriptions';
import { patientStore } from '../../../stores/patient.store';
import { localClinicalNotesStore } from '../../../stores/localPersistentClinicalNotes.store';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';

interface IProps {
  noteId: string;
  sectionId: string;
  uiSection?: string;
  field: ClinicalNoteFieldValue;
  updateCallback: (newValue: string) => void;
  localStoreEnabled: boolean;
}

export const PrescriptionsField: React.FC<IProps> = observer(
  ({
    noteId,
    uiSection,
    sectionId,
    field,
    updateCallback,
    localStoreEnabled,
  }) => {
    const fullPageRefresh = clinicalNoteStore.refreshing;
    const patientId = patientStore.id;
    let encounterId = clinicalNoteStore.clinicalNote?.encounterId;
    const resourceIdentifier =
      clinicalNoteStore.clinicalNote?.encounter?.encounterIdentifier;
    const prescriptionItems =
      clinicalNoteRefreshableAutofillStore.prescriptions?.items;

    const localNoteSection = localStoreEnabled
      ? localClinicalNotesStore.getSectionByIds(noteId, sectionId)
      : null;

    if (
      !encounterId &&
      resourceIdentifier &&
      resourceIdentifier.type === 'capella_encounter_id'
    ) {
      encounterId = resourceIdentifier.value;
    }

    const { fetchPrescriptions, isLoading } = usePatientPrescriptions(
      patientId,
      encounterId
    );

    useEffect(() => {
      if (fullPageRefresh) {
        fetchPrescriptions();
      }
    }, [fullPageRefresh, fetchPrescriptions]);

    return (
      <ClinicalNotesAutoFillField
        id={sectionId}
        key={`field_${sectionId}`}
        title={field.title}
        uiSection={uiSection ?? sectionId}
        showTopAction
        autofillData={prescriptionItems}
        value={localNoteSection?.text || field.text}
        onChange={(newValue: string) => {
          clinicalNoteStore.setSectionValue(
            sectionId,
            newValue,
            field.sortOrder,
            field.type,
            field.uiSection
          );
          updateCallback(newValue);
        }}
        refreshCallback={fetchPrescriptions}
        isRefreshLoading={fullPageRefresh || isLoading}
      />
    );
  }
);
