import React from 'react';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronIcon } from '../Icon';
import { useSelectContext } from './Select';

type SelectButtonProps = {
  'data-testid'?: string;
  children: React.ReactNode | (({ value }: { value: any }) => React.ReactNode);
};

export function SelectButton(props: SelectButtonProps) {
  const { 'data-testid': dataTestId, children } = props;
  const { placeholder, error, isLoading } = useSelectContext();

  return (
    <Listbox.Button
      data-testid={dataTestId}
      className={clsx(
        'relative w-full cursor-default rounded-lg border border-gray-300 bg-white py-2.5 pl-3 text-left focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-purple-200 disabled:bg-gray-50 disabled:text-gray-500',
        {
          'border-red-500 focus:border-red-500 focus:ring-red-200':
            Boolean(error),
        }
      )}
    >
      {/* This value render prop comes from the Listbox context */}
      {({ value }) => (
        <>
          <span className='block truncate'>
            {/* Render the place holder if nothing is selected */}
            {value ? (
              // If children is being passes as a function, we want to call it with the currently selected value
              // otherwise we can just render children as is */}
              typeof children === 'function' ? (
                children({ value })
              ) : (
                children
              )
            ) : (
              <span className='text-gray-500'>
                {isLoading ? 'Loading...' : placeholder}
              </span>
            )}
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
            <ChevronIcon className='text-gray-500' />
          </span>
        </>
      )}
    </Listbox.Button>
  );
}
