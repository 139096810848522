import { action, computed, makeObservable, observable } from 'mobx';
import { ScribeNoteModalProps } from '../types/transcriptions';

export class ScribeNoteModalStore {
  state = 'hidden';
  noteProps: ScribeNoteModalProps | null = null;
  refreshData: () => Promise<void> | void = () => {};

  constructor() {
    makeObservable(this, {
      state: observable,
      noteProps: observable,
      refreshData: observable,
      showModal: action,
      hideModal: action,
      isVisible: computed,
      updateNoteText: action,
      transcriptionId: computed,
      encounterId: computed,
      noteValue: computed,
      setNoteProps: action,
      onModalClose: action,
    });
  }

  showModal() {
    this.state = 'visible';
  }

  hideModal() {
    this.state = 'hidden';
  }

  async onModalClose() {
    this.hideModal();
    await this.refreshData();
  }

  get isVisible() {
    return this.state === 'visible';
  }

  get transcriptionId() {
    return this.noteProps?.transcriptionId;
  }

  get encounterId() {
    return this.noteProps?.encounterId;
  }

  get noteValue() {
    return this.noteProps?.note;
  }

  updateNoteText(text: string) {
    if (this.noteProps) {
      this.noteProps.note = text;
    }
  }

  setNoteProps(updatedNoteProps: ScribeNoteModalProps) {
    this.noteProps = updatedNoteProps;
  }
}

export const scribeNoteModalStore = new ScribeNoteModalStore();
