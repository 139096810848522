import { useApolloClient } from '@apollo/client';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useSaveClinicalNote } from '../../routes/ClinicalNotesEditor/hooks/useSaveClinicalNote';
import { useUpdateClinicalNote } from '../../routes/ClinicalNotesEditor/hooks/useUpdateClinicalNote';
import authStore from '../../stores/auth.store';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';
import { localClinicalNotesStore } from '../../stores/localPersistentClinicalNotes.store';
import { logout } from '../../utilities/auth';

interface SignOutProps {
  shouldAutoSaveOnLogout?: boolean;
  closeMenu: () => void;
}

const SignOutButton = styled.button`
  margin: 16px 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #0078d8;
  font-weight: 600;
  margin: 16px 0 16px 12px;
`;

export const SignOut: FC<SignOutProps> = ({
  shouldAutoSaveOnLogout = false,
  closeMenu,
}) => {
  const apolloClient = useApolloClient();

  const { saveClinicalNote } = useSaveClinicalNote({
    externalEncounterId: clinicalNoteStore.externalId,
    clinicalNoteTitle: clinicalNoteStore.editableNoteTitle,
    templateId: clinicalNoteStore.templateId,
  });

  const { updateClinicalNote } = useUpdateClinicalNote({
    clinicalNoteId: clinicalNoteStore.clinicalNote?.id,
  });

  const saveNote = useCallback(async () => {
    let result;
    clinicalNoteStore.setSavingExternally(true);

    if (!clinicalNoteStore.clinicalNote?.id) {
      result = await saveClinicalNote();
    } else {
      result = await updateClinicalNote();
    }

    clinicalNoteStore.setSavingExternally(false);
    return result;
  }, [updateClinicalNote, saveClinicalNote]);

  const handleLogout = async () => {
    closeMenu();
    if (shouldAutoSaveOnLogout) {
      await saveNote();
    }
    localClinicalNotesStore.reset();
    await logout(authStore, apolloClient);
  };

  return <SignOutButton onClick={handleLogout}>Sign Out</SignOutButton>;
};
