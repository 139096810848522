import { dragonSettingsStore } from '../../stores/dragonSettings.store';
import { DragonSpeechToText } from '../SpeechToText/DragonSpeechToText';
import authStore from '../../stores/auth.store';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FEATURE_FLAGS } from '../../config/launchdarkly';
import { TestID } from '../../constants/testIds';
import { Microphone } from '../AkidoUI/Icon/Microphone';
import {
  AWSCredentials,
  useLiveTranscriptions,
} from '../AkidoUI/LiveTranscriptions';
import {
  AmazonTranscribeCreds,
  getAmazonTranscribeCreds,
} from '../../services/graphql/credentials';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { useMemo } from 'react';
import { clinicalNoteTemplatesStore } from '../../stores/clinicalNoteTemplates.store';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';

export const TranscribeControl = () => {
  const canUseAmazonTranscribe = useFeatureFlag(
    FEATURE_FLAGS.AMAZON_TRANSCRIBE,
    false
  );
  const template = clinicalNoteTemplatesStore?.templates?.find(
    (t) => t.name === 'AI Note'
  );

  const isAINoteTemplate = template?.id === clinicalNoteStore?.templateId;

  const { data } = useQuery<AmazonTranscribeCreds>(getAmazonTranscribeCreds);
  const currentCredentials: AWSCredentials = useMemo(() => {
    if (data?.credentials?.amazon?.transcribe) {
      return {
        ...data.credentials.amazon.transcribe,
        expiration: new Date(data.credentials.amazon.transcribe.expiration),
      };
    }

    return {
      accessKeyId: '',
      secretAccessKey: '',
      sessionToken: '',
      expiration: new Date(),
    };
  }, [data?.credentials?.amazon?.transcribe]);

  const { isTranscribing, startTranscribing, stopTranscribing } =
    useLiveTranscriptions({
      currentCredentials,
    });

  const onTranscribeClick = () => {
    if (isTranscribing) {
      stopTranscribing();
    } else {
      startTranscribing();
    }
  };

  if (canUseAmazonTranscribe && isAINoteTemplate) {
    return (
      <div
        className='ml-5 flex h-full items-center rounded-lg bg-white '
        data-testid={TestID.ClinicalNote.TranscribeControl.Container}
      >
        <button
          className='color box-border h-full rounded-lg border-[1px] border-gray-300 bg-white px-2.5 text-sm font-semibold text-blue-500'
          type='button'
          onClick={onTranscribeClick}
          data-testid={TestID.ClinicalNote.TranscribeControl.Button}
        >
          Transcribe
          <Microphone
            className={clsx('ml-4 inline-block', {
              'text-red-500': isTranscribing,
            })}
            height={20}
            width={20}
            data-testid={TestID.ClinicalNote.TranscribeControl.Icon}
          />
        </button>
      </div>
    );
  }

  return (
    <div
      className='border-gray-2000 h-11.5 ml-5 flex items-center rounded-lg border bg-white px-2.5'
      data-testid={TestID.ClinicalNote.DragonTranscribeControl.Container}
    >
      <button
        className='text-md border-0 bg-white font-semibold text-blue-500'
        data-testid={TestID.ClinicalNote.DragonTranscribeControl.Button}
        type='button'
        onClick={() => {
          dragonSettingsStore.toggleRecording();
        }}
      >
        Transcribe
      </button>
      <DragonSpeechToText
        userId={authStore.authInfo?.preferred_username}
        styles={{ container: { marginLeft: '18px' } }}
      />
    </div>
  );
};
