import { useMemo } from 'react';
import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';

interface IProps {
  vitals: string[] | undefined;
}

const VitalsTextItem = styled.div({
  fontWeight: 400,
  fontSize: '0.775rem',
});

const Spacer = styled.div({
  height: '0.775rem',
});

export const VitalsItems: React.FC<IProps> = ({ vitals }) => {
  const ItemComponents = useMemo(() => {
    let components = [];

    if (Array.isArray(vitals) && vitals.length > 0) {
      for (const vitalsItem of vitals) {
        if (vitalsItem === 'spacer') {
          components.push(
            <Spacer
              data-testid={TestID.ClinicalNote.VitalsSpacer}
              key='spacer'
            />
          );
        } else {
          components.push(
            <VitalsTextItem data-public key={vitalsItem}>
              {vitalsItem}
            </VitalsTextItem>
          );
        }
      }
    }
    return components;
  }, [vitals]);

  return <>{ItemComponents}</>;
};
