import React, { ReactNode } from 'react';
import styled from 'styled-components';

export type IconButtonProps = {
  icon?: ReactNode;
  text?: string;
  callback?: () => void;
  testId?: string;
  disabled?: boolean;
  title?: string;
  styles?: {
    buttonContainer?: React.CSSProperties;
    icon?: React.CSSProperties;
  };
};

const Tooltip = styled.span`
  position: absolute;
  padding: 5px;
  color: black;
  font-size: 0.7rem;
  visibility: hidden;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  transform: translateY(-10px);
  top: -10px;
  left: -150px;
  width: 160px;
  height: 26px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
`;

const IconContainer = styled.div`
  overflow: hidden;
`;

const IconButtonText = styled.div`
  margin-left: 5px;
`;

const IconButtonContainer = styled.button`
  display: flex;
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
    background-color: unset;
    opacity: 0.38;
  }

  &:hover ${Tooltip} {
    visibility: visible;
    transform: translateY(0);
  }
`;

export function IconButton({
  icon,
  text,
  callback,
  testId,
  styles,
  disabled,
  title,
}: IconButtonProps) {
  return (
    <IconButtonContainer
      onClick={callback}
      data-testid={testId}
      style={styles?.buttonContainer}
      disabled={disabled}
      data-title={title}
    >
      <IconContainer>{icon}</IconContainer>
      <IconButtonText>{text}</IconButtonText>
      <Tooltip data-testid={`${testId}-tooltip`}>{title}</Tooltip>
    </IconButtonContainer>
  );
}
