import { useMutation } from '@apollo/client';
import { clinicalNoteStore } from '../stores/clinicalNote.store';
import {
  runAIAnalysisMutation,
  RunAIAnalysisMutationVariables,
  RunAIAnalysisReturnType,
} from '../services/graphql/runAIAnalysis';
import { useEffect } from 'react';
import { useAnalytics } from './useAnalytics';
import { getGraphQLErrors } from '../routes/AINote/utils/getGraphQLErrors';
import { useToast } from '../components/AkidoUI/Toast';
import {
  ProviderDesktopErrorCodes,
  ProviderDesktopErrors,
} from '../common/errors/errors';

export const useClinicalNoteAI = () => {
  const updateContentSections = clinicalNoteStore.getClinicalNoteUpdateContent(
    clinicalNoteStore.sectionValues
  );
  const title = clinicalNoteStore.editableNoteTitle;
  const templateId = clinicalNoteStore.templateId;
  const id = clinicalNoteStore.clinicalNote?.id ?? '';
  const version = clinicalNoteStore.clinicalNote?.version ?? 0;

  const [runAIAnalysis, { data, error, reset }] = useMutation<
    RunAIAnalysisReturnType,
    RunAIAnalysisMutationVariables
  >(runAIAnalysisMutation, {
    variables: {
      id,
      content: {
        sections: updateContentSections,
        title,
        templateId,
        version,
      },
    },
  });

  const { addToast } = useToast();

  useEffect(() => {
    for (const graphQLError of getGraphQLErrors(error)) {
      if (graphQLError?.code === ProviderDesktopErrorCodes.EMPTY_TRANSCRIPT) {
        addToast(ProviderDesktopErrors.EMPTY_TRANSCRIPT);
      }
    }
  }, [error, addToast]);

  useEffect(() => {
    if (data?.runAiAnalysis?.requestId) {
      clinicalNoteStore.setCurrentRequestId(data.runAiAnalysis.requestId);
    }

    if (data?.runAiAnalysis?.result) {
      clinicalNoteStore.setAISummaryContent(data.runAiAnalysis.result);
    }
  }, [data?.runAiAnalysis?.result, data?.runAiAnalysis?.requestId]);

  const { sendGenerateSummaryEvent } = useAnalytics();

  const runAIAnalysisWithAnalytics = async () => {
    await clinicalNoteStore.clearAISummaryContent();
    sendGenerateSummaryEvent({
      akido_eid: id,
      capella_eid: clinicalNoteStore?.clinicalNote?.encounterId,
    });
    clinicalNoteStore.setAISummaryEdited(false);
    clinicalNoteStore.setNewVersion(version + 1);
    return runAIAnalysis();
  };

  return {
    runAIAnalysis: runAIAnalysisWithAnalytics,
    error,
    reset,
  };
};
