import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

interface MetaDataProps {
  name?: string;
  age?: number;
  dateOfBirth?: string;
}

const Name = styled.span``;

const Age = styled.span``;

const DateOfBirth = styled.span``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.85rem;
  font-weight: 500;
  color: #0c1e2c;
  align-self: flex-start;
`;

export const MetaData: FC<MetaDataProps> = (props) => {
  const { age, name, dateOfBirth } = props;

  const formattedDOB = useMemo(() => {
    if (dateOfBirth) {
      return DateTime.fromISO(dateOfBirth, { zone: 'utc' }).toFormat('MM/dd/y');
    } else {
      return null;
    }
  }, [dateOfBirth]);

  return (
    <Container>
      <Name data-private>{name}</Name>
      <Age data-private>, {age} YO</Age>
      {formattedDOB && (
        <DateOfBirth data-private>, DOB: {formattedDOB}</DateOfBirth>
      )}
    </Container>
  );
};
