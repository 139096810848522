import { useEffect, useState, useMemo } from 'react';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarOverride.css';
import { DateTime, Settings } from 'luxon';
import { FilterOptions, DayController } from './Toolbar';
import { Event } from './Event';
import { TimeSlotWrapper } from './TimeSlotWrapper';
import { StatusFilter } from './StatusFilter';
import styled from 'styled-components';
import authStore from '../../stores/auth.store';
import { observer } from 'mobx-react-lite';
import { useSchedule } from './hooks/useSchedule';
import { FEATURE_FLAGS } from '../../config/launchdarkly';
import { CreateEncounterDrawer } from './CreateEncounterDrawer';
import { TestID } from '../../constants/testIds';
import {
  CreateEncounterVariables,
  createEncounterMutation,
} from '../../services/graphql/createEncounter';
import FeatureFlaggedConditionalRender from '../FeatureFlaggedConditionalRender/FeatureFlaggedConditionalRender';
import { useMutation } from '@apollo/client';
import {
  EncounterModel,
  PatientModel,
} from '@akido/provider-desktop-bffe-types';
import { useToast } from '../AkidoUI/Toast';
import { AlertModal } from '../AkidoUI';
import { ResourceType } from '../../constants/resourceTypes';
import { CapellaSchedulerButton } from './CapellaSchedulerButton';
Settings.defaultLocale = 'en-US';

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 80vh;
`;

const CalendarContainer = styled.div`
  background: transparent;
  margin-top: 23px;
  padding-bottom: 10px;
  width: 100%;
  overflow: scroll;
  flex-shrink: 1;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const ToolbarRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const CreateEncounterButton = styled.button`
  padding: 8px 16px 8px 16px;
  color: white;
  background: #0078d8;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #0078d8;

  :disabled {
    background: #dcedfa;
    border: 1px solid #dcedfa;
  }
`;

const localizer = luxonLocalizer(DateTime);

// Create the default times for the calendar
const MAX_TIME = new Date();
const MIN_TIME = new Date();
const DEFAULT_TIME = new Date();

MAX_TIME.setHours(23, 59, 0);
MIN_TIME.setHours(0, 0, 0);
DEFAULT_TIME.setHours(7, 0, 0);

export const CalendarSchedule = observer(() => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const practitionerOktaId = authStore.authInfo?.sub;
  const { scheduleItems, retrieveSchedule, retrieveScheduleOnce } = useSchedule(
    {
      practitionerOktaId,
      date: DateTime.fromJSDate(selectedDate).toFormat('yyyy-MM-dd'),
    }
  );

  const { addToast } = useToast();

  const [createEncounter] = useMutation<
    EncounterModel,
    CreateEncounterVariables
  >(createEncounterMutation);

  useEffect(() => {
    retrieveSchedule();
  }, [practitionerOktaId, retrieveSchedule, selectedDate]);

  function handleDateSelected(newDate: Date) {
    setSelectedDate(newDate);
  }

  async function handleSaveNewEncounter(
    patient: PatientModel,
    startTime: Date,
    endTime: Date
  ) {
    const duration = DateTime.fromJSDate(endTime).diff(
      DateTime.fromJSDate(startTime),
      'minutes'
    );

    try {
      await createEncounter({
        variables: {
          createEncounterData: {
            startDateTime: startTime.toISOString(),
            minutesDuration: duration.minutes,
            fhirPatientId: patient?.patientId,
            appointmentIdentifier: {
              type: ResourceType.CMG_STREET_MEDICINE_APPOINTMENT_ID,
              value: `${patient?.patientId}-${startTime.toISOString()}`,
            },
          },
        },
      });

      addToast({
        message: 'Encounter created successfully',
        type: 'success',
      });

      setIsDrawerOpen(false);
      await retrieveScheduleOnce();
    } catch (error) {
      setErrorMessage(
        'Error creating encounter. Please try again and contact support if the issue persists.'
      );
    }
  }

  // need a key to force the re-render for the autoscroll
  // useMemo here to avoid calendar rerenders when state besides scheduleItems changes.
  const calendarKey = useMemo(() => {
    return scheduleItems ? Date.now() : 0;
  }, [scheduleItems]);

  return (
    <Container>
      <CalendarContainer data-public>
        <Calendar
          data-public
          key={calendarKey}
          style={{
            backgroundColor: 'transparent',
            overflow: 'auto',
            padding: '0px 24px 0px 24px',
          }}
          enableAutoScroll
          localizer={localizer}
          dayLayoutAlgorithm={'no-overlap'}
          events={scheduleItems || []}
          step={5}
          date={selectedDate}
          timeslots={3}
          components={{
            toolbar: ({
              date,
              onNavigate,
              onView,
              localizer: { messages },
              view,
              views,
              label,
            }) => (
              <div>
                <ToolbarRow>
                  <FilterOptions
                    date={date}
                    onNavigate={onNavigate}
                    onView={onView}
                    messages={messages}
                    view={view}
                    views={views}
                    selected={selectedDate}
                    setSelected={handleDateSelected}
                  />
                  <div className='flex gap-4'>
                    <CapellaSchedulerButton />
                    <FeatureFlaggedConditionalRender
                      featureFlag={FEATURE_FLAGS.CREATE_NEW_ENCOUNTER_DRAWER}
                    >
                      <CreateEncounterButton
                        data-public
                        data-testid={TestID.Calendar.OpenCreateEncounterButton}
                        onClick={() => setIsDrawerOpen(true)}
                        disabled={isDrawerOpen}
                      >
                        Create New Encounter
                      </CreateEncounterButton>
                    </FeatureFlaggedConditionalRender>
                  </div>
                </ToolbarRow>
                <ToolbarRow>
                  <DayController
                    onNavigate={onNavigate}
                    messages={messages}
                    label={label}
                  />
                  <StatusFilter />
                </ToolbarRow>
              </div>
            ),
            timeSlotWrapper: TimeSlotWrapper,
            day: {
              event: Event,
            },
          }}
          eventPropGetter={(event) => {
            const status = event.clinicalNoteStatus;
            const styling =
              status === 'SIGNED'
                ? {
                    backgroundColor: '#E2F4F1',
                    border: '1px solid #3BAF9E',
                  }
                : {
                    backgroundColor: '#ECF0F1',
                    border: '1px solid #718492',
                  };
            return {
              style: {
                borderRadius: '8px',
                padding: '15px',
                color: 'black',
                marginLeft: '6px',
                marginTop: '6px',
                ...styling,
              },
            };
          }}
          scrollToTime={scheduleItems?.[0]?.start || DEFAULT_TIME}
          defaultView='day'
          max={MAX_TIME}
          min={MIN_TIME}
          onNavigate={handleDateSelected}
        />
      </CalendarContainer>
      <FeatureFlaggedConditionalRender
        featureFlag={FEATURE_FLAGS.CREATE_NEW_ENCOUNTER_DRAWER}
      >
        <>
          <CreateEncounterDrawer
            isOpen={isDrawerOpen}
            onSave={handleSaveNewEncounter}
            onClose={() => setIsDrawerOpen(false)}
          />
          <AlertModal
            isOpen={!!errorMessage}
            onClose={() => setErrorMessage('')}
            title='Error'
            type='error'
            message={errorMessage}
            data-testid={TestID.Calendar.ErrorAlertModal}
          />
        </>
      </FeatureFlaggedConditionalRender>
    </Container>
  );
});
