import { FC } from 'react';
import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';
import { ClinicalNotePreviewMode } from '../ClinicalNotePreview';
import { HeaderReportTitle } from './HeaderReportTitle';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginBottom: '20px',
});

const ReportStatusSignedNoteContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: '16px',
});

interface SignedPreviewHeaderProps {
  mode: ClinicalNotePreviewMode;
}

export const SignedPreviewHeader: FC<SignedPreviewHeaderProps> = ({ mode }) => {
  return (
    <Container data-testid={TestID.ClinicalNote.NotePreviewHeader}>
      <ReportStatusSignedNoteContainer>
        <HeaderReportTitle mode={mode} />
      </ReportStatusSignedNoteContainer>
    </Container>
  );
};
