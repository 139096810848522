import { useCallback } from 'react';
import {
  ClinicalNoteCreateDtoType,
  ClinicalNoteFieldValue,
  ClinicalNoteSaveStatus,
} from '../../../types/clinical-notes';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';
import { CreateClinicalNote } from '../../../services/graphql/createClinicalNote';
import { useNavigate } from 'react-router-dom';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { clinicalEditorPageState } from '../../../stores/clinicalEditorPageState.store';
import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';
interface IProps {
  externalEncounterId?: string | null;
  clinicalNoteTitle: string;
  templateId: string;
}

export const useSaveClinicalNote = ({
  externalEncounterId,
  clinicalNoteTitle,
  templateId,
}: IProps) => {
  const navigate = useNavigate();
  const [createClinicalNote] = useMutation<
    ClinicalNoteModel,
    ClinicalNoteCreateDtoType
  >(CreateClinicalNote);

  const saveClinicalNote = useCallback(async () => {
    clinicalNoteStore.isSaving = true;
    try {
      if (externalEncounterId) {
        const sections: ClinicalNoteFieldValue[] = [];
        const clinicalNoteValues = clinicalNoteStore.sectionValues;

        const keys = Object.keys(clinicalNoteValues);
        keys.forEach((key) => {
          const autofillData = clinicalNoteStore.getAutofillData(
            clinicalNoteValues[key]?.uiSection
          );

          sections.push({
            id: key,
            type: clinicalNoteValues[key].type,
            category: clinicalNoteValues[key].category,
            title: clinicalNoteValues[key].title,
            text: clinicalNoteValues[key].text,
            autofillData,
            sortOrder: clinicalNoteValues[key].sortOrder,
            uiSection: clinicalNoteValues[key].uiSection,
            requestId: clinicalNoteValues[key].requestId,
          });
        });

        const createdNote = await createClinicalNote({
          variables: {
            encounterId: externalEncounterId,
            content: {
              title: clinicalNoteTitle,
              templateId,
              sections,
            },
          },
        });
        const noteId = get(createdNote, 'data.createClinicalNote.id', '');

        /* istanbul ignore next */
        if (noteId) {
          clinicalNoteStore.setClinicalNoteId(noteId);

          clinicalEditorPageState.setSaveStatus(
            ClinicalNoteSaveStatus.hasSaved
          );

          // TODO: we need to mock the navigate to check that the redirect works
          if (process.env.NODE_ENV !== 'test') {
            clinicalNoteStore.reset();

            navigate(`/clinical-notes/${noteId}`);
          }

          return noteId;
        }
      }
      return null;
    } catch (e) {
      console.log(JSON.stringify(e));
      return null;
    } finally {
      clinicalNoteStore.isSaving = false;
    }
  }, [
    externalEncounterId,
    createClinicalNote,
    clinicalNoteTitle,
    templateId,
    navigate,
  ]);

  return { saveClinicalNote };
};
