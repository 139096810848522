const UNWANTED_SESSIONS = [
  {
    email: 'test_practitioner@test.com',
    environment: 'production',
  },
  {
    environment: 'local',
  },
];

export const replaceSensitiveFields = (jsonString: string) => {
  const sensitiveFields: string[] = [
    'givenName',
    'familyName',
    'given_name',
    'family_name',
    'firstName',
    'lastName',
    'name',
    'given',
    'family',
    'fullName',
    'birthDate',
    'phone',
    'email',
    'address',
    'ssn',
    'searchQuery',
  ];
  const regexPattern: RegExp = new RegExp(
    `("(${sensitiveFields.join(
      '|'
    )})":\\s*")([^"]*)(")|(?<=("accountNumber":\\s*{[^{}]*"value":\\s*"))[^"]*(?=")|"address":\\s*{[^}]*}`,
    'g'
  );
  return jsonString.replace(regexPattern, '$1*****$4');
};

export const shouldRecordSession = (email?: string) => {
  // check if email is in the unwanted sections
  for (const session of UNWANTED_SESSIONS) {
    const isEmailAndSessionUnwanted =
      session.email === email &&
      session.environment === process.env.REACT_APP_ENV;

    const isSessionEnvironmentUnwanted =
      session.environment === process.env.REACT_APP_ENV && !session.email;
    if (isEmailAndSessionUnwanted || isSessionEnvironmentUnwanted) {
      return false;
    }
  }
  return true;
};
