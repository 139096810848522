import { gql } from '@apollo/client';
import { UserAuthorizations } from '@akido/provider-desktop-bffe-types';

export type GetAuthorizationsResponse = {
  user: {
    authorizations: Omit<
      UserAuthorizations,
      'canSeeBilling' | 'canSeeAllBillings'
    >;
  };
};

export const getAuthorizations = gql`
  query GetAuthorizations {
    user {
      authorizations {
        canEditScribeNotes
        canEditTranscriptions
        canCreateScribeNotes
        canAddAddendum
        canUseSpeechToText
        canViewOwnSchedules
      }
    }
  }
`;
