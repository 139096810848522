import { ReactNode } from 'react';

interface IProps {
  shouldRender: boolean;
  children: ReactNode;
}

function ConditionalRender({
  shouldRender,
  children,
}: IProps): JSX.Element | null {
  if (shouldRender && children) {
    return <>{children}</>;
  }

  return null;
}

export default ConditionalRender;
