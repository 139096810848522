import styled, { keyframes } from 'styled-components';

const animatedGradient = keyframes`
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
`;

export const LoadingBar = styled.span`
  height: 12px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: ${animatedGradient};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  display: block;
  padding: 0px !important;
  margin: 0px 5px;
`;
