import { LDContext } from 'launchdarkly-js-sdk-common';
import { REACT_APP_LD_CLIENT_ID } from './environment';

const user: LDContext = {
  key: 'unauthenticated',
  kind: 'user',
};

export const ldClientSettings = {
  clientSideID: REACT_APP_LD_CLIENT_ID || '',
  user,
};

export const FEATURE_FLAGS = {
  TEST_FEATURE_FLAG: 'i-am-not-a-real-flag-do-not-use-me',
  CAPELLA_LINKS: 'ACHRT-222-capella-iframe-links',
  MY_TASKS_LINKS: 'ACHRT-1537-my-tasks-link',
  EDIT_SCRIBE_NOTE: 'desktop-provider-1022-edit-scribe-note',
  LOCAL_STORE_CLINICAL_NOTES: 'desktop-provider-121-local-store-clinical-notes',
  IMMEDIATE_AUTOSAVE_ON_EXTERNAL_ID_LOAD: 'desktop-provider-page-save-PRVD-112',
  IMMUNIZATIONS_FROM_BFFE: 'desktop-provider-immunizations-PRVD-75',
  NEW_ADDENDUM_UI: 'desktop-provider-new-addendum-ui-PRVD-255',
  COPY_BUTTON: 'desktop-provider-PRVD-239-copy-button',
  EDIT_IN_CAPELLA_BUTTON: 'desktop-provider-PRVD-236-edit-in-capella-button',
  SCHEDULE_NEW_HOME_PAGE:
    'desktop-provider-PRVD-242-navigation-in-schedules-page',
  POLLING_APPOINTMENTS: 'desktop-provider-PRVD-273-polling-appointments',
  AI_TRANSCRIPTION: 'desktop-provider-PRVD-310-ai-transcription',
  LOGIN_TO_SCHEDULE: 'desktop-provider-PRVD-368-scheduling-redirect',
  NEW_EDITOR: 'desktop-provider-PRVD-399-new-editor',
  AI_NOTE_PAGE: 'provider-desktop-PRVD-505-ai-note-page',
  CREATE_NEW_ENCOUNTER_DRAWER:
    'provider-desktop-PRVD-469-create-encounter-side-bar',
  CLINICAL_NOTE_UPDATED_VERSION:
    'desktop-provider-PRVD-395-clinical-notes-versioning',
  LOGIN_AUTOREDIRECT: 'provider-desktop-PRVD-553',
  NEW_PATIENT_COLUMNS: 'provider-desktop-PRVD-489-patient-columns',
  RESOURCE_ID_PARAM:
    'desktop-provider-PRVD-607-resourceId-street-medicine-flow',
  AMAZON_TRANSCRIBE: 'desktop-provider-PRVD-797-amazon-transcribe',
  NEW_DRAGON_FLOW: 'desktop-provider-PRVD-793-new-dragon-flow',
  AUTOSAVE_INTERVAL_CONFIG:
    'desktop-provider-PRVD-841-autosave-interval-config',
  AUTOSAVE_INTERVAL_SECONDS:
    'desktop-provider-PRVD-864-autosave-interval-seconds',
  REDIRECT_TO_AKIDO_CHART:
    'desktop-provider-prvd-902-open-clinical-notes-in-akido-chart',
  USE_DATE_RANGE_TO_FETCH_SCHEDULE:
    'desktop-provider-schedules-by-range-prvd-906',
  MAINTENANCE: 'permanent-flag-maintenance',
  CREATE_PATIENT_WITH_OPTIONAL_REGION:
    'desktop-provider-PRVD-964-optional-region',
  CAPELLA_REFILL_PAGE_IFRAME: 'ACHRT-1067-capella-erx-page-integration',
  TASKS_CONFIG: 'ACHRT-1513-my-tasks-link',
  PATIENT_SEARCH_PAGE: 'ACHRT-1085-patient-search-page',
};

export type FeatureFlagsKeys = keyof typeof FEATURE_FLAGS;
export type FeatureFlagsValues = (typeof FEATURE_FLAGS)[FeatureFlagsKeys];
