import React, { useEffect, useRef } from 'react';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import { CheckIcon } from '../Icon/CheckIcon';
import { useSelectContext } from './Select';

type SelectOptionProps<ValueType> = {
  'data-testid'?: string;
  value: ValueType;
  isSelected?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
};

export function SelectOption<ValueType>(props: SelectOptionProps<ValueType>) {
  const {
    'data-testid': dataTestId,
    value,
    isSelected = false,
    disabled,
    children,
  } = props;
  const { scrollToSelected } = useSelectContext();

  const optionRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    // Scroll the dropdown list so the currently selected option is at the top
    if (isSelected && optionRef.current && scrollToSelected) {
      optionRef.current.scrollIntoView();
    }
  }, [isSelected, scrollToSelected]);

  return (
    <Listbox.Option
      ref={optionRef}
      data-testid={dataTestId}
      value={value}
      disabled={disabled}
      className={({ active }) =>
        clsx(
          'relative cursor-default select-none px-3 py-2.5 font-semibold text-gray-900',
          // We need styles for when an option is disabled here
          {
            'bg-gray-50': active,
          }
        )
      }
    >
      {({ selected }) => (
        <>
          {children}
          {(selected || isSelected) && (
            <span className='absolute inset-y-0 right-2 flex items-center pl-3 text-blue-500'>
              <CheckIcon aria-hidden='true' />
            </span>
          )}
        </>
      )}
    </Listbox.Option>
  );
}
