import { gql } from '@apollo/client';

export const UpdateClinicalNote = gql`
  mutation UpdateClinicalNote(
    $id: String!
    $content: ClinicalNoteContentInput!
    $override: Boolean
  ) {
    updateClinicalNote(id: $id, content: $content, override: $override) {
      id
      version
    }
  }
`;
