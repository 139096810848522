import { observer } from 'mobx-react-lite';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { AddendumContent } from '../Addendum/AddendumContent';
import { TestID } from '../../../constants/testIds';

const Container = styled.div``;

const Separator = styled.div`
  height: 2px;
  background-color: #f2f2f2;
`;

interface ClinicalNotePreviewAddendaProps {}

export const ClinicalNotePreviewAddenda: FC<ClinicalNotePreviewAddendaProps> =
  observer(() => {
    const addenda = clinicalNoteStore.clinicalNote?.addenda ?? [];

    if (addenda.length === 0) {
      return null;
    }

    const addendaContent: ReactElement[] = [];
    addenda.forEach((addendum, index) => {
      addendaContent.push(
        <AddendumContent
          data-testid={`addendum-content_${addendum.id}`}
          key={addendum.id}
          text={addendum.text}
          signedBy={addendum.createdBy}
          signedAt={addendum.createdAt}
        />
      );
      if (index < addenda.length - 1) {
        addendaContent.push(
          <Separator
            style={{ marginBottom: 25 }}
            key={`${addendum.id}_separator`}
          />
        );
      }
    });

    return (
      <Container data-testid={TestID.ClinicalNote.AddendaPreviewContainer}>
        <Separator />
        {addendaContent}
      </Container>
    );
  });
