import { FC } from 'react';
import { Panel } from './components/Panel';
import { TestID } from '../../constants/testIds';
import { TabController } from './components/TabController/TabController';
import { ControlPanel } from './components/ControlPanel/ControlPanel';
import { TemplateSelector } from './components/TemplateSelector/TemplateSelector';
import { TranscribeControl } from './components/TranscribeControl/TranscribeControl';

interface AINotePageLayoutProps {
  id: string;
}

export const AINotePageLayout: FC<AINotePageLayoutProps> = (props) => {
  const { id } = props;
  return (
    <div
      data-testid={TestID.AIClinicalNote.AINotePageLayout}
      className='grid flex-1 grid-cols-10'
    >
      <div className='col-span-4 flex h-full flex-col bg-white'>
        <ControlPanel>
          <TemplateSelector />
          <TranscribeControl clinicalNoteId={id} />
        </ControlPanel>
        <TabController />
      </div>
      <div className='col-span-6 flex h-full items-center justify-center bg-green-200'></div>
      <Panel visible={false} />
    </div>
  );
};
