import { observer } from 'mobx-react-lite';
import { Card } from '../../components/AkidoUI/Card';
import { TextField } from '../../components/AkidoUI/TextField';
import { TranscriptionsTableContainer } from './TranscriptionsTableContainer';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { CardTheme } from '../../theme';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { FilterButton } from '../../components/Filters/FilterButton';
import {
  getAuthorizations,
  GetAuthorizationsResponse,
} from '../../services/graphql/getAuthorizations';
import authStore from '../../stores/auth.store';
import { CreateScribeNoteModal } from './CreateScribeNoteModal/CreateScribeNoteModal';
import { EditScribeNoteModal } from './EditScribeNoteModal/EditScribeNoteModal';
import { TranscriptionModal } from './TranscriptionModal/TranscriptionModal';
import { TranscriptionsTable } from './TranscriptionsTable';
import { useSearchParams } from 'react-router-dom';
import { TestID } from '../../constants/testIds';

const cardWrapperStyles: CSSObject = {
  borderRadius: '46px 46px 0px 0px',
  backgroundColor: '#FFFFFF',
  padding: '45px 65px',
  margin: 0,
  width: '100%',
  height: '100%',
};

const cardStyles: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
};

const SearchInput = styled.div({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
});

const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const TranscriptionsListPage = observer(() => {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchParams] = useSearchParams('');
  const handleSearch = debounce((value: string) => {
    setSearchParams({ search: value });
  }, 500);

  const [searchValueInput, setSearchValue] = useState(
    searchValue.get('search') || ''
  );

  const updatePageNumber = (newPage: number) => {
    setPageNumber(newPage);
  };
  const [getUserAuthorizations] = useLazyQuery<GetAuthorizationsResponse>(
    getAuthorizations,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    setPageNumber(1);
  }, [searchValue]);

  useEffect(() => {
    getUserAuthorizations({
      fetchPolicy: 'network-only',
    }).then((result) => {
      authStore?.setAuthorizations(result.data);
    });
  }, [getUserAuthorizations]);

  return (
    <TranscriptionsTableContainer>
      <Card
        wrapperStyle={cardWrapperStyles}
        style={cardStyles}
        theme={CardTheme}
      >
        <InputRow>
          <SearchInput>
            <Search />
            <TextField
              isPrivate={true}
              inputStyles={{ border: 'none' }}
              placeholder='Search Here'
              value={searchValueInput}
              onChange={(text) => {
                handleSearch(text);
                setSearchValue(text);
              }}
              testId={TestID.Transcriptions.SearchInput}
            />
          </SearchInput>
          <FilterButton />
        </InputRow>
        <TranscriptionsTable
          currentPage={pageNumber}
          onPageClick={updatePageNumber}
        />

        <TranscriptionModal />
        <CreateScribeNoteModal />
        <EditScribeNoteModal />
      </Card>
    </TranscriptionsTableContainer>
  );
});
