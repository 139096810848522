import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { PatientPrescriptionsDisplayLabels } from '../../../services/graphql/patientPrescriptions';
import { PrescriptionsGraphQLDisplayLabelReturn } from '../../../types/prescriptions';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { AkidoIdentifier, Maybe } from '@akido/provider-desktop-bffe-types';
import { PatientTypes } from '../../../services/graphql/commonTypes';

export interface PatientPrescriptionsVariables {
  patientId: AkidoIdentifier;
  externalEncounterId: string;
}

export const usePatientPrescriptions = (
  patientId?: string,
  encounterId?: Maybe<string> | undefined
) => {
  const [retrievePatientPrescriptions, { loading: isLoading }] = useLazyQuery<
    PrescriptionsGraphQLDisplayLabelReturn,
    PatientPrescriptionsVariables
  >(PatientPrescriptionsDisplayLabels, { fetchPolicy: 'network-only' });

  const fetchPrescriptions = useCallback(async () => {
    if (patientId && encounterId) {
      const { data, error } = await retrievePatientPrescriptions({
        variables: {
          patientId: {
            value: patientId,
            type: PatientTypes.FHIR_PATIENT_ID.toString(),
          },
          externalEncounterId: encounterId,
        },
      });

      if (error !== undefined) {
        clinicalNoteRefreshableAutofillStore.setPrescriptionsDidError();
      }

      const patientPrescriptions = data?.patient?.prescriptions?.list;

      if (patientPrescriptions) {
        clinicalNoteRefreshableAutofillStore.setPrescriptions(
          patientPrescriptions
        );
      }
    }
  }, [patientId, encounterId, retrievePatientPrescriptions]);

  return {
    isLoading,
    fetchPrescriptions,
  };
};
