import { get } from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { TestID } from '../../constants/testIds';

const PageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxWidth: '2560px',
  alignSelf: 'center',
  width: '100%',
  backgroundColor: '#E6F2F4',
  justifyContent: 'center',
});

const CenterContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  justifyContent: 'center',
});

const TitleText = styled.div({
  fontFamily: 'Fraunces',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '48px',
  lineHeight: '56px',
  textAlign: 'center',
  letterSpacing: '0.15px',
  color: '#083B60',
});

const HighlightedButton = styled.button({
  backgroundColor: '#1B4C73',
  marginTop: '40px',
  padding: '16px 80px',
  fontWeight: '600',
  fontSize: '18px',
  border: 'none',
  borderRadius: '16px',
  color: 'white',
  letterSpacing: '0.5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  alignSelf: 'center',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  lineHeight: '24px',
});

const MainText = styled.div({
  marginTop: '12px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '18px',
  lineHeight: '24px',
  textAlign: 'center',
  color: '#0C1E2C',
  width: '60%',
  alignSelf: 'center',
});

const Divider = styled.div({
  margin: '38px 0 22px',
  border: '1px solid #083B60',
  width: '70px',
  alignSelf: 'center',
});

export enum ErrorCodes {
  ENCOUNTER_NOT_FOUND = 'ENCOUNTER_NOT_FOUND',
  PAGE_NOT_FOUND = '404',
  GENERAL_ERROR = '500',
}

type ErrorTextValues = {
  header: string;
  text: string;
};

const DEFAULT_ERROR_TEXT =
  'We’re sorry, there seems to be an error and this page is currently not available';

const CODE_TO_ERROR_TEXT_MAPPING: Record<ErrorCodes, ErrorTextValues> = {
  [ErrorCodes.ENCOUNTER_NOT_FOUND]: {
    header: 'This encounter was not found',
    text: DEFAULT_ERROR_TEXT,
  },
  [ErrorCodes.PAGE_NOT_FOUND]: {
    header: 'This page was not found',
    text: DEFAULT_ERROR_TEXT,
  },
  [ErrorCodes.GENERAL_ERROR]: {
    header: 'An error occurred',
    text: DEFAULT_ERROR_TEXT,
  },
};

export const ErrorPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const previousPath = get(state, 'previousPath', undefined);
  const errorCode = searchParams.get('code') ?? '';

  const error =
    CODE_TO_ERROR_TEXT_MAPPING[errorCode as ErrorCodes] ??
    CODE_TO_ERROR_TEXT_MAPPING[ErrorCodes.GENERAL_ERROR];

  return (
    <PageContainer data-testid={TestID.Error.ErrorPage}>
      <CenterContainer>
        <TitleText>{error.header}</TitleText>
        <Divider />
        <MainText>{error.text}</MainText>
        {errorCode && <MainText>Error code: {errorCode}</MainText>}
        {previousPath && (
          <HighlightedButton
            onClick={() => {
              navigate(previousPath);
            }}
          >
            Go Back
          </HighlightedButton>
        )}
      </CenterContainer>
    </PageContainer>
  );
};
