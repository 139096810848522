import { ToastData } from '../../components/AkidoUI/Toast/ToastProvider';

export enum ProviderDesktopErrorCodes {
  EMPTY_TRANSCRIPT = 'EMPTY_TRANSCRIPT',
}

export const ProviderDesktopErrors: Record<
  ProviderDesktopErrorCodes,
  ToastData
> = {
  [ProviderDesktopErrorCodes.EMPTY_TRANSCRIPT]: {
    type: 'error',
    message: 'Please provide a transcript before generating a summary.',
    duration: 5000,
  },
};
