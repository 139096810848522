import { Tab } from '@headlessui/react';
import { FC, ReactNode } from 'react';
import { EmptyView } from '../../../../components/AkidoUI/EmptyView/EmptyView';
import { ReactComponent as EmptyAIIcon } from '../../../../assets/icons/EmptyAiOutput.svg';
import { TestID } from '../../../../constants/testIds';

export interface SummaryPanelProps {
  className?: string;
  children?: ReactNode;
}

export const SummaryPanel: FC<SummaryPanelProps> = () => {
  return (
    <Tab.Panel>
      <EmptyView
        testId={{
          container: TestID.AIClinicalNote.AINoteTabs.SummaryEmpty,
        }}
        title='No summary generated'
        subtitle='Select Generate to read and edit the summary note'
        Icon={() => <EmptyAIIcon height={250} />}
        classNames={{
          title: 'text-2xl',
          subtitle: 'text-xs',
          container: 'pt-11',
        }}
      />
    </Tab.Panel>
  );
};
