import { makeAutoObservable } from 'mobx';
import { SetPatientDataInStoreModel } from '../types/patient';
import { formatDate } from '../utilities/formatting';
import { isNull, isNumber, isUndefined, isFinite } from 'lodash';
import { AkidoIdentifier } from '@akido/provider-desktop-bffe-types';

export class PatientStore {
  id: string;
  familyName: string;
  givenName: string;
  birthDate: Date | null;
  age: number;
  accountNumber: AkidoIdentifier | null;

  constructor() {
    makeAutoObservable(this);

    this.id = '';
    this.familyName = '';
    this.givenName = '';
    this.birthDate = null;
    this.accountNumber = null;
    this.age = -1;
  }

  setPatientData({
    patientId,
    familyName,
    givenName,
    birthDate,
    age,
    accountNumber,
  }: SetPatientDataInStoreModel) {
    this.id = patientId;
    this.familyName = familyName ?? '';
    this.givenName = givenName ?? '';
    this.accountNumber = accountNumber ?? null;
    if (!isNull(birthDate) && !isUndefined(birthDate)) {
      this.birthDate = new Date(birthDate);
    }
    if (isNumber(age) && isFinite(age)) {
      this.age = age;
    }
  }

  get fullName() {
    return `${this.givenName} ${this.familyName}`;
  }

  get formattedBirthDate() {
    if (this.birthDate) {
      return formatDate(this.birthDate.toISOString(), false, 'utc');
    }
    return '';
  }

  reset() {
    this.id = '';
    this.familyName = '';
    this.givenName = '';
    this.birthDate = null;
    this.age = -1;
  }
}

export const patientStore = new PatientStore();
