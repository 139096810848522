import { FC, ReactNode } from 'react';

interface ControlPanelProps {
  children?: ReactNode;
}

export const ControlPanel: FC<ControlPanelProps> = (props) => {
  const { children } = props;
  return (
    <div className='flex h-20 flex-row items-center justify-between border-b-2 border-b-gray-200 px-4'>
      {children}
    </div>
  );
};
