import { gql } from '@apollo/client';

export type GetTranscriptionDataResponse = {
  transcriptionData: {
    statuses: {
      label: string;
      id: string;
    }[];
    filterablePractitioners: {
      name: string;
      id: string;
    }[];
  };
};

export const getTranscriptionData = gql`
  query GetTranscriptionData {
    transcriptionData {
      statuses {
        label
        id
      }

      filterablePractitioners {
        name
        id
      }
    }
  }
`;
