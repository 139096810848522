import { gql } from '@apollo/client';

import { FhirEncounterModel } from '@akido/provider-desktop-bffe-types';

export type GetBillingByExternalIdReturn = {
  getFhirEncounter: Pick<FhirEncounterModel, 'billing'>;
};

export const ClinicalNoteBillingDisplayLabels = gql`
  query ClinicalNoteBillingDisplayLabels($externalId: String) {
    getFhirEncounter(externalId: $externalId) {
      billing {
        displayLabels
      }
    }
  }
`;
