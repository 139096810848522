import { MedicalItem } from '@aws-sdk/client-transcribe-streaming/dist-types/models/models_0';

export const joinWords = (items: MedicalItem[]) => {
  return items.reduce((sentence, medicalItem) => {
    const content: string = medicalItem?.Content ?? '';

    if (content.match(/\p{P}/gu)) {
      return sentence + medicalItem.Content;
    } else {
      return sentence + (sentence && content ? ' ' : '') + content;
    }
  }, '');
};
