import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface SeparatorProps {
  className?: string;
  height?: number;
  color?: string;
}

export const Separator: FC<SeparatorProps> = (props) => {
  const { height, color, className } = props;

  return (
    <Container
      className={className}
      style={{
        height: height ?? '1px',
        backgroundColor: color ?? '#E2E8F0',
      }}
    />
  );
};
