import React from 'react';
import styled from 'styled-components';

export type InfoTextProps = {
  /**
   * Title of the information displayed
   */
  title: string;
  /**
   * Main text of the information displayed
   */
  value: string;
  /**
   * Display title and value on same row (false) or on different rows (true)
   */
  multiLine?: boolean;
};

const InfoContainer = styled.div({
  display: 'flex',
  width: '100%',
});
const TitleText = styled.div({
  marginLeft: '15px',
  fontWeight: 'bold',
  marginBottom: '5px',
});
const ValueText = styled.div({
  marginLeft: '15px',
  color: 'gray',
  marginBottom: '5px',
});

export function InfoText({ title, value, multiLine }: InfoTextProps) {
  return (
    <InfoContainer
      style={multiLine ? { flexDirection: 'column' } : { flexDirection: 'row' }}
    >
      <TitleText>{title}</TitleText>
      <ValueText>{value}</ValueText>
    </InfoContainer>
  );
}
