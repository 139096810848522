import { useCallback, useState } from 'react';
import {
  ClinicalNoteSignModel,
  ClinicalNoteSignDtoType,
} from '../../../types/clinical-notes';
import { useMutation } from '@apollo/client';
import { SignClinicalNote } from '../../../services/graphql/signClinicalNote';
import { get } from 'lodash';

interface IProps {
  clinicalNoteId?: string;
}

export const useSignClinicalNote = ({ clinicalNoteId }: IProps) => {
  const [signClinicalNoteMutation] = useMutation<
    ClinicalNoteSignModel,
    ClinicalNoteSignDtoType
  >(SignClinicalNote);
  const [isLoading, setIsLoading] = useState(false);

  const signClinicalNote = useCallback(async () => {
    setIsLoading(true);
    try {
      if (clinicalNoteId) {
        const signedNote = await signClinicalNoteMutation({
          variables: {
            id: clinicalNoteId,
          },
        });
        const noteId = get(signedNote, 'data.signClinicalNote.id', '');
        if (noteId) {
          setIsLoading(false);
          return true;
        }
      } else {
        //@debt: Figure out what to do if we don't have an id (should probably fail before this)
      }
    } catch (e) {
      console.log(JSON.stringify(e));
    }
    setIsLoading(false);
    return false;
  }, [clinicalNoteId, signClinicalNoteMutation, setIsLoading]);

  return { signClinicalNote, isLoading };
};
