import clsx from 'clsx';

type PatientSelectProps = {
  name: string;
  accountNumber?: string;
  birthDate?: string;
  className?: string;
  onDelete: () => void;
  'data-testid'?: string;
};

export function PatientSelectResult(props: PatientSelectProps) {
  const {
    name,
    accountNumber,
    birthDate,
    onDelete,
    className,
    'data-testid': dataTestId,
  } = props;

  return (
    <div className={clsx('flex py-2', className)} data-testid={dataTestId}>
      <div>
        <p data-private className='text-sm font-semibold text-gray-700'>
          {name}
        </p>
        <p className='whitespace-pre-wrap text-sm text-gray-800'>
          #<span>{accountNumber}</span> | <span>{birthDate}</span>
        </p>
      </div>
      <button
        data-testid={`${dataTestId}-DeleteButton`}
        className='ml-auto cursor-pointer text-sm font-semibold text-error-700 hover:text-error-800'
        onClick={onDelete}
      >
        Remove
      </button>
    </div>
  );
}
