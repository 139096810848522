import { observer } from 'mobx-react-lite';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { transcriptionFilterStore } from '../../stores/transcriptionFilter.store';
import { Filter } from './Filter';
import { TestID } from '../../constants/testIds';

interface FilterButtonProps {}

const Container = styled.div`
  background-color: transparent;
  display: flex;
  position: relative;
  border-radius: 20px;
  align-items: center;
  width: fit-content;
  border: 1px solid #c9d2dc;
`;

const Label = styled.span`
  font-size: 1rem;
  margin-left: 8px;
`;

const Button = styled.button`
  cursor: pointer;
  padding: 8px 24px;
  border-radius: 20px;
  background-color: transparent;
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const FilterButton: FC<FilterButtonProps> = observer((props) => {
  const [filtersVisible, setFiltersVisible] = useState(false);

  const toggleFilters = useCallback(() => {
    setFiltersVisible((prev) => !prev);
  }, []);

  const filterText = transcriptionFilterStore.isActive
    ? `Filter: On`
    : 'Filter';

  return (
    <Container>
      <Button
        data-testid={TestID.Transcriptions.FilterButtonDefault}
        onClick={toggleFilters}
      >
        <FilterIcon width={20} height={20} />
        <Label>{filterText}</Label>
      </Button>
      <Filter
        onClose={() => setFiltersVisible(false)}
        isVisible={filtersVisible}
      />
    </Container>
  );
});
