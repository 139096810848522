import { observer } from 'mobx-react-lite';
import { TextareaAutosizeProps } from 'react-textarea-autosize';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { clinicalNoteTemplatesStore } from '../../../stores/clinicalNoteTemplates.store';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import styled from 'styled-components';
import { NavigableTextarea } from '../../../components/NavigableTextarea/NavigableTextarea';
import { observeProperty } from '../../../utilities/observe-property';
import { CustomNUSAEvents } from '../../../utilities/events';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { transcriptionSubject } from '../../../components/AkidoUI/LiveTranscriptions';
import { Subscription } from 'rxjs';

interface ClinicalTextAreaProps {
  value: string;
  dataTestId: string;
  onChange: (newValue: string) => void;
  uiSection?: string | null;
  placeholder?: string;
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  styles?: {
    textarea?: TextareaAutosizeProps['style'];
  };
}

const blurPlaceholder = 'Start Documenting...';
const focusPlaceholder =
  'Start typing or select the microphone icon to dictate';

const TextArea = styled(NavigableTextarea)`
  color: #8aa2b5;
  resize: none;
  font-size: 0.775rem;
  background-color: transparent;
  border: none;
  background-position: top 5px right -15px;
  outline-color: transparent;
  padding-left: 5px;

  &:not(:placeholder-shown) {
    color: #0c1e2c;
  }
`;

export const ClinicalTextArea: FC<ClinicalTextAreaProps> = observer((props) => {
  const {
    styles,
    dataTestId,
    uiSection,
    value,
    onChange,
    placeholder,
    disabled,
    minRows = 3,
    maxRows = 20,
  } = props;
  const ldClient = useLDClient();
  const isNewDragonFlow = ldClient?.variation(
    FEATURE_FLAGS.NEW_DRAGON_FLOW,
    false
  );
  const isAmazonTranscribeEnabled = ldClient?.variation(
    FEATURE_FLAGS.AMAZON_TRANSCRIBE,
    false
  );

  const unfocusedPlaceholder = placeholder ?? blurPlaceholder;

  const [placeholderText, setPlaceholder] =
    useState<string>(unfocusedPlaceholder);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const subscriptionRef = useRef<Subscription>();

  const template = clinicalNoteTemplatesStore?.templates?.find(
    (t) => t.name === 'AI Note'
  );

  const isAINoteTemplate = template?.id === clinicalNoteStore?.templateId;
  const isTranscribeEnabledForTextField =
    !isAINoteTemplate || (isAINoteTemplate && uiSection === 'transcription');

  const onBlur = useCallback(() => {
    if (textAreaRef.current?.value) {
      if (textAreaRef.current?.value !== value) {
        onChange(textAreaRef.current.value);
      }
    }
  }, [onChange, value]);

  useEffect(() => {
    if (!isAmazonTranscribeEnabled || uiSection !== 'transcription') {
      return;
    }

    if (!subscriptionRef.current) {
      console.log(
        `Subscribing section ${uiSection} to messages from Amazon Transcribe`
      );
      subscriptionRef.current = transcriptionSubject.subscribe(
        (transcription) => {
          if (transcription.id === uiSection) {
            onChange(transcription.text);
          }
        }
      );
    }
  }, [isAmazonTranscribeEnabled, uiSection, onChange]);

  useEffect(() => {
    const textarea = textAreaRef.current;
    if (textarea && isTranscribeEnabledForTextField) {
      if (isNewDragonFlow) {
        return observeProperty(textAreaRef.current, 'value', (value) => {
          window.dispatchEvent(new Event('resize')); // to trigger `TextareaAutosize` resize
          onChange(value);
        });
      } else {
        const listener = () => onChange(textarea.value);
        textarea.addEventListener('input', listener);
        document.addEventListener(
          CustomNUSAEvents.FINISHED_PROCESSING,
          listener
        );
        return () => {
          textarea.removeEventListener('input', listener);
          document.removeEventListener(
            CustomNUSAEvents.FINISHED_PROCESSING,
            listener
          );
        };
      }
    } else {
      return () => {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaRef.current, isTranscribeEnabledForTextField, onChange]);

  return (
    <TextArea
      /* TODO: We'd need to replace the ids to uiSections everywhere */
      data-testid={dataTestId}
      styles={styles}
      value={value}
      id={uiSection ?? undefined}
      onChange={(event: any) => {
        onChange(event.target.value);
      }}
      placeholder={placeholderText}
      onFocus={() => {
        setPlaceholder(focusPlaceholder);
      }}
      onBlur={() => {
        setPlaceholder(unfocusedPlaceholder);
        onBlur();
      }}
      textAreaRef={textAreaRef}
      minRows={minRows}
      maxRows={maxRows}
      data-nusa-enabled={
        isTranscribeEnabledForTextField && !isAmazonTranscribeEnabled
          ? 'true'
          : 'false'
      }
      data-akido-transcribe-input={
        isTranscribeEnabledForTextField && isAmazonTranscribeEnabled
      }
      disabled={disabled}
    />
  );
});
