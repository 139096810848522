export function ChevronUpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='8'
      viewBox='0 0 13 8'
      fill='none'
      {...props}
    >
      <path
        d='M11.289 7.45312L6.66642 3.03448L2.04386 7.45312L0.623854 6.0928L6.66642 0.304188L12.709 6.0928L11.289 7.45312Z'
        fill={props.color || '#0078D8'}
      />
    </svg>
  );
}
