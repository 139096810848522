import { DefaultTheme } from './default';
import { Theme } from './theme';

export const WallboardTheme = {
  ...DefaultTheme,
  name: 'Wallboard',
  colors: {
    ...DefaultTheme?.colors,
    typography: {
      primary: '#FFFFFF',
      secondary: '#A1ABBD',
    },
    action: {
      primary: '#58A8FF',
      disabled: '#BEDDFF',
      pressed: 'rgba(88, 168, 255, 0.25)',
    },
    states: {
      error: {
        primary: '#FF6773',
        secondary: '#7A193C',
      },
      success: {
        primary: '#ACDC6E',
        secondary: '#267B39',
      },
      warning: {
        primary: '#FFAB5D',
        secondary: '#8D4C10',
      },
    },
  },
} as Theme;
