import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';
import { usePatientVitals } from '../hooks/usePatientVitals';
import {
  VitalsActionButton,
  VitalsActionButtonText,
  VitalsImageContainer,
} from './VitalsCommonComponents';
import { VitalsItems } from './VitalsItems';
import { RefreshButton } from '../RefreshButton';
import { FC, useEffect } from 'react';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { observer } from 'mobx-react-lite';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';
import FeatureFlaggedConditionalRender from '../../../components/FeatureFlaggedConditionalRender/FeatureFlaggedConditionalRender';
import { AkidoIdentifierArg } from '@akido/provider-desktop-bffe-types';

const vitalsHeartbeat = require('../../../assets/icons/vitalsIcon.svg').default;

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  textAlign: 'start',
  margin: '0 20px 20px 20px',
});

const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #E0E0E0',
  borderRadius: '24px',
  backgroundColor: '#FFFFFF99',
  minHeight: '159px',
  padding: '22px 27px 22px 34px',
  flex: 1,
});

const TitleRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const LeftTitle = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const RightTitle = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
});

const VitalsTitle = styled.div({
  fontWeight: 600,
  fontSize: '0.872rem',
});

const VitalsItemsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const VitalsUpdatedText = styled.div({
  fontWeight: 400,
  marginTop: '7px',
  fontSize: '0.58125rem',
  color: '#8AA2B5',
});

const BottomSection = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const ErrorText = styled.div({
  color: '#CC1E12',
  fontWeight: 500,
  fontSize: '0.775rem',
});

interface IProps {
  externalEncounterId?: string | null | undefined;
  resourceIdentifier?: AkidoIdentifierArg;
  sectionId?: string;
}

export const VitalsCard: FC<IProps> = observer((props) => {
  const refreshingClinicalNote = clinicalNoteStore.refreshing;
  const vitals = clinicalNoteRefreshableAutofillStore.vitals?.items;
  const refreshDateString =
    clinicalNoteRefreshableAutofillStore.vitals?.refreshDateString;
  const didError = clinicalNoteRefreshableAutofillStore.vitals?.didError;
  const { isLoading, refreshVitals } = usePatientVitals(
    props.externalEncounterId,
    props.resourceIdentifier
  );

  useEffect(() => {
    if (refreshingClinicalNote) {
      refreshVitals();
    }
  }, [refreshVitals, refreshingClinicalNote]);

  useEffect(() => {
    if (props.sectionId) {
      clinicalNoteStore.setAutofillData(props.sectionId, vitals);
    }
  }, [vitals, props.sectionId]);

  return (
    <Container>
      <Card>
        <TitleRow>
          <LeftTitle data-public>
            <VitalsImageContainer
              src={vitalsHeartbeat}
              alt='vitals stock image'
            />
            <VitalsTitle data-public>Vitals</VitalsTitle>
          </LeftTitle>
          <RightTitle>
            <FeatureFlaggedConditionalRender
              featureFlag={FEATURE_FLAGS.EDIT_IN_CAPELLA_BUTTON}
            >
              <VitalsActionButton>
                <VitalsActionButtonText data-public>
                  Edit in Capella
                </VitalsActionButtonText>
              </VitalsActionButton>
            </FeatureFlaggedConditionalRender>
            <RefreshButton
              isLoading={isLoading}
              onRefreshClicked={refreshVitals}
            />
          </RightTitle>
        </TitleRow>
        <VitalsItemsContainer
          data-testid={TestID.ClinicalNote.VitalsItemsContainer}
        >
          <VitalsItems vitals={vitals} />
        </VitalsItemsContainer>
        <BottomSection>
          <VitalsUpdatedText data-public>{refreshDateString}</VitalsUpdatedText>
          <ConditionalRender shouldRender={didError}>
            <ErrorText data-public>Error, Please Try Again</ErrorText>
          </ConditionalRender>
        </BottomSection>
      </Card>
    </Container>
  );
});
