import { useLazyQuery } from '@apollo/client';
import {
  getClinicalNoteQuery,
  GetClinicalNoteReturn,
} from '../../../services/graphql/clinicalNote';
import { useCallback, useEffect, useState } from 'react';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { isStringWithLength } from '../../../utilities/helpers';
import { ClinicalNoteScreens } from '../../../types/screens';

interface IProps {
  akidoNoteId?: string;
}

export const useClinicalNoteQuery = ({ akidoNoteId }: IProps) => {
  const [hasLoadedClinicalNoteReturn, setHasLoadedClinicalNoteReturn] =
    useState(false);
  const isRefreshingClinicalNote = clinicalNoteStore.refreshing;

  const [
    fetchClinicalNote,
    {
      loading,
      error: fetchClinicalNoteError,
      data: clinicalNoteReturn,
      refetch: getClinicalNoteRefetch,
    },
  ] = useLazyQuery<GetClinicalNoteReturn>(getClinicalNoteQuery, {
    variables: { id: akidoNoteId, screen: ClinicalNoteScreens.EDITOR },
    fetchPolicy: 'network-only',
  });

  const initialFetch = useCallback(async () => {
    setHasLoadedClinicalNoteReturn(false);
    await fetchClinicalNote();
    setHasLoadedClinicalNoteReturn(true);
  }, [fetchClinicalNote]);

  useEffect(() => {
    if (isStringWithLength(akidoNoteId)) {
      initialFetch();
    }
  }, [akidoNoteId, initialFetch]);

  const refreshFullClinicalNote = useCallback(async () => {
    setHasLoadedClinicalNoteReturn(false);
    await getClinicalNoteRefetch();
    clinicalNoteStore.setRefreshing(false);
    setHasLoadedClinicalNoteReturn(true);
  }, [getClinicalNoteRefetch]);

  useEffect(() => {
    if (isRefreshingClinicalNote) {
      refreshFullClinicalNote();
    }
  }, [
    getClinicalNoteRefetch,
    refreshFullClinicalNote,
    isRefreshingClinicalNote,
  ]);

  return {
    fetchClinicalNote,
    isClinicalNoteQueryLoading: loading,
    clinicalNoteReturn,
    fetchClinicalNoteError,
    hasLoadedClinicalNoteReturn,
    refreshFullClinicalNote,
  };
};
