import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface TitleProps {
  children: ReactNode;
}

const TitleText = styled.span`
  font-size: 1.1rem;
  font-weight: 400;
  margin-left: 24px;
`;

export const Title: FC<TitleProps> = (props) => {
  const { children } = props;

  return <TitleText>{children}</TitleText>;
};
