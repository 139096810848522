import { gql } from '@apollo/client';

export const searchPatients = gql`
  query SearchPatients(
    $patientId: AkidoIdentifierArg
    $givenName: String
    $familyName: String
    $fullName: String
  ) {
    searchPatients(
      patientId: $patientId
      givenName: $givenName
      familyName: $familyName
      fullName: $fullName
    ) {
      patients {
        fullName
        familyName
        givenName
        birthDate
        patientId
        accountNumber {
          type
          value
        }
      }
      hasMoreResults
    }
  }
`;
