import styled from 'styled-components';

export const VitalsActionButton = styled.button({
  display: 'flex',
  background: 'none',
  border: 'none',
});

export const VitalsActionButtonText = styled.div({
  color: '#1E88C1',
  fontWeight: 500,
  fontSize: '0.775rem',
});

export const VitalsImageContainer = styled.img({
  overflow: 'hidden',
  marginRight: '10px',
});
