import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { PatientInfoCard } from '../patientInfoCard/PatientInfoCard';
import { CategoryVariant } from '../../../types/clinical-notes';
import { VitalsCard } from '../vitals/VitalsCard';
import { patientStore } from '../../../stores/patient.store';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { getFields } from './utils';
import { SectionReadonlyAutofill } from './SectionReadonlyAutofill';
import { FC, useMemo } from 'react';
import { UISectionIds } from './uiSectionIds';
import { formatDate } from '../../../utilities/formatting';
import { ClientTimezones } from '../../../constants/clients';
import { get } from 'lodash';

const LeftSectionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  backgroundColor: '#F6F8FA',
  overflowY: 'scroll',
  border: '1px solid #D0D7E4',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  borderBottom: 'none',
  width: '29.843%',
});

interface IProps {
  currentId: string;
  isPatientLoading: boolean;
  handleHasBeenModified: () => void;
}

export const LeftSection: FC<IProps> = observer(
  ({ currentId, isPatientLoading, handleHasBeenModified }) => {
    const {
      givenName,
      familyName,
      formattedBirthDate,
      age: patientAge,
    } = patientStore;

    const dateOfService = clinicalNoteStore?.dateOfService
      ? formatDate(
          clinicalNoteStore.dateOfService.toISOString(),
          false,
          ClientTimezones.CMG
        )
      : '';

    const externalEncounterId = clinicalNoteStore.clinicalNote?.encounterId;
    const referringPractitionerFullName =
      clinicalNoteStore.referringPractitioner?.fullName;
    const accountNumber = patientStore?.accountNumber?.value;
    const sectionValues = clinicalNoteStore.sectionValues;
    const encounterIdentifier = get(
      clinicalNoteStore,
      'clinicalNote.encounter.encounterIdentifier'
    );
    delete encounterIdentifier?.__typename;

    const historyFields = useMemo(
      () => getFields(sectionValues, CategoryVariant.history),
      [sectionValues]
    );

    return (
      <LeftSectionContainer>
        <PatientInfoCard
          givenName={givenName}
          familyName={familyName}
          birthDate={formattedBirthDate}
          isLoading={isPatientLoading}
          patientAge={patientAge}
          referringPractitionerFullName={referringPractitionerFullName ?? ''}
          dateOfService={dateOfService}
          accountNumber={accountNumber}
        />
        {historyFields.map((field) => {
          if (field.uiSection?.toLowerCase() === UISectionIds.Vitals) {
            return (
              <VitalsCard
                key='field_vitals'
                externalEncounterId={externalEncounterId}
                resourceIdentifier={encounterIdentifier}
              />
            );
          }
          return (
            <SectionReadonlyAutofill
              key={`field_${field.uiSection}`}
              noteId={currentId}
              categoryToDisplay={CategoryVariant.history}
              field={{
                ...field,
                sectionId: field?.sectionId ?? field?.id,
              }}
              updateCallback={handleHasBeenModified}
            />
          );
        })}
      </LeftSectionContainer>
    );
  }
);
