import { Theme as ThemeType } from '../../components/AkidoUI/Themes';
import { Button } from '../../components/AkidoUI/Button';
import { TextField } from '../../components/AkidoUI/TextField';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import Microsoft from '../../assets/icons/microsoft-logo.svg';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';
import { LoadingSpinner, MessageBubble } from '../../components/AkidoUI';
import authStore from '../../stores/auth.store';
import { Theme } from '../../theme';
import { login, setupUser, storeAuthInfo } from '../../utilities/auth';
import { useAnalytics } from '../../hooks/useAnalytics';
import { urlLocationStore } from '../../stores/urlLocation.store';
import { TestID } from '../../constants/testIds';
import { getUserInfo, oktaAuth } from '../../config/okta';
import { FEATURE_FLAGS } from '../../config/launchdarkly';

const SignInForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  min-height: 85vh;
  width: 30%;
  min-width: 350px;
`;

const Title = styled.h1`
  color: ${({ theme }: { theme: ThemeType }) => theme?.colors?.action?.primary};
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2.5rem;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0;
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 0 1.3rem -0.5rem;
`;

const MicrosoftLabel = styled.label`
  font-size: 1em;
  display: flex;
  color: #5e5e5e;
`;

const StyledLogo = styled.img`
  width: 19px;
  height: 19px;
`;
const StyledMicrosoftButton = styled.button`
  width: 215px;
  height: 41px;
  background: #fff;
  border: 1px solid #8c8c8c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 38px;
`;

export const SignInPage = observer(() => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  let ldClient = useLDClient();
  const location = useLocation();
  const { setUserPropertiesOnFirebase } = useAnalytics();

  const shouldNavigateToSchedule = ldClient?.variation(
    FEATURE_FLAGS.LOGIN_TO_SCHEDULE,
    false
  );

  const shouldAutoredirect = ldClient?.variation(
    FEATURE_FLAGS.LOGIN_AUTOREDIRECT,
    false
  );

  const defaultNavigationRoute = useMemo(() => {
    if (
      shouldNavigateToSchedule &&
      authStore.authorizations.canViewOwnSchedules
    ) {
      return '/schedule';
    } else {
      return '/transcriptions';
    }
  }, [shouldNavigateToSchedule]);

  const handleNavigation = useCallback(async () => {
    navigate(defaultNavigationRoute);
  }, [navigate, defaultNavigationRoute]);

  useEffect(() => {
    if (authStore?.isAuthenticated && shouldNavigateToSchedule) {
      handleNavigation();
    } else if (shouldAutoredirect) {
      oktaAuth.signInWithRedirect({
        originalUri: window.location.href,
      });
    }
  }, [
    navigate,
    shouldNavigateToSchedule,
    shouldAutoredirect,
    handleNavigation,
  ]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const isValidEmail = Yup.string().email().required().isValidSync(email);

    if (!isValidEmail) {
      setHasError(true);
      return;
    }

    setHasError(false);

    try {
      const result = await login(email, password);
      if (result.status !== 'SUCCESS') {
        setHasError(true);
        return;
      }

      setHasError(false);

      await storeAuthInfo(result?.user, authStore);

      const { specialty, userId, role } = await getUserInfo();

      setUserPropertiesOnFirebase({
        userId: userId,
        specialty: specialty,
        role: role,
      });

      setupUser(email);

      await ldClient?.identify({ key: email, email, kind: 'user' });

      Sentry.addBreadcrumb({
        category: 'auth',
        message: `Authenticated user ${email}`,
        level: 'info',
      });

      Sentry.setUser({ email });

      if (location.state) {
        // Auto-navigate to the url the user wanted to access in the first place
        const path = get(
          location,
          'state.from.pathname',
          defaultNavigationRoute
        );
        const params = get(location, 'state.from.search', '');
        navigate(`${path}${params}`);
      } else {
        if (urlLocationStore.autoLoggedOutFrom) {
          navigate(urlLocationStore.autoLoggedOutFrom);
          urlLocationStore.reset();
        } else {
          const defaultRoute =
            shouldNavigateToSchedule &&
            authStore.authorizations.canViewOwnSchedules
              ? '/schedule'
              : '/transcriptions';
          navigate(defaultRoute);
        }
      }
    } catch (e) {
      setHasError(true);
    }
  }
  if (shouldAutoredirect) {
    return <LoadingSpinner testId='loading-spinner' />;
  }
  return (
    <SignInForm data-testid='sign-in-page'>
      <Title>Log In</Title>
      <MessageBubble
        text='You have been logged out due inactivity'
        type='warning'
        icon={<Warning />}
        isVisible={!searchParams.get('timeout')}
      />
      <Field>
        <LabelContainer>
          <Label>Email:</Label>
        </LabelContainer>

        <TextField
          testId={TestID.Auth.SignInEmailField}
          isEmail
          fullWidth
          inputStyles={{
            padding: '1rem .5rem',
            fontSize: '1.25rem',
            borderColor: hasError ? '#CC1E12' : undefined,
          }}
          placeholder='example@cmg.akidolabs.com'
          value={email}
          error={hasError ? ' ' : undefined}
          onChange={(value) => setEmail(value)}
        />
      </Field>

      <Field>
        <LabelContainer>
          <Label>Password:</Label>
        </LabelContainer>

        <TextField
          testId={TestID.Auth.SignInPasswordField}
          placeholder='Enter your password'
          fullWidth
          isPassword
          inputStyles={{
            padding: '1rem .5rem',
            fontSize: '1.25rem',
            borderColor: hasError ? '#CC1E12' : undefined,
          }}
          error={hasError ? 'Email or password are incorrect.' : undefined}
          errorStyles={{
            color: hasError ? '#CC1E12' : undefined,
            fontSize: '.9rem',
            margin: 0,
          }}
          value={password}
          onChange={(value) => setPassword(value)}
        />
      </Field>

      <Button
        testId={TestID.Auth.SignInSubmitButton}
        theme={Theme}
        disabled={!email || !password}
        variant='primary'
        size='large'
        styles={{ padding: '1.9rem 4.5rem', marginTop: '3rem' }}
        label='Log In'
        onClick={handleSubmit}
      />

      <StyledMicrosoftButton
        name='microsoft'
        onClick={() => {
          oktaAuth.signInWithRedirect({
            originalUri: window.location.href,
          });
        }}
      >
        <StyledLogo src={Microsoft} alt='microsoft' />
        <MicrosoftLabel>Sign in with Microsoft</MicrosoftLabel>
      </StyledMicrosoftButton>
    </SignInForm>
  );
});
