import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { ModalCloseButton } from './ModalCloseButton';
import { Modal } from '../AkidoUI/Modal';
import { Button } from '../AkidoUI/Button';
import ConditionalRender from '../ConditionalRender/ConditionalRender';

interface ActionsModalProps {
  isVisible: boolean;
  onAccept?: () => void;
  onDecline: () => void;
  testId: string;
  children?: ReactNode;
  title?: string;
  declineLabel?: string;
  acceptLabel?: string;
  contentStyles?: Record<string, any>;
  showCloseButton?: boolean;
  contentClassNames?: string;
}

const Container = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
`;

const Header = styled.div`
  margin-bottom: 12px;
`;

const Content = styled.div`
  overflow: hidden;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

const ButtonRow = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

const TitleText = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  margin-left: 24px;
  color: #0c1e2c;
`;

const ModalText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: #333333;
`;

export const ActionsModal: FC<ActionsModalProps> = ({
  isVisible,
  onAccept,
  onDecline,
  testId,
  children,
  title,
  declineLabel,
  acceptLabel,
  contentStyles = {},
  showCloseButton = true,
  contentClassNames,
}) => {
  return (
    <Modal
      contentStyles={{
        border: 'none',
        width: '25.857rem',
        height: '17.25rem',
        padding: '28px 44px 44px 44px',
        boxSizing: 'border-box',
        borderRadius: '16px',
        ...contentStyles,
      }}
      isVisible={isVisible}
      onClose={showCloseButton ? onDecline : () => {}}
    >
      <Container>
        <Header>
          <ConditionalRender shouldRender={showCloseButton}>
            <ModalCloseButton
              onClick={onDecline}
              testId={`${testId}-close-button`}
            />
            <TitleText>{title}</TitleText>
          </ConditionalRender>
        </Header>
        <Content className={contentClassNames}>
          <ModalText>{children}</ModalText>
        </Content>
        <ButtonRow>
          <Button
            testId={`${testId}-decline`}
            label={declineLabel ?? 'Back to Editing'}
            styles={{ backgroundColor: 'white', borderColor: 'white' }}
            labelStyles={{
              fontSize: '1rem',
              textTransform: 'none',
              weight: 600,
              color: '#365986',
            }}
            onClick={onDecline}
          />
          <Button
            label={acceptLabel ?? 'OK'}
            styles={{ borderRadius: '16px' }}
            labelStyles={{
              fontSize: '1rem',
              textTransform: 'none',
              weight: 600,
            }}
            onClick={onAccept}
            testId={`${testId}-accept`}
          />
        </ButtonRow>
      </Container>
    </Modal>
  );
};
