import {
  useNavigation,
  useDayPicker,
  type CaptionProps,
} from 'react-day-picker';
import { Select } from '../../AkidoUI/Select/Select';
import { generateDropdownMonths, generateDropdownYears } from './utils';
import { TestID } from '../../../constants/testIds';
import { DateTime } from 'luxon';

// Get the month from a date object
// This is used to generate the month dropdown options labels
function getMonthLabel(date: Date) {
  return DateTime.fromJSDate(date).toFormat('LLLL');
}

type MonthSelectOption = {
  label: string;
  value: number;
};

type YearSelectOption = {
  label: number;
  value: number;
};

export function CaptionComponent(props: CaptionProps) {
  const { displayMonth } = props;
  const { goToMonth } = useNavigation();
  const { fromDate, toDate } = useDayPicker();

  if (!fromDate || !toDate) return null;

  // track the values for the month and year dropdowns
  const monthValue = {
    value: displayMonth.getMonth(),
    label: getMonthLabel(displayMonth),
  };

  const yearValue = {
    value: displayMonth.getFullYear(),
    label: displayMonth.getFullYear(),
  };

  // Handler for changing the date picker's month
  // Updates the viewed month in the date picker
  function handleMonthChange(option: MonthSelectOption) {
    const newDate = new Date(displayMonth);
    newDate.setMonth(option.value);
    goToMonth(newDate);
  }

  // Handler for changing the date picker's year
  // Updates the viewed year in the date picker
  function handleYearChange(option: YearSelectOption) {
    const newDate = new Date(displayMonth);
    newDate.setFullYear(option.value);
    goToMonth(newDate);
  }

  // Generate the options for the month and year dropdowns
  const yearOptions: YearSelectOption[] = generateDropdownYears(
    fromDate.getFullYear(),
    toDate.getFullYear()
  ).map((year) => ({
    label: year.getFullYear(),
    value: year.getFullYear(),
  }));

  const monthOptions: MonthSelectOption[] = generateDropdownMonths(
    fromDate,
    toDate
  ).map((month) => ({
    label: getMonthLabel(month),
    value: month.getMonth(),
  }));

  return (
    <div className='flex gap-4'>
      {/* Month Select */}
      <Select
        className='w-full'
        value={monthValue}
        onChange={handleMonthChange}
      >
        <Select.Button
          data-public
          data-testid={TestID.Calendar.CalendarMonthSelect}
        >
          {({ value }) => value.label}
        </Select.Button>
        <Select.Options>
          {monthOptions.map((monthOption) => (
            <Select.Option
              data-public
              key={monthOption.value}
              value={monthOption}
              isSelected={monthValue.value === monthOption.value}
            >
              {monthOption.label}
            </Select.Option>
          ))}
        </Select.Options>
      </Select>

      {/* Year Select */}
      <Select className='w-full' value={yearValue} onChange={handleYearChange}>
        <Select.Button data-testid={TestID.Calendar.CalendarYearSelect}>
          {({ value }) => value.label}
        </Select.Button>
        <Select.Options>
          {yearOptions.map((yearOption) => (
            <Select.Option
              data-public
              key={yearOption.value}
              value={yearOption}
              isSelected={yearValue.value === yearOption.value}
            >
              {yearOption.label}
            </Select.Option>
          ))}
        </Select.Options>
      </Select>
    </div>
  );
}
