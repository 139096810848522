import { useLDClient } from 'launchdarkly-react-client-sdk';
import ConditionalRender from '../ConditionalRender/ConditionalRender';
import { FeatureFlagsValues } from '../../config/launchdarkly';

interface IProps {
  featureFlag: FeatureFlagsValues;
  children: JSX.Element;
}

function FeatureFlaggedConditionalRender({
  featureFlag,
  children,
}: IProps): JSX.Element | null {
  const ldClient = useLDClient();

  const isFlagOn = ldClient?.variation(featureFlag, false);

  return (
    <ConditionalRender shouldRender={isFlagOn}>{children}</ConditionalRender>
  );
}

export default FeatureFlaggedConditionalRender;
