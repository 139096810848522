import {
  ClinicalNoteModel,
  DragonSpeechToTextSecrets,
  FhirEncounterModel,
  TemplateModel,
} from '@akido/provider-desktop-bffe-types';

export type ClinicalNoteFieldValue = {
  id: string;
  title?: string;
  type?: string | null;
  text?: string;
  category?: string;
  defaultText?: string;
  autofillData?: string[] | null;
  autoFillFrom?: string;
  placeholderText?: string;
  autofillFrom?: string | null;
  sortOrder?: number;
  uiSection?: string | null;
  sectionId?: string;
  requestId: string | null;
  refreshCallback?: () => void;
};

export type ClinicalNoteContent = {
  title: string;
  templateId: string;
  sections: ClinicalNoteFieldValue[];
  version?: number;
};

export type ClinicalNoteCreateDtoType = {
  encounterId: string;
  content?: ClinicalNoteContent;
};

export type ClinicalNoteUpdateDtoType = {
  id: string;
  content?: ClinicalNoteContent;
  override?: boolean;
};

export type ClinicalNoteAIAnalysisDtoType = ClinicalNoteUpdateDtoType;

export type ClinicalNoteSignDtoType = {
  id: string;
};

export type ClinicalNoteSignModel = {
  id: string;
};

export enum ClinicalNoteStatus {
  Draft = 'DRAFT',
  Signed = 'SIGNED',
}

export type ClinicalNoteAddendum = {
  createdAt: string;
  updatedAt: string;
  id: string;
  clinicalNoteId: string;
  createdBy: string;
  userId: string;
  text: string;
};

export enum CategoryVariant {
  history = 'History',
  encounter = 'Encounter',
  assessmentAndPlan = 'Assessment and Plan',
}

export enum ClinicalNoteSaveStatus {
  isIdle = 'isIdle',
  hasSaveError = 'hasSaveError',
  isSaving = 'isSaving',
  hasSaved = 'hasSaved',
}

export type DisplayLabelArray = { displayLabel: string }[];

export enum AINoteSectionTypes {
  AISummary = 'ai-generated',
  Transcription = 'transcription',
}

export type ClinicalNoteEditor = {
  clinicalNote: ClinicalNoteModel;
  getFhirEncounter?: FhirEncounterModel;
  dragonSpeechToTextSecrets: DragonSpeechToTextSecrets;
  clinicalNoteTemplates: { templates: TemplateModel[] };
};
