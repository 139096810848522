import { getAnalytics } from '@firebase/analytics';
import { initializeApp } from '@firebase/app';
import { REACT_APP_FIREBASE_CONFIG } from '../../config/environment';

export const initFirebase = () => {
  const firebaseConfig = JSON.parse(REACT_APP_FIREBASE_CONFIG ?? '');

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
};
