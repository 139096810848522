import { gql } from '@apollo/client';

export type AISummarySubscriptionVariables = {
  requestId: string;
};

export type AISummarySubscriptionResponse = {
  aiSummaryStreamingUpdated: {
    requestId: string;
    content?: string;
    id?: string;
    order?: number;
  };
};

export const aiSummaryStreamingSubscription = gql`
  subscription AISummary($requestId: String!) {
    aiSummaryStreamingUpdated(requestId: $requestId) {
      requestId
      content
      order
      id
    }
  }
`;
