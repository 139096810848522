import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { ClinicalNotePreviewColors } from '../../../constants/colors';

const Container = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
  verticalAlign: 'middle',
  paddingBottom: '10px',
});

const PatientInfoHeader = styled.span({
  fontSize: '0.688rem',
  marginRight: '5px',
  color: ClinicalNotePreviewColors.HEADER_TEXT,
});

const PatientInfoText = styled.span({
  fontSize: '0.775rem',
  color: ClinicalNotePreviewColors.HEADER_TEXT,
});

interface PatientInfoLabelProps {
  header?: string;
  value?: string;
  testId?: string;
  containerStyles?: CSSProperties;
}

export const PatientInfoLabel: FC<PatientInfoLabelProps> = ({
  header,
  value,
  testId,
  containerStyles,
}) => {
  if (!value) return null;

  return (
    <Container
      style={containerStyles}
      data-testid={testId ?? 'patient-info-label-container'}
    >
      {header ? (
        <PatientInfoHeader>{`${header ?? ''} `} </PatientInfoHeader>
      ) : null}
      <PatientInfoText data-private>{value}</PatientInfoText>
    </Container>
  );
};
