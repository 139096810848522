import * as Sentry from '@sentry/react';
import { captureConsoleIntegration } from '@sentry/integrations';
import { REACT_APP_ENV, REACT_APP_SENTRY_DSN } from '../../config/environment';
import { Environments } from '../../config/environmentEnum';

export const initSentry = () => {
  if (REACT_APP_ENV !== Environments.local) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        captureConsoleIntegration({
          levels: ['error', 'debug'],
        }),
      ],
      environment: REACT_APP_ENV,
      tracesSampleRate: 0.3,
    });
  }
};
