import { OnDataOptions, useSubscription } from '@apollo/client';
import {
  aiSummaryStreamingSubscription,
  AISummarySubscriptionResponse,
  AISummarySubscriptionVariables,
} from '../services/graphql/aiSummaryStreamingSubscription';
import { clinicalNoteStore } from '../stores/clinicalNote.store';
import { useEffect } from 'react';

export const onData = (
  options: OnDataOptions<AISummarySubscriptionResponse>
) => {
  const aiOutput = options?.data?.data?.aiSummaryStreamingUpdated?.content;

  clinicalNoteStore.setAISummaryContent(aiOutput);
};

export const useAIOutput = (requestId: string) => {
  useEffect(() => {
    clinicalNoteStore.setAISummarySectionId();
  }, [requestId]);

  return useSubscription<
    AISummarySubscriptionResponse,
    AISummarySubscriptionVariables
  >(aiSummaryStreamingSubscription, {
    variables: {
      requestId,
    },
    shouldResubscribe: true,
    onData,
  });
};
