import styled from 'styled-components';
import { LeftSection } from './sections/LeftSection';
import { CenterSection } from './sections/CenterSection';
import { RightSection } from './sections/RightSection';
import { SaveBar } from './SaveBar';
import { SaveMessage } from './SaveMessage';
import { TestID } from '../../constants/testIds';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { clinicalEditorPageState } from '../../stores/clinicalEditorPageState.store';
import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';
import { LoadingSpinner } from '../../components';
import { FEATURE_FLAGS } from '../../config/launchdarkly';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useAINotePage } from '../../hooks/useAINotePage';
import { ClinicalNoteNewVersionObserver } from '../../components/TrackUserInactivity/ClinicalNoteNewVersionObserver';

const PageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 58px)', //Acount for header height
  maxWidth: '2560px',
  alignSelf: 'center',
  width: '100%',
});

interface MainContainerProps {
  isVisible: boolean;
}

const MainContainer = styled.div<MainContainerProps>((props) => ({
  display: props.isVisible ? 'flex' : 'none',
  flex: 1,
  flexDirection: 'row',
  width: '100%',
  overflow: 'hidden',
}));

const LoadingContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: 'calc(100% - 58px)', //Account for header height
  justifyContent: 'center',
  alignItems: 'center',
});

interface IProps {
  pageTestId: string;
  noteId: string;
  isPatientLoading: boolean;
  clinicalNote: ClinicalNoteModel | undefined;
  saveNote: (arg0?: boolean) => void;
  onNoteSign: () => Promise<void>;
  isSaving: boolean;
  hasSuccessfullySaved: boolean;
  hasSaveError: boolean;
  isDataForEditorLoading: boolean;
  isLoadingFromExternalId: boolean;
}

export const ClinicalNoteEditorPage: FC<IProps> = observer(
  ({
    pageTestId,
    noteId,
    isPatientLoading,
    clinicalNote,
    saveNote,
    onNoteSign,
    isSaving,
    hasSuccessfullySaved,
    hasSaveError,
    isDataForEditorLoading,
    isLoadingFromExternalId,
  }) => {
    const handleHasBeenModified = useCallback(() => {
      clinicalEditorPageState.setHasBeenModifiedToTrue();
    }, []);

    const isAutoSaveAfterLoadEnabled = useFeatureFlag(
      FEATURE_FLAGS.IMMEDIATE_AUTOSAVE_ON_EXTERNAL_ID_LOAD,
      false
    );
    let isContentVisible = true;
    useAINotePage();

    if (isAutoSaveAfterLoadEnabled) {
      isContentVisible = !isLoadingFromExternalId;
    }
    if (isDataForEditorLoading) {
      return (
        <LoadingContainer data-testid='clinical-notes-loading'>
          <LoadingSpinner />
        </LoadingContainer>
      );
    }

    // Here we're hiding the content if we're loading from an external ID from the first time
    // After the note has been save, we'll be redirected to this page using the Akido note ID
    // the reason for hiding (display: none) is that there's still some logic inside the sections that still needs to be run on the first load
    // TODO: we'd need to refactor the whole fetching flow, e.g. use actions in Mobx
    return (
      <ClinicalNoteNewVersionObserver>
        <PageContainer data-testid={pageTestId}>
          <MainContainer
            isVisible={isContentVisible}
            data-testid={TestID.ClinicalNote.ClinicalNotesEditorContainer}
          >
            <LeftSection
              currentId={noteId}
              isPatientLoading={isPatientLoading}
              handleHasBeenModified={handleHasBeenModified}
            />
            <CenterSection
              noteId={noteId}
              isContentVisible={isContentVisible}
              clinicalNote={clinicalNote}
              handleHasBeenModified={handleHasBeenModified}
            />
            <RightSection
              noteId={noteId}
              handleHasBeenModified={handleHasBeenModified}
            />
          </MainContainer>
          {isContentVisible ? (
            <>
              <SaveBar onSave={saveNote} onSign={onNoteSign} />
              <SaveMessage
                testId={TestID.ClinicalNote.EditorStatusBox}
                saving={isSaving}
                showOnStart={hasSuccessfullySaved}
                error={hasSaveError}
              />
            </>
          ) : null}
        </PageContainer>
      </ClinicalNoteNewVersionObserver>
    );
  }
);
