import { gql } from '@apollo/client';

export const getAssessments = gql`
  query getAssessments(
    $externalId: String
    $encounterIdentifier: AkidoIdentifierArg
  ) {
    getFhirEncounter(
      externalId: $externalId
      encounterIdentifier: $encounterIdentifier
    ) {
      assessments {
        list {
          displayLabel
        }
      }
    }
  }
`;
