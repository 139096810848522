import * as React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'typeface-roboto';
import './index.css';
import { initFirebase } from './services/firebase/initFirebase';
import { initSentry } from './services/sentry/initSentry';
import { initDatadogRum } from './services/datadog/datadog';

declare global {
  interface Window {
    zE: any;
    AkidoTranscribe?: {
      focusedElement: HTMLElement | null;
    };
  }
}

initDatadogRum();
initFirebase();
initSentry();

window.AkidoTranscribe = {
  focusedElement: null,
};

window.addEventListener(
  'focus',
  (event) => {
    const element = event.target as HTMLElement;

    if (element?.getAttribute?.('data-akido-transcribe-input') === 'true') {
      window.AkidoTranscribe = {
        focusedElement: element,
      };
    }
  },
  true
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
