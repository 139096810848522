import { Children, useMemo } from 'react';
import styled from 'styled-components';

export interface TabsProps {
  sections: {
    label: string;
    value: string;
  }[];
  activeTab?: string;
  children: React.ReactNode[];
  additionalHeader?: React.ReactNode;
  setActiveTab: (tab: string) => void;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  padding-left: 28px;
  padding-right: 28px;
  flex-direction: column;
  align-items: flex-start;
`;

const Button = styled.button`
  background: transparent;
  border-radius: 8px;
  border: 0;
  padding: 15px 20px;
  color: #2f80ed;
  font-weight: bold;

  &.active {
    background: #e3f3ff;
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
  }
`;

const TabsContent = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`;

const TabsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ChildWrapper = styled.div`
  visibility: visible;
  width: 100%;

  &.hidden {
    visibility: hidden;
    max-height: 0;
    width: 0;
  }
`;

export function Tabs({
  sections,
  activeTab: defaultActiveTab,
  children,
  setActiveTab,
  additionalHeader = null,
}: TabsProps) {
  const activeTabIndex = useMemo(() => {
    return sections.findIndex(({ value }) => value === defaultActiveTab);
  }, [defaultActiveTab, sections]);

  const content = Children.toArray(children);

  return (
    <Container>
      <TabsHeader>
        <div>
          {sections.map(({ label, value }, idx) => {
            return (
              <Button
                key={`tab_header_${idx}`}
                type='button'
                className={idx === activeTabIndex ? 'active' : ''}
                onClick={() => setActiveTab(value)}
              >
                {label}
              </Button>
            );
          })}
        </div>
        {additionalHeader}
      </TabsHeader>
      <TabsContent>
        {Children.map(content, (child, index) => {
          const active = activeTabIndex === index;
          return (
            <ChildWrapper
              className={`${!active ? 'hidden' : ''}`}
              key={`child_tab_${index}`}
            >
              {child}
            </ChildWrapper>
          );
        })}
      </TabsContent>
    </Container>
  );
}
