import { ReactNode } from 'react';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  width: 260px;
  padding-left: 30px;
  padding-top: 30px;
  box-shadow: 5px 0px 5px -5px rgba(0, 0, 0, 0.2);
`;

export function Sidebar({ children }: { children: ReactNode }) {
  return <SidebarContainer>{children}</SidebarContainer>;
}
