import { FC, SVGProps } from 'react';
import clsx from 'clsx';

export interface EmptyViewProps {
  Icon: FC<SVGProps<SVGSVGElement> & { title?: string }>;
  title: string;
  subtitle?: string;
  classNames?: {
    container?: string;
    title?: string;
    subtitle?: string;
    icon?: string;
  };
  testId?: {
    container?: string;
    title?: string;
    subtitle?: string;
    icon?: string;
  };
}

export const EmptyView: FC<EmptyViewProps> = (props) => {
  const { Icon, testId, classNames, title, subtitle } = props;

  return (
    <div
      data-testid={testId?.container}
      className={clsx('flex flex-col items-center', classNames?.container)}
    >
      <h1
        data-testid={testId?.title}
        className={clsx('text-lg font-bold text-gray-900', classNames?.title)}
      >
        {title}
      </h1>
      {subtitle && (
        <p
          data-testid={testId?.subtitle}
          className={clsx('mt-2 text-sm text-gray-900', classNames?.subtitle)}
        >
          {subtitle}
        </p>
      )}
      <Icon
        data-testid={testId?.icon}
        style={{ width: '212.1px', height: '172.2px', marginTop: '31.5px' }}
        className={classNames?.icon}
      />
    </div>
  );
};
