import { InputElement } from '../types';
import { transcriptionSubject } from './transcription-subject';

export const updateTranscription = (
  text?: string,
  previousText?: string | null
) => {
  const focusedElement = window.AkidoTranscribe?.focusedElement as InputElement;

  if (!focusedElement || !text) {
    return;
  }

  transcriptionSubject.next({
    id: focusedElement.id,
    text: `${previousText} ${text}`,
  });
};
