import { Button } from '../../../components/AkidoUI/Button';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { editScribeNoteModalStore } from '../../../stores/editScribeNoteModal.store';

interface SaveScribeNoteButtonProps {
  onClick?: (note: string) => void;
}

export const SaveScribeNoteButton: FC<SaveScribeNoteButtonProps> = observer(
  (props) => {
    const { onClick } = props;

    const currentNote = editScribeNoteModalStore.note ?? '';

    const _onClick = useCallback(() => {
      editScribeNoteModalStore.setMode('view');
      onClick?.(currentNote);
    }, [onClick, currentNote]);

    if (!editScribeNoteModalStore.isEditMode) {
      return null;
    }

    return (
      <Button
        onClick={_onClick}
        styles={{
          width: '240px',
          alignSelf: 'center',
          borderRadius: '16px',
          marginTop: 12,
        }}
        labelStyles={{
          fontSize: '0.9rem',
          textTransform: 'none',
        }}
        label='Save'
      />
    );
  }
);
