export function UserIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='26'
      height='22'
      viewBox='0 0 26 22'
      fill={props.color || '#0078D8'}
      stroke={props.color || '#0078D8'}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13 15C16.3137 15 19 11.866 19 8C19 4.13401 16.3137 1 13 1C9.68629 1 7 4.13401 7 8C7 11.866 9.68629 15 13 15Z'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path d='M0.875 21.9988C2.10445 19.8708 3.87227 18.1037 6.00085 16.8752C8.12944 15.6467 10.5438 15 13.0015 15C15.4592 15 17.8736 15.6468 20.0021 16.8754C22.1307 18.1039 23.8985 19.871 25.1279 21.9991' />
    </svg>
  );
}
