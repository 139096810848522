import { DateTime } from 'luxon';

export class VitalsParsingUtils {
  static createDateString(fromIsoString?: string) {
    let startDate = DateTime.now();

    if (fromIsoString) {
      startDate = DateTime.fromISO(fromIsoString);
    }

    let dateString = startDate
      .setZone('America/Los_Angeles')
      .toLocaleString(DateTime.DATETIME_SHORT);

    dateString = dateString.replace(',', '');

    dateString = dateString.replace(/\sAM/g, 'a');
    dateString = dateString.replace(/\sPM/g, 'p');

    return `Updated ${dateString}`;
  }

  static createPlaceHolderArrayOfVitals() {
    let vitalsStringArray: string[] = [];

    vitalsStringArray.push('Ht: --');
    vitalsStringArray.push('Wt: --');
    vitalsStringArray.push('BMI: --');
    vitalsStringArray.push('BP: --');

    return vitalsStringArray;
  }
}
