export function TimeSlotWrapper(props: any) {
  return (
    <div
      style={{
        fontSize: '14px',
        color: '#333',
      }}
    >
      {props.children}
    </div>
  );
}
