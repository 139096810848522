import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useClinicalNoteEditor } from './hooks/useClinicalNoteEditor';
import { clinicalEditorPageState } from '../../stores/clinicalEditorPageState.store';
import { LoadingSpinner } from '../../components';
import { RoutePaths } from '../../constants/routes';
import { ErrorCodes } from '../ErrorPage/ErrorPage';
import { useCallback, useEffect } from 'react';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';
import { getIdentifierFromURL } from '../../utilities/helpers';
import { ClinicalNoteEditorPage } from './ClinicalNoteEditorPage';
import { useHandleOnClinicalNotePageSave } from './hooks/useHandleOnClinicalNotePageSave';
import { localClinicalNotesStore } from '../../stores/localPersistentClinicalNotes.store';
import { useAnalytics } from '../../hooks/useAnalytics';
import { ClinicalNoteAnalyticsEventName } from '../../constants/analytics';
import useNUSAProcessingWatcher from '../../hooks/useNUSAProcessingWatcher';
import { useUserAuthorization } from '../../hooks/useUserAuthorization';
import { useNoteEditorAutoSave } from './hooks/useNoteEditorAutoSave';
import { useAuthInfo } from './hooks/useAuthInfo';
import { useQuery } from '@apollo/client';
import { getAmazonTranscribeCreds } from '../../services/graphql/credentials';
import { observer } from 'mobx-react-lite';
import { useClinicalNoteQuery } from './hooks/useClinicalNoteQuery';

const LoadingContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: 'calc(100% - 58px)', //Account for header height
  justifyContent: 'center',
  alignItems: 'center',
});

export const ClinicalNoteEditor = observer(() => {
  useNUSAProcessingWatcher();
  useUserAuthorization();
  const { sendClinicalNoteAnalyticsEvent } = useAnalytics();
  const { id: akidoNoteId } = useParams();
  const [searchParams] = useSearchParams();
  const { tenant, specialty } = useAuthInfo();
  const navigate = useNavigate();

  const externalEncounterId = searchParams.get('externalEncounterId');
  const client = searchParams.get('client');
  const resourceIdType = searchParams.get('resourceIdType');
  const resourceId = searchParams.get('resourceId');

  const identifier = getIdentifierFromURL({
    akidoNoteId,
    externalEncounterId,
    resourceIdType,
    resourceId,
  });

  const {
    hasSaved: hasSuccessfullySaved,
    isSaving,
    hasSaveError,
  } = clinicalEditorPageState;

  // get data
  const { loading, error, getClinicalNoteEditorData } = useClinicalNoteEditor(
    'EDITOR',
    specialty,
    identifier,
    client ?? tenant
  );

  // need this for the full page refresh
  useClinicalNoteQuery({ akidoNoteId });

  // This primes the cache so the credentials when the transcribe control is clicked
  useQuery(getAmazonTranscribeCreds);

  useEffect(() => {
    getClinicalNoteEditorData();
    // If I add the function as dependency I'm getting infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { saveNote } = useHandleOnClinicalNotePageSave({
    akidoNoteId,
    currentId: null,
    externalEncounterId: clinicalNoteStore?.clinicalNote?.encounterId,
  });

  useNoteEditorAutoSave({
    saveNote,
    hasLoadLocal: clinicalNoteStore.isLoaded,
  });

  const onNoteSign = useCallback(async () => {
    const noteId = await saveNote();
    if (externalEncounterId) {
      localClinicalNotesStore.deleteClinicalNoteById(externalEncounterId);
    }

    sendClinicalNoteAnalyticsEvent(ClinicalNoteAnalyticsEventName.PreviewNote, {
      capella_note_id: clinicalNoteStore.clinicalNote?.encounterId,
      akido_note_id: noteId,
    });
    navigate(`${RoutePaths.ClinicalNotePreview.replace(':id', noteId)}`);
  }, [externalEncounterId, navigate, saveNote, sendClinicalNoteAnalyticsEvent]);

  // save data locally
  if (loading) {
    return (
      <LoadingContainer data-testid='clinical-notes-loading'>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (!clinicalNoteStore?.id && error) {
    navigate(`${RoutePaths.Error}?code=${ErrorCodes.ENCOUNTER_NOT_FOUND}`);
  }

  if (
    clinicalNoteStore.clinicalNote?.id &&
    (externalEncounterId || resourceId)
  ) {
    navigate(
      `${RoutePaths.ClinicalNotesWithId.replace(
        ':id',
        clinicalNoteStore.clinicalNote?.id
      )}`,
      { replace: true }
    );
  }

  // show component
  return (
    <ClinicalNoteEditorPage
      pageTestId={'clinical-notes-editor-page-new-editor'}
      noteId={clinicalNoteStore.externalId ?? ''}
      isPatientLoading={false}
      clinicalNote={clinicalNoteStore.clinicalNote}
      saveNote={saveNote}
      onNoteSign={onNoteSign}
      isSaving={isSaving}
      hasSuccessfullySaved={hasSuccessfullySaved}
      hasSaveError={hasSaveError}
      isDataForEditorLoading={false}
      isLoadingFromExternalId={false}
    />
  );
});
