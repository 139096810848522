import {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SelectInstance,
  ActionMeta,
  OptionsOrGroups,
  GroupBase,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { DropdownIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';
import styled from 'styled-components';
import { ReactComponent as ArrowDropdownDown } from '../../../assets/icons/arrow-dropdown-down.svg';
import { randomId } from '../../../utilities/helpers';
import { CustomFormatOptionLabel, SelectOption, SelectStyles } from './types';
import ConditionalRender from '../../ConditionalRender/ConditionalRender';

interface AsyncSelectProps<T> {
  className?: string;
  id?: string;
  label?: string;
  name: string;
  onChange?: (option: SelectOption<T>, action: string) => void;
  onInputChange?: (value: string) => void;
  customFormatOptionLabel?: CustomFormatOptionLabel;
  selectedOption?: T | undefined;
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionsOrGroups<T, GroupBase<T>>) => void
  ) => void;
  options?: SelectOption<T>[];
  placeholder?: string;
  isAsync?: boolean;
  styles?: SelectStyles;
}

const DropdownArrow: FunctionComponent<
  DropdownIndicatorProps & { isOpen: boolean }
> = (props) => {
  const { isOpen, getStyles } = props;

  const style = getStyles('dropdownIndicator', props) as CSSProperties;

  return (
    <ArrowDropdownDown
      style={{ ...style, transform: isOpen ? 'rotate(180deg)' : '' }}
    ></ArrowDropdownDown>
  );
};

const Label = styled.label`
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 12px;
  align-self: flex-start;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export function SelectAsync<T>(props: AsyncSelectProps<T>) {
  const {
    options,
    onChange,
    customFormatOptionLabel,
    placeholder,
    name,
    label,
    id,
    className,
    styles,
    selectedOption,
    loadOptions,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<SelectInstance | null>(null);

  const selectId = id ?? randomId(8);

  const onSelectOptionChange = useCallback(
    (value: SelectOption<T>, actionMeta: ActionMeta<SelectOption>) => {
      onChange?.(value, actionMeta.action);
    },
    [onChange]
  );

  const selected = options?.find((option) => option.value === selectedOption);

  useEffect(() => {
    if (!selected) {
      ref.current?.clearValue();
    }
  }, [selected]);

  return (
    <Container data-private className={className} style={styles?.container}>
      <ConditionalRender shouldRender={Boolean(label)}>
        <Label htmlFor={selectId} style={styles?.label}>
          {label}
        </Label>
      </ConditionalRender>
      <AsyncSelect
        data-private
        ref={ref}
        onChange={(value, actionMeta) =>
          onSelectOptionChange(
            value as SelectOption<T>,
            actionMeta as ActionMeta<SelectOption>
          )
        }
        inputId={selectId}
        value={selected as unknown}
        name={name}
        escapeClearsValue
        menuPlacement='auto'
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        components={{
          DropdownIndicator: (props) => DropdownArrow({ ...props, isOpen }),
        }}
        placeholder={placeholder}
        formatOptionLabel={customFormatOptionLabel}
        loadOptions={loadOptions}
        styles={{
          control: (base: any) => ({
            ...base,
            borderRadius: '4px',
            border: '1px solid #C9D2DC',
          }),
          dropdownIndicator: (base: any) => ({
            ...base,
            margin: '0 12px 0 0',
            padding: 0,
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          placeholder: (base: any) => ({
            ...base,
            color: '#0B1826',
            fontSize: '1rem',
          }),
          singleValue: (base: any) => ({
            ...base,
            color: '#0B1826',
            fontSize: '1rem',
          }),
          valueContainer: (base: any) => ({ ...base, margin: '3.5px 0' }),
          menuList: (base: any) => ({
            ...base,
            fontSize: '1rem',
            padding: 0,
          }),
          option: (base: any, props: any) => {
            const styles = { ...base, color: '#0C1E2C' };
            if (props.isSelected || props.isFocused) {
              styles.backgroundColor = '#F4FAFB';
            } else {
              styles.backgroundColor = 'transparent';
            }
            return styles;
          },
          menu: (base: any) => ({
            ...base,
            overflow: 'hidden',
            margin: '1px 0 0 0',
            borderRadius: '4px',
            boxShadow:
              '0px 4px 7px rgba(9, 40, 66, 0.05), 0px 0px 1px rgba(9, 40, 66, 0.2)',
          }),
          ...styles?.select,
        }}
      />
    </Container>
  );
}
