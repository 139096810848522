import { observer } from 'mobx-react-lite';
import { FC, useCallback, useState, useEffect } from 'react';
import { TestID } from '../../../constants/testIds';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { Status } from './Status';

interface StatusMessageProps {
  error?: boolean;
  testId?: string;
  closeTestId?: string;
}

const ERROR_MESSAGE = 'Error, please try again';

export const StatusMessage: FC<StatusMessageProps> = observer((props) => {
  const { error, testId, closeTestId } = props;
  const message = clinicalNoteStore.statusMessage;
  const isVisible = clinicalNoteStore.isStatusMessageVisible;
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout | undefined>(
    undefined
  );

  const onClose = useCallback(() => {
    clinicalNoteStore.setStatusMessageState('hidden');
  }, []);

  useEffect(() => {
    if (isVisible && !timeoutID) {
      const newTimeoutID = setTimeout(() => {
        clinicalNoteStore.setStatusMessageState('hidden');
        clearTimeout(timeoutID);
        setTimeoutID(undefined);
      }, 5000);
      setTimeoutID(newTimeoutID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (message) {
      clearTimeout(timeoutID);
      const newTimeoutID = setTimeout(() => {
        clinicalNoteStore.setStatusMessageState('hidden');
        clearTimeout(timeoutID);
        setTimeoutID(undefined);
      }, 5000);
      setTimeoutID(newTimeoutID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Status
      label={error ? ERROR_MESSAGE : message}
      onClose={onClose}
      error={error}
      testId={testId || TestID.ClinicalNote.ResultDialog}
      closeTestId={closeTestId || TestID.ClinicalNote.CloseResultDialog}
    />
  );
});
