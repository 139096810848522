export type CapellaScreenType = 'MyQ' | 'Scheduler' | 'Refill' | 'RxPending';

const typeToCapellaUrl = {
  MyQ: () => `frmMyQueueNew.aspx?IsDirectUrl=Y`,
  Scheduler: () => `frmAppointments.aspx?hdnSourceScreen=Menu&IsDirectUrl=Y`,
  Refill: () => `frmMyQueueNew.aspx?ScreenName=Refill&IsDirectUrl=Y`,
  RxPending: () => `frmMyQueueNew.aspx?ScreenName=RXPending&IsDirectUrl=Y`,
};

export function generateFullCapellaURL(
  type: CapellaScreenType | null,
  baseUrl: string
) {
  if (!type) {
    return '';
  }

  const capellaScreenUrl = typeToCapellaUrl[type]();

  return `${baseUrl}/${capellaScreenUrl}`;
}
