import { FC } from 'react';
import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { ClinicalNotePreviewMode } from '../ClinicalNotePreview';
import { patientStore } from '../../../stores/patient.store';
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';
import { isNil } from 'lodash';

const ReportTextWrapper = styled.div`
  display: flex;
  padding: 10px 0 0 40px;
  @media print {
    padding: 10px 0 0 36px;
  }
  width: 100%;
  font-size: 0.775rem;
  justify-content: space-between;
  color: #405580;
  align-items: center;
`;
const ReportPatientName = styled.div`
  font-weight: bold;
`;

const ReportText = styled.div({
  color: '#8AA2B5',
  fontStyle: 'italic',
  flex: 2,
});

const PatientInfo = styled.div`
  flex: 1;
  padding-left: 33px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 10px;
`;

interface HeaderReportTitleProps {
  mode: ClinicalNotePreviewMode;
}

export const HeaderReportTitle: FC<HeaderReportTitleProps> = (props) => {
  const { mode } = props;
  const addenda = clinicalNoteStore.clinicalNote?.addenda;
  const givenName = clinicalNoteStore.clinicalNote?.patient?.givenName ?? '';
  const familyName = clinicalNoteStore.clinicalNote?.patient?.familyName ?? '';
  const { accountNumber } = patientStore;
  const fullName = `${givenName} ${familyName}`;

  const getTitle = () => {
    if (mode !== ClinicalNotePreviewMode.SignedPreview) {
      return 'Preliminary Report';
    }
    if (addenda && addenda.length > 0) {
      return 'Final Report with Addenda';
    }
    return 'Final Report';
  };

  return (
    <ReportTextWrapper>
      <ReportText
        data-testid={TestID.ClinicalNote.PreviewHeaderReportTitleText}
      >
        {getTitle()}
      </ReportText>
      <PatientInfo
        data-testid={TestID.ClinicalNote.PreviewHeaderReportAccountNumber}
      >
        <ReportPatientName data-private>{fullName}</ReportPatientName>
        <ConditionalRender shouldRender={!isNil(accountNumber?.value)}>
          <div data-private>Acct #{accountNumber?.value}</div>
        </ConditionalRender>
      </PatientInfo>
    </ReportTextWrapper>
  );
};
