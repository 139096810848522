import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import { TestID } from '../../constants/testIds';
import { PatientSelect } from '../Filters/PatientSelect';
import { PatientSelectResult } from '../Filters/PatientSelectResult';
import {
  CreatePatientModal,
  FormSchema,
} from '../Modal/CreatePatientModal/CreatePatientModal';
import { SideDrawer } from '../SideDrawer';
import { CaptionComponent } from './DatePicker/CaptionComponent';
import { SideDrawerTimePicker } from './DatePicker/SideDrawerDateTimePicker/SideDrawerTimePicker';
import {
  type CreatePatientVariables,
  createPatientMutation,
  createPatientMutationOptionalRegion,
} from '../../services/graphql/createPatient';
import { AlertModal } from '../AkidoUI';
import { useToast } from '../AkidoUI/Toast';
import { type PatientModel } from '@akido/provider-desktop-bffe-types';
import { buildReturnDate } from './DatePicker/utils';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../config/launchdarkly';

const MIN_DATE = DateTime.now().minus({ years: 5 }).toJSDate();
const MAX_DATE = DateTime.now().plus({ years: 15 }).toJSDate();
const DEFAULT_ENCOUNTER_DURATION = 60;

type CreatePatientResponse = {
  patient: PatientModel;
};

type CreateEncounterDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    patient: PatientModel,
    startTime: Date,
    endTime: Date
  ) => Promise<void>;
};
export function CreateEncounterDrawer(props: CreateEncounterDrawerProps) {
  const { isOpen, onSave, onClose } = props;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startTime, setStartTime] = useState(
    DateTime.now().startOf('minute').toJSDate()
  );
  const [endTime, setEndTime] = useState(
    DateTime.now()
      .plus({ minutes: DEFAULT_ENCOUNTER_DURATION })
      .startOf('minute')
      .toJSDate()
  );

  const [selectedPatient, setSelectedPatient] = useState<PatientModel>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [showCreatePatientModal, setShowCreatePatientModal] = useState(false);
  const { addToast } = useToast();

  const ldClient = useLDClient();

  const shouldUseOptionalRegionQuery = ldClient?.variation(
    FEATURE_FLAGS.CREATE_PATIENT_WITH_OPTIONAL_REGION,
    false
  );

  const [createPatient] = useMutation<
    CreatePatientResponse,
    CreatePatientVariables
  >(
    shouldUseOptionalRegionQuery
      ? createPatientMutationOptionalRegion
      : createPatientMutation
  );

  const canSubmit = selectedPatient && startTime && endTime;

  const handleDateChange: SelectSingleEventHandler = (day) => {
    if (day) {
      setSelectedDate(day);
    }
  };

  function handleStartTimeChange(time: Date) {
    setStartTime(time);

    if (time > endTime) {
      setEndTime(
        DateTime.fromJSDate(time)
          .plus({ minutes: DEFAULT_ENCOUNTER_DURATION })
          .toJSDate()
      );
    }
  }

  function handleEndTimeChange(time: Date) {
    setEndTime(time);

    if (time < startTime) {
      setStartTime(
        DateTime.fromJSDate(time)
          .minus({ minutes: DEFAULT_ENCOUNTER_DURATION })
          .toJSDate()
      );
    }
  }

  function clearEncounterForm() {
    setSelectedPatient(undefined);
    setSelectedDate(new Date());
    setStartTime(DateTime.now().startOf('minute').toJSDate());
    setEndTime(
      DateTime.now()
        .plus({ minutes: DEFAULT_ENCOUNTER_DURATION })
        .startOf('minute')
        .toJSDate()
    );
  }

  async function handleCreateEncounter() {
    if (canSubmit) {
      const start = buildReturnDate(selectedDate, startTime);
      const end = buildReturnDate(selectedDate, endTime);

      await onSave(selectedPatient, start, end);
      clearEncounterForm();
    }
  }

  async function handleCreatePatient(data: FormSchema) {
    const addressLines = [];

    if (data.streetAddress) {
      addressLines.push(data.streetAddress);
    }

    if (data.streetAddress2) {
      addressLines.push(data.streetAddress2);
    }

    try {
      const newPatient = await createPatient({
        variables: {
          firstName: data.firstName,
          lastName: data.lastName,
          birthDate: data.dateOfBirth,
          sex: data.sexAtBirth?.value,
          memberId: data.memberId,
          insurancePlan: {
            name: data.insurance?.name,
            id: data.insurance?.id,
          },
          region: {
            id: data.region?.id,
            label: data.region?.label,
          },
          address: {
            line: addressLines,
            city: data.city,
            state: data.state,
            postalCode: data.zipCode,
          },
          phone: data.phoneNumber,
        },
      });

      if (newPatient.data) {
        setSelectedPatient(newPatient.data.patient);
      }

      setShowCreatePatientModal(false);
      addToast({
        type: 'success',
        message: 'New patient has been created',
      });
    } catch (e) {
      console.log(e);
      setShowCreatePatientModal(false);
      setErrorMessage(
        'Error creating patient. Please try again and contact support if the issue persists.'
      );
    }
  }

  function onHandleClose() {
    onClose();
    clearEncounterForm();
  }

  return (
    <>
      <SideDrawer
        isOpen={isOpen}
        data-testid={TestID.Calendar.CreateEncounterDrawer}
      >
        <div className='px-6 pb-4 pt-6'>
          <div className='flex justify-end space-x-3'>
            <button
              className='btn btn-md btn-white'
              onClick={onHandleClose}
              data-testid={TestID.Calendar.CancelCreateEncounterButton}
            >
              Cancel
            </button>
            <button
              className='btn btn-md btn-primary'
              onClick={handleCreateEncounter}
              data-testid={TestID.Calendar.SaveEncounterButton}
              disabled={!canSubmit}
            >
              Create
            </button>
          </div>
        </div>
        <hr className='border-gray-300' />
        <div className='h-full overflow-auto'>
          <div className='px-6 pb-6 pt-4'>
            <div>
              <p
                className='mb-2 text-sm text-gray-500'
                data-testid={TestID.SideDrawer.SetDateTitle}
              >
                Set Date
              </p>
              <DayPicker
                data-testid={TestID.SideDrawer.DatePicker}
                mode='single'
                defaultMonth={selectedDate}
                className='root m-0 mb-2'
                selected={selectedDate}
                captionLayout='dropdown'
                fromDate={MIN_DATE}
                onSelect={handleDateChange}
                toYear={MAX_DATE.getFullYear()}
                formatters={{
                  formatWeekdayName: (date) => {
                    return DateTime.fromJSDate(date).toFormat('ccccc');
                  },
                }}
                components={{
                  Caption: CaptionComponent,
                }}
              />
            </div>
            <div>
              <div className='space-y-4'>
                <div>
                  <p className='mb-2 text-sm text-gray-500'>Set Start Time</p>
                  <SideDrawerTimePicker
                    testId={TestID.SideDrawer.StartTimePicker}
                    selected={startTime}
                    handleTimeChange={handleStartTimeChange}
                  />
                </div>
                <div>
                  <p className='mb-2 text-sm text-gray-500'>Set End Time</p>
                  <SideDrawerTimePicker
                    testId={TestID.SideDrawer.EndTimePicker}
                    selected={endTime}
                    handleTimeChange={handleEndTimeChange}
                  />
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <div className='mb-2 flex'>
                <span className='text-sm text-gray-500'>Select Patient</span>
                <button
                  data-testId={TestID.SideDrawer.AddNewPatientButton}
                  className='ml-auto text-sm text-primary-500'
                  onClick={() => setShowCreatePatientModal(true)}
                >
                  Add New Patient
                </button>
              </div>
              <PatientSelect
                data-private
                onChange={(e) => setSelectedPatient(e?.value)}
              />
              {selectedPatient && (
                <PatientSelectResult
                  data-private
                  data-testid={TestID.SideDrawer.SelectedPatient}
                  name={`${selectedPatient.givenName} ${selectedPatient.familyName}`}
                  accountNumber={selectedPatient.accountNumber?.value}
                  birthDate={selectedPatient.birthDate || ''}
                  onDelete={() => setSelectedPatient(undefined)}
                  className='mt-4'
                />
              )}
            </div>
          </div>
        </div>
      </SideDrawer>
      <CreatePatientModal
        isOpen={showCreatePatientModal}
        onClose={() => setShowCreatePatientModal(false)}
        onSubmit={handleCreatePatient}
      />
      <AlertModal
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
        title='Error'
        type='error'
        message={errorMessage}
        data-testid={TestID.SideDrawer.ErrorAlertModal}
      />
    </>
  );
}
