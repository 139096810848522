import { LoadingSpinner } from '../../components/AkidoUI/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TestID } from '../../constants/testIds';
import { useClinicalNoteForPreview } from './hooks/useClinicalNoteForPreview';
import { useSignClinicalNote } from './hooks/useSignClinicalNote';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';
import authStore from '../../stores/auth.store';
import { ClinicalNoteStatus } from '../../types/clinical-notes';
import { ClinicalNotePreviewBottomBar } from './ClinicalNotePreviewBottomBar';
import {
  getAuthorizations,
  GetAuthorizationsResponse,
} from '../../services/graphql/getAuthorizations';
import { CenterSection } from './ClinicalNotePreviewPage/CenterSection';
import {
  ClinicalNotePreviewTabs,
  ClinicalNoteTopBar,
} from './ClinicalNotePreviewPage/ClinicalNoteTopBar';
import { RightSection } from './ClinicalNotePreviewPage/RightSection';
import { useFetchClinicalNoteTemplate } from '../../hooks/useFetchClinicalNoteTemplate';
import { useAnalytics } from '../../hooks/useAnalytics';
import { getErrorCodeFromGraphqlCall } from '../../utilities/helpers';
import { RoutePaths } from '../../constants/routes';
import { ClinicalNoteScreens } from '../../types/screens';
import {
  AnalyticsScreenNameMapping,
  ClinicalNoteAnalyticsEventName,
} from '../../constants/analytics';
import { AI_TEMPLATE_NAME } from '../../constants/templates';
import { clinicalNoteTemplatesStore } from '../../stores/clinicalNoteTemplates.store';

interface ClinicalNotePreviewProps {}

const Content = styled.div<{ signed?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid #dcdfe3;
  background-color: ${(props) => (props.signed ? '#e2e7ec' : 'white')};
  overflow: hidden;
`;

const LoadingContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export enum ClinicalNotePreviewMode {
  Loading = 'loading',
  SignedPreview = 'signed_preview',
  AddAddendum = 'add_addendum',
  UnsignedPreview = 'unsigned_preview',
}

const errorRoute = (errorCode?: string) => {
  return RoutePaths.Error + (errorCode ? `?code=${errorCode}` : '');
};

export const ClinicalNotePreview: FC<ClinicalNotePreviewProps> = observer(
  () => {
    const navigate = useNavigate();
    const location = useLocation();
    const printComponentRef = useRef(null);
    const [mode, setMode] = useState(ClinicalNotePreviewMode.Loading);
    const [tabShown, setTabShown] = useState(ClinicalNotePreviewTabs.NoteInfo);
    const [saveError, setSaveError] = useState(false);
    const { id } = useParams();
    const { clinicalNote } = clinicalNoteStore;
    const { sendClinicalNoteAnalyticsEvent, sendScreenEvent } = useAnalytics();

    const {
      getClinicalNote,
      error,
      isLoading: isLoadingClinicalNote,
    } = useClinicalNoteForPreview({ id, screen: ClinicalNoteScreens.PREVIEW });

    const isLoadingNote =
      isLoadingClinicalNote || !clinicalNoteTemplatesStore.hasLoadedTemplates;

    const { signClinicalNote, isLoading: isSigningNote } = useSignClinicalNote({
      clinicalNoteId: id,
    });
    const isBillingVisible = clinicalNote?.canSeeBilling ?? false;

    useFetchClinicalNoteTemplate({ screen: ClinicalNoteScreens.PREVIEW });

    const hasLoadedTemplates = clinicalNoteTemplatesStore.hasLoadedTemplates;
    const clinicalNoteTemplateId =
      clinicalNoteStore?.clinicalNote?.content?.templateId;

    const isAITemplate = useMemo(() => {
      if (clinicalNoteTemplateId && hasLoadedTemplates) {
        const template = clinicalNoteTemplatesStore.getTemplate(
          clinicalNoteTemplateId
        );
        return template?.name === AI_TEMPLATE_NAME;
      }

      return false;
    }, [clinicalNoteTemplateId, hasLoadedTemplates]);

    const printContent = useCallback(() => {
      return printComponentRef.current;
    }, [printComponentRef]);

    const signNote = useCallback(async () => {
      setMode(ClinicalNotePreviewMode.Loading);
      const signSuccess = await signClinicalNote();

      if (signSuccess) {
        sendClinicalNoteAnalyticsEvent(
          ClinicalNoteAnalyticsEventName.SignNote,
          {
            capella_note_id: clinicalNote?.encounterId,
            akido_note_id: id,
          }
        );
        await getClinicalNote();
        clinicalNoteStore.showNewStatusMessage('Note Signed');
      } else {
        setMode(ClinicalNotePreviewMode.UnsignedPreview);
        clinicalNoteStore.setStatusMessageState('visible');
        setSaveError(true);
      }
    }, [
      signClinicalNote,
      sendClinicalNoteAnalyticsEvent,
      clinicalNote,
      id,
      getClinicalNote,
    ]);

    const [getUserAuthorizations] = useLazyQuery<GetAuthorizationsResponse>(
      getAuthorizations,
      {
        fetchPolicy: 'network-only',
      }
    );

    useEffect(() => {
      getUserAuthorizations({
        fetchPolicy: 'network-only',
      }).then((result) => {
        authStore?.setAuthorizations(result.data);
      });
    }, [getUserAuthorizations]);

    const addAddendum = useCallback(() => {
      sendClinicalNoteAnalyticsEvent(
        ClinicalNoteAnalyticsEventName.AddAddendum,
        {
          capella_note_id: clinicalNote?.encounterId,
          akido_note_id: id,
        }
      );
      setMode(ClinicalNotePreviewMode.AddAddendum);
      clinicalNoteStore.hideStatusMessage();
    }, [clinicalNote, id, sendClinicalNoteAnalyticsEvent]);

    const onCancelSignAddendum = useCallback(() => {
      setMode(ClinicalNotePreviewMode.SignedPreview);
    }, []);

    const handleTabChange = (newTab: ClinicalNotePreviewTabs) => {
      switch (newTab) {
        case ClinicalNotePreviewTabs.BillingInfo:
          sendScreenEvent({
            firebase_screen: AnalyticsScreenNameMapping.billingInformation,
            firebase_screen_class:
              AnalyticsScreenNameMapping.billingInformation,
          });
          break;
        default:
          break;
      }
      setTabShown(newTab);
    };

    useEffect(() => {
      if (isLoadingNote) {
        setMode(ClinicalNotePreviewMode.Loading);
      }
    }, [isLoadingNote, isBillingVisible]);

    useEffect(() => {
      switch (clinicalNote?.status) {
        case ClinicalNoteStatus.Signed:
          setMode(ClinicalNotePreviewMode.SignedPreview);
          break;
        case ClinicalNoteStatus.Draft:
          setMode(ClinicalNotePreviewMode.UnsignedPreview);
          break;
        default:
          setMode(ClinicalNotePreviewMode.Loading);
      }
    }, [clinicalNote?.status, isLoadingNote]);

    useEffect(() => {
      switch (clinicalNote?.status) {
        case ClinicalNoteStatus.Signed:
          sendScreenEvent({
            firebase_screen: AnalyticsScreenNameMapping.signedNote,
            firebase_screen_class: AnalyticsScreenNameMapping.signedNote,
          });
          break;
        case ClinicalNoteStatus.Draft:
          sendScreenEvent({
            firebase_screen: AnalyticsScreenNameMapping.unsignedNote,
            firebase_screen_class: AnalyticsScreenNameMapping.unsignedNote,
          });
          break;
        default:
          break;
      }
    }, [clinicalNote?.status, sendScreenEvent]);

    if (isSigningNote || isLoadingNote) {
      return (
        <LoadingContainer data-testid={TestID.ClinicalNote.Loading}>
          <LoadingSpinner />
        </LoadingContainer>
      );
    }

    if (error) {
      const errorCode = getErrorCodeFromGraphqlCall(error);
      navigate(errorRoute(errorCode), {
        state: { previousPath: location.pathname },
      });
      return (
        <div data-testid='save-clinical-note-error-placeholder'>
          There was an error fetching the note: {JSON.stringify(error)}
        </div>
      );
    }

    return (
      <>
        <ClinicalNoteTopBar
          mode={mode}
          currentTab={tabShown}
          printContent={printContent}
          changeTabCallback={(newTab: ClinicalNotePreviewTabs) => {
            handleTabChange(newTab);
          }}
        />
        <Content
          signed={clinicalNote?.status === ClinicalNoteStatus.Signed}
          data-testid={TestID.ClinicalNote.NotePreviewPage}
        >
          <CenterSection
            printRef={printComponentRef}
            mode={mode}
            error={error}
            isLoadingTemplate={isLoadingNote}
            isAITemplate={isAITemplate}
          />

          <RightSection
            mode={mode}
            isBillingVisible={isBillingVisible}
            onClickAddAddendum={addAddendum}
            onCancelSignAddendum={onCancelSignAddendum}
            error={saveError}
          />
        </Content>
        <ClinicalNotePreviewBottomBar
          mode={mode}
          onClickSign={signNote}
          onClickAddAddendum={addAddendum}
        />
      </>
    );
  }
);
