import {
  MessageBubble as Message,
  MessageBubbleProps,
} from './MessageBubble/MessageBubble';

const styles = {
  bubbleStyle: {
    display: 'flex',
    alignItems: 'center',
  },
};

export const MessageBubble = (
  props: MessageBubbleProps & { isVisible: boolean }
) => {
  if (props.isVisible) return null;

  return (
    <Message
      text={props.text}
      type={props.type}
      icon={props.icon}
      containerStyles={styles.bubbleStyle}
    />
  );
};
