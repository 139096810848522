import { gql } from '@apollo/client';

export const UpdateTranscriptionStatus = gql`
  mutation UpdateTranscription($transcriptionId: String!, $status: String!) {
    updateTranscription(
      input: { transcriptionId: $transcriptionId, status: $status }
    ) {
      transcriptionId
    }
  }
`;
