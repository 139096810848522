import { action, makeObservable, observable } from 'mobx';
import {
  hydrateStore,
  isHydrated,
  isPersisting,
  makePersistable,
} from 'mobx-persist-store';

export class UrlLocationStore {
  autoLoggedOutFrom: string = '';

  constructor() {
    makeObservable(this, {
      autoLoggedOutFrom: observable,
      reset: action,
      setAutoLoggedOutFrom: action,
    });
    makePersistable(
      this,
      {
        name: 'providerDesktopUrlStore',
        properties: ['autoLoggedOutFrom'],
        storage: localStorage,
      },
      {
        delay: 200,
        fireImmediately: true,
      }
    );
  }

  isHydrated() {
    return isHydrated(this);
  }

  isPersisted() {
    return isPersisting(this);
  }

  async hydrateStore() {
    await hydrateStore(this);
  }

  setAutoLoggedOutFrom(autologoutLocation: string) {
    this.autoLoggedOutFrom = autologoutLocation;
  }

  reset() {
    this.autoLoggedOutFrom = '';
  }
}

export const urlLocationStore = new UrlLocationStore();
