import LogRocket from 'logrocket';
import { REACT_APP_LOGROCKET_APP_ID } from '../../config/environment';
import { replaceSensitiveFields } from './helper';

export const initLogRocket = () => {
  LogRocket.init(REACT_APP_LOGROCKET_APP_ID || '', {
    dom: {
      textSanitizer: false,
    },
    network: {
      responseSanitizer: (response) => {
        if (response.body) {
          response.body = replaceSensitiveFields(response.body);
        }

        return response;
      },
      requestSanitizer: (request) => {
        if (request.body) {
          request.body = replaceSensitiveFields(request.body);
        }

        return request;
      },
    },
  });
};
