import {
  AkidoIdentifier,
  ClinicalNoteModel,
} from '@akido/provider-desktop-bffe-types';
import { gql } from '@apollo/client';

export type GetClinicalNoteVariables = {
  id?: string;
  screen?: string;
};

export type GetClinicalNoteReturn = {
  getClinicalNote: ClinicalNoteModel;
};

export type GetClinicalNoteEditorVariables = {
  clinicalNoteId?: string;
  externalEncounterId?: string;
  screen: string;
  specialty: string;
  tenantId?: string;
  resourceIdentifier?: AkidoIdentifier;
};

export const ClinicalNoteContent = gql`
  fragment ClinicalNoteContent on ClinicalNoteContent {
    title
    templateId
    sections {
      id
      type
      title
      autofillData
      category
      text
      sortOrder
      uiSection
      requestId
      features
    }
  }
`;

export const getClinicalNoteQuery = gql`
  ${ClinicalNoteContent}

  query GetClinicalNote($id: String!, $screen: String) {
    getClinicalNote(id: $id, screen: $screen) {
      id
      version
      encounterId
      updatedAt
      signedAt
      signedByEmail
      signedByFullName
      status
      canAddAddendum
      canSignNote
      canSeeBilling
      billingNote
      addenda {
        createdAt
        updatedAt
        id
        clinicalNoteId
        createdBy
        userId
        text
      }
      patient {
        patientId
        birthDate
        familyName
        givenName
        age
        accountNumber {
          type
          value
        }
      }
      encounter {
        dateOfEncounter
        referringPractitioner {
          fullName
        }
      }
      content {
        ...ClinicalNoteContent
      }
    }
  }
`;

export const ClinicalNoteEditorData = gql`
  query ClinicalNote(
    $screen: String!
    $clinicalNoteId: String
    $specialty: String
    $externalEncounterId: String
    $tenantId: String
    $resourceIdentifier: AkidoIdentifierArg
  ) {
    clinicalNote(
      screen: $screen
      clinicalNoteId: $clinicalNoteId
      specialty: $specialty
      externalEncounterId: $externalEncounterId
      tenantId: $tenantId
      resourceIdentifier: $resourceIdentifier
    ) {
      id
      version
      content {
        title
        templateId
        sections {
          id
          title
          type
          category
          sortOrder
          text
          autofillData
          uiSection
          requestId
          features
        }
      }
      canAddAddendum
      canSeeBilling
      canSignNote
      signedAt
      signedByEmail
      signedByFullName
      status
      encounter {
        dateOfEncounter
        fhirEncounterId
        encounterIdentifier {
          value
          type
        }
        referringPractitioner {
          fullName
        }
        vitals {
          displayLabels
        }
        assessments {
          list {
            displayLabel
          }
        }
        billing {
          displayLabels
        }
        orders {
          list {
            displayLabel
          }
        }
        previousResourceIds {
          akidoClinicalNoteId
          encounterFhirId
        }
      }
      patient {
        patientId
        givenName
        familyName
        age
        birthDate
        fullName
        accountNumber {
          type
          value
        }
        pastMedicalHistory {
          list {
            displayLabel
          }
        }
        pastSurgicalHistory {
          list {
            displayLabel
          }
        }
        socialHistory {
          list {
            displayLabel
          }
        }
        familyHistory {
          list {
            displayLabel
          }
        }
        allergies {
          drugAllergies {
            displayLabel
          }
          nonDrugAllergies {
            displayLabel
          }
        }
        immunizations {
          list {
            displayLabel
          }
        }
      }
    }
    dragonSpeechToTextSecrets {
      applicationName
      partnerGUID
      organizationToken
    }
    clinicalNoteTemplates {
      templates {
        id
        name
        sections {
          autofillFrom
          canBeCopied
          category
          defaultText
          features
          id
          placeholderText
          title
          type
          uiSection
          sortOrder
        }
        createdBy
      }
    }
  }
`;
