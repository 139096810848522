import {
  CustomDropdown,
  DropdownElement,
} from '../../../ClinicalNotesEditor/CustomDropdown';
import { ClinicalNoteHeader } from '../../../ClinicalNotesEditor/ClinicalNoteHeader';
import { FC } from 'react';

export interface TemplateSelectorProps {
  onChange?: (newTemplate: DropdownElement) => void;
}

export const TemplateSelector: FC<TemplateSelectorProps> = (props) => {
  const { onChange } = props;

  return (
    <CustomDropdown
      classNames={{
        container:
          'm-0 border border-gray-300 h-11 flex w-1/2 rounded-lg text-[#083B60]',
        list: 'bg-white border border-gray-300 rounded-lg w-full mt-10',
      }}
      HeaderComponent={ClinicalNoteHeader}
      onChange={onChange}
      options={[
        { value: '1', label: 'One' },
        { value: '2', label: 'Two' },
      ]}
      selectedDropdownItem={{ value: '2', label: 'Two' }}
    />
  );
};
