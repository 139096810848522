import { action, makeObservable, observable } from 'mobx';
import { VitalsParsingUtils } from '../utilities/vitalsParsing';
import { DisplayLabelArray } from '../types/clinical-notes';
import { isArrayWithLength } from '../utilities/helpers';
import { UISectionIds } from '../routes/ClinicalNotesEditor/sections/uiSectionIds';

type AutofilledSection = {
  items: string[];
  refreshDateString: string;
  didError: boolean;
};

export type SectionIdsWithReadonlyAutofill =
  | UISectionIds.Assessments
  | UISectionIds.Orders
  | UISectionIds.Vitals
  | UISectionIds.Prescriptions
  | UISectionIds.Billing;

export class ClinicalNoteRefreshableAutofillStore {
  assessments: AutofilledSection | null;
  vitals: AutofilledSection;
  prescriptions: AutofilledSection | null;
  orders: AutofilledSection | null;
  billing: AutofilledSection | null;

  constructor() {
    makeObservable(this, {
      assessments: observable,
      vitals: observable,
      prescriptions: observable,
      orders: observable,
      billing: observable,
      setAssessments: action,
      setAssessmentsDidError: action,
      setVitals: action,
      setVitalsDidError: action,
      setPrescriptions: action,
      setPrescriptionsDidError: action,
      setOrders: action,
      setOrdersDidError: action,
      setBilling: action,
      setBillingDidError: action,
      reset: action,
    });

    this.assessments = null;
    this.vitals = {
      items: VitalsParsingUtils.createPlaceHolderArrayOfVitals(),
      refreshDateString: '',
      didError: false,
    };
    this.prescriptions = null;
    this.orders = null;
    this.billing = null;
  }

  setAssessments(assessmentItems: DisplayLabelArray) {
    if (Array.isArray(assessmentItems) && assessmentItems.length > 0) {
      this.assessments = {
        items: assessmentItems.map((item) => item.displayLabel),
        refreshDateString: VitalsParsingUtils.createDateString(),
        didError: false,
      };
    }
  }

  setAssessmentsDidError() {
    this.assessments = {
      items: this.assessments?.items ?? [],
      refreshDateString: this.assessments?.refreshDateString ?? '',
      didError: true,
    };
  }

  setVitals(vitalsItems: string[]) {
    if (Array.isArray(vitalsItems) && vitalsItems.length > 0) {
      this.vitals = {
        items: vitalsItems,
        refreshDateString: VitalsParsingUtils.createDateString(),
        didError: false,
      };
    }
  }

  setVitalsDidError() {
    this.vitals = {
      items: this.vitals.items,
      refreshDateString: this.vitals?.refreshDateString,
      didError: true,
    };
  }

  private resetVitals() {
    this.vitals = {
      items: VitalsParsingUtils.createPlaceHolderArrayOfVitals(),
      refreshDateString: '',
      didError: false,
    };
  }

  setPrescriptions(prescriptionItems: DisplayLabelArray) {
    if (Array.isArray(prescriptionItems) && prescriptionItems.length > 0) {
      this.prescriptions = {
        items: prescriptionItems.map((item) => item.displayLabel),
        refreshDateString: VitalsParsingUtils.createDateString(),
        didError: false,
      };
    }
  }

  setPrescriptionsDidError() {
    this.prescriptions = {
      items: this.prescriptions?.items ?? [],
      refreshDateString: this.prescriptions?.refreshDateString ?? '',
      didError: true,
    };
  }

  setOrders(items: DisplayLabelArray) {
    if (isArrayWithLength(items)) {
      this.orders = {
        items: items.map((item) => item.displayLabel),
        refreshDateString: VitalsParsingUtils.createDateString(),
        didError: false,
      };
    }
  }

  setOrdersDidError() {
    this.orders = {
      items: this.orders?.items ?? [],
      refreshDateString: this.orders?.refreshDateString ?? '',
      didError: true,
    };
  }

  setBilling(items: string[]) {
    if (isArrayWithLength(items)) {
      this.billing = {
        items,
        refreshDateString: VitalsParsingUtils.createDateString(),
        didError: false,
      };
    }
  }

  setBillingDidError() {
    this.billing = {
      items: this.billing?.items ?? [],
      refreshDateString: this.billing?.refreshDateString ?? '',
      didError: true,
    };
  }

  reset() {
    this.assessments = null;
    this.resetVitals();
    this.prescriptions = null;
    this.orders = null;
    this.billing = null;
  }
}

export const clinicalNoteRefreshableAutofillStore =
  new ClinicalNoteRefreshableAutofillStore();
