import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';

import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';
import { ClinicalNotePreviewColors } from '../../../constants/colors';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { patientStore } from '../../../stores/patient.store';
import { formatDate } from '../../../utilities/formatting';
import { ClinicalNotePreviewMode } from '../ClinicalNotePreview';
import { PatientInfoLabel } from './PatientInfoLabel';
import { ClientTimezones } from '../../../constants/clients';

const SubContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
});

const LeftHeader = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 2,
});

const RightHeader = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const NoteTitleText = styled.span({
  paddingLeft: '40px',
  marginTop: '12px',
  fontSize: '0.88rem',
  fontWeight: 700,
  fontStyle: 'normal',
  color: ClinicalNotePreviewColors.TEXT,
});

const ServiceDateText = styled.span({
  fontSize: '0.775rem',
  fontWeight: 500,
  color: '#405580',
  paddingTop: '10px',
  paddingLeft: '26px',
});

const DemographicsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  paddingLeft: '40px',
  marginTop: '12px',
  marginBottom: '33px',
});

const TopDemographicsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
});

interface ClinicalNotePreviewHeaderProps {
  mode: ClinicalNotePreviewMode;
}

export const ClinicalNotePreviewHeader: FC<ClinicalNotePreviewHeaderProps> =
  observer(() => {
    const { fullName, formattedBirthDate, age, accountNumber } = patientStore;
    const { dateOfService, referringPractitioner } = clinicalNoteStore;

    const noteTitleText = clinicalNoteStore.editableNoteTitle;

    const formattedDateOfService = dateOfService
      ? formatDate(dateOfService?.toISOString(), false, ClientTimezones.CMG)
      : '';

    const patientDemographicTextString = useMemo(() => {
      let returnString = '';

      if (formattedBirthDate) {
        returnString = `${formattedBirthDate}`;

        if (isFinite(age)) {
          returnString += ` ${age} YO`;
        }
      }

      return returnString;
    }, [age, formattedBirthDate]);

    return (
      <SubContainer data-testid={TestID.ClinicalNote.NotePreviewHeader}>
        <LeftHeader>
          <NoteTitleText data-testid={TestID.ClinicalNote.PreviewNoteTitleText}>
            {noteTitleText}
          </NoteTitleText>
          <TopDemographicsContainer>
            <DemographicsContainer
              data-testid={TestID.ClinicalNote.PreviewDemographicsContainer}
            >
              <PatientInfoLabel
                value={fullName}
                containerStyles={{ fontWeight: 700 }}
              />
              <PatientInfoLabel
                header='DOB'
                value={patientDemographicTextString}
              />
            </DemographicsContainer>
            <ConditionalRender shouldRender>
              <DemographicsContainer
                data-testid={
                  TestID.ClinicalNote.PreviewPhysicianAndAccountNumber
                }
                style={{
                  flex: 2,
                }}
              >
                <PatientInfoLabel
                  header='Acct #'
                  testId={TestID.ClinicalNote.PreviewAccountNumber}
                  value={accountNumber?.value}
                />
                <PatientInfoLabel
                  header='Referring Provider'
                  testId={TestID.ClinicalNote.PreviewReferringPractitioner}
                  value={referringPractitioner?.fullName ?? ''}
                />
              </DemographicsContainer>
            </ConditionalRender>
          </TopDemographicsContainer>
        </LeftHeader>
        <RightHeader data-public>
          <ServiceDateText
            data-public
            data-testid={TestID.ClinicalNote.PreviewServiceDateText}
          >
            Date of Service {formattedDateOfService}
          </ServiceDateText>
        </RightHeader>
      </SubContainer>
    );
  });
