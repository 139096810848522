import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import { TestID } from '../../../../constants/testIds';
import { AmPmSelector } from '../AmPmSelector';
import { buildReturnTime } from '../utils';
import { ReactComponent as TimeIcon } from '../../../../assets/icons/time.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/single-chevron.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './SideDrawerTimePicker.css';

export const TimePickerIcon = styled(TimeIcon)`
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 12px;
  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  width: 20px;
`;

export const DropDownIcon = styled(ArrowIcon)`
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  right: 12px;
  margin-top: auto;
  margin-bottom: auto;
  &.isOpen {
    transform: rotate(180deg);
  }
`;

interface SideDrawerTimePickerProps {
  handleTimeChange: (arg0: Date) => void;
  selected: Date;
  testId?: string;
}

export const SideDrawerTimePicker: React.FC<SideDrawerTimePickerProps> = ({
  handleTimeChange,
  selected,
  testId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const amPm = selected.getHours() >= 12 ? 'PM' : 'AM';

  const onTimeUpdate = (newTime: Date | null) => {
    if (newTime) {
      handleTimeChange(newTime);
    }
  };

  const onAmPmUpdate = (newAmPm: 'AM' | 'PM') => {
    const newTime = buildReturnTime(selected, newAmPm);
    handleTimeChange(newTime);
  };

  return (
    <div className='flex'>
      <div
        className='relative flex'
        data-testid={testId ?? TestID.SideDrawer.TimePickerContainer}
      >
        <ReactDatePicker
          selected={selected}
          placeholderText={'7:00 am'}
          dateFormat={'h:mm'}
          timeCaption='Time'
          popperPlacement='bottom-start'
          showPopperArrow={false}
          timeIntervals={15}
          showTimeSelect
          showTimeSelectOnly
          className='rounded-lg border border-gray-300 px-3 py-2.5 pl-10'
          onChange={(date) => {
            onTimeUpdate(date);
            setIsOpen(false);
          }}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
          onSelect={() => setIsOpen(false)}
        />
        <TimePickerIcon />
        <DropDownIcon
          data-testid={TestID.SideDrawer.TimePickerDropDownArrow}
          className={isOpen ? 'isOpen' : ''}
        />
      </div>
      <AmPmSelector
        onSelect={onAmPmUpdate}
        selected={amPm}
        className='ml-auto'
      />
    </div>
  );
};
