import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useAssessments } from '../../../hooks/useAssessments';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { localClinicalNotesStore } from '../../../stores/localPersistentClinicalNotes.store';
import ClinicalNotesAutoFillField from '../ClinicalNotesAutoFillFields';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { get } from 'lodash';

interface IProps {
  noteId: string;
  sectionId: string;
  type?: string | null;
  title?: string;
  sortOrder?: number;
  uiSection?: string | null;
  assessmentTextValue?: string;
  localStoreEnabled: boolean;
  updateCallback: () => void;
}

export const AssessmentsField = observer(
  ({
    noteId,
    sectionId,
    title,
    sortOrder,
    uiSection,
    type,
    assessmentTextValue,
    localStoreEnabled,
    updateCallback,
  }: IProps) => {
    const fullPageRefresh = clinicalNoteStore.refreshing;
    const externalEncounterId = clinicalNoteStore.clinicalNote?.encounterId;
    const encounterIdentifier = get(
      clinicalNoteStore,
      'clinicalNote.encounter.encounterIdentifier'
    );
    delete encounterIdentifier?.__typename;

    const assessment = clinicalNoteRefreshableAutofillStore.assessments;

    const localNoteAssessments = localStoreEnabled
      ? localClinicalNotesStore.getSectionByIds(noteId, sectionId)
      : null;

    const { isLoading, refreshAssessments } = useAssessments(
      externalEncounterId,
      encounterIdentifier
    );

    useEffect(() => {
      if (fullPageRefresh) {
        refreshAssessments();
      }
    }, [fullPageRefresh, refreshAssessments]);

    return (
      <ClinicalNotesAutoFillField
        id={sectionId}
        showTopAction
        isRefreshLoading={isLoading || fullPageRefresh}
        onChange={(newValue: string) => {
          clinicalNoteStore.setSectionValue(
            sectionId,
            newValue,
            sortOrder,
            type,
            uiSection
          );
          updateCallback();
        }}
        title={title}
        value={localNoteAssessments?.text || assessmentTextValue}
        autofillData={assessment?.items}
        refreshCallback={refreshAssessments}
        autofillBottomText={assessment?.refreshDateString}
        autofillError={assessment?.didError}
        uiSection={uiSection ?? sectionId}
      />
    );
  }
);
