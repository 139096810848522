import { FC } from 'react';
import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';
import { formatSignedDate } from '../../../utilities/formatting';

const Content = styled.div`
  padding: 40px 40px 30px 40px;
`;

const Title = styled.div`
  font-size: 0.775rem;
  font-weight: 700;
  color: #333333;
  line-height: 20px;
`;

const AddendumText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.775rem;
  color: #405580;
  line-height: 20px;
  white-space: pre-line;
`;

const Signature = styled.span`
  display: inline-block;
  font-size: 0.775rem;
  color: #405580;
  line-height: 20px;
  margin: 30px 0 0 0;
  font-style: italic;
`;

const Separator = styled.div`
  height: 2px;
  background-color: #f2f2f2;
`;

interface AddendumContentProps {
  text: string;
  signedBy: string;
  signedAt: string;
}

export const AddendumContent: FC<AddendumContentProps> = (props) => {
  const { text, signedBy, signedAt } = props;
  let formattedDate = '';

  try {
    formattedDate = formatSignedDate(signedAt);
  } catch (err) {
    console.error(`Unable to parse date: ${signedAt}`);
  }

  if (!signedBy || !formattedDate) {
    return null;
  }

  const signature = `Signed by ${signedBy} ${formattedDate}`;
  return (
    <Content data-testid={TestID.Addendum.AddendumContent}>
      <Title>Addendum</Title>
      <Separator style={{ margin: '3px 0 8px 0' }} />
      <AddendumText>{text}</AddendumText>
      <Signature>{signature}</Signature>
    </Content>
  );
};
