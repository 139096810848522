import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ArrowSvg } from './ArrowSvg';
import { TestID } from '../../../constants/testIds';

export enum BottomDividerBehavior {
  on = 'on',
  off = 'off',
  collapsible = 'collapsible',
  reverseCollapsible = 'reverseCollapsible',
}

export type CollapsibleSectionProps = {
  /**
   * Title for the component
   */
  title: string;
  /**
   * Indicates if the collapsible part should start visible or not
   */
  collapsedOnStart?: boolean;
  /**
   * Indicates if it should show a divider at the top of the element
   */
  showTopDivider?: boolean;
  /**
   * Styles for the container element
   */
  containerStyles?: any;
  /**
   * Styles for the title container element
   */
  titleContainerStyles?: any;
  /**
   * Styles for the title text element
   */
  titleTextStyles?: any;
  /**
   * Styles for the content container element
   */
  contentStyles?: any;
  /**
   * a node to be rendered if the collapsed property is off component.
   */
  children?: any;
  /**
   * fill color for the svg component
   */
  fillColor?: string;
  /**
   * determines the behavior of the bottom divider, defaults to `on`
   */
  bottomDividerBehavior?: BottomDividerBehavior;
  /**
   * Styles for the top divider element
   */
  topDividerStyles?: any;
  /**
   * Styles for the bottom divider element
   */
  bottomDividerStyles?: any;
};

const CollapsibleSectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
`;
const TitleText = styled.div`
  margin-left: 15px;
  flex-direction: column;
  align-items: center;
  color: #8aa2b5;
`;
const Divider = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
  margin-bottom: 20px;
`;
const ContentContainer = styled('div')`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

export function CollapsibleSection({
  title,
  collapsedOnStart = false,
  showTopDivider = false,
  containerStyles,
  titleContainerStyles,
  titleTextStyles,
  contentStyles,
  children,
  fillColor,
  bottomDividerBehavior = BottomDividerBehavior.on,
  topDividerStyles,
  bottomDividerStyles,
}: CollapsibleSectionProps) {
  const [isCollapsed, setIsCollapsed] = useState(collapsedOnStart);
  const arrowFillColor = typeof fillColor === 'string' ? fillColor : '#8AA2B5';

  const shouldShowBottomDivider = useMemo(() => {
    let shouldShow = false;

    if (bottomDividerBehavior === BottomDividerBehavior.on) {
      shouldShow = true;
    } else if (
      bottomDividerBehavior === BottomDividerBehavior.collapsible &&
      !isCollapsed
    ) {
      shouldShow = true;
    } else if (
      bottomDividerBehavior === BottomDividerBehavior.reverseCollapsible &&
      isCollapsed
    ) {
      shouldShow = true;
    }

    return shouldShow;
  }, [bottomDividerBehavior, isCollapsed]);

  const TopDivider = useMemo(() => {
    if (topDividerStyles) {
      return <Divider style={{ ...topDividerStyles }} />;
    }
    return <Divider />;
  }, [topDividerStyles]);

  const BottomDivider = useMemo(() => {
    if (bottomDividerStyles) {
      return <Divider style={{ ...bottomDividerStyles }} />;
    }
    return <Divider />;
  }, [bottomDividerStyles]);

  return (
    <CollapsibleSectionContainer style={containerStyles}>
      {showTopDivider ? TopDivider : null}
      <TitleContainer
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
        style={titleContainerStyles}
        data-public
      >
        {isCollapsed ? (
          <ArrowSvg fillColor={arrowFillColor} />
        ) : (
          <ArrowSvg
            fillColor={arrowFillColor}
            svgStyle={{ transform: 'scaleY(-1)' }}
          />
        )}
        <TitleText style={titleTextStyles}>{title}</TitleText>
      </TitleContainer>
      {shouldShowBottomDivider ? BottomDivider : null}
      <ContentContainer
        data-testid={TestID.CollapsibleSection.ContentContainer}
        style={{ ...contentStyles, display: isCollapsed ? 'none' : 'flex' }}
      >
        {children}
      </ContentContainer>
    </CollapsibleSectionContainer>
  );
}
