import { FC } from 'react';

export const CapellaRefillIntegration: FC = () => {
  // TODO: Update this to the correct Capella URL
  // this is a temporary URL for testing
  const fullCapellaURL =
    'https://chart-stage.akidolabs.com/frmRCopiaStatusBar.aspx';

  return (
    <iframe
      title='Refill'
      src={fullCapellaURL}
      height='25px'
      width='200px'
      className='mt-[10px]'
      data-testid='CapellaRefillIntegrationIFrame'
    />
  );
};
