import { AlertModal } from '../../components';

type MaintenanceProps = {
  message: string;
  title: string;
};

export function Maintenance(props: MaintenanceProps) {
  const { message, title } = props;

  return (
    <div>
      <AlertModal
        title={title}
        message={message}
        isOpen={true}
        showCloseButton={false}
        showLogo={true}
      />
    </div>
  );
}

export default Maintenance;
