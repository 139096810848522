import styled from 'styled-components';
import { IconButton } from '../../../components';
import { TestID } from '../../../constants/testIds';
import React, { FC, useState } from 'react';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy-icon.svg';
import { AINoteRefreshButton } from './AINoteRefreshButton';
import { AINoteHeaderButtons } from './AINoteHeaderButtons';
import { EditableNoteTitle } from '../../ClinicalNotesEditor/EditableNoteTitle';
import { observer } from 'mobx-react-lite';

const Container = styled.div`
  height: 74px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #d0d7e4;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 33px;
  justify-content: center;
  height: 100%;
`;

export const AINoteHeader: FC = observer(() => {
  const [noteTitle, setNoteTitle] = useState('AI Note');

  return (
    <Container>
      <EditableNoteTitle value={noteTitle} onEdit={setNoteTitle} />
      <Buttons>
        <AINoteHeaderButtons
          styles={{
            container: {
              marginRight: '43px',
            },
          }}
        />
        <AINoteRefreshButton isLoading={false} onRefreshClicked={() => {}} />
        <IconButton
          testId={TestID.ClinicalNote.CopyPreviousEncounterButton}
          styles={{ buttonContainer: { height: '21px', marginLeft: '18px' } }}
          icon={<CopyIcon />}
          title='Copy previous encounter'
        />
      </Buttons>
    </Container>
  );
});
