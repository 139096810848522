import { action, makeObservable, observable } from 'mobx';
import {
  hydrateStore,
  isHydrated,
  isPersisting,
  makePersistable,
  clearPersistedStore,
} from 'mobx-persist-store';
import { GetAuthorizationsResponse } from '../services/graphql/getAuthorizations';

export class AuthStore {
  authInfo: any;
  autoLoggedOut: boolean = false;
  authorizations: GetAuthorizationsResponse['user']['authorizations'] = {
    canEditScribeNotes: false,
    canEditTranscriptions: false,
    canCreateScribeNotes: false,
    canUseSpeechToText: false,
    canAddAddendum: false,
    canViewOwnSchedules: false,
    canEditClinicalNotes: false,
    canAddAddendumAll: false,
  };
  isLoaded: boolean = false;
  isAuthenticated: boolean = false;

  constructor() {
    makeObservable(this, {
      authInfo: observable,
      autoLoggedOut: observable,
      isAuthenticated: observable,
      isLoaded: observable,
      reset: action,
      setAuthInfo: action,
      setAutoLoggedOut: action,
      authorizations: observable,
      setAuthorizations: action,
    });
    makePersistable(
      this,
      {
        name: 'providerDesktopAuthStore',
        properties: ['authInfo', 'autoLoggedOut'],
        storage: localStorage,
      },
      {
        delay: 200,
        fireImmediately: true,
      }
    );
  }

  isHydrated() {
    return isHydrated(this);
  }

  isPersisted() {
    return isPersisting(this);
  }

  async hydrateStore() {
    await hydrateStore(this);
  }

  setAuthInfo(authInfo: any) {
    this.authInfo = authInfo;
    this.isAuthenticated = !!authInfo;
  }

  setAutoLoggedOut(wasAutoLoggedOut: boolean) {
    this.autoLoggedOut = wasAutoLoggedOut;
  }

  setAuthorizations(authorizations?: GetAuthorizationsResponse) {
    if (authorizations) {
      this.authorizations = authorizations.user?.authorizations;
      this.isLoaded = true;
    }
  }

  async reset() {
    return clearPersistedStore(this);
  }
}

const authStore = new AuthStore();

export default authStore;
