import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';
import { CSSProperties, FC } from 'react';

export interface AINoteHeaderButtonProps {
  styles?: {
    container?: CSSProperties;
  };
}

export const Container = styled.div`
  display: flex;
`;

export const Button = styled.button`
  font-family: Inter, sans-serif;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 7px;

  &.primary {
    background-color: #2f80ed;
    color: #ffffff;
    padding: 9px 32px;
  }

  &.secondary {
    color: #405580;
    margin-right: 22px;
    border: solid #f0f2f7 1.8px;
    font-weight: 500;
    box-shadow: 0 1.8px 4.5px 0 #26334d08;
    padding: 9px 29px;
  }
`;

export const AINoteHeaderButtons: FC<AINoteHeaderButtonProps> = (props) => {
  const { styles } = props;

  return (
    <Container
      data-testid={TestID.AIClinicalNote.AIClinicalNoteHeaderButtons.Container}
      style={styles?.container}
    >
      <Button
        data-testid={TestID.AIClinicalNote.AIClinicalNoteHeaderButtons.Save}
        className='secondary'
      >
        Save
      </Button>
      <Button
        data-testid={TestID.AIClinicalNote.AIClinicalNoteHeaderButtons.Preview}
        className='primary'
      >
        Preview Note
      </Button>
    </Container>
  );
};
