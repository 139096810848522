import { useCallback, useEffect, useState } from 'react';

export const useKeyPress = (keyId: string) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const onDown = useCallback(
    ({ key }: { key: string }) => {
      if (keyId === key) {
        setKeyPressed(true);
      }
    },
    [keyId]
  );

  const onUp = useCallback(
    ({ key }: { key: string }) => {
      if (keyId === key) {
        setKeyPressed(false);
      }
    },
    [keyId]
  );

  useEffect(() => {
    window.addEventListener('keydown', onDown);
    window.addEventListener('keyup', onUp);

    return () => {
      window.removeEventListener('keydown', onDown);
      window.removeEventListener('keyup', onUp);
    };
  }, [onDown, onUp]);

  return keyPressed;
};
