import { Tab } from '@headlessui/react';
import { FC, ReactNode } from 'react';
import { EmptyView } from '../../../../components/AkidoUI/EmptyView/EmptyView';
import { ReactComponent as EmptyAIIcon } from '../../../../assets/icons/EmptyAiOutput.svg';
import { TestID } from '../../../../constants/testIds';

export interface TranscriptPanelProps {
  className?: string;
  children?: ReactNode;
}

export const TranscriptPanel: FC<TranscriptPanelProps> = () => {
  return (
    <Tab.Panel className='flex h-full flex-row justify-center'>
      <EmptyView
        testId={{
          container: TestID.AIClinicalNote.AINoteTabs.TranscriptEmpty,
        }}
        title='No transcript yet'
        subtitle='Start note by pressing transcribe to start recording the encounter note'
        classNames={{
          title: 'text-2xl',
          subtitle: 'text-xs',
          container: 'pt-11',
        }}
        Icon={() => <EmptyAIIcon height={250} />}
      />
    </Tab.Panel>
  );
};
