import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as NoteModalClose } from '../../assets/icons/close.svg';

interface ModalCloseButtonProps {
  onClick?: () => void;
  testId?: string;
  styles?: Record<string, any>;
}

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const ModalCloseButton: FC<ModalCloseButtonProps> = (props) => {
  const { onClick, testId, styles } = props;

  const localStyles = useMemo(() => {
    if (!styles) {
      return { paddingTop: 4 };
    }
    return styles;
  }, [styles]);

  return (
    <Button
      data-testid={testId}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <NoteModalClose
        style={localStyles}
        height={12}
        width={12}
        fill='#1B4C73'
      />
    </Button>
  );
};
