import { FC, ReactElement } from 'react';
import authStore from '../../stores/auth.store';
import ConditionalRender from '../ConditionalRender/ConditionalRender';
import { Authenticated } from '../Authenticated/Authenticated';
import { TrackUserInactivity } from '../TrackUserInactivity/TrackUserInactivity';
import { PageHeader as DefaultPageHeader } from '../PageHeaders/PageHeader';

interface LoggedInLayoutProps {
  shouldAutoSaveOnLogout?: boolean;
  children: ReactElement | ReactElement[];
  shouldShowHeader?: boolean;
  Header?: FC;
}

export const LoggedInLayout = ({
  shouldAutoSaveOnLogout = false,
  children,
  shouldShowHeader = true,
  Header,
}: LoggedInLayoutProps) => {
  let PageHeader = DefaultPageHeader;
  if (Header) {
    PageHeader = Header;
  }

  return (
    <Authenticated>
      <TrackUserInactivity authStore={authStore}>
        <div className='flex h-full flex-col'>
          <ConditionalRender shouldRender={shouldShowHeader}>
            <PageHeader shouldAutoSaveOnLogout={shouldAutoSaveOnLogout} />
          </ConditionalRender>
          {children}
        </div>
      </TrackUserInactivity>
    </Authenticated>
  );
};
