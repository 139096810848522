import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import styled from 'styled-components';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import { AI_SECTION_TYPE } from '../../constants/templates';
import { TestID } from '../../constants/testIds';
import { ClinicalNoteFieldValue } from '../../types/clinical-notes';
import { ClinicalNotePreviewFieldArray } from './ClinicalNotePreviewFieldArray';

const LeftContent = styled.div`
  border-right: solid 2px #f2f2f2;
  width: 65%;
  float: left;

  &.ai-sections {
    border: 0;
    width: 100%;
  }

  @media print {
    border-right: none;
  }
`;

const LeftInner = styled.div`
  padding-left: 40px;
`;

interface IProps {
  clinicalNoteEncounterSections: ClinicalNoteFieldValue[];
  clinicalNoteAssessmentAndPlanSections: ClinicalNoteFieldValue[];
  isAiTemplate?: boolean;
}

export const ClinicalNotePreviewLeftColumn: React.FC<IProps> = observer(
  ({
    clinicalNoteAssessmentAndPlanSections,
    clinicalNoteEncounterSections,
    isAiTemplate,
  }) => {
    const aiField = clinicalNoteEncounterSections.find(
      (ec) => ec.type === AI_SECTION_TYPE
    );

    const sections = useMemo(() => {
      if (!aiField) {
        return clinicalNoteEncounterSections;
      }
      return [aiField];
    }, [aiField, clinicalNoteEncounterSections]);
    return (
      <LeftContent
        data-testid={TestID.ClinicalNote.PreviewLeftColumn}
        className={isAiTemplate ? 'ai-sections' : ''}
      >
        <LeftInner>
          <ClinicalNotePreviewFieldArray
            clinicalNoteSections={sections}
            leftSideDisplay={!isAiTemplate}
            showTitle={!isAiTemplate}
          />
          <ConditionalRender shouldRender={!isAiTemplate}>
            <ClinicalNotePreviewFieldArray
              clinicalNoteSections={clinicalNoteAssessmentAndPlanSections}
              leftSideDisplay
            />
          </ConditionalRender>
        </LeftInner>
      </LeftContent>
    );
  }
);
