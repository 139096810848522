import { gql } from '@apollo/client';

export const ScheduleQuery = gql`
  query Schedules(
    $date: String
    $practitionerOktaId: String
    $fhirPractitionerId: String
    $dateRange: ValidatedDateRange
  ) {
    schedules(
      date: $date
      practitionerOktaId: $practitionerOktaId
      fhirPractitionerId: $fhirPractitionerId
      dateRange: $dateRange
    ) {
      schedules {
        minutesDuration
        startDateTime
        encounterId {
          value
          type
        }
        patient {
          fullName
          birthDate
          patientId
          accountNumber {
            value
            type
          }
        }
        clinicalNoteStatus
        clinicalNoteId
      }
    }
  }
`;
