import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { PatientVitalsDisplayLabels } from '../../../services/graphql/patientVitals';
import { VitalsGraphQLReturn } from '../../../types/vitals';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { AkidoIdentifierArg } from '@akido/provider-desktop-bffe-types';
import { toJS } from 'mobx';

export interface PatientVitalsVariables {
  externalId?: string;
  resourceIdentifier?: AkidoIdentifierArg;
}

export const usePatientVitals = (
  externalId?: string | null | undefined,
  resourceIdentifier?: AkidoIdentifierArg
) => {
  const [retrievePatientVitals, { loading: isLoading }] = useLazyQuery<
    VitalsGraphQLReturn,
    PatientVitalsVariables
  >(PatientVitalsDisplayLabels, { fetchPolicy: 'network-only' });

  const retrieveVitals = useCallback(async () => {
    if (!externalId && !resourceIdentifier) return;
    console.log(externalId);
    console.log(toJS(resourceIdentifier));
    const variables = externalId
      ? { externalId: externalId }
      : { encounterIdentifier: resourceIdentifier };

    const { data, error } = await retrievePatientVitals({
      variables,
    });

    if (error !== undefined) {
      clinicalNoteRefreshableAutofillStore.setVitalsDidError();
    }

    const displayLabels = data?.getFhirEncounter?.vitals?.displayLabels;
    if (Array.isArray(displayLabels) && displayLabels.length > 0) {
      clinicalNoteRefreshableAutofillStore.setVitals(displayLabels);
    }
  }, [externalId, retrievePatientVitals, resourceIdentifier]);

  return { isLoading, refreshVitals: retrieveVitals };
};
