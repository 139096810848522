import { IconButton } from '../../components/AkidoUI/IconButton';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { SaveMessageLeftIcon } from './SaveMessageLeftIcon';

const Container = styled.div`
  position: absolute;
  bottom: 100px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 12px;
  min-width: 240px;
  width: 23%;
`;

const Label = styled.span`
  margin-left: 17px;
  color: #2ba564;
  font-size: 1rem;
`;

const ErrorLabel = styled.span`
  margin-left: 17px;
  color: #cc1e12;
  font-size: 1rem;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

interface SaveMessageProps {
  saving?: boolean;
  showOnStart?: boolean;
  error?: boolean;
  testId?: string;
}

const SAVING_TEXT = 'Saving...';
const SAVED_TEXT = 'Note Saved';
const ERROR_TEXT = 'Error, please try again';

export const SaveMessage: FC<SaveMessageProps> = (props) => {
  const { saving, showOnStart, error, testId } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout | undefined>(
    undefined
  );

  useEffect(() => {
    if (saving || showOnStart || error) {
      setIsVisible(true);
    }
  }, [saving, showOnStart, error]);

  useEffect(() => {
    if (isVisible && !saving && !timeoutID) {
      clearTimeout(timeoutID);
      const newTimeoutID = setTimeout(() => {
        setIsVisible(false);
        clearTimeout(timeoutID);
        setTimeoutID(undefined);
      }, 5000);
      setTimeoutID(newTimeoutID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, saving]);

  if (!isVisible) return null;

  return (
    <Container
      data-testid={testId}
      style={{ backgroundColor: error ? '#FAE6E6' : '#daf0e3' }}
    >
      <LabelContainer>
        <SaveMessageLeftIcon error={!!error} saving={saving} />
        {error ? (
          <ErrorLabel>{ERROR_TEXT}</ErrorLabel>
        ) : (
          <Label>{saving ? SAVING_TEXT : SAVED_TEXT}</Label>
        )}
      </LabelContainer>
      <IconButton
        callback={() => {
          setIsVisible(false);
        }}
        icon={<CloseIcon fill={error ? '#CC1E12' : '#2ba564'} />}
      ></IconButton>
    </Container>
  );
};
