import { TemplateModel } from '@akido/provider-desktop-bffe-types';
import { gql } from '@apollo/client';

export interface ClinicalNoteTemplatesGraphQlReturnObject {
  clinicalNoteTemplates: {
    templates: TemplateModel[];
  };
}

export const ClinicalNoteTemplates = gql`
  query clinicalNoteTemplates($screen: String, $resourceType: String) {
    clinicalNoteTemplates(screen: $screen, resourceType: $resourceType) {
      templates {
        id
        name
        createdBy
        sections {
          id
          category
          title
          type
          placeholderText
          defaultText
          sortOrder
          autofillFrom
          uiSection
          canBeCopied
        }
      }
    }
  }
`;
