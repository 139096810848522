import { forwardRef } from 'react';

export const CircleCheckIcon = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      role='img'
      aria-label='Check Circle Icon'
      ref={ref}
      {...props}
    >
      <path
        d='M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM10.5 17.1L4.90002 11.5L7 9.39999L10.4 12.8L16.8 6.39999L18.9 8.5L10.5 17.1Z'
        fill='currentColor'
      />
    </svg>
  );
});
