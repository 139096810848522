import { datadogRum } from '@datadog/browser-rum';
import {
  REACT_APP_DD_RUM_APPLICATION_ID,
  REACT_APP_DD_RUM_CLIENT_TOKEN,
  REACT_APP_DD_RUM_SERVICE,
  REACT_APP_ENV,
  REACT_APP_VERSION,
} from '../../config/environment';

export const initDatadogRum = () => {
  datadogRum.init({
    applicationId: REACT_APP_DD_RUM_APPLICATION_ID ?? '',
    clientToken: REACT_APP_DD_RUM_CLIENT_TOKEN ?? '',
    site: 'datadoghq.com',
    service: REACT_APP_DD_RUM_SERVICE,
    env: REACT_APP_ENV ?? 'unknown',
    version: REACT_APP_VERSION ?? 'unknown',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};
