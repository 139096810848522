import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSearchParams } from 'react-router-dom';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { useReloadDragonVui } from './useReloadDragonVui';
import { useEffect, useMemo, useState } from 'react';
import { useClinicalNoteData } from './useClinicalNoteId';
import { clinicalNoteTemplatesStore } from '../../../stores/clinicalNoteTemplates.store';
import { useEncounterWithPatient } from '../../../hooks/useEncounterWithPatient';
import { useLoadClinicalNote } from './useLoadClinicalNote';
import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';
import { clinicalEditorPageState } from '../../../stores/clinicalEditorPageState.store';
import { patientStore } from '../../../stores/patient.store';
import authStore from '../../../stores/auth.store';
import { keyBy } from 'lodash';
import { usePatientClinicalData } from './usePatientClinicalData';
import { useSaveClinicalNote } from './useSaveClinicalNote';

const loadDefaultTitle = () => {
  const specialty = authStore?.authInfo?.specialty;

  const newTitle = specialty ? `${specialty} Visit` : 'New Visit';

  if (clinicalNoteStore.editableNoteTitle === '') {
    clinicalNoteStore.setEditableNoteTitle(newTitle);
  }
};

// TODO: the whole process of loading data here needs to be refactored
// we shouldn't be using a bunch of useEffects and state to check if multiple
// requests have been loaded.
// This is adding a hack to set that the data is already loaded:
const isClinicalNoteLoadedInitialValue = process.env.NODE_ENV === 'test';

export const useNoteEditorFromExternalEncounterIdPageLoad = () => {
  const ldClient = useLDClient();
  const [searchParams] = useSearchParams();
  const externalEncounterId = searchParams.get('externalEncounterId');
  const isClinicalNoteLoaded = clinicalNoteStore.isLoaded;
  const client = searchParams.get('client');
  // TODO: theses states need to be replaced with a more clear way to load all the required data
  const [hasLoadLocal, setHasLoadLocal] = useState(
    isClinicalNoteLoadedInitialValue
  );
  const [clinicalNote, setClinicalNote] = useState<
    ClinicalNoteModel | undefined
  >(undefined);
  const localStoreEnabled = ldClient?.variation(
    FEATURE_FLAGS.LOCAL_STORE_CLINICAL_NOTES,
    false
  );

  const isAutoSaveAfterLoadEnabled = ldClient?.variation(
    FEATURE_FLAGS.IMMEDIATE_AUTOSAVE_ON_EXTERNAL_ID_LOAD,
    false
  );

  const currentTemplateId = clinicalNoteStore.templateId;
  const clinicalNoteTitle = clinicalNoteStore.editableNoteTitle;
  const areSectionValuesEmpty = clinicalNoteStore.areSectionValuesEmpty;

  const noteTemplateIsLoading = clinicalNoteTemplatesStore.areTemplatesLoading;
  const hasLoadedTemplates = clinicalNoteTemplatesStore.hasLoadedTemplates;
  const clinicalNoteTemplates = clinicalNoteTemplatesStore.templates;

  const { patientId, isPatientLoading } =
    useEncounterWithPatient(externalEncounterId);

  useReloadDragonVui({ valueToWatch: currentTemplateId });

  const { saveClinicalNote } = useSaveClinicalNote({
    externalEncounterId: clinicalNoteStore.externalId,
    clinicalNoteTitle: clinicalNoteStore.editableNoteTitle,
    templateId: clinicalNoteStore.templateId,
  });

  useEffect(() => {
    if (
      externalEncounterId &&
      clinicalNoteStore.externalId !== externalEncounterId
    ) {
      clinicalNoteStore.reset();
      loadDefaultTitle();
      clinicalNoteStore.setExternalId(externalEncounterId);
    }
  }, [externalEncounterId]);

  const { isClinicalDataLoading, autofillForTextSections } =
    usePatientClinicalData(patientId, externalEncounterId);

  const {
    clinicalNoteData,
    loading: isLoadingNoteId,
    error: fetchNoteByExternalIdError,
  } = useClinicalNoteData(externalEncounterId, client);

  const isDataForEditorLoading = useMemo(() => {
    const isFetching =
      noteTemplateIsLoading ||
      isLoadingNoteId ||
      isClinicalDataLoading ||
      isPatientLoading;
    return isFetching && !isClinicalNoteLoaded;
  }, [
    noteTemplateIsLoading,
    isLoadingNoteId,
    isClinicalNoteLoaded,
    isClinicalDataLoading,
    isPatientLoading,
  ]);

  const { loadClinicalNote } = useLoadClinicalNote({
    localStoreEnabled,
    currentId: externalEncounterId,
    setClinicalNote,
  });

  useEffect(() => {
    // Loads clinical note after templates have been retrieved.

    if (hasLoadedTemplates && !hasLoadLocal) {
      loadClinicalNote();
      clinicalEditorPageState.setPreviousTitle(
        clinicalNoteStore.editableNoteTitle
      );
      setHasLoadLocal(true);
    }
  }, [
    hasLoadedTemplates,
    hasLoadLocal,
    loadClinicalNote,
    areSectionValuesEmpty,
  ]);

  useEffect(() => {
    // Loads default values of the clinical note
    if (
      !isClinicalNoteLoaded &&
      hasLoadLocal &&
      !isClinicalDataLoading &&
      externalEncounterId
    ) {
      const hashedTemplates = keyBy(clinicalNoteTemplates, 'id');
      const sections = hashedTemplates[currentTemplateId]?.sections;
      if (Array.isArray(sections)) {
        clinicalNoteStore.setAutofillTextValues(autofillForTextSections);
        clinicalNoteStore.setFieldsAndDefaultValues(
          externalEncounterId,
          sections
        );
      }

      if (
        !isDataForEditorLoading &&
        isAutoSaveAfterLoadEnabled &&
        currentTemplateId
      ) {
        saveClinicalNote();
      }
    }
  }, [
    isDataForEditorLoading,
    isAutoSaveAfterLoadEnabled,
    autofillForTextSections,
    saveClinicalNote,
    clinicalNoteTemplates,
    currentTemplateId,
    isClinicalNoteLoaded,
    hasLoadLocal,
    isClinicalDataLoading,
    externalEncounterId,
  ]);

  useEffect(() => {
    // If note is signed, navigates to preview
    // If note is not signed, loads note data into stores
    if (clinicalNote) {
      clinicalNoteStore.setClinicalNote(clinicalNote);
      clinicalNoteStore.setSectionsFromClinicalNoteReturn(
        clinicalNote.content.sections
      );
      if (clinicalNote.content.title) {
        clinicalEditorPageState.setPreviousTitle(clinicalNoteTitle);
        clinicalNoteStore.setEditableNoteTitle(clinicalNote.content.title);
      }
      if (clinicalNote.patient) {
        patientStore.setPatientData(clinicalNote.patient);
      }
      if (clinicalNote.encounter?.dateOfEncounter) {
        clinicalNoteStore.setDateOfService(
          clinicalNote.encounter.dateOfEncounter
        );
      }
      clinicalEditorPageState.setIsAutoSaving(false);
    }
  }, [clinicalNote, clinicalNoteTitle]);

  return {
    clinicalNote,
    externalEncounterId,
    clinicalNoteData,
    fetchNoteByExternalIdError,
    isDataForEditorLoading,
    isPatientLoading,
    hasLoadLocal,
  };
};
