import React, { useState } from 'react';
import clsx from 'clsx';
import { XIcon } from '../Icon/XIcon';
import { CircleCheckIcon } from '../Icon/CircleCheckIcon';
import { useToast } from './ToastProvider';
import { useTimeout } from '../../../hooks/useTimeout';
import { Transition } from '@headlessui/react';
import { CircleErrorIcon } from '../Icon/CircleErrorIcon';

// Const used to define the default duration of the toast
const DEFAULT_DURATION = 3000;

const TYPE_CLASSES = {
  success: {
    bgColor: 'bg-success-100',
    textColor: 'text-success-700',
  },
  error: {
    bgColor: 'bg-error-100',
    textColor: 'text-error-700',
  },
};

export type ToastProps = {
  id: string;
  message: string;
  // use the type classes constant to define the possible types of the toast
  type: keyof typeof TYPE_CLASSES;
  duration?: number | null;
};

export function Toast(props: ToastProps) {
  const { id, message, type, duration = DEFAULT_DURATION } = props;
  const [showToast, setShowToast] = useState(true);
  const { removeToast } = useToast();

  useTimeout({ callback: handleClose, delay: duration });

  function handleClose() {
    setShowToast(false);
  }

  return (
    <Transition
      show={showToast}
      appear={true}
      afterLeave={() => removeToast(id)}
      enter='transform ease-out duration-300 transition'
      enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
      enterTo='translate-y-0 opacity-100 sm:translate-x-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div
        className={clsx(
          'pointer-events-auto z-50 w-full max-w-sm overflow-hidden rounded-lg shadow-lg',
          TYPE_CLASSES[type].bgColor
        )}
      >
        <div className='p-4'>
          <div className={clsx('flex ', TYPE_CLASSES[type].textColor)}>
            {type === 'success' && <CircleCheckIcon className='h-6 w-6' />}
            {type === 'error' && <CircleErrorIcon className='h-6 w-6' />}
            <div className='ml-2'>
              <p>{message}</p>
            </div>
            <button className='ml-auto' onClick={handleClose}>
              <XIcon className='h-6 w-6' />
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
}
