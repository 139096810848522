import { SchedulingModel } from '@akido/provider-desktop-bffe-types';
import { ScheduleEventItem } from '../types/schedule';
import { DateTime } from 'luxon';
import orderBy from 'lodash/orderBy';

export class ScheduleHelpers {
  static convertScheduleItemsToEvents(
    scheduleItems: SchedulingModel[] | undefined
  ): ScheduleEventItem[] {
    const eventItems: ScheduleEventItem[] = [];

    if (Array.isArray(scheduleItems)) {
      for (let index = 0; index < scheduleItems.length; index++) {
        const item = scheduleItems[index];
        const startDate = DateTime.fromISO(item?.startDateTime);
        const endDate = startDate.plus({ minutes: item?.minutesDuration });

        if (
          startDate.isValid &&
          endDate.isValid &&
          isFinite(item?.minutesDuration)
        ) {
          eventItems.push({
            id: String(index),
            start: startDate.toJSDate(),
            end: endDate.toJSDate(),
            patient: {
              patientId: item?.patient?.patientId,
              birthDate: item?.patient?.birthDate,
              fullName: item?.patient?.fullName,
              accountNumber: item?.patient?.accountNumber,
            },
            encounterId: item?.encounterId,
            clinicalNoteStatus: item?.clinicalNoteStatus ?? 'UNKNOWN',
            clinicalNoteId: item?.clinicalNoteId,
          });
        }
      }
    }

    return orderBy(eventItems, 'start', 'asc');
  }
}
