import { gql } from '@apollo/client';

export const PatientPrescriptionsDisplayLabels = gql`
  query PatientPrescriptionsDisplayLabels(
    $patientId: AkidoIdentifierArg!
    $externalEncounterId: String!
  ) {
    patient(patientId: $patientId) {
      prescriptions(externalEncounterId: $externalEncounterId) {
        list {
          displayLabel
        }
      }
    }
  }
`;
