import { CollapsibleSection } from '../../components/AkidoUI/CollapsibleSection';
import { BottomDividerBehavior } from '../../components/AkidoUI/CollapsibleSection/CollapsibleSection';
import { useCallback, useEffect, useRef } from 'react';
import { CustomNUSAEvents } from '../../utilities/events';
import { ClinicalTextArea } from './sections/ClinicalTextArea';

const blurPlaceholder = 'Start Documenting...';
const blurTextColor = '#8AA2B5';
const focusTextColor = '#0C1E2C';

const ClinicalNotesField = ({
  id,
  title = '',
  value = '',
  placeholderText = blurPlaceholder,
  onChange,
  titleContainerStyles = {},
  outlineColor = 'transparent',
  showTopDivider = false,
  bottomDividerStyles = {},
  disabled = false,
  uiSection,
}: {
  id: string;
  title?: string;
  value?: string;
  uiSection?: string;
  placeholderText?: string;
  onChange: (newValue: string) => void;
  titleContainerStyles?: any;
  outlineColor?: string;
  showTopDivider?: boolean;
  bottomDividerStyles?: any;
  disabled?: boolean;
}) => {
  const placeholder = placeholderText ?? blurPlaceholder;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleManualChange = useCallback(() => {
    if (textAreaRef?.current?.value) {
      onChange(textAreaRef?.current?.value);
    }
  }, [onChange]);

  useEffect(() => {
    const textField = textAreaRef.current;

    if (textField) {
      textField.addEventListener(
        CustomNUSAEvents.FINISHED_PROCESSING,
        handleManualChange
      );

      return () => {
        textField.removeEventListener(
          CustomNUSAEvents.FINISHED_PROCESSING,
          handleManualChange
        );
      };
    }
  }, [handleManualChange]);

  return (
    <CollapsibleSection
      title={title}
      containerStyles={{ width: '100%' }}
      titleTextStyles={{
        color: value ? focusTextColor : blurTextColor,
        fontWeight: 600,
        fontSize: '0.775rem',
      }}
      titleContainerStyles={titleContainerStyles}
      fillColor={value ? focusTextColor : blurTextColor}
      showTopDivider={showTopDivider}
      bottomDividerBehavior={BottomDividerBehavior.collapsible}
      bottomDividerStyles={bottomDividerStyles}
    >
      <ClinicalTextArea
        dataTestId={`field_${id}`}
        value={value}
        onChange={onChange}
        styles={{
          textarea: {
            width: '100%',
            marginLeft: '28px',
            marginRight: '28px',
            resize: 'none',
            fontSize: '0.775rem',
            backgroundColor: 'transparent',
            border: 'none',
            backgroundPosition: 'top 5px right -15px',
            outlineColor,
          },
        }}
        placeholder={disabled ? '' : placeholder}
        disabled={disabled}
        uiSection={uiSection}
      />
    </CollapsibleSection>
  );
};

export default ClinicalNotesField;
