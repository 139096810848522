import styled from 'styled-components';
import { LoadingSpinner } from '../../components/AkidoUI/LoadingSpinner';

import { LoadingTheme } from '../../theme';
import { ReactComponent as CircleCheck } from '../../assets/icons/circle-check.svg';
import { ReactComponent as CircleError } from '../../assets/icons/circle-error.svg';

const LoadingContainer = styled.div`
  background-color: #2ba564;
  padding: 6px;
  border-radius: 50%;
`;

export const SaveMessageLeftIcon = ({
  error,
  saving,
}: {
  error?: boolean;
  saving?: boolean;
}) => {
  if (error) {
    return <CircleError />;
  }
  if (saving) {
    return (
      <LoadingContainer>
        <LoadingSpinner size='xsmall' theme={LoadingTheme} />
      </LoadingContainer>
    );
  }
  return <CircleCheck />;
};
