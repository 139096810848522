import { AkidoIdentifier } from '@akido/provider-desktop-bffe-types';
import { gql } from '@apollo/client';

export const createEncounterMutation = gql`
  mutation CreateEncounter($createEncounterData: EncounterCreateDto!) {
    createEncounter(createEncounterData: $createEncounterData)
  }
`;

export interface CreateEncounterVariables {
  createEncounterData: {
    startDateTime: string;
    minutesDuration: string | number;
    fhirPatientId: string;
    appointmentIdentifier: AkidoIdentifier;
  };
}
