/*
 * This method will take the raw audio output from the audio worklet messages
 * and encode it as PCM data, which for our purposes is going to be 16-bit audio.
 * The basics here are that the float values are masked to 16-bit integers and
 * then stored in a buffer and returned for us. This is a pretty standard way to
 * encode audio data for streaming.
 *
 * Later on the application will convert this data to a Uint8Array and send it on
 * to Amazon Transcribe, which has very limited support for audio formats.
 */
export const pcmEncode = (input: Float32Array) => {
  const buffer = new ArrayBuffer(input.length * 2);
  const view = new DataView(buffer);
  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return buffer;
};
