import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import {
  getDragonSecrets,
  GetDragonSecretsReturn,
} from '../../../services/graphql/getDragonSecrets';
import { dragonSettingsStore } from '../../../stores/dragonSettings.store';

export const useDragonSecretsData = () => {
  const { data, error, loading } =
    useQuery<GetDragonSecretsReturn>(getDragonSecrets);

  useEffect(() => {
    if (data) {
      dragonSettingsStore.setSecrets(data?.dragonSpeechToTextSecrets);
    }
  }, [data]);

  return { error, isLoading: loading };
};
