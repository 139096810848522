import { observer } from 'mobx-react-lite';
import { useAIOutput } from '../../../hooks/useAIOutput';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { ClinicalNoteFieldValue } from '../../../types/clinical-notes';
import { EmptyAIOutput } from './EmptyAIOutput';
import { SectionEditableAutofill } from './SectionEditableAutofill';
import { FC } from 'react';

interface AIOutputProps {
  updateCallback: () => void;
  field?: ClinicalNoteFieldValue;
}

export const AIOutput: FC<AIOutputProps> = observer(
  ({ updateCallback, field }) => {
    useAIOutput(clinicalNoteStore.currentRequestId!);

    const hasAiSummary = !!field?.text;

    return hasAiSummary ? (
      <SectionEditableAutofill
        noteId={clinicalNoteStore.clinicalNote?.id ?? ''}
        updateCallback={updateCallback}
        field={field}
        showTitle={false}
        containerStyles={{
          borderRadius: '8px',
          flex: 1,
        }}
        minRows={3}
        maxRows={50}
      />
    ) : (
      <EmptyAIOutput />
    );
  }
);
