import styled from 'styled-components';
import { Link } from '../Link';
import { useLocation } from 'react-router-dom';

const Option = styled.div<{ active?: boolean }>`
  padding-left: 10px;
  margin-left: -1px; // to compensate for the border
  height: 32px;
  display: flex;
  align-items: center;
  border-left: ${(props) => (props.active ? '2px solid #0078D8' : 'none')};
  margin-bottom: 10px;
  font-size: 16px;
`;

export function SidebarItem({ title, url }: { title: string; url: string }) {
  const location = useLocation();
  const active = location.pathname === url;
  return (
    <Option active={active}>
      <Link to={url}>{title}</Link>
    </Option>
  );
}
