import { isValueGreaterThanOrEqualToZero } from './helpers';

export function formatPatientAgeAndDOB(
  birthDate?: string,
  patientAge?: number
) {
  let patientAgeString = '';

  if (isValueGreaterThanOrEqualToZero(patientAge)) {
    patientAgeString = `${patientAge} YO`;
  }

  return `${birthDate} ${patientAgeString}`;
}
