import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as AddCircleIcon } from '../../assets/icons/add-circle-outline.svg';
import { ReactComponent as Note } from '../../assets/icons/note.svg';
import { useAnalytics } from '../../hooks/useAnalytics';
import authStore from '../../stores/auth.store';
import { editScribeNoteModalStore } from '../../stores/editScribeNoteModal.store';
import { scribeNoteModalStore } from '../../stores/scribeNoteModal.store';
import { EncounterAnalyticsEventName } from '../../constants/analytics';

interface TranscriptionNoteIndicatorProps {
  hasNote?: boolean;
  transcriptionId?: string;
  encounterId?: string;
  scribeNoteId?: string;
  scribeNote?: string;
}

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const ScribeNoteIndicator: FC<TranscriptionNoteIndicatorProps> =
  observer((props) => {
    const { encounterId, transcriptionId, scribeNoteId, scribeNote } = props;
    const hasNote = scribeNoteId && scribeNote;
    const canCreateScribeNote = authStore.authorizations.canCreateScribeNotes;
    const { sendEncounterAnalyticsEvent } = useAnalytics();

    if (hasNote) {
      return (
        <Button
          data-testid={`editScribeNoteButton-${encounterId}`}
          onClick={(e) => {
            e.stopPropagation();
            editScribeNoteModalStore.setEncounterId(encounterId);
            editScribeNoteModalStore.setNote(scribeNote);
            editScribeNoteModalStore.setScribeNoteId(scribeNoteId);
            editScribeNoteModalStore.showModal();
            sendEncounterAnalyticsEvent(
              EncounterAnalyticsEventName.ViewScribeNote,
              {
                transcription_id: transcriptionId,
                akido_eid: encounterId,
              }
            );
          }}
        >
          <Note
            color='#1B4C73'
            data-testid={`viewScribeNoteButton-${transcriptionId}`}
          />
        </Button>
      );
    } else if (canCreateScribeNote) {
      return (
        <Button
          data-testid={`addScribeNoteButton-${transcriptionId}`}
          onClick={(event) => {
            event.stopPropagation();
            scribeNoteModalStore.setNoteProps({
              transcriptionId,
              dateAdded: DateTime.now().toISO(),
              note: '',
              encounterId,
            });
            scribeNoteModalStore.showModal();
          }}
        >
          <AddCircleIcon />
        </Button>
      );
    } else {
      return null;
    }
  });
