import React from 'react';
import styled from 'styled-components';
import { Button } from '../AkidoUI/Button';
import { Modal } from '../AkidoUI/Modal';
import { ReactComponent as AlertIcon } from '../../assets/icons/errorExclamationIcon.svg';
import { ReactComponent as NoteModalClose } from '../../assets/icons/close.svg';

interface ErrorModalProps {
  isVisible: boolean;
  onClose: () => void;
  testId: string;
  title: string;
  errorMessage: string;
}

const Container = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
`;

const Header = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  overflow: hidden;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

const ButtonRow = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

const TitleText = styled.span`
  font-size: 1rem;
  font-weight: 500;
  margin-left: 8px;
  color: #0c1e2c;
`;

const ModalText = styled.span`
  font-size: 0.775rem;
  font-weight: 600;
  color: #333333;
`;

const HeaderCloseButton = styled.button`
  height: 26px;
  width: 26px;
  border: none;
  background: transparent;
`;

export const ErrorModal: React.FC<ErrorModalProps> = ({
  isVisible,
  onClose,
  testId,
  title,
  errorMessage,
}) => {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      contentStyles={{
        border: 'none',
        width: '330.925px',
        height: '199.95px',
        padding: '20px 32px 36px 32px',
        boxSizing: 'border-box',
        borderRadius: '16px',
      }}
    >
      <Container data-testid={testId}>
        <Header>
          <HeaderCloseButton
            onClick={onClose}
            data-testid={`${testId}-header-close-button`}
          >
            <NoteModalClose height='100%' width='100%' fill='#1B4C73' />
          </HeaderCloseButton>
          <AlertIcon
            data-testid={`${testId}-header-icon`}
            height='16px'
            width='16px'
          />
          <TitleText data-testid={`${testId}-header-text`}>{title}</TitleText>
        </Header>
        <Content>
          <ModalText data-testid={`${testId}-text`}>{errorMessage}</ModalText>
        </Content>
        <ButtonRow>
          <Button
            testId={`${testId}-bottom-close-button`}
            onClick={onClose}
            label='Close'
            styles={{
              backgroundColor: '#0078D8',
              width: '100%',
              borderRadius: '5px',
              border: 'none',
            }}
          />
        </ButtonRow>
      </Container>
    </Modal>
  );
};
