import React, { useContext, createContext } from 'react';
import { Listbox } from '@headlessui/react';

import { SelectButton } from './SelectButton';
import { SelectOptions } from './SelectOptions';
import { SelectOption } from './SelectOption';

const DEFAULT_PLACEHOLDER_TEXT = 'Please select';

const SelectContext = createContext<{
  placeholder: string;
  error?: string | boolean;
  isLoading?: boolean;
  scrollToSelected?: boolean;
}>({ placeholder: '', error: undefined });

type SelectProps<ValueType> = {
  name?: string;
  label?: string;
  placeholder?: string;
  scrollToSelected?: boolean;
  hint?: string;
  error?: string | boolean;
  disabled?: boolean;
  isLoading?: boolean;
  value?: ValueType;
  defaultValue?: ValueType;
  onChange?: (value: ValueType) => void;
  className?: string;
  children: React.ReactElement[];
  'data-testid'?: string;
};

// Allow generic "value" property so consumer can pass whatever they want into the component for the options value
// This provides access to type saftey in the 'onChange' callback function
export function Select<ValueType>(props: SelectProps<ValueType>) {
  const {
    name,
    label,
    placeholder = DEFAULT_PLACEHOLDER_TEXT,
    scrollToSelected,
    hint,
    error,
    disabled,
    isLoading,
    value,
    defaultValue,
    onChange,
    className,
    children,
    'data-testid': dataTestId,
  } = props;

  return (
    <div className={className}>
      <SelectContext.Provider
        value={{ placeholder, error, isLoading, scrollToSelected }}
      >
        <Listbox
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          disabled={disabled || isLoading}
          data-testid={dataTestId}
          as='div'
        >
          {label && (
            <Listbox.Label className='mb-2 block text-sm font-semibold text-gray-700'>
              {label}
            </Listbox.Label>
          )}
          <div className='relative'>{children}</div>
        </Listbox>
      </SelectContext.Provider>
      <div className='mt-1.5 text-sm'>
        {hint && <p className=' text-gray-500'>{hint}</p>}
        {error && typeof error === 'string' && (
          <p className=' text-red-500'>{error}</p>
        )}
      </div>
    </div>
  );
}

export function useSelectContext() {
  return useContext(SelectContext);
}

Select.Button = SelectButton;
Select.Options = SelectOptions;
Select.Option = SelectOption;
