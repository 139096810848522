import { observer } from 'mobx-react-lite';
import { ReactComponent as EmptyIcon } from '../../../assets/icons/EmptyAiOutput.svg';
import { EmptyView } from '../../../components/AkidoUI/EmptyView/EmptyView';
import { TestID } from '../../../constants/testIds';

export const EmptyAIOutput = observer(() => {
  return (
    <EmptyView
      classNames={{
        container: 'mt-8',
      }}
      title='No summary generated'
      subtitle='Select Generate to read and edit the summary note'
      Icon={EmptyIcon}
      testId={{
        icon: TestID.ClinicalNote.EmptyAiOutput,
      }}
    />
  );
});
