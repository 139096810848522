import { isEmpty } from 'lodash';

export class AllergyHelpers {
  static buildFieldString(
    fieldText: string | undefined,
    drugAllergiesStringBlock: string,
    nonDrugAllergiesStringBlock: string
  ) {
    if (isEmpty(fieldText)) {
      let returnString = '';

      if (!isEmpty(drugAllergiesStringBlock)) {
        returnString += `Drug Allergies:\n${drugAllergiesStringBlock.trim()}\n\n`;
      }

      if (!isEmpty(nonDrugAllergiesStringBlock)) {
        returnString += `Non Drug Allergies:\n${nonDrugAllergiesStringBlock.trim()}`;
      }

      return returnString;
    }

    return fieldText;
  }
}
