import { makeAutoObservable } from 'mobx';
import { DragonSecrets } from '../types/dragonSecrets';

// In the NOT_INITIALIZED state the Dragon script hasn't been downloaded and added to the app (we init the library dynamically)
// in the ACTIVE state the Dragon UI is visible
// in the INACTIVE state the Dragon UI is not currently visible, but can be rendered in a different place
export enum DRAGON_STATE {
  NOT_INITIALIZED = 'NOT_INITIALIZED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export class DragonSettingsStore {
  secretsData: DragonSecrets = {
    partnerGUID: '',
    applicationName: '',
    organizationToken: '',
  };

  state: DRAGON_STATE = DRAGON_STATE.NOT_INITIALIZED;
  isRecording = false;

  constructor() {
    makeAutoObservable(this);
  }

  get dragonSecrets() {
    return this.secretsData;
  }

  get recording() {
    return this.isRecording;
  }

  toggleRecording() {
    if (this.isRecording) {
      this.stopRecording();
      this.isRecording = false;
    } else {
      this.startRecording();
      this.isRecording = true;
    }
  }

  setSecrets({
    partnerGUID,
    applicationName,
    organizationToken,
  }: DragonSecrets) {
    this.secretsData = {
      partnerGUID,
      applicationName,
      organizationToken,
    };
  }

  setActive() {
    this.state = DRAGON_STATE.ACTIVE;
  }

  setInactive() {
    if (this.state === DRAGON_STATE.ACTIVE) {
      this.state = DRAGON_STATE.INACTIVE;
    }
  }

  startRecording() {
    window.NUSA_startRecording();
  }

  stopRecording() {
    window.NUSA_stopRecording();
  }

  reset() {
    this.secretsData = {
      partnerGUID: '',
      applicationName: '',
      organizationToken: '',
    };

    this.state = DRAGON_STATE.NOT_INITIALIZED;
  }
}

export const dragonSettingsStore = new DragonSettingsStore();
