const userIsPractitioner = (groups: string[]) => {
  return groups.some((group) => group.toLowerCase().includes('practitioner'));
};

const userIsTranscriptionist = (groups: string[]) => {
  return groups.some((group) =>
    group.toLowerCase().includes('transcriptionist')
  );
};

export const getUserRole = (groups: string[]) => {
  const isPractitioner = userIsPractitioner(groups);
  if (isPractitioner) {
    return 'practitioner';
  }
  const isTranscriptionist = userIsTranscriptionist(groups);
  if (isTranscriptionist) {
    return 'transcriptionist';
  }

  return 'UNKNOWN';
};
