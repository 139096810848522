import { gql } from '@apollo/client';
import { FhirEncounterModel } from '@akido/provider-desktop-bffe-types';

export type GetEncounterByExternalIdParams = {
  externalId: string | null;
};

export type GetEncounterByExternalIdReturn = {
  getFhirEncounter: FhirEncounterModel;
};

export const getEncounterWithPatientQuery = gql`
  query GetEncounterByExternalId($externalId: String!) {
    getFhirEncounter(externalId: $externalId) {
      dateOfEncounter
      encounterId
      patient {
        patientId
        birthDate
        familyName
        givenName
        age
        accountNumber {
          type
          value
        }
      }
      referringPractitioner {
        id
        fullName
      }
      previousResourceIds {
        encounterFhirId
        akidoClinicalNoteId
      }
    }
  }
`;
