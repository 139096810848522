import { MutableRefObject, useCallback, useEffect } from 'react';

export const useOnClickOutside = (
  ref: MutableRefObject<HTMLElement | null>,
  handler: () => void
) => {
  const _handler = useCallback<EventListener>(
    (event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler();
      }
    },
    [ref, handler]
  );
  useEffect(() => {
    document.addEventListener('mousedown', _handler);
    return () => {
      document.removeEventListener('mousedown', _handler);
    };
  }, [_handler]);
};
