import { observer } from 'mobx-react-lite';
import { sortBy } from 'lodash';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { CategoryVariant } from '../../../types/clinical-notes';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { useCallback } from 'react';
import { clinicalNoteTemplatesStore } from '../../../stores/clinicalNoteTemplates.store';
import { AssessmentsField } from '../assessment/AssessmentsField';
import { PrescriptionsField } from '../prescriptions/PrescriptionsField';
import { OrdersField } from '../orders/OrdersField';
import { UISectionIds } from './uiSectionIds';
import { BillingField } from '../billing/BillingField';
import { AIFields } from './AIFields';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { SectionEditableAutofill } from './SectionEditableAutofill';
import { SectionReadonlyAutofill } from './SectionReadonlyAutofill';
import { TemplateModel } from '@akido/provider-desktop-bffe-types';

interface IProps {
  patientId?: string;
  noteId: string;
  categoryToDisplay: CategoryVariant;
  updateCallback: () => void;
  showAutofillCard?: boolean;
  template?: TemplateModel;
}

export const SectionFields = observer(
  ({
    noteId,
    categoryToDisplay,
    updateCallback,
    showAutofillCard = false,
    template,
  }: IProps) => {
    const localStoreEnabled = useFeatureFlag(
      FEATURE_FLAGS.LOCAL_STORE_CLINICAL_NOTES,
      false
    );

    const isAiEnabled = useFeatureFlag(FEATURE_FLAGS.AI_TRANSCRIPTION, false);
    const sectionValues = clinicalNoteStore.sectionValues;

    const createComponentFieldsArray = useCallback(() => {
      let dataFields = [];
      const components = [];

      for (const sectionId in sectionValues) {
        const field = sectionValues[sectionId];
        if (field.category === categoryToDisplay) {
          dataFields.push(field);
        }
      }

      if (dataFields[0]?.sortOrder === undefined) {
        dataFields = clinicalNoteTemplatesStore.sortFieldsPerTemplate(
          clinicalNoteStore.templateId,
          dataFields
        );
      }

      const sortedDataFields = sortBy(dataFields, ['sortOrder']);

      for (const field of sortedDataFields) {
        if (field?.id) {
          if (template?.name === 'AI Note' && isAiEnabled) {
            return (
              <AIFields
                fields={sortedDataFields}
                noteId={noteId}
                updateCallback={updateCallback}
              />
            );
          }

          switch (field.uiSection?.toLowerCase()) {
            case UISectionIds.Assessments: {
              components.push(
                <AssessmentsField
                  key={field.id}
                  sectionId={field.id}
                  updateCallback={updateCallback}
                  noteId={noteId}
                  title={field.title}
                  sortOrder={field.sortOrder}
                  type={field.type}
                  uiSection={field.uiSection}
                  assessmentTextValue={field.text}
                  localStoreEnabled={localStoreEnabled}
                />
              );
              break;
            }
            case UISectionIds.Prescriptions: {
              components.push(
                <PrescriptionsField
                  key={field.id}
                  sectionId={field.id}
                  uiSection={field.uiSection}
                  field={field}
                  noteId={noteId}
                  updateCallback={updateCallback}
                  localStoreEnabled={localStoreEnabled}
                />
              );
              break;
            }
            case UISectionIds.Billing: {
              components.push(
                <BillingField
                  key={field.id}
                  sectionId={field.id}
                  updateCallback={updateCallback}
                  noteId={noteId}
                  title={field.title}
                  sortOrder={field.sortOrder}
                  type={field.type}
                  uiSection={field.uiSection}
                  billingTextValue={field.text}
                  localStoreEnabled={localStoreEnabled}
                />
              );

              break;
            }
            case UISectionIds.Orders: {
              components.push(
                <OrdersField
                  key={field.id}
                  sectionId={field.id}
                  field={field}
                  noteId={noteId}
                  updateCallback={updateCallback}
                  localStoreEnabled={localStoreEnabled}
                />
              );
              break;
            }
            default: {
              if (showAutofillCard) {
                components.push(
                  <SectionEditableAutofill
                    key={field?.id}
                    field={{
                      ...field,
                      sectionId: field.id,
                    }}
                    noteId={noteId}
                    updateCallback={updateCallback}
                  />
                );
              } else {
                components.push(
                  <SectionReadonlyAutofill
                    key={field?.id}
                    field={{
                      ...field,
                      sectionId: field.id,
                    }}
                    categoryToDisplay={categoryToDisplay}
                    noteId={noteId}
                    updateCallback={updateCallback}
                  />
                );
              }
              break;
            }
          }
        }
      }
      return components;
    }, [
      sectionValues,
      categoryToDisplay,
      template?.name,
      isAiEnabled,
      noteId,
      updateCallback,
      localStoreEnabled,
      showAutofillCard,
    ]);

    return <>{createComponentFieldsArray()}</>;
  }
);
