import {
  LocationModel,
  OrganizationModel,
} from '@akido/provider-desktop-bffe-types';
import { gql, useQuery } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query GetLocations {
    getStreetMedicineLocations {
      id
      label
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    getOrganizations {
      id
      name
    }
  }
`;

export interface GetOrganizationsReturn {
  getOrganizations: OrganizationModel[];
}

export interface GetLocationsReturn {
  getStreetMedicineLocations: LocationModel[];
}

export function useCreatePatientResources() {
  const { data: locations, loading: isLoadingLocations } =
    useQuery<GetLocationsReturn>(GET_LOCATIONS);
  const { data: organizations, loading: isLoadingOrganizations } =
    useQuery<GetOrganizationsReturn>(GET_ORGANIZATIONS);

  return {
    locations,
    organizations,
    isLoading: isLoadingLocations || isLoadingOrganizations,
  };
}
