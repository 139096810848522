import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';
import { RoutePaths } from '../../constants/routes';
import { CalendarSchedule } from '../../components/Calendar/Calendar';
import { useUserAuthorization } from '../../hooks/useUserAuthorization';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import authStore from '../../stores/auth.store';
import { Sidebar, SidebarItem, SidebarSection } from '../../components';

const Container = styled.div`
  display: flex;
`;

export const Schedule = observer(() => {
  useUserAuthorization();
  return (
    <ConditionalRender shouldRender={authStore.isLoaded}>
      <ConditionalRender
        shouldRender={authStore.authorizations.canViewOwnSchedules}
      >
        <Container>
          <Sidebar>
            <SidebarSection title='HOME'>
              <SidebarItem title='Schedule' url={RoutePaths.Schedule} />
              <SidebarItem
                title='Transcriptions'
                url={RoutePaths.Transcriptions}
              />
            </SidebarSection>
          </Sidebar>
          <CalendarSchedule />
        </Container>
      </ConditionalRender>
      <ConditionalRender
        shouldRender={!authStore.authorizations.canViewOwnSchedules}
      >
        <Navigate to='/error?code=ACCESS_DENIED' />
      </ConditionalRender>
    </ConditionalRender>
  );
});
