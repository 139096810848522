import { LoadingSpinner } from '../../components/AkidoUI/LoadingSpinner';
import styled from 'styled-components';
import { TestID } from '../../constants/testIds';
import { FC } from 'react';

const refreshIcon = require('../../assets/icons/reloadIcon.svg').default;

interface IProps {
  isLoading: boolean;
  onRefreshClicked: () => void;
}

const ReloadContainer = styled.img({
  overflow: 'hidden',
  marginRight: '10px',
});

const LoadingContainer = styled.div({
  display: 'flex',
  backgroundColor: '#0078D8',
  padding: '4px',
  borderRadius: '50%',
  width: '1.1rem',
  height: '1.1rem',
  margin: '0 13px 0 7px',
});
const RefreshActionButton = styled.button({
  display: 'flex',
  background: 'none',
  border: 'none',
  alignItems: 'center',
  marginRight: '10px',
});

export const RefreshButton: FC<IProps> = ({ isLoading, onRefreshClicked }) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner
          containerStyles={{ display: 'flex', alignItems: 'center' }}
          size='xsmall'
          type='line'
        />
      </LoadingContainer>
    );
  }

  return (
    <RefreshActionButton
      onClick={onRefreshClicked}
      data-testid={TestID.ClinicalNote.RefreshButton}
    >
      <ReloadContainer src={refreshIcon} alt='refresh button' />
    </RefreshActionButton>
  );
};
