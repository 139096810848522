import { TextField } from '../../../components/AkidoUI/TextField';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';
import { DragonSpeechToText } from '../../../components/SpeechToText/DragonSpeechToText';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { useSignAddendum } from '../hooks/useSignAddendum';
import authStore from '../../../stores/auth.store';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { localClinicalNotesStore } from '../../../stores/localPersistentClinicalNotes.store';
import { StatusMessage } from '../StatusMessage/StatusMessage';
import { AddAddendumButtons } from './AddAddendumButtons';
import { CustomNUSAEvents } from '../../../utilities/events';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { ClinicalNoteAnalyticsEventName } from '../../../constants/analytics';

const Container = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  flex-direction: column;
  border: 1px solid #0078d8;
  border-radius: 10px;
`;

const FormContainer = styled.div``;

const Title = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
  margin: 24px 32px;
`;

const Separator = styled.div`
  background-color: #f2f2f2;
  height: 1px;
`;

const StatusContainer = styled.div`
  width: 90%;
  margin: auto;
`;

interface AddendumInputProps {
  onCancelSignAddendum: () => Promise<void> | void;
  visible: boolean;
}

export const AddendumInput: FC<AddendumInputProps> = (props) => {
  const { onCancelSignAddendum, visible } = props;
  const [value, setValue] = useState('');
  const { id = '' } = useParams();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { sendClinicalNoteAnalyticsEvent } = useAnalytics();
  const { clinicalNote } = clinicalNoteStore;
  const ldClient = useLDClient();

  const onChange = useCallback(
    (text: string) => {
      setValue(text);
      localClinicalNotesStore.addAddendum(id, text);
    },
    [id]
  );

  const handleManualChange = useCallback(() => {
    if (textAreaRef?.current?.value) {
      onChange(textAreaRef?.current?.value);
    }
  }, [onChange]);

  useEffect(() => {
    const addendumTextField = textAreaRef.current;

    if (addendumTextField) {
      addendumTextField.addEventListener(
        CustomNUSAEvents.FINISHED_PROCESSING,
        handleManualChange
      );

      return () => {
        addendumTextField.removeEventListener(
          CustomNUSAEvents.FINISHED_PROCESSING,
          handleManualChange
        );
      };
    }
  }, [handleManualChange]);

  const { data, error, isLoading, signAddendum } = useSignAddendum(value, id);

  useEffect(() => {
    const storedAddendum = localClinicalNotesStore.getAddendumByNoteId(id);
    if (!storedAddendum) {
      localClinicalNotesStore.createAddendumForNote(id);
    } else {
      setValue(storedAddendum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      clinicalNoteStore.addAddendum(data);
    }
  }, [data]);

  const onSignAddendum = useCallback(async () => {
    if (value === '' || value === null || value === undefined) {
      return;
    }

    if (!isLoading) {
      try {
        await signAddendum();
        sendClinicalNoteAnalyticsEvent(
          ClinicalNoteAnalyticsEventName.SignAddendum,
          {
            capella_note_id: clinicalNote?.encounterId,
            akido_note_id: id,
          }
        );
        onCancelSignAddendum();
        localClinicalNotesStore.deleteAddendum(id);
        clinicalNoteStore.showNewStatusMessage('Addendum Signed');
        setValue('');
      } catch {
        clinicalNoteStore.setStatusMessageState('visible');
      }
    }
  }, [
    value,
    isLoading,
    signAddendum,
    sendClinicalNoteAnalyticsEvent,
    clinicalNote,
    id,
    onCancelSignAddendum,
  ]);

  const addendumStyle = ldClient?.variation(FEATURE_FLAGS.NEW_ADDENDUM_UI)
    ? {
        height: 'unset',
        paddingBottom: '30px',
      }
    : {};

  return (
    <Container
      data-testid={TestID.Addendum.AddendumInputsContainer}
      style={{
        display: visible ? '' : 'none',
        ...addendumStyle,
      }}
    >
      <FormContainer>
        <Title>Addendum</Title>
        <Separator />
        <TextField
          value={value}
          onChange={onChange}
          multiline
          placeholder='| Start typing or select the microphone icon to dictate'
          inputStyles={{
            width: '100%',
            border: 'none',
            padding: '16px 0',
            minHeight: '150px',
          }}
          containerStyles={{
            margin: '24px 32px',
          }}
          onBlur={handleManualChange}
          ref={textAreaRef}
        />
        <Separator />
        <DragonSpeechToText
          styles={{
            container: {
              margin: '25px 35px 25px 0',
              justifyContent: 'flex-end',
            },
          }}
          userId={authStore.authInfo?.preferred_username}
        />
        <AddAddendumButtons
          onSignAddendum={onSignAddendum}
          isSigning={isLoading}
          onCancelSignAddendum={onCancelSignAddendum}
        />
      </FormContainer>
      <StatusContainer>
        <StatusMessage
          error={!!error}
          testId={TestID.Addendum.AddAddendumResultDialog}
          closeTestId={TestID.Addendum.AddAddendumCloseResultDialog}
        />
      </StatusContainer>
    </Container>
  );
};
