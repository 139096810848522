import styled from 'styled-components';
import { TestID } from '../../constants/testIds';
import { ArrowIcon } from './ArrowIcon';

const HeaderContainer = styled.div({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  width: '100%',
  height: '100%',
});

const StaticNoteTemplateText = styled.div({
  alignSelf: 'center',
  fontWeight: 500,
  fontSize: '.775rem',
  marginLeft: '20px',
});

const SelectedNoteTemplateText = styled.div({
  marginLeft: '8px',
  backgroundColor: '#E3F3FF',
  borderRadius: '12px',
  padding: '4px',
  paddingRight: '16px',
  paddingLeft: '16px',
  fontSize: '0.65rem',
  height: 'fit-content',
  fontWeight: 400,
});

const TextContainer = styled.div({
  display: 'flex',
  flex: 5,
  flexDirection: 'row',
});

const ArrowContainer = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'end',
  marginRight: '20px',
});

export const ClinicalNoteHeader = ({
  label,
  isOpen = false,
  testId = TestID.ClinicalNote.TemplateDropdownTextDefault,
}: {
  label: string;
  isOpen?: boolean;
  testId?: string;
}) => (
  <HeaderContainer data-testid={testId}>
    <TextContainer>
      <StaticNoteTemplateText>Note Template </StaticNoteTemplateText>
      <SelectedNoteTemplateText>{label}</SelectedNoteTemplateText>
    </TextContainer>
    <ArrowContainer>
      <ArrowIcon
        style={{
          transform: isOpen ? 'rotate(0.5turn)' : '',
          alignSelf: 'center',
        }}
      />
    </ArrowContainer>
  </HeaderContainer>
);
