import { useCallback } from 'react';
import { localClinicalNotesStore } from '../../../stores/localPersistentClinicalNotes.store';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { useUpdateClinicalNote } from './useUpdateClinicalNote';
import { useSaveClinicalNote } from './useSaveClinicalNote';
import { clinicalEditorPageState } from '../../../stores/clinicalEditorPageState.store';
import { ClinicalNoteSaveStatus } from '../../../types/clinical-notes';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { ClinicalNoteAnalyticsEventName } from '../../../constants/analytics';
import { Maybe } from '@akido/provider-desktop-bffe-types';

interface IProps {
  currentId: string | null;
  akidoNoteId: string | undefined;
  externalEncounterId: Maybe<string> | undefined;
}

export const useHandleOnClinicalNotePageSave = ({
  currentId,
  akidoNoteId,
  externalEncounterId,
}: IProps) => {
  const { sendClinicalNoteAnalyticsEvent } = useAnalytics();
  const clinicalNoteTitle = clinicalNoteStore.editableNoteTitle;
  const currentTemplateId = clinicalNoteStore.templateId;
  const { updateClinicalNote } = useUpdateClinicalNote({
    clinicalNoteId: akidoNoteId,
  });

  const { saveClinicalNote } = useSaveClinicalNote({
    externalEncounterId,
    clinicalNoteTitle,
    templateId: currentTemplateId,
  });

  const saveNote = useCallback(
    async (autoSave = false, override = false) => {
      clinicalEditorPageState.clearAutoSaveTimeoutInterval();
      clinicalEditorPageState.setSaveStatus(ClinicalNoteSaveStatus.isSaving);
      clinicalEditorPageState.setShouldBlockNavigation(false);
      let result;
      clinicalEditorPageState.setIsAutoSaving(autoSave);
      if (!akidoNoteId) {
        result = await saveClinicalNote();
      } else {
        result = await updateClinicalNote(override);
      }

      if (currentId) {
        localClinicalNotesStore.deleteClinicalNoteById(currentId);
      }

      if (result) {
        sendClinicalNoteAnalyticsEvent(
          ClinicalNoteAnalyticsEventName.SaveNote,
          {
            capella_note_id: externalEncounterId,
            capella_eid: externalEncounterId,
            akido_note_id: result,
          }
        );
        clinicalEditorPageState.setSaveStatus(ClinicalNoteSaveStatus.hasSaved);
      } else {
        clinicalEditorPageState.setSaveStatus(
          ClinicalNoteSaveStatus.hasSaveError
        );
      }

      return result;
    },
    [
      akidoNoteId,
      currentId,
      saveClinicalNote,
      updateClinicalNote,
      sendClinicalNoteAnalyticsEvent,
      externalEncounterId,
    ]
  );

  return {
    saveNote,
  };
};
