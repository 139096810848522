import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { TestID } from '../../constants/testIds';
import { ActionsModal } from '../../components/Modal/ActionsModal';

interface ChangeTemplateModalProps {
  isVisible: boolean;
  onAcceptClose: () => void;
  onDeclineClose: () => void;
}

export const ChangeTemplateModal: FC<ChangeTemplateModalProps> = observer(
  ({ isVisible, onAcceptClose, onDeclineClose }) => {
    return (
      <ActionsModal
        title='Change Template'
        isVisible={isVisible}
        onDecline={onDeclineClose}
        onAccept={onAcceptClose}
        testId={TestID.ClinicalNote.ChangeTemplateModal}
        contentClassNames='pb-[10px]'
        contentStyles={{
          height: '18rem',
        }}
      >
        Changing template will result in loss of entered information in the
        previous template.
        <br />
        <br />
        Select OK to continue.
      </ActionsModal>
    );
  }
);
