export const observeProperty = (
  target: any,
  propName: string,
  onChange: (value: any) => void
) => {
  const descr = Object.getOwnPropertyDescriptor(target, propName);
  if (descr) {
    let _value = target[propName];
    Object.defineProperty(target, propName, {
      set(value) {
        if (_value !== value) {
          _value = value;
          onChange(value);
          if (descr.set) {
            descr.set.call(this, value);
          }
        }
      },
      get() {
        if (descr.get) {
          return descr.get.call(this);
        } else {
          return _value;
        }
      },
    });
    return () => {
      Object.defineProperty(target, propName, descr);
    };
  } else {
    throw new Error(`property "${propName}" not found`);
  }
};
