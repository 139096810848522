import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from 'styled-components';
import { updateScribeNote } from '../../../services/graphql/updateScribeNote';
import authStore from '../../../stores/auth.store';
import { editScribeNoteModalStore } from '../../../stores/editScribeNoteModal.store';
import { scribeNoteModalStore } from '../../../stores/scribeNoteModal.store';
import { ScribeNoteStyles } from '../CreateScribeNoteModal/scribeNote.styles';
import { Modal } from '../../../components/AkidoUI/Modal';
import { EditScribeNoteContent } from './EditScribeNoteContent';
import { EditScribeNoteHeader } from './EditScribeNoteHeader';
import { SaveScribeNoteButton } from './SaveScribeNoteButton';

interface ScribeNoteModalProps {}

const Container = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
`;

export const EditScribeNoteModal: FC<ScribeNoteModalProps> = observer(() => {
  const selectedTranscriptionId = editScribeNoteModalStore.selectedEncounterId;
  const scribeNoteId = editScribeNoteModalStore.scribeNoteId;

  const canEditScribeNotes = authStore.authorizations.canEditScribeNotes;

  const [saveScribeNote] = useMutation(updateScribeNote);

  return (
    <Modal
      contentStyles={ScribeNoteStyles.modalContentStyles}
      isVisible={editScribeNoteModalStore.isVisible}
      onClose={() => editScribeNoteModalStore.hideModal()}
    >
      <Container data-testid='scribeNoteModalContainer'>
        <EditScribeNoteHeader
          id={selectedTranscriptionId}
          canEdit={canEditScribeNotes}
        />
        <EditScribeNoteContent />
        <SaveScribeNoteButton
          onClick={async (scribeNote: string) => {
            try {
              await saveScribeNote({
                variables: {
                  note: scribeNote,
                  scribeNoteId: scribeNoteId,
                },
              });
              await scribeNoteModalStore.refreshData();
              editScribeNoteModalStore.hideModal();
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </Container>
    </Modal>
  );
});
