import { FC, useCallback } from 'react';
import { useTranscriptionData } from '../../routes/TranscriptionsList/hooks/useTranscriptionData';
import { transcriptionFilterStore } from '../../stores/transcriptionFilter.store';
import { TestID } from '../../constants/testIds';
import { FilterDisplay } from './FilterDisplay';
import { SelectOption } from './Select/types';

interface FilterProps {
  onClose?: () => void;
  isVisible?: boolean;
}

export const Filter: FC<FilterProps> = (props) => {
  const { onClose, isVisible } = props;

  useTranscriptionData();

  const setSubmissionFilter = useCallback((fromDate: Date, toDate: Date) => {
    transcriptionFilterStore.setSubmissionDateFilter(fromDate, toDate);
  }, []);

  const setAppointmentFilter = useCallback((fromDate: Date, toDate: Date) => {
    transcriptionFilterStore.setAppointmentDateFilter(fromDate, toDate);
  }, []);

  const setStatusFilter = useCallback(
    (option: SelectOption, action: string) => {
      switch (action) {
        case 'select-option':
          transcriptionFilterStore.setStatusIds([option.value]);
          break;
        case 'deselect-option':
          transcriptionFilterStore.setStatusIds(null);
          break;
      }
    },
    []
  );

  const setPractitionerFilter = useCallback(
    (option: SelectOption, action: string) => {
      switch (action) {
        case 'select-option':
          transcriptionFilterStore.setPractitionerIds([option.value]);
          break;
        case 'deselect-option':
          transcriptionFilterStore.setPractitionerIds(null);
          break;
      }
    },
    []
  );

  return (
    <FilterDisplay
      data-testid={TestID.Transcriptions.FilterDisplay}
      onClose={onClose}
      isVisible={isVisible}
      onSubmissionFilterChange={setSubmissionFilter}
      onAppointmentFilterChange={setAppointmentFilter}
      onStatusFilterChange={setStatusFilter}
      onPractitionerFilterChange={setPractitionerFilter}
    />
  );
};
