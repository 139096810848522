import { Dialog } from '@headlessui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TestID } from '../../../constants/testIds';
import { Input } from '../../AkidoUI/Input/Input';
import { Select } from '../../AkidoUI/Select/Select';
import { SelectButton } from '../../AkidoUI/Select/SelectButton';
import { SelectOption } from '../../AkidoUI/Select/SelectOption';
import { SelectOptions } from '../../AkidoUI/Select/SelectOptions';
import { useCreatePatientResources } from './hooks/useCreatePatientResource';

const SEX_AT_BIRTH_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Unknown', value: 'unknown' },
  { label: 'Undifferentiated', value: 'undifferentiated' },
];

export type FormSchema = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber?: string;
  sexAtBirth?: (typeof SEX_AT_BIRTH_OPTIONS)[number];
  streetAddress?: string;
  streetAddress2?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  region?: { id: string; label: string };
  insurance?: { id: string; name: string };
  memberId?: string;
};

type CreatePatientModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormSchema) => Promise<void>;
};

// Format phone number to 000-000-0000 format
function formatPhoneNumber(value: string) {
  if (!value) return '';

  let formatted: string[] = [];
  // remove any white space
  const cleaned = value.replace(/\D/g, '');
  // match the 000-000-0000 format
  const match = cleaned.match(/^(\d{1,3})?(\d{1,3})?(\d{1,4})?$/);

  // Regex will separate the value into 3 groups. Loop through those groups and add to
  // formatted array so we can join them with dashes.
  if (match) {
    for (let i = 1; i < match.length; i++) {
      if (match[i]) {
        formatted.push(match[i]);
      }
    }

    // Add dashes between number groups.
    return formatted.join('-');
  }

  return null;
}

export function CreatePatientModal(props: CreatePatientModalProps) {
  const { isOpen, onClose, onSubmit } = props;
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormSchema>();

  const { organizations, locations, isLoading } = useCreatePatientResources();

  const handleFormSubmit: SubmitHandler<FormSchema> = async (data) => {
    await onSubmit(data);
    reset();
  };

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className='relative z-50'
      data-testid={TestID.CreatePatientModal.Dialog}
    >
      <div
        className='fixed inset-0 bg-gray-700/70 backdrop-blur-sm'
        aria-hidden='true'
      />
      <div className='fixed inset-0 w-screen overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4'>
          <Dialog.Panel className='mx-auto rounded bg-white'>
            <div className='p-6'>
              <Dialog.Title className='text-lg font-semibold text-gray-900'>
                Create New Patient
              </Dialog.Title>
              <Dialog.Description className='text-sm text-gray-500'>
                Select date, time and patient for your new encounter
              </Dialog.Description>
            </div>
            <hr className='border-gray-300' />
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className='grid grid-cols-2 gap-4 p-6'>
                <Input
                  data-private
                  label='Patient First Name'
                  placeholder='First name'
                  {...register('firstName', {
                    required: 'First name is required!',
                  })}
                  error={errors.firstName?.message}
                  data-testid={TestID.CreatePatientModal.FirstNameInput}
                />
                <Input
                  data-private
                  label='Patient Last Name'
                  placeholder='Last name'
                  {...register('lastName', {
                    required: 'Last name is required!',
                  })}
                  error={errors.lastName?.message}
                  data-testid={TestID.CreatePatientModal.LastNameInput}
                />
                <Input
                  data-private
                  label='Date of Birth'
                  type='date'
                  {...register('dateOfBirth', {
                    required: 'Date of birth is required!',
                  })}
                  error={errors.dateOfBirth?.message}
                  data-testid={TestID.CreatePatientModal.DateOfBirthInput}
                />
                <Controller
                  name='phoneNumber'
                  control={control}
                  render={({ field }) => (
                    <Input
                      data-private
                      label='Phone Number (optional)'
                      placeholder='000-000-0000'
                      value={field.value || ''}
                      onChange={(e) => {
                        const formattedPhoneNumber = formatPhoneNumber(
                          e.target.value
                        );
                        if (formattedPhoneNumber !== null) {
                          field.onChange(formattedPhoneNumber);
                        }
                      }}
                      data-testid={TestID.CreatePatientModal.PhoneNumberInput}
                    />
                  )}
                />
                <Controller
                  name='sexAtBirth'
                  control={control}
                  render={({ field }) => (
                    <Select
                      label='Sex at birth (optional)'
                      className='col-span-2'
                      onChange={field.onChange}
                      value={field.value ?? ''}
                      data-testid={TestID.CreatePatientModal.SexAtBirthInput}
                    >
                      <SelectButton>{field.value?.label ?? ''}</SelectButton>
                      <SelectOptions>
                        {SEX_AT_BIRTH_OPTIONS.map((option) => (
                          <SelectOption
                            value={option}
                            key={option.value}
                            isSelected={option.value === field.value?.value}
                          >
                            {option.label}
                          </SelectOption>
                        ))}
                      </SelectOptions>
                    </Select>
                  )}
                />

                <Controller
                  disabled={isLoading}
                  name='region'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        label='Region (optional)'
                        className='col-span-2'
                        onChange={field.onChange}
                        value={field.value ?? ''}
                        data-testid={TestID.CreatePatientModal.Region}
                      >
                        <SelectButton>{field.value?.label ?? ''}</SelectButton>
                        <SelectOptions>
                          {locations?.getStreetMedicineLocations?.map(
                            (option) => (
                              <SelectOption
                                value={option}
                                key={option.id}
                                isSelected={option.label === field.value?.label}
                              >
                                {option.label}
                              </SelectOption>
                            )
                          )}
                        </SelectOptions>
                      </Select>
                    );
                  }}
                />
                <Controller
                  disabled={isLoading}
                  name='insurance'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        data-private
                        label='Insurance Plan (optional)'
                        onChange={field.onChange}
                        value={field.value?.id ?? ''}
                        data-testid={TestID.CreatePatientModal.InsurancePlan}
                      >
                        <SelectButton data-private>
                          {field.value?.name ?? ''}
                        </SelectButton>
                        <SelectOptions data-private>
                          {organizations?.getOrganizations?.map((option) => (
                            <SelectOption
                              value={option}
                              key={option.id}
                              isSelected={option.name === field.value?.name}
                            >
                              {option.name}
                            </SelectOption>
                          ))}
                        </SelectOptions>
                      </Select>
                    );
                  }}
                />
                <Input
                  data-private
                  label='Member ID (optional)'
                  placeholder=''
                  {...register('memberId')}
                  data-testid={TestID.CreatePatientModal.MemberId}
                />
                <Input
                  data-private
                  label='Street Address (optional)'
                  placeholder='Street Address Line 1'
                  className='col-span-2'
                  {...register('streetAddress')}
                  data-testid={TestID.CreatePatientModal.StreetAddressInput1}
                />
                <Input
                  data-private
                  placeholder='Street Address Line 2'
                  className='col-span-2'
                  {...register('streetAddress2')}
                  data-testid={TestID.CreatePatientModal.StreetAddressInput2}
                />
                <Input
                  data-private
                  label='City (optional)'
                  placeholder='City Name'
                  className='col-span-2'
                  {...register('city')}
                  data-testid={TestID.CreatePatientModal.CityInput}
                />
                <Input
                  data-private
                  label='State (optional)'
                  placeholder='State Name'
                  {...register('state')}
                  data-testid={TestID.CreatePatientModal.StateInput}
                />
                <Input
                  data-private
                  label='Zip Code (optional)'
                  placeholder='Zip Code'
                  {...register('zipCode')}
                  data-testid={TestID.CreatePatientModal.ZipCodeInput}
                />
              </div>
              <hr className='border-gray-300' />
              <div className='flex justify-end space-x-3 p-6'>
                <button
                  type='button'
                  className='btn btn-md btn-white'
                  onClick={handleOnClose}
                  data-testid={TestID.CreatePatientModal.CancelButton}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='btn btn-md btn-primary'
                  data-testid={TestID.CreatePatientModal.SubmitButton}
                >
                  Create
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
