import * as React from 'react';

export const ArrowIcon = ({ style }: { style: any }) => (
  <svg
    width={12}
    height={8}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <path
      d='M2.007.454 6.25 4.88 10.491.454l1.304 1.362-5.546 5.797L.704 1.816 2.007.454Z'
      fill='#B8C3D9'
    />
  </svg>
);
