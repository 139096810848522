import { gql } from '@apollo/client';

export const createPatientMutation = gql`
  mutation CreatePatient(
    $firstName: String!
    $lastName: String!
    $birthDate: String!
    $sex: String
    $address: PatientAddressInput
    $phone: String
    $region: PatientLocationInput!
    $memberId: String
    $insurancePlan: PatientOrganizationInput
  ) {
    patient(
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      sex: $sex
      address: $address
      cellPhone: $phone
      region: $region
      memberId: $memberId
      insurancePlan: $insurancePlan
    ) {
      patientId
      createdAt
      updatedAt
      familyName
      givenName
      birthDate
      age
      address
      sex
      accountNumber {
        value
      }
    }
  }
`;

export const createPatientMutationOptionalRegion = gql`
  mutation CreatePatient(
    $firstName: String!
    $lastName: String!
    $birthDate: String!
    $sex: String
    $address: PatientAddressInput
    $phone: String
    $region: PatientLocationInput
    $memberId: String
    $insurancePlan: PatientOrganizationInput
  ) {
    patient(
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      sex: $sex
      address: $address
      cellPhone: $phone
      region: $region
      memberId: $memberId
      insurancePlan: $insurancePlan
    ) {
      patientId
      createdAt
      updatedAt
      familyName
      givenName
      birthDate
      age
      address
      sex
      accountNumber {
        value
      }
    }
  }
`;

export interface CreatePatientVariables {
  firstName: string;
  lastName: string;
  birthDate: string;
  sex?: string;
  address?: {
    line?: string[];
    city?: string;
    state?: string;
    postalCode?: string;
  };
  memberId?: string;
  insurancePlan?: {
    id?: string;
    name?: string;
  };
  phone?: string;
  region?: {
    id?: string;
    label?: string;
  };
}
