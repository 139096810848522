import { useCallback, useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { usePopper } from 'react-popper';
import { CalendarIcon } from '../../AkidoUI/Icon/Calendar';
import { DateTime } from 'luxon';
import './DatePickerOverride.css';
import { CaptionComponent } from './CaptionComponent';
import styled from 'styled-components';

const DateDisplayer = styled.button`
  background: rgba(0, 120, 216, 0.1);
  color: #0078d8;
  font-size: 28px;
  gap: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

// Allow the user go back to 2020 for appointments. There should be no appointments before then since the app wasn't live.
// If there are appointments before 2020, then time travel has probably been invented.
const MIN_DATE = DateTime.local(2020, 1, 1);
const MAX_DATE = DateTime.now().plus({ years: 15 });

export function DatepickerCalendarModal({
  onSelect,
  value,
  isOpen = false,
}: {
  onSelect?: (date: Date) => void;
  value: Date;
  isOpen?: boolean;
  date?: Date;
}) {
  const [selected, setSelected] = useState<Date>(value);
  const [isPopperOpen, setIsPopperOpen] = useState(isOpen);

  const popperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  useEffect(() => {
    setIsPopperOpen(isOpen);
  }, [isOpen]);

  const handleSelected = useCallback(
    (passedInDate: any) => {
      if (passedInDate) {
        setSelected(passedInDate);
        onSelect?.(passedInDate);
      }
      setIsPopperOpen(false);
    },
    [onSelect]
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
  });

  const handleButtonClick = () => {
    setIsPopperOpen((prev) => !prev);
  };

  return (
    <>
      <div ref={popperRef}>
        <DateDisplayer
          onClick={() => setIsPopperOpen((prev) => !prev)}
          role={'button'}
        >
          <CalendarIcon
            height={20}
            width={20}
            color={'#0078d8'}
            onClick={handleButtonClick}
            ref={buttonRef as any}
            aria-label='datepicker'
            style={{
              cursor: 'pointer',
            }}
          />
          <span data-public>
            {selected.toLocaleDateString('en-US', { month: 'long' })}
          </span>
          <span data-public>{selected.getFullYear()}</span>
        </DateDisplayer>
      </div>
      {isPopperOpen && (
        <div
          style={{
            ...popper.styles.popper,
            background: 'white',
            zIndex: '9',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            width: '414px',
            height: '380px',
            color: 'black',
          }}
          className='dialog-sheet'
          {...popper.attributes.popper}
          ref={setPopperElement}
          role='dialog'
          aria-label='DayPicker calendar'
        >
          <DayPicker
            data-public
            initialFocus={isPopperOpen}
            mode='single'
            defaultMonth={selected}
            className='root'
            selected={selected}
            captionLayout='dropdown'
            fromYear={MIN_DATE.year}
            onSelect={handleSelected}
            toYear={MAX_DATE.year}
            formatters={{
              formatWeekdayName: (date) => {
                return DateTime.fromJSDate(date).toFormat('ccccc');
              },
            }}
            components={{
              Caption: CaptionComponent,
            }}
          />
        </div>
      )}
    </>
  );
}
