import { get } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import {
  Maybe,
  TemplateSectionModel,
} from '@akido/provider-desktop-bffe-types';
import {
  hydrateStore,
  isHydrated,
  isPersisting,
  makePersistable,
} from 'mobx-persist-store';
import {
  ClinicalNoteAddendum,
  ClinicalNoteStatus,
} from '../types/clinical-notes';

import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';

export type LocalClinicalNoteSection = {
  id: string;
  title?: string;
  type?: string | null;
  category?: string;
  text?: string;
  autofillFrom?: string | null;
  sortOrder?: number;
  uiSection?: string | null;
  requestId?: string;
};

export type LocalClinicalNote = {
  id: string;
  localUpdatedAt?: number;
  updatedAt?: string;
  status?: ClinicalNoteStatus;
  canAddAddendum?: boolean;
  canSignNote?: boolean;
  encounterId?: Maybe<string> | undefined;
  signedAt?: string;
  signedByEmail?: string;
  signedByFullName?: string;
  addenda?: ClinicalNoteAddendum[];
  content: {
    title?: string;
    templateId?: string;
    sections: LocalClinicalNoteSection[];
  };
};

export class LocalClinicalNotesStore {
  userId: string = '';
  localClinicalNotes: LocalClinicalNote[] = [];
  localAddendums: Record<string, string> = {};

  constructor() {
    makeObservable(this, {
      userId: observable,
      localClinicalNotes: observable,
      addNote: action,
      localAddendums: observable,
      addAddendum: action,
      setSectionValue: action,
      setNoteTitle: action,
      reset: action,
      setTemplateId: action,
      deleteClinicalNoteById: action,
      createAddendumForNote: action,
    });
    makePersistable(
      this,
      {
        name: 'providerDes',
        properties: ['userId', 'localClinicalNotes', 'localAddendums'],
        storage: localStorage,
      },
      {
        delay: 200,
        fireImmediately: true,
      }
    );
  }

  isHydrated() {
    return isHydrated(this);
  }

  isPersisted() {
    return isPersisting(this);
  }

  async hydrateStore() {
    await hydrateStore(this);
  }

  generateNote(id: string) {
    const foundNote = this.getClinicalNoteById(id);
    if (!foundNote) {
      this.localClinicalNotes.push({
        id: id,
        content: { sections: [] },
        localUpdatedAt: new Date().getTime(),
      });
    }
  }

  addNote(id: string, newNote?: ClinicalNoteModel) {
    const foundNote = this.getClinicalNoteById(id);
    if (newNote) {
      if (foundNote) {
        this.deleteClinicalNoteById(id);
      }
      this.localClinicalNotes.push({
        ...newNote,
        status: newNote.status as ClinicalNoteStatus,
        signedAt: newNote.signedAt as string,
        signedByEmail: newNote.signedByEmail as string,
        signedByFullName: newNote.signedByFullName as string,
        content: {
          ...newNote.content,
          templateId: newNote.content.templateId as string,
          sections: newNote.content.sections as LocalClinicalNoteSection[],
        },
        id: id,
      });
    } else {
      if (foundNote) {
        this.localClinicalNotes.push({
          ...foundNote,
          id: id,
          localUpdatedAt: new Date().getTime(),
        });
      } else {
        this.localClinicalNotes.push({
          id: id,
          content: { sections: [] },
          localUpdatedAt: new Date().getTime(),
        });
      }
    }
  }

  getClinicalNoteById(id: string) {
    const foundNote = this.localClinicalNotes.find((note) => {
      return note.id === id;
    });
    if (foundNote) {
      return foundNote;
    }
    return null;
  }

  getSectionByIds(noteId: string, sectionId: string) {
    const note = this.getClinicalNoteById(noteId);
    const sections = get(note, 'content.sections');
    if (sections) {
      const foundSection = note?.content.sections.find((section) => {
        return section.id === sectionId;
      });
      if (foundSection) {
        return foundSection;
      }
    }
    return null;
  }

  createAddendumForNote(noteId: string) {
    if (!this.localAddendums[noteId]) {
      this.localAddendums[noteId] = '';
    }
  }

  addAddendum(noteId: string, text: string) {
    this.localAddendums[noteId] = text;
  }

  deleteAddendum(noteId: string) {
    delete this.localAddendums[noteId];
  }

  getAddendumByNoteId(noteId: string) {
    return this.localAddendums[noteId];
  }

  deleteClinicalNoteById(id: string) {
    const newArray = this.localClinicalNotes.filter((note) => {
      return note.id !== id;
    });
    this.localClinicalNotes = newArray;
  }

  setSectionValue(
    noteId: string,
    sectionId: string,
    value: string,
    sortOrder?: number,
    type?: string | null,
    uiSection?: string | null,
    requestId?: string | null
  ) {
    const newArray = this.localClinicalNotes.map((note) => {
      if (note.id === noteId) {
        const newNote = { ...note, localUpdatedAt: new Date().getTime() };
        const newSections = note.content.sections || [];
        const sectionIndex = newSections.findIndex(
          (section) => section.id === sectionId
        );
        if (sectionIndex < 0) {
          newSections.push({
            id: sectionId,
            text: value,
            sortOrder: sortOrder,
            type: type,
            uiSection: uiSection,
            requestId: requestId ?? undefined,
          });
        }
        newSections[sectionIndex] = {
          ...newSections[sectionIndex],
          text: value,
          sortOrder: sortOrder,
          type: type,
          uiSection: uiSection,
          requestId: requestId ?? undefined,
        };
        newNote.content.sections = newSections;
        return newNote;
      }
      return note;
    });
    this.localClinicalNotes = newArray;
  }

  setDefaultSectionValues(
    noteId: string,
    sectionValues: TemplateSectionModel[]
  ) {
    sectionValues.forEach((section) => {
      if (!this.getSectionByIds(noteId, section.id)) {
        this.setSectionValue(
          noteId,
          section.id,
          section.defaultText || '',
          section.sortOrder,
          section.type
        );
      }
    });
  }

  setNoteTitle(noteId: string, newTitle: string) {
    const newArray = this.localClinicalNotes.map((note) => {
      if (note.id === noteId) {
        note.content.title = newTitle;
        note.localUpdatedAt = new Date().getTime();
      }
      return note;
    });
    this.localClinicalNotes = newArray;
  }

  setTemplateId(noteId: string, newTemplateId: string) {
    const newArray = this.localClinicalNotes.map((note) => {
      if (note.id === noteId) {
        note.content.templateId = newTemplateId;
        note.localUpdatedAt = new Date().getTime();
      }
      return note;
    });
    this.localClinicalNotes = newArray;
  }

  reset() {
    this.userId = '';
    this.localClinicalNotes = [];
    this.localAddendums = {};
  }
}

export const localClinicalNotesStore = new LocalClinicalNotesStore();
