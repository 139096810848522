import { useQuery } from '@apollo/client';
import {
  GetAuthorizationsResponse,
  getAuthorizations,
} from '../services/graphql/getAuthorizations';
import authStore from '../stores/auth.store';
import { useEffect } from 'react';

export const useUserAuthorization = () => {
  const { data } = useQuery<GetAuthorizationsResponse>(getAuthorizations, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    authStore?.setAuthorizations(data);
  }, [data]);
};
