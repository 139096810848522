import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { patientOrdersDisplayLabels } from '../../../services/graphql/patientOrders';
import { OrdersGraphQLDisplayLabelReturn } from '../../../types/orders';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { AkidoIdentifierArg, Maybe } from '@akido/provider-desktop-bffe-types';

export interface PatientOrdersVariables {
  externalId?: string;
  resourceIdentifier?: AkidoIdentifierArg;
}

export const usePatientOrders = (
  externalId?: Maybe<string> | undefined,
  resourceIdentifier?: AkidoIdentifierArg
) => {
  const [retrieveEncounterOrders, { loading: isLoading }] = useLazyQuery<
    OrdersGraphQLDisplayLabelReturn,
    PatientOrdersVariables
  >(patientOrdersDisplayLabels, {
    fetchPolicy: 'network-only',
  });

  const fetchOrders = useCallback(async () => {
    if (!externalId && !resourceIdentifier) {
      return;
    }

    const variables = externalId
      ? { externalId: externalId }
      : { encounterIdentifier: resourceIdentifier };

    const { data, error } = await retrieveEncounterOrders({
      variables,
    });

    if (error !== undefined) {
      clinicalNoteRefreshableAutofillStore.setOrdersDidError();
    }

    if (data?.getFhirEncounter?.orders) {
      clinicalNoteRefreshableAutofillStore.setOrders(
        data?.getFhirEncounter?.orders.list
      );
    }
  }, [externalId, retrieveEncounterOrders, resourceIdentifier]);

  return {
    isLoading,
    fetchOrders,
  };
};
