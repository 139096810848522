import { Button } from '../../../components/AkidoUI/Button';
import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as NotepadPen } from '../../../assets/icons/notepad-pen.svg';
import { TestID } from '../../../constants/testIds';
import useNUSAProcessingWatcher from '../../../hooks/useNUSAProcessingWatcher';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const AddAddendumContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 28px;
  width: 33%;
`;

const Title = styled.span`
  font-size: 0.775rem;
  font-weight: 600;
  margin-left: 12px;
  flex: 2;
`;

interface AddAddendumProps {
  onAddAddendum?: () => Promise<void> | void;
}

export const AddAddendum: FC<AddAddendumProps> = (props) => {
  useNUSAProcessingWatcher();
  const { onAddAddendum } = props;
  return (
    <Container data-testid={TestID.Addendum.AddAddendumContainer}>
      <AddAddendumContainer>
        <NotepadPen style={{ paddingTop: '.19rem' }} />
        <Title>Edit by adding an addendum</Title>
        <Button
          testId={TestID.Addendum.AddAddendumButton}
          onClick={() => onAddAddendum?.()}
          label='Add Addendum'
          styles={{
            backgroundColor: '#0078D8',
            textTransform: 'none',
            borderColor: '#0078D8',
            width: '100%',
            display: 'flex',
            flex: 1,
          }}
          labelStyles={{
            textTransform: 'none',
            fontWeight: 'bold',
            fontSize: '0.775rem',
          }}
        />
      </AddAddendumContainer>
    </Container>
  );
};
