import { get, isFinite } from 'lodash';
import { ApolloError } from '@apollo/client';
import { LocalClinicalNoteSection } from '../stores/localPersistentClinicalNotes.store';
import { ClinicalNoteTemplateDropdownInterface } from '../types/clinicalNoteTemplateTypes';
import {
  AkidoIdentifier,
  ClinicalNoteContentSection,
  TemplateModel,
} from '@akido/provider-desktop-bffe-types';
import {
  ClinicalNoteTypes,
  EncounterTypes,
} from '../services/graphql/commonTypes';

const generateId = (length = 6) => {
  if (length > 6)
    throw new Error('generateId only supports a maximum length of 6');

  const value = `0x1${'0'.repeat(length)}`;
  return Math.floor((1 + Math.random()) * parseInt(value, 16))
    .toString(16)
    .substring(1);
};

export const randomId = (length = 6) => {
  const remainder = length % 6;
  const setsOfSix = (length - remainder) / 6;
  let id = '';
  for (let i = 0; i < setsOfSix; i++) {
    id += generateId();
  }

  id += generateId(remainder);

  return id;
};

export const setOnlyDate = (existing: Date | undefined, newDate: Date) => {
  if (!existing) {
    return newDate;
  }

  const prevTime = existing.toISOString().split('T')[1];
  const date = newDate.toISOString().split('T')[0];
  const dateString = `${date}T${prevTime}`;

  return new Date(dateString);
};

export const setOnlyTime = (existing: Date | undefined, newDate: Date) => {
  if (!existing) {
    return newDate;
  }

  const prevDate = existing.toISOString().split('T')[0];
  const time = newDate.toISOString().split('T')[1];
  const dateString = `${prevDate}T${time}`;

  return new Date(dateString);
};

export const yesterday = (date: Date) => {
  const yesterdayDate = new Date(date);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  return yesterdayDate;
};

export const truncateUUID = (uuid?: string): string => {
  return uuid?.split('').splice(0, 8).join('') ?? '';
};

export const getErrorCodeFromGraphqlCall = (error: ApolloError): string => {
  return (
    get(error, 'networkError.statusCode', '') ||
    get(error, 'graphQLErrors[0].extensions.code', '')
  );
};

export const createDropdownInterfacesFromClinicalNoteTemplates = (
  clinicalNoteTemplates: TemplateModel[]
): ClinicalNoteTemplateDropdownInterface[] => {
  const templateDropdownItems: ClinicalNoteTemplateDropdownInterface[] = [];

  for (const template of clinicalNoteTemplates) {
    if (template.id && template.name) {
      const item: ClinicalNoteTemplateDropdownInterface = {
        value: template.id,
        label: template.name,
        textColor: '#083B60',
      };
      templateDropdownItems.push(item);
    }
  }
  return templateDropdownItems;
};

export const mergeClinicalNoteSectionsArray = (
  locallySavedArray: any[],
  originalArray: any[]
) => {
  const res = originalArray.map((originalElement) => {
    const foundElement = locallySavedArray.find((localElement) => {
      return originalElement.id === localElement.id;
    });
    if (foundElement) {
      const newElement = { ...originalElement };
      newElement.text = foundElement.text;
      return newElement;
    }
    return originalElement;
  });

  return res;
};

export const addAutofillFromClinicalNoteValues = (
  sections: LocalClinicalNoteSection[],
  template: TemplateModel
): ClinicalNoteContentSection[] => {
  if (sections.length === 0) {
    return template.sections.map((section) => ({
      ...section,
      __typename: 'ClinicalNoteContentSection',
      category: section.category ?? '',
      type: section.type ?? '',
      text: '',
    }));
  }
  return sections.map((originalSection) => {
    const returnSection = {
      ...originalSection,
      title: originalSection.title ?? '',
      category: originalSection.category ?? '',
      text: originalSection.text ?? '',
      type: originalSection.type ?? '',
    };

    const foundSection = template.sections.find((templateSections) => {
      return originalSection.id === templateSections.id;
    });
    if (foundSection) {
      returnSection.autofillFrom = foundSection.autofillFrom;
    }

    return returnSection;
  });
};

export const isStringWithLength = (value: any): boolean => {
  return typeof value === 'string' && value.length > 0;
};

export const isArrayWithLength = (value: any): boolean => {
  return Array.isArray(value) && value.length > 0;
};

export const isValueGreaterThanOrEqualToZero = (value: any): boolean => {
  return isFinite(value) && value >= 0;
};

interface IGetIdentifierFromURLArgs {
  akidoNoteId?: string | null;
  externalEncounterId?: string | null;
  resourceIdType?: string | null;
  resourceId?: string | null;
}

export const getIdentifierFromURL = ({
  akidoNoteId,
  externalEncounterId,
  resourceId,
  resourceIdType,
}: IGetIdentifierFromURLArgs): AkidoIdentifier => {
  let identifier: AkidoIdentifier = {
    type: '',
    value: '',
  };
  if (akidoNoteId) {
    identifier = {
      type: ClinicalNoteTypes.AKIDO_CLINICAL_NOTE_ID,
      value: akidoNoteId,
    };
  } else if (externalEncounterId) {
    identifier = {
      type: EncounterTypes.CAPELLA_ENCOUNTER_ID,
      value: externalEncounterId,
    };
  } else if (resourceId && resourceIdType) {
    identifier = {
      type: resourceIdType,
      value: resourceId,
    };
  }

  return identifier;
};

export const resourceIdTypeToResourceType = (resourceIdType: string) => {
  if (
    resourceIdType.toLowerCase() === 'capella_encounter_id' ||
    resourceIdType.toLowerCase() === 'fhir_encounter_id'
  ) {
    return 'clinical-notes';
  } else if (resourceIdType.toLowerCase() === 'capella_lab_order_id') {
    return 'interpretation-reports';
  } else {
    throw new Error(`Wrong "resourceIdType": ${resourceIdType}`);
  }
};
