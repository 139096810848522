import { useToast } from './ToastProvider';
import { Toast } from './Toast';

export function ToastContainer() {
  const { toasts } = useToast();

  return (
    <div
      aria-live='assertive'
      className='pointer-events-none fixed inset-0 flex flex-col-reverse items-end px-4 py-6 sm:items-start sm:p-6'
    >
      <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
        {[...toasts.values()].map((toastData) => (
          <Toast key={toastData.id} {...toastData} />
        ))}
      </div>
    </div>
  );
}
