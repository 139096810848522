import { makeAutoObservable } from 'mobx';
import {
  AINoteSectionTypes,
  CategoryVariant,
  ClinicalNoteAddendum,
  ClinicalNoteFieldValue,
} from '../types/clinical-notes';
import { get, isEmpty, isNil } from 'lodash';
import { localClinicalNotesStore } from './localPersistentClinicalNotes.store';
import {
  clinicalNoteRefreshableAutofillStore,
  SectionIdsWithReadonlyAutofill,
} from './clinicalNoteRefreshableAutofill.store';
import {
  ClinicalNoteContentSection,
  ClinicalNoteModel,
  FhirPractitionerModel,
  TemplateModel,
  TemplateSectionModel,
} from '@akido/provider-desktop-bffe-types';

export class ClinicalNoteStore {
  id: string;
  sectionValues: Record<string, ClinicalNoteFieldValue> = {};
  autofillValues: Record<string, string | undefined> = {};
  editableNoteTitle: string;
  clinicalNote?: ClinicalNoteModel;
  externalId?: string | null;
  referringPractitioner?: FhirPractitionerModel | null;
  dateOfService?: Date | null;
  statusMessage: string;
  statusMessageState: string;
  templateId: string;
  savingExternally: boolean;
  refreshing: boolean;
  isLoaded: boolean;
  previousEncounterFhirId: string | null;
  previousAkidoClinicalNoteId: string | null;
  templateName: string | null;
  currentRequestId: string | null;
  aiSummarySectionId: string | null;
  hasNewVersion?: boolean;
  aiSummaryEdited: boolean;
  newVersionInfo?: {
    version: number;
    id: string;
  } | null;
  newVersionModalOpen: boolean;
  isSaving: boolean = false;
  autosaveEnabled: boolean = true;

  constructor() {
    makeAutoObservable(this);

    this.id = '';
    this.editableNoteTitle = '';
    this.statusMessage = '';
    this.statusMessageState = 'hidden';
    this.templateId = '';
    this.externalId = '';
    this.referringPractitioner = null;
    this.savingExternally = false;
    this.refreshing = false;
    this.isLoaded = false;
    this.previousEncounterFhirId = null;
    this.previousAkidoClinicalNoteId = null;
    this.templateName = '';
    this.currentRequestId = null;
    this.aiSummarySectionId = null;
    this.hasNewVersion = false;
    this.aiSummaryEdited = false;
    this.newVersionInfo = null;
    this.newVersionModalOpen = false;
  }

  setTemplateName(name: string) {
    this.templateName = name;
  }

  setModalOpen(open: boolean) {
    if (
      this.newVersionInfo &&
      this.clinicalNote &&
      this.newVersionInfo.version > this.clinicalNote?.version
    ) {
      this.newVersionModalOpen = open;
    }
  }

  hideModal() {
    this.newVersionModalOpen = false;
  }

  setId(id: string) {
    this.id = id;
  }

  addFields(fields: Record<string, ClinicalNoteFieldValue>) {
    this.sectionValues = { ...fields, ...this.sectionValues };
  }

  overrideClinicaNoteVersion() {
    if (this.clinicalNote && this.newVersionInfo?.version) {
      this.clinicalNote = {
        ...this.clinicalNote,
        version: this.newVersionInfo.version,
      };
    }
  }

  setNewVersionInfo(newVersionInfo: { version: number; id: string } | null) {
    this.newVersionInfo = newVersionInfo;
  }

  setSectionValue(
    id: string,
    value: string,
    sortOrder?: number,
    type?: string | null,
    uiSection?: string | null,
    requestId?: string | null
  ) {
    if (this.sectionValues[id]) {
      this.sectionValues[id].text = value;
      if (sortOrder) {
        this.sectionValues[id].sortOrder = sortOrder;
      }
      if (type) {
        this.sectionValues[id].type = type;
      }
      if (uiSection) {
        this.sectionValues[id].uiSection = uiSection;
      }
      if (requestId) {
        this.sectionValues[id].requestId = requestId;
      }
      if (
        uiSection?.toLowerCase() === 'analysis' &&
        type === AINoteSectionTypes.AISummary
      ) {
        if (!this.aiSummaryEdited) {
          this.aiSummaryEdited = true;
        }
      }
      if (this.id) {
        localClinicalNotesStore.setSectionValue(
          this.id,
          id,
          value,
          sortOrder,
          type,
          uiSection,
          requestId
        );
      }
    }
  }

  setEditableNoteTitle(newTitle: string) {
    this.editableNoteTitle = newTitle;
    if (this.id) {
      localClinicalNotesStore.setNoteTitle(this.id, newTitle);
    }
  }

  setDateOfService(encounterDate: string) {
    this.dateOfService = new Date(encounterDate);
  }

  setAutofillTextValues(autofillValues: Record<string, string | undefined>) {
    this.autofillValues = autofillValues;
  }

  setSectionsFromClinicalNoteReturn(
    sections: ClinicalNoteContentSection[] = []
  ) {
    const newFields: Record<string, ClinicalNoteFieldValue> = {};
    sections.forEach((section: ClinicalNoteContentSection) => {
      const sectionId = get(section, 'id', '');
      const text = JSON.parse(
        JSON.stringify(section?.text?.replace(/\n/g, '\n'))
      );
      if (sectionId) {
        if (this.sectionValues[sectionId]) {
          newFields[sectionId] = {
            ...section,
            text,
            autofillFrom: this.sectionValues[sectionId].autofillFrom,
            category: section.category as CategoryVariant,
            sortOrder: section.sortOrder ?? 0,
            uiSection: section.uiSection ?? '',
            requestId: section.requestId ?? '',
          };
        } else {
          newFields[sectionId] = {
            ...section,
            category: section.category as CategoryVariant,
            sortOrder: section.sortOrder ?? 0,
            uiSection: section.uiSection ?? '',
            requestId: section.requestId ?? '',
          };
        }
      }
    });
    this.sectionValues = newFields;
  }

  setSectionsFromClinicalNote() {
    if (this.clinicalNote) {
      this.setSectionsFromClinicalNoteReturn(
        this.clinicalNote.content.sections
      );
    }
  }

  setFieldsAndDefaultValues(
    noteId: string,
    templateSections: TemplateSectionModel[]
  ) {
    const fields: Record<string, ClinicalNoteFieldValue> = {};

    templateSections.forEach((section) => {
      const autofillData = this.getAutofillData(section.uiSection ?? undefined);

      const newField: ClinicalNoteFieldValue = {
        title: section.title,
        type: section.type,
        text: this.sectionValues[section.id]?.text || section.defaultText || '',
        category: section.category,
        id: section.id,
        sortOrder: section.sortOrder,
        autofillFrom: section.autofillFrom,
        autofillData: autofillData ?? [],
        uiSection: section.uiSection,
        requestId: null,
      };

      // Prefill the text with autofilled data
      if (
        !newField.text &&
        newField.autofillFrom &&
        this.autofillValues[newField.autofillFrom]
      ) {
        newField.text = this.autofillValues[newField.autofillFrom] || '';
      }

      if (newField.type === AINoteSectionTypes.AISummary) {
        newField.text = (newField.text ?? '').replace(/\\n/g, '\n');
      }

      newField.text = (newField.text ?? '').replace(/\\n/g, '\n');

      fields[section.id] = newField;
    });

    this.sectionValues = fields;

    localClinicalNotesStore.generateNote(noteId);
    localClinicalNotesStore.setDefaultSectionValues(noteId, templateSections);

    this.isLoaded = true;
  }

  setFieldsAndDefaultValuesFromPreviousClinicalNote(
    previousNote: ClinicalNoteModel,
    template: TemplateModel
  ) {
    const newFields: Record<string, ClinicalNoteFieldValue> = {};
    if (Array.isArray(previousNote?.content?.sections)) {
      for (const previousSection of previousNote.content.sections) {
        const foundTemplateSection = template?.sections?.find(
          (templateSection) => {
            return (
              templateSection.uiSection?.toLowerCase() ===
              previousSection.uiSection?.toLowerCase()
            );
          }
        );

        if (foundTemplateSection) {
          let textToFill = '';

          const canContentBeCopied = foundTemplateSection.canBeCopied;

          const shouldInsertPreviousNoteText =
            canContentBeCopied &&
            previousSection.text &&
            previousSection.text?.length > 0;

          if (shouldInsertPreviousNoteText) {
            textToFill = previousSection.text;
          }

          const shouldInsertPreviousNoteAutofill =
            canContentBeCopied &&
            previousSection.autofillData &&
            previousSection.autofillData?.length > 0;

          if (
            previousSection.autofillData &&
            shouldInsertPreviousNoteAutofill
          ) {
            textToFill =
              previousSection.autofillData.join('\n') + '\n' + textToFill;
          }

          if (textToFill?.length === 0) {
            textToFill = foundTemplateSection.defaultText || '';
          }

          const currentAutofillData =
            !canContentBeCopied &&
            foundTemplateSection.autofillFrom &&
            this.autofillValues?.[foundTemplateSection?.autofillFrom];

          if (currentAutofillData) {
            textToFill = currentAutofillData || '';
          }

          newFields[previousSection.id] = {
            title: previousSection.title,
            type: previousSection.type,
            text: textToFill,
            category: previousSection.category as CategoryVariant,
            id: previousSection.id,
            sortOrder: previousSection.sortOrder ?? 0,
            autofillData: [],
            autofillFrom: foundTemplateSection.autofillFrom,
            uiSection: previousSection.uiSection ?? '',
            requestId: previousSection.requestId ?? '',
          };
        }
      }
    }

    this.sectionValues = newFields;
  }

  get areSectionValuesEmpty() {
    let isEmpty = true;
    for (const sectionId in this.sectionValues) {
      const section = this.sectionValues[sectionId];
      if (typeof section.text === 'string' && section.text?.length > 0) {
        isEmpty = false;
        break;
      }
    }

    return isEmpty;
  }

  getAutofillData(uiSection: string | undefined | null): string[] {
    if (uiSection) {
      const autofilledSection =
        clinicalNoteRefreshableAutofillStore[
          uiSection.toLowerCase() as SectionIdsWithReadonlyAutofill
        ];

      if (autofilledSection) {
        return autofilledSection.items;
      }
    }

    return [];
  }

  setClinicalNote(value: ClinicalNoteModel | undefined) {
    if (!value) {
      return;
    }
    this.clinicalNote = value;
    if (value.content.templateId) {
      this.templateId = value.content.templateId;
    }
    if (this.areSectionValuesEmpty) {
      this.setSectionsFromClinicalNote();
      this.externalId = '';
    }
  }

  setClinicalNoteId(value: string) {
    if (this.clinicalNote) {
      this.clinicalNote.id = value;
    }
  }

  reset() {
    this.sectionValues = {};
    this.editableNoteTitle = '';
    this.clinicalNote = undefined;
    this.savingExternally = false;
    this.templateId = '';
    this.externalId = '';
    this.isLoaded = false;
    this.referringPractitioner = null;
    this.previousAkidoClinicalNoteId = null;
    this.previousEncounterFhirId = null;
    this.currentRequestId = null;
    this.aiSummaryEdited = false;
  }

  addAddendum(addendum: ClinicalNoteAddendum) {
    const addenda = this.clinicalNote?.addenda;
    if (Array.isArray(addenda)) {
      const addendumIndex = addenda.findIndex((entry) => {
        return entry.id === addendum.id;
      });
      if (addendumIndex < 0) {
        addenda.push(addendum);
      } else {
        addenda.splice(addendumIndex, 1, addendum);
      }
    } else if (this.clinicalNote) {
      this.clinicalNote.addenda = [addendum];
    }
  }

  setStatusMessage(value: string) {
    this.statusMessage = value;
  }

  setStatusMessageState(value: string) {
    this.statusMessageState = value;
  }

  get isStatusMessageVisible() {
    return this.statusMessageState === 'visible';
  }

  showNewStatusMessage(message: string) {
    this.setStatusMessage(message);
    this.setStatusMessageState('visible');
  }

  hideStatusMessage() {
    this.setStatusMessageState('hidden');
  }

  setTemplateId(newTemplateId: string, replaceLocal: boolean = true) {
    this.templateId = newTemplateId;
    if (this.id && replaceLocal) {
      localClinicalNotesStore.setTemplateId(this.id, newTemplateId);
    }
  }

  setReferringPractitioner(referringPractitioner: FhirPractitionerModel) {
    this.referringPractitioner = referringPractitioner;
  }

  setAutofillData(sectionId: string, autofillData: string[] = []) {
    if (this.sectionValues[sectionId]) {
      this.sectionValues[sectionId].autofillData = autofillData;
    } else {
      console.error(`Field "${sectionId}" not found!`);
    }
  }

  loadLocalClinicalNote(noteId: string) {
    const localClinicalNote =
      localClinicalNotesStore.getClinicalNoteById(noteId);
    if (localClinicalNote) {
      this.id = localClinicalNote.id;
      if (localClinicalNote.content.templateId) {
        this.templateId = get(localClinicalNote, 'content.templateId');
      }
      if (localClinicalNote.content.title) {
        this.editableNoteTitle = get(localClinicalNote, 'content.title');
      }
      this.setSectionsFromClinicalNoteReturn(
        localClinicalNote.content.sections as ClinicalNoteContentSection[]
      );
    }
  }

  setExternalId(newExternalId: string | null) {
    this.externalId = newExternalId;
  }

  setSavingExternally(newSavingExternally: boolean) {
    this.savingExternally = newSavingExternally;
  }

  setRefreshing(newRefreshing: boolean) {
    this.refreshing = newRefreshing;
  }

  setPreviousEncounterFhirId(encounterFhirId: string) {
    this.previousEncounterFhirId = encounterFhirId;
  }

  setPreviousAkidoClinicalNoteId(akidoClinicalNoteId: string) {
    this.previousAkidoClinicalNoteId = akidoClinicalNoteId;
  }

  getClinicalNoteUpdateContent(
    sectionValues: (typeof clinicalNoteStore)['sectionValues']
  ) {
    const sections: ClinicalNoteFieldValue[] = [];
    const keys = Object.keys(sectionValues);
    keys.forEach((key) => {
      const autofillData = clinicalNoteStore.getAutofillData(
        sectionValues[key]?.uiSection
      );

      sections.push({
        id: key,
        category: sectionValues[key].category,
        type: sectionValues[key].type,
        title: sectionValues[key].title,
        text: sectionValues[key].text,
        autofillData,
        sortOrder: sectionValues[key].sortOrder,
        uiSection: sectionValues[key].uiSection,
        requestId: sectionValues[key].requestId,
      });
    });

    return sections;
  }

  setCurrentRequestId(requestId: string) {
    if (this.currentRequestId !== requestId) {
      this.currentRequestId = requestId;
    }
  }

  setAISummaryContent(text?: string) {
    if (isNil(text) || isEmpty(text)) {
      return;
    }

    const aiSummarySection = this.sectionValues[this.aiSummarySectionId ?? ''];
    if (aiSummarySection) {
      aiSummarySection.text += text;
    }
  }

  clearAISummaryContent() {
    const aiSummarySection = Object.entries(this.sectionValues).find(
      ([key, value]) => {
        return value.type === AINoteSectionTypes.AISummary;
      }
    );

    if (aiSummarySection) {
      this.sectionValues[aiSummarySection[0]].text = '';
    }
  }

  setAISummarySectionId() {
    const summarySectionId = Object.keys(this.sectionValues).find(
      (sectionId) =>
        this.sectionValues[sectionId]?.type === AINoteSectionTypes.AISummary
    );

    if (summarySectionId) {
      this.aiSummarySectionId = summarySectionId;
    }
  }

  setHasNewVersion() {
    this.hasNewVersion = true;
    this.setModalOpen(true);
  }

  setNewVersion(newVersion: number) {
    if (this.clinicalNote) {
      this.clinicalNote = {
        ...this.clinicalNote,
        version: newVersion,
      };
    }
  }

  setAISummaryEdited(edited: boolean) {
    this.aiSummaryEdited = edited;
  }

  resetVersionTracker() {
    this.hasNewVersion = false;
    this.newVersionInfo = null;
  }

  setAutosaveEnabled(value: boolean) {
    this.autosaveEnabled = value;
  }
}

export const clinicalNoteStore = new ClinicalNoteStore();
