import { DefaultTheme, Theme as ThemeType } from './components/AkidoUI/Themes';

export const Theme: ThemeType = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    typography: {
      ...DefaultTheme?.colors?.typography,
      secondary: '#fff',
    },
    action: {
      ...DefaultTheme?.colors?.action,
    },
    background: {
      solid: {
        ...DefaultTheme.colors?.background?.solid,
        primary: '#FAFBFC',
      },
    },
  },
};

export const CardTheme: ThemeType = {
  ...DefaultTheme,
  effects: {
    ...DefaultTheme.effects,
    dropShadow: {
      default: 'none',
    },
  },
  colors: {
    ...DefaultTheme.colors,
    elements: {
      ...DefaultTheme.colors?.elements,
      divider: 'transparent',
    },
    background: {
      solid: {
        ...DefaultTheme.colors?.background?.solid,
        primary: '#FFFFFF',
      },
    },
  },
};

export const LoadingTheme: ThemeType = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: {
      solid: {
        ...DefaultTheme.colors?.background?.solid,
        primary: '#2BA564',
        dark: '#FFFFFF',
      },
    },
  },
};
