import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { ClinicalNoteStatus } from '../../../types/clinical-notes';
import { formatSignedDate } from '../../../utilities/formatting';

const SignedNoteContainer = styled.div({
  flex: 1,
  padding: '20px 0 60px 40px',
});
const SignedNoteText = styled.label({
  paddingRight: '33px',
  fontSize: '0.775rem',
  color: '#405580',
  fontStyle: 'italic',
});

export const SignedNoteLabel = () => {
  const clinicalNote = clinicalNoteStore.clinicalNote;

  if (
    clinicalNote?.status === ClinicalNoteStatus.Signed &&
    clinicalNote?.signedAt
  ) {
    return (
      <SignedNoteContainer>
        <SignedNoteText
          data-testid={TestID.ClinicalNote.PreviewHeaderReportSigned}
        >{`Electronically signed by ${clinicalNote?.signedByFullName} ${formatSignedDate(
          clinicalNote?.signedAt
        )}`}</SignedNoteText>
      </SignedNoteContainer>
    );
  }

  return null;
};
