import styled from 'styled-components';
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';

const SectionContainer = styled.div`
  width: 90%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 3px;
`;

const TitleText = styled.div`
  flex-direction: column;
  align-items: center;
  color: #333333;
`;

const FieldValueText = styled.div`
  width: 100%;
  font-size: 0.775rem;
  color: #405580;
  white-space: pre-wrap;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #f2f2f2;
  margin-bottom: 8px;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-bottom: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const ClinicalNoteFieldTitleTextStyle = {
  width: '100%',
  fontWeight: '700',
  fontSize: '0.775rem',
  lineHeight: '20px',
  marginBottom: '3px',
  color: '#293D66',
  borderBottom: 'none',
};

export const ClinicalNotePreviewFieldTitleLeftStyles = {
  borderBottom: '2px solid #F2F2F2',
  marginBottom: 0,
  color: '#333333',
};

const AutoFillListContainer = styled.ul`
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: column;
  font-size: 0.6rem;
  padding: 0;
  paddin-left: 0;
  list-style: none;
`;

const AutoFillListElement = styled.li`
  width: 100%;
  font-size: 0.775rem;
  color: #405580;
  white-space: pre-wrap;
`;

export const ClinicalNotePreviewField = ({
  title,
  fieldValue = '',
  fieldAutofillValue = [],
  titleStyle,
  titleTextStyle,
  shouldShowDivider = true,
}: {
  title: string;
  fieldValue?: string;
  fieldAutofillValue?: string[] | null;
  titleStyle?: any;
  titleTextStyle?: any;
  shouldShowDivider?: boolean;
}) => {
  const renderAutoFillLines = (textList: string[] | null) => {
    if (!textList) return null;

    return textList.map((field, i) => (
      <AutoFillListElement key={`autofill_${title}_${i}`}>
        {field?.trim()}
      </AutoFillListElement>
    ));
  };

  return (
    <SectionContainer className='fieldcontainer'>
      <TitleContainer style={titleStyle}>
        <TitleText style={titleTextStyle}>{title}</TitleText>
      </TitleContainer>
      <ConditionalRender shouldRender={shouldShowDivider}>
        <Divider />
      </ConditionalRender>
      <ContentContainer>
        <AutoFillListContainer data-testid={`autofill-list-container-${title}`}>
          {renderAutoFillLines(fieldAutofillValue)}
        </AutoFillListContainer>
        <FieldValueText>{fieldValue}</FieldValueText>
      </ContentContainer>
    </SectionContainer>
  );
};
