import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { CSSProperties } from 'styled-components';
import { ReactComponent as TimeIcon } from '../../assets/icons/time.svg';
import './date-picker.css';

const Container = styled.div`
  position: relative;
`;

interface TimePickerStyles {
  container?: CSSProperties;
}

interface TimePickerProps {
  onChange: (date: Date) => void;
  selectedTime?: Date;
  styles?: TimePickerStyles;
  timeInterval?: number;
  timeFormat?: string;
}

export const TimePicker = (props: TimePickerProps) => {
  const { styles, timeInterval, timeFormat, onChange, selectedTime } = props;

  return (
    <Container style={styles?.container}>
      <ReactDatePicker
        showIcon
        icon={<TimeIcon />}
        selected={selectedTime}
        placeholderText={'7:00 am'}
        dateFormat={timeFormat ?? 'h:mm a'}
        timeCaption='Time'
        popperPlacement='bottom-start'
        showPopperArrow={false}
        timeIntervals={timeInterval ?? 5}
        showTimeSelect
        showTimeSelectOnly
        timeClassName={() => 'time'}
        className='date_input'
        onChange={onChange}
      />
    </Container>
  );
};
