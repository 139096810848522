import { useCallback } from 'react';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { UpdateClinicalNote } from '../../../services/graphql/updateClinicalNote';
import { ClinicalNoteUpdateDtoType } from '../../../types/clinical-notes';
import { localClinicalNotesStore } from '../../../stores/localPersistentClinicalNotes.store';
import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';

interface IProps {
  clinicalNoteId?: string | null;
}

export const useUpdateClinicalNote = ({ clinicalNoteId }: IProps) => {
  const clinicalNoteTitle = clinicalNoteStore.editableNoteTitle;
  const templateId = clinicalNoteStore.templateId;

  const [updateClinicalNoteMutation] = useMutation<
    ClinicalNoteModel,
    ClinicalNoteUpdateDtoType
  >(UpdateClinicalNote);

  const updateClinicalNote = useCallback(
    async (override = false) => {
      clinicalNoteStore.isSaving = true;
      try {
        if (clinicalNoteId) {
          const updateContentSections =
            clinicalNoteStore.getClinicalNoteUpdateContent(
              clinicalNoteStore.sectionValues
            );

          const version =
            clinicalNoteStore.newVersionInfo?.version ??
            clinicalNoteStore.clinicalNote?.version;
          const createdNote = await updateClinicalNoteMutation({
            variables: {
              id: clinicalNoteId,
              content: {
                title: clinicalNoteTitle,
                templateId,
                sections: updateContentSections,
                version,
              },
              override,
            },
          });
          const noteId = get(createdNote, 'data.updateClinicalNote.id', '');
          const noteVersion = get(
            createdNote,
            'data.updateClinicalNote.version',
            version
          );

          if (noteVersion) {
            clinicalNoteStore.setNewVersion(noteVersion);
          }

          if (noteId) {
            localClinicalNotesStore.deleteClinicalNoteById(clinicalNoteId);
            return noteId;
          }
        }
        return null;
      } catch (e) {
        console.error(JSON.stringify(e));
        return null;
      } finally {
        clinicalNoteStore.isSaving = false;
      }
    },
    [clinicalNoteId, updateClinicalNoteMutation, clinicalNoteTitle, templateId]
  );

  return { updateClinicalNote };
};
