import styled from 'styled-components';

const StatusFilterContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const StatusFilterButton = styled.button<{ status: 'submitted' | 'drafts' }>`
  background-color: ${({ status }) =>
    status === 'submitted' ? 'rgba(46, 230, 202, 0.10)' : '#ECF0F1'};
  color: ${({ status }) => (status === 'submitted' ? '#3BAF9E' : '#5F7584')};
  border: 0;
  border-radius: 10px;
  padding: 2px 10px;
  cursor: pointer;
`;

export function StatusFilter() {
  return (
    <StatusFilterContainer>
      <StatusFilterButton data-public status={'drafts'} type='button'>
        Drafts
      </StatusFilterButton>
      <StatusFilterButton data-public status={'submitted'} type='button'>
        Submitted
      </StatusFilterButton>
    </StatusFilterContainer>
  );
}
