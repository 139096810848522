import { LDClient } from 'launchdarkly-js-client-sdk';
import { get } from 'lodash';
import { ClinicalNoteRefreshableAutofillStore } from '../stores/clinicalNoteRefreshableAutofill.store';
import { FEATURE_FLAGS } from '../config/launchdarkly';
import { PastHistoryPatientFieldParsing } from './PastHistoryPatientFieldParsing';
import { createClinicalNoteListItemsBlockFromDisplayLabels } from './formatting';
import { AllergyHelpers } from './allergyHelpers';
import { ApolloError } from '@apollo/client';
import {
  ClinicalNoteEditor,
  ClinicalNoteFieldValue,
} from '../types/clinical-notes';

export const setAutofillText = (
  error: ApolloError | undefined,
  data: ClinicalNoteEditor | undefined,
  ldClient: LDClient | undefined,
  clinicalNoteRefreshableAutofillStore: ClinicalNoteRefreshableAutofillStore
) => {
  const shouldUseNewEditor = ldClient?.variation(
    FEATURE_FLAGS.NEW_EDITOR,
    false
  );
  const encounterLevelData = shouldUseNewEditor
    ? data?.clinicalNote?.encounter
    : data?.getFhirEncounter;

  const shouldUseImmunizationsFromBffe = ldClient?.variation(
    FEATURE_FLAGS.IMMUNIZATIONS_FROM_BFFE,
    false
  );

  const graphqlMedications =
    data?.clinicalNote?.patient?.pastMedicalHistory?.list;
  const medications =
    PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
      graphqlMedications
    );

  const billing = encounterLevelData?.billing?.displayLabels;
  if (billing) {
    clinicalNoteRefreshableAutofillStore.setBilling(billing);
  } else if (error) {
    clinicalNoteRefreshableAutofillStore.setBillingDidError();
  }

  const graphqlPrescriptions = data?.clinicalNote?.patient?.prescriptions?.list;
  if (graphqlPrescriptions) {
    clinicalNoteRefreshableAutofillStore.setPrescriptions(graphqlPrescriptions);
  } else if (error) {
    clinicalNoteRefreshableAutofillStore.setPrescriptionsDidError();
  }

  const graphqlAssessments = encounterLevelData?.assessments?.list;
  if (graphqlAssessments) {
    clinicalNoteRefreshableAutofillStore.setAssessments(graphqlAssessments);
  } else if (error) {
    clinicalNoteRefreshableAutofillStore.setAssessmentsDidError();
  }

  const graphqlVitals = encounterLevelData?.vitals?.displayLabels;
  if (graphqlVitals) {
    clinicalNoteRefreshableAutofillStore.setVitals(graphqlVitals);
  } else if (error) {
    clinicalNoteRefreshableAutofillStore.setVitalsDidError();
  }

  const graphqlOrdersResult = encounterLevelData?.orders?.list;
  if (graphqlOrdersResult) {
    clinicalNoteRefreshableAutofillStore.setOrders(graphqlOrdersResult);
  } else if (error) {
    clinicalNoteRefreshableAutofillStore.setOrdersDidError();
  }

  const graphqlPastMedicalHistory =
    data?.clinicalNote?.patient?.pastMedicalHistory?.list;
  const pastMedicalHistory =
    PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
      graphqlPastMedicalHistory
    );
  const graphqlPastSurgicalHistory =
    data?.clinicalNote?.patient?.pastSurgicalHistory?.list;
  const pastSurgicalHistory =
    PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
      graphqlPastSurgicalHistory
    );
  const graphqlSocialHistory = data?.clinicalNote?.patient?.socialHistory?.list;
  const socialHistory =
    PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
      graphqlSocialHistory
    );
  const graphqlFamilyHistory = data?.clinicalNote?.patient?.familyHistory?.list;
  const familyHistory =
    PastHistoryPatientFieldParsing.parseGraphqlHistoryListToString(
      graphqlFamilyHistory
    );

  const drugAllergiesStringBlock =
    createClinicalNoteListItemsBlockFromDisplayLabels(
      get(data, 'patient.allergies.drugAllergies') ?? []
    );

  const nonDrugAllergiesStringBlock =
    createClinicalNoteListItemsBlockFromDisplayLabels(
      get(data, 'patient.allergies.nonDrugAllergies') ?? []
    );

  const allergies = AllergyHelpers.buildFieldString(
    '',
    drugAllergiesStringBlock,
    nonDrugAllergiesStringBlock
  );

  const immunizations = shouldUseImmunizationsFromBffe
    ? createClinicalNoteListItemsBlockFromDisplayLabels(
        get(data, 'patient.immunizations.list') ?? []
      )
    : '';

  return {
    pastMedicalHistory,
    pastSurgicalHistory,
    socialHistory,
    familyHistory,
    allergies,
    immunizations,
    medications,
  };
};

export const cleanUpAutofilledData = (
  autofillData: string[] | null | undefined
) => {
  if (!autofillData) return null;
  return autofillData.filter((autofillItem) => {
    const [, value] = autofillItem.split(':');
    return !['--', ''].includes(value?.trim());
  });
};

export const sectionHasData = (section: ClinicalNoteFieldValue) => {
  const autofillData = cleanUpAutofilledData(section.autofillData);
  return section.text || (autofillData && autofillData.length > 0);
};
