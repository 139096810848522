import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { getAssessments } from '../services/graphql/patientAssessments';
import { AssessmentsGraphQLReturn } from '../types/assessments';
import { clinicalNoteRefreshableAutofillStore } from '../stores/clinicalNoteRefreshableAutofill.store';
import { AkidoIdentifierArg } from '@akido/provider-desktop-bffe-types';

export interface EncounterAssessmentsVariables {
  externalId?: string;
  encounterIdentifier?: AkidoIdentifierArg;
}

export const useAssessments = (
  externalId?: string | null | undefined,
  resourceIdentifier?: AkidoIdentifierArg
) => {
  const [retrieveEncounterAssessments, { loading: isLoading }] = useLazyQuery<
    AssessmentsGraphQLReturn,
    EncounterAssessmentsVariables
  >(getAssessments, { fetchPolicy: 'network-only' });

  const refreshAssessments = useCallback(async () => {
    if (!externalId && !resourceIdentifier) {
      return;
    }

    const variables = externalId
      ? { externalId: externalId }
      : { encounterIdentifier: resourceIdentifier };

    const { data, error } = await retrieveEncounterAssessments({
      variables,
    });

    if (error !== undefined) {
      clinicalNoteRefreshableAutofillStore.setAssessmentsDidError();
    }

    const encounterAssessments =
      data?.getFhirEncounter?.assessments?.list ?? [];

    clinicalNoteRefreshableAutofillStore.setAssessments(encounterAssessments);
  }, [externalId, retrieveEncounterAssessments, resourceIdentifier]);

  return { isLoading, refreshAssessments: refreshAssessments };
};
