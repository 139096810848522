import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import styled from 'styled-components';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';

const EditButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: #1b4c73;
  font-size: 0.9rem;
  border: none;
  cursor: pointer;
`;

interface EditScribeNoteButtonProps {
  onClick?: () => void;
  isVisible?: boolean;
}

export const EditScribeNoteButton: FC<EditScribeNoteButtonProps> = (props) => {
  const { isVisible, onClick } = props;
  const ldClient = useLDClient();

  const editFlagEnabled = ldClient?.variation(FEATURE_FLAGS.EDIT_SCRIBE_NOTE);

  if (!editFlagEnabled || !isVisible) return null;

  return <EditButton onClick={onClick}>EDIT</EditButton>;
};
