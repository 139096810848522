import { useLazyQuery } from '@apollo/client';
import { searchPatients } from '../services/graphql/getPatient';
import { Query, QueryPatientsArgs } from '@akido/provider-desktop-bffe-types';

export const usePatientSearch = () => {
  const [fetchPatients, { loading: isLoading }] = useLazyQuery<
    Query,
    QueryPatientsArgs
  >(searchPatients, { fetchPolicy: 'network-only' });

  const getPatientList = async (query: string) => {
    let variables: QueryPatientsArgs = { fullName: query };

    if (!isNaN(Number(query))) {
      variables = {
        patientId: {
          type: 'capella_patient_id',
          value: query,
        },
      };
    }
    return fetchPatients({
      variables,
    });
  };

  return { getPatientList, isLoading };
};
