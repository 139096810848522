import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { TestID } from '../../constants/testIds';
import { ClinicalNoteFieldValue } from '../../types/clinical-notes';
import { ClinicalNotePreviewFieldArray } from './ClinicalNotePreviewFieldArray';

const RightContent = styled.div({
  width: '33%',
  float: 'right',
  flexDirection: 'column',
});

const RightInner = styled.div({
  paddingLeft: '26px',
});

interface IProps {
  clinicalNoteHistorySections: ClinicalNoteFieldValue[];
}

export const ClinicalNotePreviewRightColumn: React.FC<IProps> = observer(
  ({ clinicalNoteHistorySections }) => {
    return (
      <RightContent data-testid={TestID.ClinicalNote.PreviewRightColumn}>
        <RightInner>
          <ClinicalNotePreviewFieldArray
            clinicalNoteSections={clinicalNoteHistorySections}
          />
        </RightInner>
      </RightContent>
    );
  }
);
