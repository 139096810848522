import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  type?: 'text' | 'tel' | 'password' | 'email' | 'date';
  label?: string;
  placeholder?: string;
  hint?: string;
  error?: string | boolean;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  'data-testid'?: string;
  className?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      name,
      type = 'text',
      label,
      hint,
      error,
      'data-testid': testId,
      className,
      ...rest
    } = props;

    return (
      <div className={className}>
        {label && (
          <label
            htmlFor={name}
            className='mb-2 block text-sm font-semibold text-gray-700'
          >
            {label}
          </label>
        )}
        <div>
          <input
            ref={ref}
            name={name}
            type={type}
            data-testid={testId}
            className={clsx(
              'w-full overflow-hidden rounded-lg border border-gray-300 bg-white px-3 py-2.5 text-gray-900 placeholder:text-gray-500 focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-purple-200 disabled:bg-gray-50 disabled:text-gray-500',
              {
                // change border color to red on error incase input validation is handled externally
                'border-red-500 focus:border-red-500 focus:ring-red-200':
                  Boolean(error),
              }
            )}
            {...rest}
          />
        </div>
        {hint && <p className='mt-1.5 text-sm text-gray-500'>{hint}</p>}
        {error && typeof error === 'string' && (
          <p
            className='mt-1.5 text-sm text-red-500'
            data-testid={testId ? `${testId}-Error` : undefined}
          >
            {error}
          </p>
        )}
      </div>
    );
  }
);
