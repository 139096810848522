import { ApolloError } from '@apollo/client';
import { isEmpty, isUndefined, uniqBy } from 'lodash';

export const getGraphQLErrors = (error?: ApolloError) => {
  let errors = [];
  if (error && Array.isArray(error?.graphQLErrors)) {
    for (const graphQLError of error.graphQLErrors) {
      errors.push(graphQLError?.extensions?.exception?.response);
    }
  }

  return uniqBy(errors, (err) => JSON.stringify(err)).filter(
    (err) => !isEmpty(err) && !isUndefined(err)
  );
};
