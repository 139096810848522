import { useEffect, useRef } from 'react';

type UseTimeoutParams = {
  callback: () => void;
  delay: number | null;
};

export function useTimeout(params: UseTimeoutParams) {
  const { callback, delay } = params;
  // Create a ref to the callback so we can update it without re-rendering which would cause the timer to reset
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    // Don't schedule if no delay is specified
    // We have this incase consumers want to disable timeouts conditionally
    if (delay === null) return;

    // This will run the most up-to-date callback function when the timeout expires
    const id = setTimeout(() => savedCallback.current(), delay);
    return () => clearTimeout(id);
  }, [delay]);
}
