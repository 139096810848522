import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Theme } from './theme';
import { AppRouter } from './routes/AppRouter';
import client from './config/apollo';
import { ApolloProvider } from '@apollo/client';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ldClientSettings } from './config/launchdarkly';
import { useEffect } from 'react';
import { ToastProvider } from './components/AkidoUI/Toast';
import { ToastContainer } from './components/AkidoUI/Toast/ToastContainer';

function App() {
  useEffect(() => {
    // @ts-ignore
    window.appVersion = process.env.REACT_APP_VERSION ?? '';
    try {
      // @ts-ignore
      window.AKIDO_VERSION = JSON.parse(
        process.env.REACT_APP_AKIDO_VERSION ?? '{}'
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={Theme}>
        <ToastProvider>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
          <ToastContainer />
        </ToastProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default withLDProvider(ldClientSettings)(App);
