import { observer } from 'mobx-react-lite';
import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import authStore, { AuthStore } from '../../stores/auth.store';
import { REACT_APP_SESSION_TIMEOUT_MINUTES } from '../../config/environment';
import useInactivity from '../../hooks/useInactivity';
import { urlLocationStore } from '../../stores/urlLocation.store';
import { logout } from '../../utilities/auth';

interface TrackUserInactivityProps {
  children: React.ReactNode;
  authStore?: AuthStore;
}

export const TrackUserInactivity = observer(
  ({ children, authStore: authStoreProp }: TrackUserInactivityProps) => {
    let location = useLocation();
    const apolloClient = useApolloClient();
    // minutes * 60
    const COUNTDOWN_START =
      ((REACT_APP_SESSION_TIMEOUT_MINUTES || 120) as number) * 60;

    const timeLeft = useInactivity(COUNTDOWN_START);
    const authStoreToUse = authStoreProp ?? authStore;

    if (timeLeft <= 0) {
      if (!location.pathname.includes('error')) {
        urlLocationStore.setAutoLoggedOutFrom(location.pathname);
      }
      logout(authStoreToUse, apolloClient, true);
    }

    return <>{children}</>;
  }
);
