import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from 'styled-components';
import {
  OptionColors,
  StatusOptionId,
  statusOptionsById,
} from '../../../constants/transcriptionStatuses';
import { transcriptionDataStore } from '../../../stores/transcriptionData.store';

interface StatusDropdownProps {
  selectedOption?: StatusOptionId;
}

const Container = styled.div`
  border: 1px solid #d0d7e4;
  border-radius: 8px;
  color: #1b4c73;
  width: fit-content;
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-right: 12px;
`;

const Status = styled.div`
  margin-right: 10px;
  border-radius: 12px;
  color: #0c1e2c;
  padding: 0.6em;
  font-weight: 600;
  border-radius: 4px;
  font-size: 0.85rem;
  text-align: center;
`;

export const StatusDropdown: FC<StatusDropdownProps> = observer((props) => {
  const { selectedOption } = props;
  const options = transcriptionDataStore.statuses?.map((status) => ({
    label: status.label,
    value: status.id,
    backgroundColor: OptionColors[status.id],
  }));

  const hasOptions = options.length > 0;
  if (!hasOptions) {
    return null;
  }

  const hasSelectedOption = !!selectedOption;
  if (!hasSelectedOption) {
    return null;
  }

  return (
    <Container>
      <Label>Status</Label>
      <Status style={{ backgroundColor: OptionColors[selectedOption] }}>
        {statusOptionsById[selectedOption]?.label}
      </Status>
    </Container>
  );
});
