import styled from 'styled-components';
import { ReactNode } from 'react';
import authStore from '../../stores/auth.store';
import { Authenticated } from '../Authenticated/Authenticated';
import { TrackUserInactivity } from '../TrackUserInactivity/TrackUserInactivity';
import { PageHeaderV2 } from '../PageHeaders/v2/PageHeaderV2';
import { UserName } from '../UserName/UserName';
const MidHeader = styled.div`
  border-top: 1px solid #d0d7e4;
  border-bottom: 1px solid #d0d7e4;
  padding: 25px 60px;
  font-weight: bold;
  color: #333;
`;

const Page = styled.div`
  background-color: #fff;
  overflow: hidden;
`;

const Title = styled.span`
  margin-right: 5px;
`;

export function PageLayout({
  shouldAutoSaveOnLogout = false,
  children,
}: {
  shouldAutoSaveOnLogout?: boolean;
  children: ReactNode;
}) {
  return (
    <Authenticated>
      <TrackUserInactivity authStore={authStore}>
        <Page>
          <PageHeaderV2 shouldAutoSaveOnLogout={shouldAutoSaveOnLogout} />
          <MidHeader>
            <Title data-public>Schedule for</Title>
            <UserName />
          </MidHeader>
          <>{children}</>
        </Page>
      </TrackUserInactivity>
    </Authenticated>
  );
}
