import styled from 'styled-components';
import { ClinicalNotePreviewHeader } from './ClinicalNotePreviewPage/ClinicalNotePreviewHeader';
import { ClinicalNotePreviewLeftColumn } from './ClinicalNotePreviewLeftColumn';
import { ClinicalNotePreviewRightColumn } from './ClinicalNotePreviewRightColumn';
import { ClinicalNotePreviewMode } from './ClinicalNotePreview';
import { FC, useMemo } from 'react';
import { SignedPreviewHeader } from './ClinicalNotePreviewPage/SignedPreviewHeader';
import { ClinicalNotePreviewAddenda } from './ClinicalNotePreviewPage/ClinicalNotePreviewAddenda';
import { SignedNoteLabel } from './ClinicalNotePreviewPage/SignedNoteLabel';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';
import { observer } from 'mobx-react-lite';
import { clinicalNoteTemplatesStore } from '../../stores/clinicalNoteTemplates.store';
import {
  CategoryVariant,
  ClinicalNoteStatus,
} from '../../types/clinical-notes';
import { ClinicalNotePreviewSectionsParsing } from '../../utilities/clinicalNotePreviewSectionsParsing';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import { TestID } from '../../constants/testIds';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: transparent;

  & > * {
    background-color: white;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media print {
    height: auto;
    padding: 0;
    margin: 0;
  }
`;

const HeaderContent = styled.div`
  width: 100%;
  display: table-header-group;
  @media print {
    display: none;
  }
`;

const PrintingHeader = styled.div`
  @media screen {
    display: none;
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  flex: 1;
`;

const PrintingContainer = styled.div<{ shadow?: boolean }>`
  height: 100vh;
  max-width: 960px;
  overflow: scroll;
  background-color: #e2e7ec;
  box-shadow: ${(props) =>
    props.shadow ? '0px 0px 30px rgba(0, 0, 0, 0.1)' : null};

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen {
    width: 100%;
  }
`;

const TablePrinting = styled.table`
  width: 100%;
  @page {
    margin: 0 2mm 10mm 0;
  }
  @media screen {
    height: 98%;
    margin-bottom: 40px;
  }
`;

interface ClinicalNotePreviewMainContentProps {
  mode: ClinicalNotePreviewMode;
  printRef?: React.MutableRefObject<null>;
  isAITemplate?: boolean;
}

export const ClinicalNotePreviewMainContent: FC<ClinicalNotePreviewMainContentProps> =
  observer(({ mode, printRef, isAITemplate = false }) => {
    const { clinicalNote } = clinicalNoteStore;
    const clinicalNoteSections = clinicalNoteStore.sectionValues;

    // TODO: move the section preparation code to the BFFE
    const sectionFields = useMemo(() => {
      return ClinicalNotePreviewSectionsParsing.createPreviewSectionsObject(
        clinicalNoteSections,
        clinicalNoteTemplatesStore,
        clinicalNoteStore.templateId
      );
    }, [clinicalNoteSections]);

    return (
      <>
        <PrintingContainer
          shadow={clinicalNote?.status === ClinicalNoteStatus.Draft}
        >
          <TablePrinting ref={printRef}>
            <thead>
              <tr>
                <td>
                  <PrintingHeader>
                    <SignedPreviewHeader mode={mode} />
                  </PrintingHeader>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Container
                    data-testid={TestID.ClinicalNote.PreviewMainContent}
                  >
                    <HeaderContent>
                      <SignedPreviewHeader mode={mode} />
                    </HeaderContent>
                    <BodyContainer>
                      <ClinicalNotePreviewHeader mode={mode} />
                      <ClinicalNotePreviewLeftColumn
                        clinicalNoteEncounterSections={
                          sectionFields[CategoryVariant.encounter]
                        }
                        clinicalNoteAssessmentAndPlanSections={
                          sectionFields[CategoryVariant.assessmentAndPlan]
                        }
                        isAiTemplate={isAITemplate || false}
                      />
                      <ConditionalRender shouldRender={!isAITemplate}>
                        <ClinicalNotePreviewRightColumn
                          clinicalNoteHistorySections={
                            sectionFields[CategoryVariant.history]
                          }
                        />
                      </ConditionalRender>
                    </BodyContainer>
                    <SignedNoteLabel />
                    <ClinicalNotePreviewAddenda />
                  </Container>
                </td>
              </tr>
            </tbody>
          </TablePrinting>
        </PrintingContainer>
      </>
    );
  });
