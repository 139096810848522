import { gql } from '@apollo/client';

export type AmazonTranscribeCreds = {
  credentials: {
    amazon: {
      transcribe: {
        accessKeyId: string;
        secretAccessKey: string;
        sessionToken: string;
        expiration: string;
      };
    };
  };
};

export const getAmazonTranscribeCreds = gql`
  query GetAmazonTranscribeCreds {
    credentials {
      amazon {
        transcribe {
          accessKeyId
          secretAccessKey
          sessionToken
          expiration
        }
      }
    }
  }
`;
