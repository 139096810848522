import { TextField } from '../../../components/AkidoUI/TextField';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { editScribeNoteModalStore } from '../../../stores/editScribeNoteModal.store';
import { ScribeNoteStyles } from '../CreateScribeNoteModal/scribeNote.styles';

interface EditScribeNoteContentProps {}

export const EditScribeNoteContent: FC<EditScribeNoteContentProps> = observer(
  (props) => {
    return (
      <TextField
        testId='EditScribeNoteModalInput'
        containerStyles={ScribeNoteStyles.containerFlexOne}
        inputStyles={ScribeNoteStyles.scribeNoteModalContentInput}
        value={editScribeNoteModalStore.note ?? ''}
        onChange={(newValue: string) => {
          if (editScribeNoteModalStore.isEditMode) {
            editScribeNoteModalStore.editNote(newValue);
          }
        }}
        multiline
      />
    );
  }
);
