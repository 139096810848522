import React, { useState } from 'react';
import styled, { ThemedStyledProps } from 'styled-components';
import { math } from 'polished';
import { Theme } from '../Themes';

type TextFieldWithThemeProps = TextFieldProps &
  ThemedStyledProps<TextFieldProps, Theme>;

function getTextColor(props: TextFieldWithThemeProps) {
  return props.disabled
    ? props.theme?.colors?.action?.disabled
    : props.theme?.colors?.typography?.primary;
}

function getDecorationColor(props: TextFieldWithThemeProps) {
  return props.disabled
    ? props.theme?.colors?.action?.disabled
    : props.theme?.colors?.action?.secondary;
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
`;

const StyledInput = styled.input<TextFieldProps>`
  border: 1px solid ${(props) => getDecorationColor(props)};
  border-radius: 4px;
  padding: 15px 10px;
  color: ${(props) => getTextColor(props)};
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;min-width:100%;' : '')}
  min-height: ${({ multiline, numberOfLines }) => {
    if (multiline) {
      return math(`${numberOfLines} * 24px`);
    }
    return 'initial';
  }};
  &::placeholder {
    color: ${(props) => props.placeholderColor};
  }
`;

const StyledErrorContainer = styled.div`
  position: absolute;
  bottom: -24px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledErrorText = styled.span`
  color: ${({ theme }) => theme?.colors?.states?.error?.primary};
  font-size: 12px;
  margin-left: 5px;
`;

const StyledTextArea = styled.textarea<TextFieldWithThemeProps>`
  border: 1px solid ${(props) => getDecorationColor(props)};
  border-radius: 4px;
  font-family: inherit;
  padding: 15px 10px;
  color: ${(props) => getTextColor(props)};
  &::placeholder {
    color: ${(props) => props.placeholderColor};
  }
`;

export interface TextFieldProps {
  /**
   * If true, the input will be disabled.
   */
  disabled?: boolean;
  /**
   * The value of the input
   */
  value: string;
  /**
   * Placeholder text
   */
  placeholder?: string;
  /**
   * Placeholder color
   */
  placeholderColor?: string;
  /**
   * If true, the input will be a textarea
   */
  multiline?: boolean;
  /**
   * The number of lines to display when multiline is true.
   */
  numberOfLines?: number;
  /**
   * Only usable on multiline mode. Indicates if height should increase
   * automatically with text.
   */
  autoGrow?: boolean;
  /**
   * If true, the input will be type password.
   */
  isPassword?: boolean;
  /**
   * The error message to display.
   */
  error?: string;
  /**
   * If true, the input will be full width.
   */
  fullWidth?: boolean;
  /**
   * If true, the input will be type email.
   */
  isEmail?: boolean;
  /**
   * Test ID used for component tests
   */
  testId?: string;
  /**
   * On change event handler
   */
  onChange: (text: string) => void;
  /**
   * On focus event handler
   */
  onFocus?: () => void;
  /**
   * On focus event handler
   */
  onBlur?: () => void;
  /**
   * Styles for the container element
   */
  containerStyles?: any;
  /**
   * Styles for the input element
   */
  inputStyles?: any;
  /**
   * Styles for the error text element
   */
  errorStyles?: any;
  /**
   * If true, the input will be private for logRocket
   */
  isPrivate?: boolean;
}

const TextField = React.forwardRef(
  (props: TextFieldProps, forwardedRef: any) => {
    const {
      disabled,
      placeholder,
      placeholderColor,
      value,
      multiline,
      isPassword,
      numberOfLines,
      error,
      fullWidth,
      testId,
      autoGrow,
      isEmail,
      onChange,
      onFocus,
      onBlur,
      containerStyles,
      inputStyles,
      errorStyles,
      isPrivate,
    } = props;

    const [internalValue, setInternalValue] = useState<string>('');
    const renderedValue = value ?? internalValue;
    let inputType = 'text';
    if (isPassword) {
      inputType = 'password';
    } else if (isEmail) {
      inputType = 'email';
    }

    function onChangeTextHandler(
      event:
        | React.ChangeEvent<HTMLTextAreaElement>
        | React.ChangeEvent<HTMLInputElement>
        | string
    ) {
      if (typeof event === 'object') {
        const { value: text } = event?.target;

        setInternalValue(text);
        onChange(text);
      }
    }

    function handleAutoGrow(event: React.SyntheticEvent<HTMLTextAreaElement>) {
      // We remove 30px from the scrollHeight to account for the TextArea padding (15 top, 15 bottom)
      const target = event.target as HTMLTextAreaElement;

      // eslint-disable-next-line no-param-reassign
      target.style.height = `${target.scrollHeight - 30}px`;
    }

    return (
      <StyledContainer data-testid={testId} style={containerStyles}>
        {multiline ? (
          <StyledTextArea
            data-private={isPrivate}
            onInput={(event) => {
              if (autoGrow) handleAutoGrow(event);
            }}
            style={inputStyles}
            disabled={disabled}
            placeholder={placeholder}
            placeholderColor={placeholderColor}
            value={renderedValue}
            fullWidth={fullWidth}
            multiline={multiline}
            ref={forwardedRef}
            onChange={onChangeTextHandler}
            onFocus={() => {
              if (onFocus) {
                onFocus();
              }
            }}
            onBlur={() => {
              if (onBlur) {
                onBlur();
              }
            }}
            rows={multiline ? numberOfLines : undefined}
          />
        ) : (
          <StyledInput
            data-private={isPrivate}
            style={inputStyles}
            type={inputType}
            disabled={disabled}
            placeholder={placeholder}
            placeholderColor={placeholderColor}
            value={renderedValue}
            fullWidth={fullWidth}
            multiline={multiline}
            ref={forwardedRef}
            onChange={onChangeTextHandler}
            onFocus={() => {
              if (onFocus) {
                onFocus();
              }
            }}
            onBlur={() => {
              if (onBlur) {
                onBlur();
              }
            }}
          />
        )}
        {error && (
          <StyledErrorContainer>
            <StyledErrorText style={errorStyles}>{error}</StyledErrorText>
          </StyledErrorContainer>
        )}
      </StyledContainer>
    );
  }
);

TextField.defaultProps = {
  disabled: false,
  isPassword: false,
  value: '',
  multiline: false,
  placeholder: '',
  numberOfLines: 1,
  isEmail: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

export { TextField };
