import { TestID } from '../../../constants/testIds';
import clsx from 'clsx';

interface IProps {
  selected: 'AM' | 'PM';
  onSelect: (arg0: 'AM' | 'PM') => void;
  className?: string;
}

export const AmPmSelector = ({ selected, onSelect, className }: IProps) => {
  const isAMSelected = selected === 'AM';
  const isPMSelected = !isAMSelected;

  return (
    <div
      className={clsx('flex items-center space-x-1', className)}
      data-testid={TestID.SideDrawer.AmPmPicker}
    >
      <button
        data-is-active={isAMSelected}
        onClick={() => onSelect('AM')}
        className={clsx('h-9 w-9 rounded-lg p-2 text-sm font-medium', {
          'bg-primary-600 text-white': isAMSelected,
          'bg-white text-gray-800': isPMSelected,
        })}
      >
        AM
      </button>
      <button
        data-is-active={isPMSelected}
        onClick={() => onSelect('PM')}
        className={clsx('h-9 w-9 rounded-lg text-sm font-medium', {
          'bg-primary-600 text-white': isPMSelected,
          'bg-white text-gray-800': isAMSelected,
        })}
      >
        PM
      </button>
    </div>
  );
};
