import { observer } from 'mobx-react-lite';
import { TextField } from '../../../components/AkidoUI/TextField';
import { scribeNoteModalStore } from '../../../stores/scribeNoteModal.store';
import { ScribeNoteStyles } from './scribeNote.styles';

export const CreateScribeNoteModalContent = observer(() => {
  return (
    <TextField
      testId='ScribeNoteModalInput'
      containerStyles={ScribeNoteStyles.containerFlexOne}
      inputStyles={ScribeNoteStyles.scribeNoteModalContentInput}
      value={scribeNoteModalStore.noteValue || ''}
      onChange={(newValue: string) => {
        scribeNoteModalStore.updateNoteText(newValue);
      }}
      multiline
    />
  );
});
