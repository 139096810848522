import { observer } from 'mobx-react-lite';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { localClinicalNotesStore } from '../../../stores/localPersistentClinicalNotes.store';
import { ClinicalNoteFieldValue } from '../../../types/clinical-notes';
import ClinicalNotesAutoFillField from '../ClinicalNotesAutoFillFields';

export const SectionEditableAutofill = observer(
  ({
    noteId,
    showTitle = true,
    updateCallback,
    containerStyles,
    field,
    minRows,
    maxRows,
  }: {
    noteId: string;
    showTitle?: boolean;
    minRows?: number;
    maxRows?: number;
    updateCallback: () => void;
    field: ClinicalNoteFieldValue;
    containerStyles?: Record<string, unknown>;
  }) => {
    const sectionId = field?.sectionId ?? field?.id ?? '';
    const uiSection = field?.uiSection;
    const localStoreEnabled = useFeatureFlag(
      FEATURE_FLAGS.LOCAL_STORE_CLINICAL_NOTES,
      false
    );
    let localNoteSection = undefined;
    if (localStoreEnabled) {
      localNoteSection = localClinicalNotesStore.getSectionByIds(
        noteId,
        sectionId
      );
    }
    const value = localNoteSection?.text || field.text;

    const key = `field_${sectionId}`;
    const title = showTitle ? field.title : null;

    const onHandleChange = (newValue: string) => {
      clinicalNoteStore.setSectionValue(
        sectionId ?? '',
        newValue,
        field.sortOrder,
        field.type,
        field.uiSection,
        field.requestId
      );
      updateCallback();
    };

    return (
      <ClinicalNotesAutoFillField
        id={sectionId}
        key={key}
        title={title ?? ''}
        showTopAction
        autofillData={field.autofillData ?? undefined}
        value={value}
        onChange={onHandleChange}
        refreshCallback={field?.refreshCallback}
        containerStyles={containerStyles}
        uiSection={uiSection}
        minRows={minRows}
        maxRows={maxRows}
      />
    );
  }
);
