import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePaths } from '../../constants/routes';
import { useFetchClinicalNoteTemplate } from '../../hooks/useFetchClinicalNoteTemplate';
import { TestID } from '../../constants/testIds';
import { ClinicalNoteScreens } from '../../types/screens';
import { localClinicalNotesStore } from '../../stores/localPersistentClinicalNotes.store';
import { clinicalNoteTemplatesStore } from '../../stores/clinicalNoteTemplates.store';
import { useUserAuthorization } from '../../hooks/useUserAuthorization';
import { useHandleOnClinicalNotePageSave } from './hooks/useHandleOnClinicalNotePageSave';
import { clinicalEditorPageState } from '../../stores/clinicalEditorPageState.store';
import { useClinicalNoteEditorPageNavigation } from './hooks/useClinicalNoteEditorPageNavigation';
import { useNoteEditorAutoSave } from './hooks/useNoteEditorAutoSave';
import { useNoteEditorFromAkidoNotePageLoad } from './hooks/useNoteEditorFromAkidoNotePageLoad';
import {
  getErrorCodeFromGraphqlCall,
  resourceIdTypeToResourceType,
} from '../../utilities/helpers';
import { ClinicalNoteEditorPage } from './ClinicalNoteEditorPage';
import { useAnalytics } from '../../hooks/useAnalytics';
import useNUSAProcessingWatcher from '../../hooks/useNUSAProcessingWatcher';
import {
  AnalyticsScreenNameMapping,
  ClinicalNoteAnalyticsEventName,
} from '../../constants/analytics';

const errorRoute = (errorCode?: string) => {
  return RoutePaths.Error + (errorCode ? `?code=${errorCode}` : '');
};

export const ClinicalNotesEditorPageFromAkidoNoteId = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sendClinicalNoteAnalyticsEvent, sendScreenEvent } = useAnalytics();

  const [searchParams] = useSearchParams();
  const resourceIdType = searchParams.get('resourceIdType');
  const resourceType = resourceIdType
    ? resourceIdTypeToResourceType(resourceIdType)
    : undefined;

  const fetchTemplatesError = clinicalNoteTemplatesStore.templateErrors;
  const {
    isAutoSaving,
    hasSaved: hasSuccessfullySaved,
    isSaving,
    hasSaveError,
  } = clinicalEditorPageState;

  useFetchClinicalNoteTemplate({
    screen: ClinicalNoteScreens.EDITOR,
    resourceType,
  });
  useUserAuthorization();
  useNUSAProcessingWatcher();

  const {
    clinicalNote,
    akidoNoteId,
    hasLoadLocal,
    fetchClinicalNoteError,
    isDataForEditorLoading,
    isPatientLoading,
    isNoteSigned,
  } = useNoteEditorFromAkidoNotePageLoad();

  const { saveNote } = useHandleOnClinicalNotePageSave({
    akidoNoteId,
    currentId: null,
    externalEncounterId: clinicalNote?.encounterId,
  });

  useNoteEditorAutoSave({ saveNote, hasLoadLocal });

  useClinicalNoteEditorPageNavigation({
    clinicalNoteData: null,
    saveNote,
    akidoNoteId,
    clinicalNote,
    isNoteSigned,
  });

  useEffect(() => {
    // We want this to execute exactly once when the note is opened and no more on future re-renders
    sendScreenEvent({
      firebase_screen: AnalyticsScreenNameMapping.noteEditor,
      firebase_screen_class: AnalyticsScreenNameMapping.noteEditor,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNoteSign = useCallback(async () => {
    const noteId = await saveNote();
    if (akidoNoteId) {
      localClinicalNotesStore.deleteClinicalNoteById(akidoNoteId);
    }

    sendClinicalNoteAnalyticsEvent(ClinicalNoteAnalyticsEventName.PreviewNote, {
      capella_note_id: clinicalNote?.encounterId,
      akido_note_id: noteId,
    });
    navigate(`${RoutePaths.ClinicalNotePreview.replace(':id', noteId)}`);
  }, [
    akidoNoteId,
    clinicalNote,
    navigate,
    saveNote,
    sendClinicalNoteAnalyticsEvent,
  ]);

  if (!akidoNoteId) {
    navigate(`${RoutePaths.Error}?code=404`, {
      state: { previousPath: location.pathname },
    });
    return (
      <div data-testid={TestID.ClinicalNote.Error.MissingIdError}>
        There is no id to fetch an encounter
      </div>
    );
  }

  if (fetchClinicalNoteError) {
    const errorCode = getErrorCodeFromGraphqlCall(fetchClinicalNoteError);
    navigate(errorRoute(errorCode), {
      state: { previousPath: location.pathname },
    });
    return (
      <div data-testid={TestID.ClinicalNote.Error.FetchErrorAkidoId}>
        There was an error fetching the clinical note
        {JSON.stringify(fetchClinicalNoteError)}
      </div>
    );
  }

  if (fetchTemplatesError) {
    const errorCode = getErrorCodeFromGraphqlCall(fetchTemplatesError);
    navigate(errorRoute(errorCode), {
      state: { previousPath: location.pathname },
    });
    return (
      <div data-testid={TestID.ClinicalNote.Error.FetchTemplatesError}>
        There was an error when loading the templates
        {JSON.stringify(fetchTemplatesError)}
      </div>
    );
  }

  if (
    akidoNoteId &&
    clinicalNote &&
    !clinicalNote.canSignNote &&
    !isAutoSaving
  ) {
    navigate(`${RoutePaths.ClinicalNotePreview.replace(':id', akidoNoteId)}`);
    return (
      <div data-testid='save-clinical-note-error-placeholder'>
        User is not allowed to edit this note
      </div>
    );
  }

  return (
    <ClinicalNoteEditorPage
      pageTestId={'clinical-notes-editor-page-akido-note-id'}
      noteId={akidoNoteId}
      isPatientLoading={isPatientLoading}
      clinicalNote={clinicalNote}
      saveNote={saveNote}
      onNoteSign={onNoteSign}
      isSaving={isSaving}
      hasSuccessfullySaved={hasSuccessfullySaved}
      hasSaveError={hasSaveError}
      isDataForEditorLoading={isDataForEditorLoading}
      isLoadingFromExternalId={false}
    />
  );
});
