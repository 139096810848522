import { useLazyQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { ScheduleQuery } from '../../../services/graphql/schedule';
import {
  ScheduleEventItem,
  ScheduleQueryVariables,
  ScheduleReturn,
} from '../../../types/schedule';
import { ScheduleHelpers } from '../../../utilities/scheduleHelpers';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { useInterval } from '../../../hooks/useInterval';
import { REACT_APP_SCHEDULE_POLLING_PERIOD_MINUTES } from '../../../config/environment';
import { DateTime } from 'luxon';

const POLLING_MINUTES = Number(REACT_APP_SCHEDULE_POLLING_PERIOD_MINUTES) || 5;
const POLLING_PERIOD = 60 * POLLING_MINUTES * 1000;

interface IUseScheduleProps {
  practitionerOktaId: string;
  date: string;
  pollingPeriod?: number;
}

export const useSchedule = ({
  practitionerOktaId,
  date,
  pollingPeriod = POLLING_PERIOD,
}: IUseScheduleProps) => {
  const [shouldActivatePolling, setShouldActivatePolling] = useState(false);

  const [scheduleItems, setScheduleItems] = useState<ScheduleEventItem[]>([]);
  const ldClient = useLDClient();

  const shouldPollForAppointmentUpdates = ldClient?.variation(
    FEATURE_FLAGS.POLLING_APPOINTMENTS,
    false
  );

  const shouldUseDateRangeToFetchSchedule = ldClient?.variation(
    FEATURE_FLAGS.USE_DATE_RANGE_TO_FETCH_SCHEDULE,
    false
  );

  const [getSchedule, { loading, error }] = useLazyQuery<
    ScheduleReturn,
    ScheduleQueryVariables
  >(ScheduleQuery, {
    fetchPolicy: 'network-only',
  });

  const retrieveScheduleOnce = useCallback(async () => {
    if (practitionerOktaId && date) {
      const variables: ScheduleQueryVariables = {
        practitionerOktaId,
      };

      if (shouldUseDateRangeToFetchSchedule) {
        variables.dateRange = {
          startDate: DateTime.fromISO(date).startOf('day').toString(),
          endDate: DateTime.fromISO(date).endOf('day').toString(),
        };
      } else {
        variables.date = date;
      }

      const returnValue = await getSchedule({
        variables,
      });
      const scheduleEvents = ScheduleHelpers.convertScheduleItemsToEvents(
        returnValue.data?.schedules?.schedules
      );
      setScheduleItems(scheduleEvents);
    }
  }, [
    date,
    getSchedule,
    practitionerOktaId,
    shouldUseDateRangeToFetchSchedule,
  ]);

  useInterval(retrieveScheduleOnce, pollingPeriod, shouldActivatePolling);

  const retrieveSchedule = useCallback(async () => {
    await retrieveScheduleOnce();
    if (shouldPollForAppointmentUpdates) {
      setShouldActivatePolling(true);
    }

    return () => {
      setShouldActivatePolling(false);
    };
  }, [retrieveScheduleOnce, shouldPollForAppointmentUpdates]);

  return {
    isLoading: loading,
    error,
    retrieveScheduleOnce,
    scheduleItems,
    retrieveSchedule,
    shouldActivatePolling,
  };
};
