import { useMutation } from '@apollo/client';
import { signAddendum } from '../../../services/graphql/signAddendum';

export type SignAddendumResponse = {
  addAddendum: {
    clinicalNoteId: string;
    createdAt: string;
    createdBy: string;
    id: string;
    text: string;
    updatedAt: string;
    userId: string;
  };
};

export const useSignAddendum = (text: string, clinicalNoteId?: string) => {
  const [signAddendumMutation, { data, error, loading }] =
    useMutation<SignAddendumResponse>(signAddendum, {
      variables: {
        text,
        clinicalNoteId,
      },
    });

  return {
    signAddendum: signAddendumMutation,
    isLoading: loading,
    error,
    data: data?.addAddendum,
  };
};
