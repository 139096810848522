import { observer } from 'mobx-react-lite';
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';

const TitleWrapper = styled.div`
  padding: 10px;
  align-self: center;
  display: flex;
  flex-direction: row;
`;

const TitleText = styled.input`
  margin-left: 25px;
  font-weight: 600;
  font-size: 0.872rem;
  border: none;
  background-color: transparent;
  color: #333737;

  &:disabled {
    color: #333737;
  }
`;

const ActionText = styled.button`
  color: #3892c3;
  font-size: 0.775rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

interface EditableNoteTitleProps {
  onEdit?: (title: string) => void;
  value?: string;
}

export const EditableNoteTitle = observer((props: EditableNoteTitleProps) => {
  const { onEdit, value } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { editableNoteTitle: title } = clinicalNoteStore;

  const [disabled, setDisabled] = useState(true);

  const toggleEdit = useCallback(() => {
    setDisabled((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!disabled) {
      inputRef?.current?.focus();
    }
  }, [disabled]);

  const editTitle: FormEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const eventValue = (event.target as HTMLInputElement).value;
      if (onEdit) {
        onEdit(eventValue);
      } else {
        clinicalNoteStore.setEditableNoteTitle(eventValue);
      }
    },
    [onEdit]
  );

  return (
    <TitleWrapper>
      <form
        style={{}}
        onSubmit={(event) => {
          event.preventDefault();
          toggleEdit();
        }}
      >
        <TitleText
          ref={inputRef}
          onChange={editTitle}
          value={value ?? title}
          disabled={disabled}
        />
      </form>
      <ActionText onClick={toggleEdit}>Edit</ActionText>
    </TitleWrapper>
  );
});
