import React, { useState } from 'react';
import styled from 'styled-components';
import { readableColor, math } from 'polished';
import { Theme, DefaultTheme } from '../Themes';

function getButtonState(props: ButtonProps): string {
  if (props.disabled) {
    return 'disabled';
  }

  if (props.hovered || props.pressed) {
    return 'pressed';
  }

  return 'idle';
}

function getButtonSizeMultiplier(props: ButtonProps): number {
  switch (props.size) {
    case 'small':
      return 0.75;
    case 'medium':
      return 1;
    case 'large':
      return 1.25;
    default:
      return 1;
  }
}

function getBackgroundColor(props: ButtonProps): string | undefined {
  const state = getButtonState(props);
  const { variant, theme } = props;

  switch (variant) {
    case 'text':
    case 'secondary':
      return 'transparent';
    case 'primary':
    default:
      if (state === 'pressed') {
        return theme?.colors?.action?.pressed;
      }
      if (state === 'disabled') {
        return theme?.colors?.action?.disabled;
      }
      return theme?.colors?.action?.primary;
  }
}

function getBorderColor(props: ButtonProps): string | undefined {
  const state = getButtonState(props);
  const { variant, theme } = props;

  switch (variant) {
    case 'text':
      return 'transparent';
    case 'primary':
    case 'secondary':
    default:
      if (state === 'pressed') {
        return theme?.colors?.action?.pressed;
      }
      if (state === 'disabled') {
        return theme?.colors?.action?.disabled;
      }
      return theme?.colors?.action?.primary;
  }
}

function getTextColor(props: ButtonProps): string | undefined {
  const state = getButtonState(props);
  const { variant, theme } = props;

  switch (variant) {
    case 'secondary':
    case 'text':
      if (state === 'pressed') {
        return theme?.colors?.action?.pressed;
      }
      if (state === 'disabled') {
        return theme?.colors?.action?.disabled;
      }
      return theme?.colors?.action?.primary;

    case 'primary':
    default:
      if (state === 'pressed') {
        return readableColor(theme?.colors?.action?.pressed as string);
      }
      if (state === 'disabled') {
        return readableColor(theme?.colors?.action?.primary as string);
      }

      return readableColor(theme?.colors?.action?.primary as string);
  }
}

const ButtonBox = styled.button<ButtonProps>`
  background-color: ${(props) => getBackgroundColor(props)};
  border: 2px solid ${(props) => getBorderColor(props)};
  border-radius: 4px;
  height: ${(props) => math(`40px * ${getButtonSizeMultiplier(props)}`)};
  min-width: ${(props: any) => (props.variant === 'text' ? '25px' : '100px')};
  padding: 0px ${(props) => math(`40px * ${getButtonSizeMultiplier(props)}`)};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ButtonText = styled.span<ButtonProps>`
  color: ${(props) => getTextColor(props)};
  font-size: ${(props) => math(`14px * ${getButtonSizeMultiplier(props)}`)};
  font-style: normal;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
`;

export interface ButtonProps {
  /** Should the button be disabled? */
  disabled?: boolean;
  /** Text label for the button */
  label?: string;
  /** Raw style object for the button label */
  labelStyles?: any;
  /** Size of the button */
  size?: 'small' | 'medium' | 'large';
  /** Raw style object for the button */
  styles?: any;
  /** Variant of the button */
  variant?: 'primary' | 'secondary' | 'text';
  /** theme passed from styled-components.
   * Not required if using a provider.
   */
  theme?: Theme;
  /** Indicates if the button should display loading state */
  loading?: boolean;
  /** Component to render when loading state should be shown */
  loadingComponent?: JSX.Element | null;
  /** TestID to target the component with jest */
  testId?: string;
  /** Wheter the button is being hovered (internal) */
  hovered?: boolean;
  /** Wheter the button is being pressed (internal) */
  pressed?: boolean;
  /** Children */
  children?: React.ReactNode;
  /** OnClick handler */
  onClick?: (event: any) => any;
}

export const Button = ({
  disabled = false,
  size = 'medium',
  variant = 'primary',
  labelStyles = {},
  loading = false,
  loadingComponent = null,
  styles = {},
  label,
  testId,
  theme = DefaultTheme,
  onClick,
  children,
}: ButtonProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isPressed, setIsPressed] = useState<boolean>(false);

  let buttonContent = (
    <ButtonText
      disabled={disabled}
      hovered={isHovered}
      size={size}
      style={labelStyles}
      theme={theme}
      variant={variant}
    >
      {label ?? children}
    </ButtonText>
  );

  if (loading && loadingComponent) {
    buttonContent = loadingComponent;
  }

  return (
    <ButtonBox
      /* Pass-through */
      aria-label={label}
      data-testid={testId}
      disabled={disabled}
      /* Theming */
      style={styles}
      theme={theme}
      size={size}
      hovered={isHovered}
      pressed={isPressed}
      variant={variant}
      /* Events */
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onClick={onClick}
    >
      {buttonContent}
    </ButtonBox>
  );
};
