import { DateTime } from 'luxon';

export const formatDate = (
  ISODate: string,
  isDateTime: boolean = true,
  zone?: string
) => {
  const date = DateTime.fromISO(ISODate, { zone });
  let format = "MM/dd/yy',' HH:mm a";

  if (!isDateTime) {
    format = 'MM/dd/yy';
  }

  if (date.isValid) {
    // Returns date in desired format and local timezone
    return date.toFormat(format);
  }

  return '-';
};

export const formatSignedDate = (date?: string) => {
  if (date) {
    const parsedDate = DateTime.fromISO(date);
    if (parsedDate.isValid) {
      return parsedDate.toFormat('MM/dd/yy hh:mm a');
    }
  }
  return '';
};

export const getClinicalNoteURL = (clinicalNoteData: {
  id: string;
  signedAt?: string;
}) => {
  const signedUrl = clinicalNoteData.signedAt ? '/preview' : '';
  return `/clinical-notes/${clinicalNoteData.id}${signedUrl}`;
};

export const createClinicalNoteListItemsBlock = (list: string[]): string => {
  let returnString = '';
  if (list.length > 0) {
    for (const item of list) {
      returnString += `${item}\n`;
    }
  }
  return returnString;
};

interface IDisplayLabels {
  displayLabel: string;
}
export const createClinicalNoteListItemsBlockFromDisplayLabels = (
  list: IDisplayLabels[]
): string => {
  return list.reduce((prev, cur) => {
    return prev + `${cur.displayLabel}\n`;
  }, '');
};
