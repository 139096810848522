import { FC } from 'react';
import { useAINotePage } from '../../hooks/useAINotePage';
import { useParams } from 'react-router-dom';
import { LoggedInLayout } from '../../components';
import { AINoteGlobalHeader } from './AINoteGlobalHeader';
import { AINoteHeader } from './components/AINoteHeader';
import { AINotePageLayout } from './AINotePageLayout';

interface AINoteLoadProps {}

export const AINoteLoad: FC<AINoteLoadProps> = () => {
  useAINotePage();
  const params = useParams<{ id: string }>();

  if (!params.id) {
    return null;
  }

  return (
    <div className='flex h-full flex-col'>
      <LoggedInLayout
        shouldAutoSaveOnLogout
        shouldShowHeader
        Header={AINoteGlobalHeader}
      >
        <AINoteHeader />
        <AINotePageLayout id={params.id} />
      </LoggedInLayout>
    </div>
  );
};
