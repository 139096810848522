import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import {
  getClinicalNoteQuery,
  GetClinicalNoteReturn,
  GetClinicalNoteVariables,
} from '../services/graphql/clinicalNote';
import { clinicalNoteStore } from '../stores/clinicalNote.store';
import { clinicalNoteTemplatesStore } from '../stores/clinicalNoteTemplates.store';
import { ClinicalNoteScreens } from '../types/screens';
import { useHandleOnClinicalNotePageSave } from '../routes/ClinicalNotesEditor/hooks/useHandleOnClinicalNotePageSave';
import { localClinicalNotesStore } from '../stores/localPersistentClinicalNotes.store';
import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';

export const useCopyPreviousNote = (previousNoteId: string | null) => {
  const currentNoteId = clinicalNoteStore.clinicalNote?.id;
  const currentEncounterId = clinicalNoteStore.clinicalNote?.encounterId;
  const [error, setError] = useState<string | null>(null);
  const [getClinicalNote, { loading: isLoading, error: graphQlError }] =
    useLazyQuery<GetClinicalNoteReturn, GetClinicalNoteVariables>(
      getClinicalNoteQuery,
      {
        fetchPolicy: 'network-only',
      }
    );

  const getPreviousNote = useCallback(async () => {
    if (previousNoteId) {
      const result = await getClinicalNote({
        variables: {
          id: previousNoteId ?? undefined,
          screen: ClinicalNoteScreens.EDITOR,
        },
      });

      return result.data?.getClinicalNote;
    }
  }, [previousNoteId, getClinicalNote]);

  const { saveNote } = useHandleOnClinicalNotePageSave({
    akidoNoteId: currentNoteId,
    currentId: null,
    externalEncounterId: currentEncounterId,
  });

  const copyPreviousNote = useCallback(
    async (previousClinicalNote: ClinicalNoteModel | undefined) => {
      if (previousClinicalNote && previousClinicalNote?.content?.templateId) {
        const templateId = previousClinicalNote.content.templateId;
        const template = clinicalNoteTemplatesStore.getTemplateById(templateId);

        if (template) {
          clinicalNoteStore.setTemplateId(templateId);

          clinicalNoteStore.setFieldsAndDefaultValuesFromPreviousClinicalNote(
            previousClinicalNote,
            template
          );

          if (currentNoteId) {
            localClinicalNotesStore.deleteClinicalNoteById(currentNoteId);
          }

          await saveNote();
        } else {
          setError('template not found');
        }
      } else {
        setError('noteId is not defined');
      }
    },
    [currentNoteId, saveNote]
  );

  useEffect(() => {
    if (graphQlError) {
      setError(graphQlError.message);
    }
  }, [graphQlError]);

  return { copyPreviousNote, getPreviousNote, isLoading, error };
};
