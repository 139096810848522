import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from 'styled-components';
import { useAnalytics } from '../../hooks/useAnalytics';
import { transcriptionModalStore } from '../../stores/transcriptionModal.store';
import {
  AnalyticsScreenNameMapping,
  EncounterAnalyticsEventName,
} from '../../constants/analytics';
import { TranscriptionModel } from '@akido/provider-desktop-bffe-types';
import { truncateUUID } from '../../utilities/helpers';
import { Clients } from '../../constants/clients';
import { Source } from '../../constants/transcriptionSources';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../config/launchdarkly';
import { REACT_APP_AKIDO_CHART_URL } from '../../config/environment';

const Button = styled.button`
  color: #1b4c73;
  border: none;
  background-color: transparent;
  font-size: 0.95rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

interface EncounterIdButtonProps {
  id: string;
  transcription: TranscriptionModel;
}

export const EncounterIdButton: FC<EncounterIdButtonProps> = observer(
  (props) => {
    const { id, transcription } = props;
    const { sendEncounterAnalyticsEvent, sendScreenEvent } = useAnalytics();
    const ldClient = useLDClient();
    const shouldRedirectToAkidoChart = ldClient?.variation(
      FEATURE_FLAGS.REDIRECT_TO_AKIDO_CHART,
      false
    );

    const redirectHost = shouldRedirectToAkidoChart
      ? REACT_APP_AKIDO_CHART_URL
      : '';

    return (
      <Button
        onClick={() => {
          if (transcription.source === Source.DESKTOP) {
            if (!transcription.encounter.resourceType) {
              window.open(
                `${redirectHost}/clinical-notes?externalEncounterId=${transcription.encounter.encounterId}&client=${Clients.CMG}`
              );
            } else {
              window.open(
                `${redirectHost}/clinical-notes?resourceIdType=${transcription.encounter.resourceType}&resourceId=${transcription.encounter.encounterId}&client=${Clients.CMG}`
              );
            }
            return;
          }
          transcriptionModalStore.setSelectedTranscription(transcription);
          transcriptionModalStore.showModal();
          sendEncounterAnalyticsEvent(
            EncounterAnalyticsEventName.ViewEncounterNote,
            {
              akido_eid: id,
              transcription_id: transcription?.transcriptionId,
            }
          );

          sendScreenEvent({
            firebase_screen:
              AnalyticsScreenNameMapping['transcriptions/details'],
            firebase_screen_class:
              AnalyticsScreenNameMapping['transcriptions/details'],
          });
        }}
      >
        {truncateUUID(id)}
      </Button>
    );
  }
);
