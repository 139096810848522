import { ApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { oktaAuth } from '../config/okta';
import apolloClient from '../config/apollo';
import { AuthStore } from '../stores/auth.store';
import { localClinicalNotesStore } from '../stores/localPersistentClinicalNotes.store';
import {
  GetAuthorizationsResponse,
  getAuthorizations,
} from '../services/graphql/getAuthorizations';

export const login = async (email: string, password: string) => {
  const { status, sessionToken } = await oktaAuth.signInWithCredentials({
    username: email,
    password,
  });

  try {
    const tokens = await oktaAuth.token.getWithoutPrompt({ sessionToken });
    oktaAuth.tokenManager.setTokens(tokens.tokens);
  } catch (err) {
    console.error(err);
    throw new Error(`Unable to login with user ${email}.`);
  }

  const user = await oktaAuth.getUser();
  return {
    status,
    user,
  };
};

export const getAuthz = async () => {
  const { data } = await apolloClient.query<GetAuthorizationsResponse>({
    query: getAuthorizations,
  });
  return data;
};

export const getClaims = async () => {
  const tokens = await oktaAuth.tokenManager.getTokens();
  const claims: Record<string, any> = tokens?.accessToken?.claims ?? {};

  return claims;
};

export const storeAuthInfo = async (
  user: any,
  authStore: AuthStore | undefined
) => {
  const authz = await getAuthz();
  const claims = await getClaims();
  const authInfo = {
    ...user,
    specialty: claims?.specialty,
    tenant: claims?.tenant,
  };
  authStore?.setAuthInfo(authInfo);
  authStore?.setAuthorizations(authz);
};

export const logout = async (
  authStore?: AuthStore,
  apolloClient?: ApolloClient<object>,
  timeOutLogout: boolean = false
) => {
  oktaAuth.tokenManager.clear();
  apolloClient?.clearStore();
  Sentry.setUser(null);

  await oktaAuth.signOut({
    postLogoutRedirectUri: `${window.location.origin}/login${
      timeOutLogout ? '?timeout=true' : ''
    }`,
  });
  await authStore?.reset();
};

export const setupUser = (userId: string) => {
  if (localClinicalNotesStore.userId !== userId) {
    localClinicalNotesStore.reset();
    localClinicalNotesStore.userId = userId;
  }
};
