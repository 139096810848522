import { gql } from '@apollo/client';

export const signAddendum = gql`
  mutation SignAddendum($clinicalNoteId: String!, $text: String!) {
    addAddendum(clinicalNoteId: $clinicalNoteId, text: $text) {
      clinicalNoteId
      createdAt
      createdBy
      id
      text
      updatedAt
      userId
    }
  }
`;
