import { FC } from 'react';
import styled from 'styled-components';

interface NoteContentProps {
  title?: string;
  note?: string;
}

const NoteTitle = styled.div`
  font-weight: 400;
  font-size: 1.05rem;
  margin-bottom: 8px;
`;

const Note = styled.span`
  color: #5f7584;
`;

const NoteContainer = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 12px 16px 12px 8px;
  border: 1px solid #abbdca;
  border-radius: 4px;
  overflow: scroll;
  white-space: pre-line;
`;

export const NoteContent: FC<NoteContentProps> = (props) => {
  const { title, note } = props;
  return (
    <>
      <NoteTitle>{title ?? 'Note'}</NoteTitle>
      <NoteContainer>
        <Note>{note}</Note>
      </NoteContainer>
    </>
  );
};
