import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Clients } from '../../../constants/clients';
import {
  getClinicalNoteIdQuery,
  GetClinicalNoteIdReturn,
  GetClinicalNoteIdVariables,
} from '../../../services/graphql/getClinicalNoteId';

export const useClinicalNoteData = (
  encounterId: string | null,
  client: string | null
) => {
  const [getClinicalNoteData, { data, error, loading }] = useLazyQuery<
    GetClinicalNoteIdReturn,
    GetClinicalNoteIdVariables
  >(getClinicalNoteIdQuery);

  useEffect(() => {
    if (encounterId && client === Clients.CMG) {
      getClinicalNoteData({
        variables: {
          client,
          id: encounterId,
        },
      });
    }
  }, [encounterId, client, getClinicalNoteData]);

  return {
    clinicalNoteData: data?.getClinicalNote ?? null,
    loading,
    error,
  };
};
