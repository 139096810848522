import { Button } from '../../../components/AkidoUI/Button';
import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as NotepadPen } from '../../../assets/icons/notepad-pen.svg';
import { TestID } from '../../../constants/testIds';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const SignContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  padding: 32px 28px;
  width: 50%;
`;

const Title = styled.span`
  font-size: 0.775rem;
  font-weight: 600;
  margin-left: 12px;
  display: flex;
  flex: 2;
`;

interface PreviewSignCardProps {
  onSign?: () => Promise<void> | void;
}

export const PreviewSignCard: FC<PreviewSignCardProps> = (props) => {
  const { onSign } = props;
  return (
    <Container data-testid={TestID.Sign.SignContainer}>
      <SignContainer>
        <NotepadPen style={{ paddingTop: '.19rem' }} />
        <Title>Review the document preview before signing</Title>
        <Button
          testId={TestID.Sign.SignButton}
          onClick={() => onSign?.()}
          label='Sign'
          styles={{
            backgroundColor: '#0078D8',
            textTransform: 'none',
            borderColor: '#0078D8',
            width: '100%',
            display: 'flex',
            flex: 1,
          }}
          labelStyles={{
            textTransform: 'none',
            fontWeight: 'bold',
            fontSize: '0.775rem',
          }}
        />
      </SignContainer>
    </Container>
  );
};
