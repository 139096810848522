import { OktaAuth } from '@okta/okta-auth-js';
import { getUserRole } from '../utilities/analytics';
import { get } from 'lodash';

export const oktaAuth = new OktaAuth({
  issuer: 'https://akido-cdp.okta.com/oauth2/default',
  clientId: '0oa1jqpz2yFcT45As4h7',
  scopes: ['openid', 'profile', 'offline_access'],
  redirectUri: `${window.location.origin}/login/callback`,
  postLogoutRedirectUri: `${window.location.origin}/login`,
});

export interface UserInfo {
  specialty?: string;
  userId?: string;
  token?: string;
  role?: ReturnType<typeof getUserRole>;
  groups?: string[];
  email?: string;
  familyName?: string;
  givenName?: string;
}

// method that exports user info, specialty and role from okta
export const getUserInfo: () => Promise<UserInfo> = async () => {
  const user = await oktaAuth.getUser();
  const token = (await oktaAuth.getAccessToken()) ?? '';
  const sessionData = oktaAuth.token.decode(token);
  const email = get(sessionData, 'payload.sub');
  const groups = get(sessionData, 'payload.groups', []);
  const role = getUserRole(groups);
  const specialty = get(sessionData, 'payload.specialty', '');

  const returnData: UserInfo = {
    userId: user.sub,
    specialty,
    role,
    groups,
    email,
    familyName: user.family_name,
    givenName: user.given_name,
  };
  return returnData;
};
