import { useEffect } from 'react';
import {
  dragonSettingsStore,
  DRAGON_STATE,
} from '../../../stores/dragonSettings.store';
import { reInitSpeechToText } from '../../../components/SpeechToText/DragonSpeechToText';

interface IProps {
  valueToWatch?: any;
}

/*
 This hook is used to reload the Dragon UI in the case the UI has been
 changed, e.g. the template has been switched
*/

export const useReloadDragonVui = ({ valueToWatch }: IProps) => {
  useEffect(() => {
    if (
      valueToWatch &&
      dragonSettingsStore.state !== DRAGON_STATE.NOT_INITIALIZED
    ) {
      reInitSpeechToText();
    }
  }, [valueToWatch]);
};
