import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { useClinicalNoteQuery } from './useClinicalNoteQuery';
import { clinicalNoteTemplatesStore } from '../../../stores/clinicalNoteTemplates.store';
import { useEncounterWithPatient } from '../../../hooks/useEncounterWithPatient';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useLoadClinicalNote } from './useLoadClinicalNote';
import { ClinicalNoteStatus } from '../../../types/clinical-notes';
import { clinicalEditorPageState } from '../../../stores/clinicalEditorPageState.store';
import { keyBy } from 'lodash';
import { patientStore } from '../../../stores/patient.store';
import { useReloadDragonVui } from './useReloadDragonVui';
import { usePatientClinicalData } from './usePatientClinicalData';
import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';

export const useNoteEditorFromAkidoNotePageLoad = () => {
  const ldClient = useLDClient();
  const { id: akidoNoteId } = useParams();

  const currentTemplateId = clinicalNoteStore.templateId;
  const clinicalNoteTitle = clinicalNoteStore.editableNoteTitle;
  const encounterId = clinicalNoteStore.clinicalNote?.encounterId ?? null;

  const isClinicalNoteLoaded = clinicalNoteStore.isLoaded;
  const noteTemplateIsLoading = clinicalNoteTemplatesStore.areTemplatesLoading;
  const hasLoadedTemplates = clinicalNoteTemplatesStore.hasLoadedTemplates;
  const clinicalNoteTemplates = clinicalNoteTemplatesStore.templates;

  const isAutoSaving = clinicalEditorPageState.isAutoSaving;

  const localStoreEnabled = ldClient?.variation(
    FEATURE_FLAGS.LOCAL_STORE_CLINICAL_NOTES,
    false
  );

  useReloadDragonVui({ valueToWatch: currentTemplateId });

  const [clinicalNote, setClinicalNote] = useState<
    ClinicalNoteModel | undefined
  >(undefined);
  const [hasLoadLocal, setHasLoadLocal] = useState(false);

  const {
    isClinicalNoteQueryLoading,
    clinicalNoteReturn,
    fetchClinicalNoteError,
    hasLoadedClinicalNoteReturn,
  } = useClinicalNoteQuery({ akidoNoteId });

  const { isPatientLoading, patientId } = useEncounterWithPatient(encounterId);
  const {
    isClinicalDataLoading,
    isClinicalDataLoaded,
    autofillForTextSections,
  } = usePatientClinicalData(patientId, encounterId);

  const isDataForEditorLoading = useMemo(() => {
    const isFetching =
      isClinicalNoteQueryLoading ||
      isClinicalDataLoading ||
      noteTemplateIsLoading ||
      isPatientLoading;
    return isFetching && !isClinicalNoteLoaded;
  }, [
    isClinicalNoteQueryLoading,
    isClinicalDataLoading,
    noteTemplateIsLoading,
    isPatientLoading,
    isClinicalNoteLoaded,
  ]);

  const { loadClinicalNote } = useLoadClinicalNote({
    localStoreEnabled: localStoreEnabled,
    currentId: akidoNoteId ?? null,
    setClinicalNote,
    clinicalNoteReturn,
  });

  useEffect(() => {
    if (akidoNoteId) {
      clinicalNoteStore.setId(akidoNoteId);
    }
  }, [akidoNoteId]);

  useEffect(() => {
    // Loads clinical note after templates have been retrieved.
    if (
      clinicalNoteReturn &&
      hasLoadedTemplates &&
      hasLoadedClinicalNoteReturn &&
      !hasLoadLocal
    ) {
      loadClinicalNote();
      clinicalEditorPageState.setPreviousTitle(
        clinicalNoteStore.editableNoteTitle
      );
      setHasLoadLocal(true);
    }
  }, [
    hasLoadedClinicalNoteReturn,
    hasLoadedTemplates,
    hasLoadLocal,
    clinicalNoteReturn,
    loadClinicalNote,
  ]);

  useEffect(() => {
    // TODO: move this logic to the clinical notes store and add proper tests
    // Loads default values of the clinical note
    /* istanbul ignore next */
    if (!isClinicalNoteLoaded && hasLoadLocal && isClinicalDataLoaded) {
      const hashedTemplates = keyBy(clinicalNoteTemplates, 'id');
      const sections = hashedTemplates[currentTemplateId]?.sections;

      if (Array.isArray(sections) && akidoNoteId) {
        clinicalNoteStore.setAutofillTextValues(autofillForTextSections);
        clinicalNoteStore.setFieldsAndDefaultValues(akidoNoteId, sections);
      }
    }
  }, [
    clinicalNoteTemplates,
    currentTemplateId,
    isClinicalNoteLoaded,
    hasLoadLocal,
    isClinicalDataLoaded,
    autofillForTextSections,
    akidoNoteId,
  ]);

  const isNoteSigned = useMemo(() => {
    return (akidoNoteId &&
      clinicalNote?.status === ClinicalNoteStatus.Signed &&
      !isAutoSaving) as boolean;
  }, [akidoNoteId, clinicalNote?.status, isAutoSaving]);

  useEffect(() => {
    // Loads note data into stores
    if (clinicalNote) {
      clinicalNoteStore.setClinicalNote(clinicalNote);
      if (!isNoteSigned) {
        clinicalNoteStore.setSectionsFromClinicalNoteReturn(
          clinicalNote.content.sections
        );
        if (clinicalNote.content.title) {
          clinicalEditorPageState.setPreviousTitle(clinicalNoteTitle);
          clinicalNoteStore.setEditableNoteTitle(clinicalNote.content.title);
        }
        if (clinicalNote.patient) {
          patientStore.setPatientData(clinicalNote.patient);
        }
        if (clinicalNote.encounter?.dateOfEncounter) {
          clinicalNoteStore.setDateOfService(
            clinicalNote.encounter.dateOfEncounter
          );
        }
      }
      clinicalEditorPageState.setIsAutoSaving(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalNote, isNoteSigned]);

  return {
    clinicalNote,
    akidoNoteId,
    clinicalNoteReturn,
    fetchClinicalNoteError,
    isDataForEditorLoading,
    isPatientLoading,
    hasLoadLocal,
    isNoteSigned,
  };
};
