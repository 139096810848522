import { FC } from 'react';
import { TestID } from '../../../constants/testIds';

export interface CircleErrorIconProps {
  className?: string;
}

export const CircleErrorIcon: FC<CircleErrorIconProps> = (props) => {
  return (
    <svg
      aria-label='Error Icon'
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid={TestID.AkidoUI.Icon.CircleErrorIcon}
      {...props}
    >
      <path
        d='M12.9999 8.33331V13M12.9999 17.6666H13.0116M24.6666 13C24.6666 19.4433 19.4432 24.6666 12.9999 24.6666C6.5566 24.6666 1.33325 19.4433 1.33325 13C1.33325 6.55666 6.5566 1.33331 12.9999 1.33331C19.4432 1.33331 24.6666 6.55666 24.6666 13Z'
        stroke='#D92D20'
        strokeWidth='2.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
