import React from 'react';
import { CSSObject } from 'styled-components';
import { TableFooter } from './TableFooter';

const PAGES_TO_DISPLAY = 7;
const ELLIPSIS = '.......';

export const defaultFooterStyle: CSSObject = {
  textAlign: 'right',
  padding: '33px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
};

export const defaultPageButtonStyle: CSSObject = {
  borderRadius: '5px',
  width: '18px',
  height: '21px',
  margin: '0px 5px',
  textAlign: 'center',
  cursor: 'pointer',
  fontSize: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ellipsisPageStyle: CSSObject = {
  color: '#0F2136',
  width: '20px',
  height: '18px',
  fontSize: '10px',
  display: 'flex',
  alignItems: 'flex-end',
};

export const selectedPageButtonStyle: CSSObject = {
  ...defaultPageButtonStyle,
  backgroundColor: '#1C395B',
  color: '#FFFFFF',
};

export const unselectedPageButtonStyle: CSSObject = {
  ...defaultPageButtonStyle,
  backgroundColor: '#C9D2DC',
  color: '#0F2136',
};

/**
 * Calculate how we want to display the pages
 * @param pageSize
 * @param totalCount
 *
 * we will display at most 7 consecutive pages/elements
 * then we will shrink by leaving the last or firstPage
 * the first 5 and everything in between will be "..."
 * e.g.
 * [1] [2] [3] [4] [5] [6] [7]
 * [1] [2] [3] [4] [5] ... [10]
 * [1] ... [4] [5] [6] ... [10]
 * [1] ... [6] [7] [8] [9] [10]
 */
export const convertPagesToDisplay = (
  pageSize: number,
  totalCount: number,
  selectedPage: number
) => {
  const pages = Math.ceil(totalCount / pageSize);
  let pagesToDisplay;
  // We will need to show ellipsis either second, second to last or both
  if (pages > PAGES_TO_DISPLAY) {
    const pagesPlaceholder = Array(PAGES_TO_DISPLAY).fill(null);
    pagesToDisplay = pagesPlaceholder.map((e, i) => {
      const SHOW_ELLIPSIS_END = selectedPage < pages - 3;
      const SHOW_ELLIPSIS_START = selectedPage > 4;

      if (SHOW_ELLIPSIS_END && i === pagesPlaceholder.length - 2)
        return ELLIPSIS;
      if (SHOW_ELLIPSIS_START && i === 1) return ELLIPSIS;

      // Last item will always be the last page number
      if (i === pagesPlaceholder.length - 1) return pages;
      // First item will always be 1
      if (i === 0) return 1;

      // if both ellipsis are showing we should show the selectedPage in the middle
      // Logic for -> [1] ... [4] [5] [6] ... [10]
      if (SHOW_ELLIPSIS_END && SHOW_ELLIPSIS_START) return selectedPage + i - 3;
      // Logic for -> [1] ... [6] [7] [8] [9] [10]
      if (SHOW_ELLIPSIS_START) return pages + i - 6;

      // default for -> [1] [2] [3] [4] [5] ... [10]
      return i + 1;
    });
  } else {
    const pagesPlaceholder = Array(pages).fill(null);
    pagesToDisplay = pagesPlaceholder.map((e, i) => i + 1);
  }

  return pagesToDisplay;
};

export const renderPages = ({
  onPageClick,
  pageSize,
  totalCount,
  selectedPage,
}: {
  pageSize: number;
  totalCount: number;
  selectedPage: number;
  onPageClick?: (page: number) => void;
}) =>
  convertPagesToDisplay(pageSize, totalCount, selectedPage).map((page, idx) => {
    if (page === ELLIPSIS)
      return (
        <span key='pgellipsis' style={ellipsisPageStyle}>
          {page}
        </span>
      );

    if (page === selectedPage) {
      return (
        <span
          key={`pg${page}`}
          tabIndex={idx}
          role='button'
          onKeyDown={() => onPageClick?.(page)}
          onClick={() => onPageClick?.(page)}
          style={selectedPageButtonStyle}
        >
          {page}
        </span>
      );
    }

    return (
      <span
        key={`pg${page}`}
        tabIndex={idx}
        role='button'
        onKeyDown={() => onPageClick?.(page)}
        onClick={() => onPageClick?.(page)}
        style={unselectedPageButtonStyle}
      >
        {page}
      </span>
    );
  });

export type TablePaginationProps = {
  pageSize: number;
  totalCount: number;
  onPageClick?: (page: number) => void;
  selectedPage: number;
  columnCount: number;
};

export const TablePagination = ({
  pageSize,
  totalCount,
  onPageClick,
  selectedPage = 1,
  columnCount,
}: TablePaginationProps) => (
  <TableFooter>
    <tr>
      <td colSpan={columnCount}>
        <div style={defaultFooterStyle}>
          {renderPages({ pageSize, totalCount, selectedPage, onPageClick })}
        </div>
      </td>
    </tr>
  </TableFooter>
);
