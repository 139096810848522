import { PastMedicalHistoryItem } from '../types/patient';
import { createClinicalNoteListItemsBlock } from './formatting';

export class PastHistoryPatientFieldParsing {
  static parseGraphqlHistoryListToString(
    historyList: PastMedicalHistoryItem[] | undefined
  ): string {
    if (Array.isArray(historyList)) {
      const list = historyList?.map((item) => item.displayLabel.toString());
      return createClinicalNoteListItemsBlock(list);
    }

    return '';
  }
}
