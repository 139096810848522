import { observer } from 'mobx-react-lite';
import { transcriptionDataStore } from '../../stores/transcriptionData.store';
import { Select } from './Select';
import { SelectOption } from './Select/types';

interface PhysicianSelectProps {
  onChange?: (select: SelectOption, action: string) => void;
  selectedOption?: string;
}

export const PhysicianSelect = observer((props: PhysicianSelectProps) => {
  const { onChange, selectedOption } = props;

  const practitioners = transcriptionDataStore.filterablePractitioner.map(
    (practitioner) => ({
      value: practitioner.id,
      label: practitioner.name,
    })
  );

  return (
    <Select
      label='Physician'
      name='physician-filter-select'
      placeholder='Select Physician'
      options={practitioners}
      styles={{
        container: {
          marginBottom: 24,
        },
      }}
      onChange={onChange}
      selectedOption={selectedOption}
    />
  );
});
