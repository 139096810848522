import React, { useCallback, useMemo, useState } from 'react';
import { isUndefined } from 'lodash';
import { TestID } from '../../constants/testIds';
import clsx from 'clsx';

export type DropdownElement = {
  value: any;
  label: string;
  backgroundColor?: string;
  textColor?: string;
};

export type DropdownProps = {
  /** Selected/Starting value for the dropdown (defaults to first element) */
  selectedDropdownItem: DropdownElement;
  options: DropdownElement[];
  onChange?: (selection: any) => void;
  /** Raw style object for the container element */
  dropdownContainerStyles?: any;
  /** Raw style object for the list element */
  listStyles?: any;
  onClick?: (isOpen: boolean) => void;
  onClose?: () => void;
  isOpen?: boolean;
  HeaderComponent?: React.FC<{ label: string; isOpen?: boolean }>;
  classNames?: {
    container?: string;
    header?: string;
    list?: string;
  };
};

export const DEFAULT_DROPDOWN_ELEMENT: DropdownElement = {
  label: '',
  value: '',
};

export function CustomDropdown({
  options,
  onChange,
  onClose,
  onClick,
  isOpen,
  dropdownContainerStyles,
  listStyles,
  HeaderComponent,
  selectedDropdownItem,
  classNames,
}: DropdownProps) {
  const [internalIsOpen, setIsOpen] = useState(isOpen);

  const toggleMenu = useCallback(() => {
    if (!isUndefined(isOpen)) {
      onClick?.(isOpen);
    } else {
      setIsOpen((prev) => !prev);
    }
  }, [isOpen, onClick]);

  const closeMenu: React.FocusEventHandler<HTMLButtonElement> = () => {
    if (!isUndefined(isOpen)) {
      onClose?.();
      onClick?.(false);
    } else {
      setIsOpen(false);
    }
  };

  const shouldShowList = useMemo(() => {
    if (!isUndefined(isOpen)) {
      return isOpen;
    }

    return internalIsOpen;
  }, [isOpen, internalIsOpen]);

  const onOptionClicked = (option: DropdownElement) => {
    onChange?.(option);
  };

  return (
    <div
      data-public
      style={dropdownContainerStyles}
      className={clsx(
        'relative flex flex-col items-start',
        classNames?.container
      )}
    >
      <button
        data-public
        tabIndex={0}
        onClick={toggleMenu}
        onBlur={closeMenu}
        type='button'
        className={clsx(
          'm-0 flex w-full flex-1 cursor-pointer rounded p-0 text-center text-sm',
          classNames?.header
        )}
        style={{
          backgroundColor: selectedDropdownItem?.backgroundColor,
          color: selectedDropdownItem?.textColor,
        }}
        data-testid={TestID.ClinicalNote.TemplateDropdownHeader}
      >
        {HeaderComponent ? (
          <HeaderComponent
            data-public
            label={selectedDropdownItem?.label}
            isOpen={internalIsOpen}
          />
        ) : (
          selectedDropdownItem?.label
        )}
      </button>
      {shouldShowList && (
        <ul
          data-public
          className={clsx(
            'absolute inset-x-0 z-[100] mx-auto bg-white p-0',
            classNames?.list
          )}
          style={listStyles}
        >
          {options.map((option: DropdownElement, index) => (
            <li
              data-public
              className='mt-1.5 cursor-pointer list-none rounded p-2.5 text-left text-sm font-semibold'
              onMouseDown={() => {
                onOptionClicked(option);
              }}
              key={`option_${option.value}`}
              style={{
                backgroundColor: option.backgroundColor,
                color: option.textColor,
              }}
              data-testid={`dropdown_option_${index}`}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
