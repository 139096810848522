import { usePatientSearch } from '../../hooks/usePatientSearch';
import { debounce, sortBy, capitalize } from 'lodash';
import { CustomFormatOptionLabel, SelectOption } from './Select/types';
import { SelectAsync } from './Select/SelectAsync';
import { PatientModel } from '@akido/provider-desktop-bffe-types';

const moreResultsString = 'Please refine your search to see better results';

const formatLabel: CustomFormatOptionLabel = (data) => {
  const option = data as SelectOption<PatientModel>;
  return (
    <div>
      <span data-private className='mr-2 font-medium text-gray-900'>
        {option.label.split('#')[0]}
      </span>
      <span data-private className='text-gray-700'>
        #{option.label.split('#').slice(1)}
      </span>
    </div>
  );
};

interface PatientSelectProps {
  onChange?: (select: SelectOption<PatientModel>, action: string) => void;
  selectedOption?: string;
}

export const PatientSelect = (props: PatientSelectProps) => {
  const { onChange } = props;
  const { getPatientList } = usePatientSearch();

  const fetchPatients = (query: any, callback: any) => {
    getPatientList(query).then((result) => {
      if (!result) {
        throw new Error('No data returned from API');
      }

      const results = sortBy(result?.data?.searchPatients?.patients, [
        'familyName',
        'givenName',
      ]).map((patient) => ({
        value: patient,
        label: `${capitalize(patient?.familyName)}, ${capitalize(
          patient?.givenName
        )} #${patient?.accountNumber?.value} | ${patient?.birthDate}`,
      }));

      if (result?.data?.searchPatients?.hasMoreResults) {
        const groupedResults = [
          {
            label: moreResultsString,
            options: results,
          },
        ];
        callback(groupedResults);
        return;
      }

      callback(results);
    });
  };

  const loadOptions = debounce(fetchPatients, 800);

  return (
    <SelectAsync
      data-private
      id={'patient-select'}
      name='patient-filter-select'
      placeholder='Select Patient'
      onChange={(option: SelectOption<PatientModel>, action) => {
        if (option?.label !== moreResultsString) {
          onChange && onChange(option, action);
        }
      }}
      loadOptions={loadOptions}
      customFormatOptionLabel={formatLabel}
    />
  );
};
