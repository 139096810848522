import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  RefObject,
  useCallback,
} from 'react';
import { useTextMarkers } from './hooks/useTextMarkers';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import styled from 'styled-components';

export interface NavigableTextareaProps extends TextareaAutosizeProps {
  value: string;
  navigationKey?: string;
  'data-testid'?: string;
  token?: string;
  textAreaRef?: RefObject<HTMLTextAreaElement>;
  styles?: {
    textarea?: TextareaAutosizeProps['style'];
  };
}

const TextArea = styled(TextareaAutosize)`
  color: #8aa2b5;
  resize: none;
  font-size: 0.775rem;
  background-color: transparent;
  border: none;
  background-position: top 5px right -15px;
  outline-color: transparent;

  &:not(:placeholder-shown) {
    color: #0c1e2c;
  }
`;

export const NavigableTextarea: FC<NavigableTextareaProps> = (props) => {
  const {
    id,
    value,
    onChange,
    styles,
    token = '??',
    textAreaRef,
    navigationKey = 'Tab',
    placeholder,
    onFocus,
    className,
    maxRows,
    minRows,
    ...textareaProps
  } = props;

  const _onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => {
      onChange?.(e);
    },
    [onChange]
  );

  const { onKeyDown, onFocus: selectTextOnFocus } = useTextMarkers({
    value,
    token,
    navigationKey,
    textArea: textAreaRef?.current,
  });

  const _onFocus = useCallback<FocusEventHandler<HTMLTextAreaElement>>(
    (e) => {
      onFocus?.(e);
      selectTextOnFocus();
    },
    [selectTextOnFocus, onFocus]
  );

  return (
    <TextArea
      {...textareaProps}
      id={id}
      maxRows={maxRows}
      minRows={minRows}
      className={className}
      placeholder={placeholder}
      data-testid={props['data-testid']}
      ref={textAreaRef}
      style={styles?.textarea}
      value={value}
      onChange={_onChange}
      onKeyDown={onKeyDown}
      onFocus={_onFocus}
    />
  );
};
