import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  ClinicalNoteEditorData,
  GetClinicalNoteEditorVariables,
} from '../../../services/graphql/clinicalNote';
import { AkidoIdentifier } from '@akido/provider-desktop-bffe-types';
import {
  ClinicalNoteTypes,
  EncounterTypes,
} from '../../../services/graphql/commonTypes';
import { useCallback } from 'react';
import { clinicalNoteTemplatesStore } from '../../../stores/clinicalNoteTemplates.store';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { clinicalEditorPageState } from '../../../stores/clinicalEditorPageState.store';
import { patientStore } from '../../../stores/patient.store';
import { setAutofillText } from '../../../utilities/autofillHelper';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { ClinicalNoteEditor } from '../../../types/clinical-notes';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { keyBy } from 'lodash';

export const useClinicalNoteEditor = (
  screen: string,
  specialty: string,
  identifier: AkidoIdentifier,
  tenantId: string
) => {
  const ldClient = useLDClient();
  const [getClinicalNote, { data, error, loading }] = useLazyQuery<
    ClinicalNoteEditor,
    GetClinicalNoteEditorVariables
  >(ClinicalNoteEditorData);

  const getClinicalNoteEditorData = useCallback(async () => {
    let variables: GetClinicalNoteEditorVariables = {
      screen,
      specialty,
      tenantId,
    };

    const shouldUseResourceIdentifier = ldClient?.variation(
      FEATURE_FLAGS.RESOURCE_ID_PARAM,
      false
    );

    if (shouldUseResourceIdentifier) {
      variables = {
        ...variables,
        resourceIdentifier: identifier,
      };
    } else {
      variables = {
        ...variables,
        clinicalNoteId:
          identifier?.type === ClinicalNoteTypes.AKIDO_CLINICAL_NOTE_ID
            ? identifier.value
            : undefined,
        externalEncounterId:
          identifier?.type === EncounterTypes.CAPELLA_ENCOUNTER_ID
            ? identifier.value
            : undefined,
      };
    }

    const { data, error } = await getClinicalNote({
      variables,
    });

    if (!data?.clinicalNoteTemplates?.templates.length) {
      const templateError = new ApolloError({
        errorMessage: 'Templates are empty',
      });
      clinicalNoteTemplatesStore.setTemplateErrors(templateError);
    }
    clinicalNoteTemplatesStore.setTemplates(
      data?.clinicalNoteTemplates.templates
    );

    if (data?.clinicalNote) {
      // Update clinical Note Store
      clinicalNoteStore.setClinicalNote(data?.clinicalNote);
      clinicalNoteStore.setSectionsFromClinicalNoteReturn(
        data?.clinicalNote.content.sections
      );
      clinicalNoteStore.setEditableNoteTitle(data.clinicalNote.content.title);

      // Update ClinicalNoteEditor Page state
      clinicalEditorPageState.setPreviousTitle(data.clinicalNote.content.title);

      if (data?.clinicalNote?.patient) {
        patientStore.setPatientData(data?.clinicalNote.patient);
      }

      if (data?.clinicalNote?.encounter?.dateOfEncounter) {
        clinicalNoteStore.setDateOfService(
          data?.clinicalNote?.encounter?.dateOfEncounter
        );
      }

      // Update Autofill text stores
      const autofillTexts = setAutofillText(
        error,
        data,
        ldClient,
        clinicalNoteRefreshableAutofillStore
      );

      clinicalNoteStore.setAutofillTextValues(autofillTexts);

      if (data.clinicalNoteTemplates && clinicalNoteStore.templateId) {
        const hashedTemplates = keyBy(
          data?.clinicalNoteTemplates.templates,
          'id'
        );

        const sections =
          hashedTemplates[clinicalNoteStore.templateId]?.sections;

        clinicalNoteStore.setFieldsAndDefaultValues(
          data?.clinicalNote?.id,
          sections
        );
      }

      if (data?.clinicalNote?.encounter?.referringPractitioner) {
        clinicalNoteStore.setReferringPractitioner(
          data.clinicalNote.encounter.referringPractitioner
        );
      }
    }
  }, [screen, specialty, identifier, tenantId, getClinicalNote, ldClient]);

  return {
    clinicalNoteData: data,
    loading,
    error,
    getClinicalNoteEditorData,
  };
};
