import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import {
  getEncounterWithPatientQuery,
  GetEncounterByExternalIdReturn,
  GetEncounterByExternalIdParams,
} from '../services/graphql/getEncounterWithPatient';
import { clinicalNoteStore } from '../stores/clinicalNote.store';
import { patientStore } from '../stores/patient.store';
import { isStringWithLength } from '../utilities/helpers';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../constants/routes';
import { ErrorCodes } from '../routes/ErrorPage/ErrorPage';

export const useEncounterWithPatient = (externalId: string | null) => {
  const { data, loading, error } = useQuery<
    GetEncounterByExternalIdReturn,
    GetEncounterByExternalIdParams
  >(getEncounterWithPatientQuery, {
    variables: { externalId },
    fetchPolicy: 'network-only',
    skip: !isStringWithLength(externalId),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!data?.getFhirEncounter && error) {
      navigate(`${RoutePaths.Error}?code=${ErrorCodes.ENCOUNTER_NOT_FOUND}`);
    }

    if (externalId && data?.getFhirEncounter?.patient) {
      patientStore.setPatientData(data?.getFhirEncounter?.patient);
    }

    if (data?.getFhirEncounter?.referringPractitioner) {
      clinicalNoteStore.setReferringPractitioner(
        data.getFhirEncounter.referringPractitioner
      );
    }

    if (externalId && data?.getFhirEncounter?.dateOfEncounter) {
      clinicalNoteStore.setDateOfService(
        data?.getFhirEncounter?.dateOfEncounter
      );
    }

    if (
      externalId &&
      data?.getFhirEncounter?.previousResourceIds?.akidoClinicalNoteId
    ) {
      clinicalNoteStore.setPreviousAkidoClinicalNoteId(
        data?.getFhirEncounter?.previousResourceIds?.akidoClinicalNoteId
      );
    }

    if (
      externalId &&
      data?.getFhirEncounter?.previousResourceIds?.encounterFhirId
    ) {
      clinicalNoteStore.setPreviousEncounterFhirId(
        data?.getFhirEncounter?.previousResourceIds?.encounterFhirId
      );
    }
  }, [data, error, externalId, navigate]);

  return {
    isPatientLoading: loading,
    patientId: data?.getFhirEncounter?.patient?.patientId,
    error,
  };
};
