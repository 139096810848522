import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { CreateTranscriptionScribeNote } from '../../../services/graphql/createTranscriptionScribeNote';
import { scribeNoteModalStore } from '../../../stores/scribeNoteModal.store';
import { EncounterAnalyticsEventName } from '../../../constants/analytics';
import { ScribeNoteCreationDtoType } from '../../../types/transcriptions';
import { Modal } from '../../../components/AkidoUI/Modal';
import { CreateScribeNoteModalContent } from './CreateScribeNoteModalContent';
import { CreateScribeNoteModalHeader } from './CreateScribeNoteModalHeader';
import { CreateScribeNoteModalSave } from './CreateScribeNoteModalSave';
import { ScribeNoteStyles } from './scribeNote.styles';
import { ScribeNoteModel } from '@akido/provider-desktop-bffe-types';

interface ScribeNoteModalProps {}

const Container = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
`;

export const CreateScribeNoteModal: FC<ScribeNoteModalProps> = observer(() => {
  const { sendEncounterAnalyticsEvent } = useAnalytics();
  const [createTranscriptionScribeNote] = useMutation<
    ScribeNoteModel,
    ScribeNoteCreationDtoType
  >(CreateTranscriptionScribeNote);

  const createScribeNote = useCallback(async () => {
    const transcriptionId = scribeNoteModalStore.transcriptionId;
    const note = scribeNoteModalStore.noteValue;
    const dateAdded = scribeNoteModalStore.noteProps?.dateAdded;
    const encounterId = scribeNoteModalStore.encounterId;

    if (transcriptionId && note && dateAdded) {
      await createTranscriptionScribeNote({
        variables: {
          transcriptionId,
          note,
          dateAdded,
        },
      });

      sendEncounterAnalyticsEvent(
        EncounterAnalyticsEventName.CreateScribeNote,
        {
          transcription_id: transcriptionId,
          akido_eid: encounterId,
        }
      );
      scribeNoteModalStore.onModalClose();
    }
  }, [createTranscriptionScribeNote, sendEncounterAnalyticsEvent]);

  return (
    <Modal
      contentStyles={ScribeNoteStyles.modalContentStyles}
      isVisible={scribeNoteModalStore.isVisible}
      onClose={() => scribeNoteModalStore.hideModal()}
    >
      <Container data-testid='scribeNoteModalContainer'>
        <CreateScribeNoteModalHeader />
        <CreateScribeNoteModalContent />
        <CreateScribeNoteModalSave saveCallback={createScribeNote} />
      </Container>
    </Modal>
  );
});
