import { sortBy } from 'lodash';
import { ClinicalNoteTemplatesStore } from '../stores/clinicalNoteTemplates.store';
import {
  CategoryVariant,
  ClinicalNoteFieldValue,
} from '../types/clinical-notes';

export class ClinicalNotePreviewSectionsParsing {
  static createPreviewSectionsObject(
    clinicalNoteSections: Record<string, ClinicalNoteFieldValue>,
    clinicalNoteTemplatesStore: ClinicalNoteTemplatesStore,
    templateId: string
  ): Record<string, ClinicalNoteFieldValue[]> {
    let sections: Record<string, ClinicalNoteFieldValue[]> = {};
    sections[CategoryVariant.assessmentAndPlan] = [];
    sections[CategoryVariant.encounter] = [];
    sections[CategoryVariant.history] = [];

    let sectionItems = [];
    for (const sectionId in clinicalNoteSections) {
      const section = clinicalNoteSections[sectionId];
      const sectionTitle = section?.title;
      const sectionText = section?.text;
      const sectionAutofill = section?.autofillData;
      if (sectionTitle && (sectionText || sectionAutofill)) {
        sectionItems.push(section);
      }
    }

    // TODO: the sortOrder is already saved within the clinical note
    // this needs to be removed
    if (sectionItems[0]?.sortOrder === undefined) {
      sectionItems = clinicalNoteTemplatesStore.sortFieldsPerTemplate(
        templateId,
        sectionItems
      );
    }

    for (const item of sectionItems) {
      switch (item.category) {
        case CategoryVariant.assessmentAndPlan:
          sections[CategoryVariant.assessmentAndPlan].push(item);
          break;
        case CategoryVariant.history:
          sections[CategoryVariant.history].push(item);
          break;
        case CategoryVariant.encounter:
          sections[CategoryVariant.encounter].push(item);
          break;
      }
    }

    sections[CategoryVariant.assessmentAndPlan] = sortBy(
      sections[CategoryVariant.assessmentAndPlan],
      ['sortOrder']
    );
    sections[CategoryVariant.history] = sortBy(
      sections[CategoryVariant.history],
      ['sortOrder']
    );
    sections[CategoryVariant.encounter] = sortBy(
      sections[CategoryVariant.encounter],
      ['sortOrder']
    );

    return sections;
  }
}
