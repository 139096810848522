import { CustomNUSAEvents } from '../utilities/events';
import { useEffect } from 'react';

/**
 * This hook is used to watch for when NUSA has finished processing an element.
 * This is used to trigger a custom event that can be listened to by other components.
 */
export default function useNUSAProcessingWatcher() {
  useEffect(() => {
    window.NUSA_onProcessingFinishedForElement = (element) => {
      const changeEvent = new Event(CustomNUSAEvents.FINISHED_PROCESSING, {
        bubbles: true,
      });
      element.dispatchEvent(changeEvent);
    };
  }, []);
}
