import styled from 'styled-components';
import { CategoryVariant } from '../../../types/clinical-notes';
import { SectionFields } from './SectionFields';
import { patientStore } from '../../../stores/patient.store';

export const RightSideDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  backgroundColor: '#F6F8FA',
  overflowY: 'scroll',
  border: '1px solid #D0D7E4',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  borderBottom: 'none',
  width: '24.778%',
});

interface RightSectionProps {
  noteId: string;
  handleHasBeenModified: () => void;
}

export const RightSection = ({
  noteId,
  handleHasBeenModified,
}: RightSectionProps) => {
  const patientId = patientStore.id;

  return (
    <RightSideDiv>
      <SectionFields
        patientId={patientId}
        noteId={noteId}
        categoryToDisplay={CategoryVariant.assessmentAndPlan}
        updateCallback={handleHasBeenModified}
        showAutofillCard
      />
    </RightSideDiv>
  );
};
