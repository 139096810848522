/* istanbul ignore file */
import * as React from 'react';

interface IProps {
  fillColor: string;
  svgStyle?: any;
}

export const ArrowSvg = ({ svgStyle = {}, fillColor }: IProps) => (
  <svg
    width='0.9375rem'
    height='0.5rem'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={svgStyle}
  >
    <path
      d='M11.166 7.148 6.543 2.73 1.921 7.148.5 5.788 6.543 0l6.043 5.788-1.42 1.36Z'
      fill={fillColor}
    />
  </svg>
);

ArrowSvg.defaultProps = {
  svgStyle: {},
};
