import { action, makeObservable, observable } from 'mobx';

export type Status = {
  label: string;
  id: string;
};

export type FilterablePractitioner = {
  id: string;
  name: string;
};

export class TranscriptionDataStore {
  statuses: Status[] = [];
  filterablePractitioner: FilterablePractitioner[] = [];

  constructor() {
    makeObservable(this, {
      statuses: observable,
      filterablePractitioner: observable,
      setStatuses: action,
      setFilterablePractitioner: action,
    });
  }

  setStatuses(statuses: Status[]) {
    this.statuses = statuses;
  }

  setFilterablePractitioner(filterablePractitioners: FilterablePractitioner[]) {
    this.filterablePractitioner = filterablePractitioners;
  }
}

export const transcriptionDataStore = new TranscriptionDataStore();
