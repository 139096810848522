import { action, computed, makeObservable, observable } from 'mobx';
import { DateRange } from '../types/dateType';

export class TranscriptionFilterStore {
  appointmentDateFilter: DateRange | null = null;
  submissionDateFilter: DateRange | null = null;
  practitionerIds: string[] | null = null;
  statusIds: string[] | null = null;

  constructor() {
    makeObservable(this, {
      statusIds: observable,
      appointmentDateFilter: observable,
      submissionDateFilter: observable,
      practitionerIds: observable,
      setPractitionerIds: action,
      setStatusIds: action,
      setAppointmentDateFilter: action,
      setSubmissionDateFilter: action,
      clear: action,
      isActive: computed,
    });
  }

  clear() {
    this.appointmentDateFilter = null;
    this.submissionDateFilter = null;
    this.practitionerIds = null;
    this.statusIds = null;
  }

  setPractitionerIds(practitionerIds: string[] | null) {
    this.practitionerIds = practitionerIds;
  }

  setStatusIds(statusIds: string[] | null) {
    this.statusIds = statusIds;
  }

  setAppointmentDateFilter(fromDate: Date, toDate: Date) {
    this.appointmentDateFilter = {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };
  }

  setSubmissionDateFilter(fromDate: Date, toDate: Date) {
    if (fromDate && toDate) {
      this.submissionDateFilter = {
        from: fromDate.toISOString(),
        to: toDate.toISOString(),
      };
    }
  }

  get isActive() {
    const hasPractitionerFilter =
      this.practitionerIds && this.practitionerIds.length > 0;
    const hasStatusFilter = this.statusIds && this.statusIds.length > 0;

    const hasSubmissionDate = this.submissionDateFilter !== null;
    const hasAppointmentDate = this.appointmentDateFilter !== null;

    return (
      hasAppointmentDate ||
      hasSubmissionDate ||
      hasStatusFilter ||
      hasPractitionerFilter
    );
  }
}

export const transcriptionFilterStore = new TranscriptionFilterStore();
