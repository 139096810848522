import { observer } from 'mobx-react-lite';
import { useClinicalNoteUpdatedSubscription } from '../../hooks/useClinicalNoteUpdatedSubscription';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';
import useInactivity from '../../hooks/useInactivity';
import { ReactNode, useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../config/launchdarkly';

const COUNTDOWN_START = (30 as number) * 60;

export const ClinicalNoteNewVersionObserver = observer(
  (props: { children: ReactNode }) => {
    const { children } = props;
    useClinicalNoteUpdatedSubscription(
      clinicalNoteStore.clinicalNote?.id ?? '',
      clinicalNoteStore.clinicalNote?.version ?? 1
    );

    const ldClient = useLDClient();
    const inactivityAware = ldClient?.variation(
      FEATURE_FLAGS.CLINICAL_NOTE_UPDATED_VERSION,
      false
    );

    const timeLeft = useInactivity(COUNTDOWN_START);

    useEffect(() => {
      clinicalNoteStore.setModalOpen(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinicalNoteStore.hasNewVersion]);

    if (timeLeft <= 0 && clinicalNoteStore.hasNewVersion && inactivityAware) {
      clinicalNoteStore.setModalOpen(true);
    }
    return <>{children}</>;
  }
);
