import { gql } from '@apollo/client';

export const CreateClinicalNote = gql`
  mutation CreateClinicalNote(
    $encounterId: String!
    $content: ClinicalNoteContentInput!
  ) {
    createClinicalNote(encounterId: $encounterId, content: $content) {
      version
      id
    }
  }
`;
