import { Button } from '../../../components/AkidoUI/Button';
import { FC } from 'react';
import styled from 'styled-components';
import { TestID } from '../../../constants/testIds';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 35px;
`;

const TextButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 0.682rem;
  color: #0078d8;
  font-weight: bold;
  margin-right: 45px;
  cursor: pointer;
`;

const LoadingText = styled.span`
  color: white;
  text-transform: none;
  line-height: 1rem;
  font-size: 0.682rem;
  font-weight: bold;
`;

interface AddAddendumButtonsProps {
  onSignAddendum: (text: string) => Promise<void> | void;
  onCancelSignAddendum: () => Promise<void> | void;
  isSigning: boolean;
}

export const AddAddendumButtons: FC<AddAddendumButtonsProps> = (props) => {
  const { isSigning, onSignAddendum, onCancelSignAddendum } = props;

  return (
    <ButtonRow data-testid={TestID.Addendum.SignAddendumButtons}>
      <TextButton onClick={onCancelSignAddendum}>Cancel</TextButton>
      <Button
        label='Sign Addendum'
        onClick={onSignAddendum}
        styles={{
          borderColor: '#0078D8',
          backgroundColor: '#0078D8',
          minWidth: '180px',
        }}
        labelStyles={{
          textTransform: 'none',
          lineHeight: '0.662rem',
          fontSize: '0.662rem',
          fontWeight: 'bold',
        }}
        disabled={isSigning}
        loading={isSigning}
        loadingComponent={<LoadingText>Signing...</LoadingText>}
      />
    </ButtonRow>
  );
};
