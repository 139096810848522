import { useEffect } from 'react';
import styled from 'styled-components';
import { RefreshButton } from './RefreshButton';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';
import { observer } from 'mobx-react-lite';
import { FEATURE_FLAGS } from '../../config/launchdarkly';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import { isNil } from 'lodash';
import FeatureFlaggedConditionalRender from '../../components/FeatureFlaggedConditionalRender/FeatureFlaggedConditionalRender';
import { ClinicalTextArea } from './sections/ClinicalTextArea';

const blurPlaceholder = 'Start Documenting...';

const AutoFillCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  background-color: white;
  margin: 20px 20px 0 20px;
`;

const AutoFillCardTitleContainer = styled.div`
  display: flex;
  width: 85%;
  margin: 15px auto auto auto;
  justify-content: space-between;
`;

const AutoFillCardTitleButton = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 0.775rem;
  line-height: 20px;
  color: #0078d8;
  background: none;
  border: none;
  white-space: nowrap;
`;

const AutoFillCardTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 0.775rem;
  line-height: 22px;
  color: #0c1e2c;
`;

const AutoFillListContainer = styled.ul`
  display: flex;
  width: 90%;
  margin: 10px auto;
  flex-direction: column;
  font-size: 0.775rem;
  list-style: none;
  padding-left: 10px;
`;

const AutoFillListElement = styled.li`
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 0.775rem;
  color: #333333;
`;

const AutofillBottomSection = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '85%',
  margin: 'auto',
  textAlign: 'left',
});

const AutofillBottomText = styled.div({
  fontWeight: 400,
  marginTop: '7px',
  fontSize: '0.58125rem',
  color: '#8AA2B5',
});

const AutofillErrorText = styled.div({
  color: '#CC1E12',
  fontWeight: 500,
  fontSize: '0.775rem',
});

const TitleActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const RefreshContainer = styled.div`
  display: flex;
  width: fit-content;
  align-self: center;
  margin-right: 7.5%;
`;

const AutoFillDivider = styled.div`
  width: 85%;
  height: 1px;
  margin: 10px auto;
  background-color: #f2f2f2;
`;

const ClinicalNotesAutoFillField = observer(
  ({
    id,
    title = '',
    value = '',
    uiSection = '',
    showTopAction,
    autofillData = [],
    autofillBottomText = '',
    autofillError = false,
    placeholderText = blurPlaceholder,
    onChange,
    refreshCallback = undefined,
    isRefreshLoading = false,
    containerStyles = {},
    minRows,
    maxRows,
  }: {
    id: string;
    title?: string;
    uiSection?: string | null;
    value?: string;
    showTopAction: boolean;
    autofillData?: string[];
    autofillBottomText?: string;
    autofillError?: boolean;
    placeholderText?: string;
    onChange: (newValue: string) => void;
    refreshCallback?: (sectionId: string) => void;
    isRefreshLoading?: boolean;
    containerStyles?: any;
    minRows?: number;
    maxRows?: number;
  }) => {
    const isAutofillDataAvailable =
      Array.isArray(autofillData) && autofillData.length > 0;

    const handleExternalActionButton = () => {
      if (isAutofillDataAvailable) {
        //@debt: Integrate Capella edit
        console.log('edit in capella');
      } else {
        //@debt: Integrate Capella add
        console.log('add in capella');
      }
    };

    // TODO: looks like we have the autofill data in two stores
    // recheck the flows and remove the store access from this component
    useEffect(() => {
      if (isAutofillDataAvailable) {
        clinicalNoteStore.setAutofillData(id, autofillData);
      }
    }, [isAutofillDataAvailable, autofillData, id]);

    const renderAutoFillLines = (textList?: string[]) => {
      if (!textList) return null;

      return textList.map((field, index) => (
        <AutoFillListElement key={`autofill_list_item_${title}_${index}`}>
          {field}
        </AutoFillListElement>
      ));
    };

    return (
      <AutoFillCardContainer style={containerStyles}>
        <AutoFillCardTitleContainer>
          <AutoFillCardTitle data-public>{title}</AutoFillCardTitle>
          <TitleActionsContainer>
            <FeatureFlaggedConditionalRender
              featureFlag={FEATURE_FLAGS.EDIT_IN_CAPELLA_BUTTON}
            >
              <ConditionalRender
                shouldRender={(refreshCallback && showTopAction) as boolean}
              >
                <AutoFillCardTitleButton
                  data-testid={`externalButton-${id}`}
                  onClick={handleExternalActionButton}
                >
                  {isAutofillDataAvailable
                    ? 'Edit in Capella'
                    : 'Add in Capella'}
                </AutoFillCardTitleButton>
              </ConditionalRender>
            </FeatureFlaggedConditionalRender>
            <ConditionalRender shouldRender={!isNil(refreshCallback)}>
              <RefreshContainer>
                <RefreshButton
                  onRefreshClicked={() => {
                    refreshCallback?.(id);
                  }}
                  isLoading={isRefreshLoading}
                />
              </RefreshContainer>
            </ConditionalRender>
          </TitleActionsContainer>
        </AutoFillCardTitleContainer>
        <ConditionalRender shouldRender={isAutofillDataAvailable}>
          <AutoFillListContainer data-public>
            {renderAutoFillLines(autofillData)}
          </AutoFillListContainer>
        </ConditionalRender>
        <ConditionalRender shouldRender={isAutofillDataAvailable}>
          <AutofillBottomSection>
            {autofillError ? (
              <AutofillErrorText>Error, Please Try Again</AutofillErrorText>
            ) : (
              <AutofillBottomText>{autofillBottomText}</AutofillBottomText>
            )}
          </AutofillBottomSection>
        </ConditionalRender>
        <ConditionalRender shouldRender={!!title}>
          <AutoFillDivider />
        </ConditionalRender>
        <ClinicalTextArea
          dataTestId={`field_${uiSection || id}`}
          value={value}
          onChange={onChange}
          placeholder={placeholderText}
          uiSection={uiSection}
          minRows={minRows}
          maxRows={maxRows}
          styles={{
            textarea: {
              margin: '0px 28px 5px 28px',
            },
          }}
        />
      </AutoFillCardContainer>
    );
  }
);

export default ClinicalNotesAutoFillField;
