import { Navigate as navigate } from 'react-big-calendar';
import styled from 'styled-components';
import { ArrowLeftIcon } from '../AkidoUI/Icon/ArrowLeftIcon';
import { ArrowRightIcon } from '../AkidoUI/Icon/ArrowRightIcon';
import { DatepickerCalendarModal } from './DatePicker/DatePickerModal';

export function DayController({ onNavigate, messages, label }: any) {
  return (
    <div className='space-between flex w-[300px] items-center justify-between'>
      <button
        className='btn btn-sm btn-gray-ghost'
        onClick={() => onNavigate(navigate.PREVIOUS)}
        aria-label={messages.previous}
      >
        <ArrowLeftIcon />
      </button>
      <button
        data-public
        className='btn btn-xl btn-gray-link'
        onClick={() => onNavigate(navigate.TODAY)}
        aria-label={messages.today}
      >
        {label}
      </button>
      <button
        className='btn btn-sm btn-gray-ghost'
        onClick={() => onNavigate(navigate.NEXT)}
        aria-label={messages.next}
      >
        <ArrowRightIcon />
      </button>
    </div>
  );
}

const FilterOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const TodayButton = styled.button`
  color: #0078d8;
  font-size: 28px;
  font-weight: 600;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export function FilterOptions({ date, selected, setSelected }: any) {
  const handleTodayClick = () => {
    setSelected(new Date());
    // do more stuff.
  };
  return (
    <FilterOptionsContainer>
      <DatepickerCalendarModal
        onSelect={(date) => setSelected(date)}
        value={selected}
        date={date}
      />
      <TodayButton data-public type='button' onClick={handleTodayClick}>
        Today
      </TodayButton>
    </FilterOptionsContainer>
  );
}
