import styled, { CSSObject } from 'styled-components';

export const defaultTableStyle: CSSObject = {
  fontFamily: 'sans-serif',
  width: '100%',
  borderCollapse: 'collapse',
  color: '#333333',
  overflow: 'scroll',
};
export const Table = styled.table(defaultTableStyle);
