import { gql } from '@apollo/client';
import { ClinicalNoteAIAnalysisDtoType } from '../../types/clinical-notes';

export const runAIAnalysisMutation = gql`
  mutation RunAIAnalysis($id: String!, $content: ClinicalNoteContentInput!) {
    runAiAnalysis(id: $id, content: $content)
  }
`;

export interface RunAiAnalysis {
  id: string;
  timeoutUnit: string;
  timeoutValue: number;
  status: string;
  source: string;
  destination: string;
  content: string;
  requestId: string;
  requestingUser: string;
  callbackUrl: string;
  updatedAt: string;
  createdAt: string;
  result: any;
  error: any;
}

export type RunAIAnalysisMutationVariables = ClinicalNoteAIAnalysisDtoType;
export type RunAIAnalysisReturnType = {
  runAiAnalysis: RunAiAnalysis;
};
