import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: flex-end;
`;

interface TranscriptionsTableContainerProps {
  children: ReactNode;
}

export const TranscriptionsTableContainer: React.FC<
  TranscriptionsTableContainerProps
> = (props) => {
  const { children } = props;

  return (
    <Container data-testid='transcriptions-list-page'>{children}</Container>
  );
};
