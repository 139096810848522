import { Dropdown } from '../../components/AkidoUI/Dropdown';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import {
  defaultStatusOptions,
  OptionColors,
} from '../../constants/transcriptionStatuses';
import { useTranscriptionData } from './hooks/useTranscriptionData';
import authStore from '../../stores/auth.store';
import { transcriptionDataStore } from '../../stores/transcriptionData.store';

interface TranscriptionStatusActionProps {
  id: string;
  status: string;
  handleStatusChange: (id: string, newStatus: string) => Promise<void>;
}

export const TranscriptionStatusAction: FC<TranscriptionStatusActionProps> =
  observer((props) => {
    const { id, status, handleStatusChange } = props;

    const { loading } = useTranscriptionData();

    const statusOptions = transcriptionDataStore.statuses;

    const statusOptionsWithColor = useMemo(() => {
      return statusOptions?.map((option) => ({
        value: option.id,
        label: option.label,
        backgroundColor: OptionColors[option.id],
      }));
    }, [statusOptions]);

    if (statusOptionsWithColor.length === 0) {
      return null;
    }

    const canEditTranscriptions =
      authStore.authorizations.canEditTranscriptions;

    return (
      <Dropdown
        dropdownContainerStyles={{
          alignItems: 'center',
          flexDirection: 'inherit',
          pointerEvents: canEditTranscriptions ? undefined : 'none',
        }}
        listStyles={{ backgroundColor: '#F2F2F2' }}
        selectedValue={status}
        onChange={(newStatus) => {
          handleStatusChange(id, newStatus);
        }}
        options={loading ? defaultStatusOptions : statusOptionsWithColor}
      />
    );
  });
