import { StyleSheetInterface } from '../../../types/stylesheet.interface';

export const ScribeNoteStyles: StyleSheetInterface = {
  containerFlexOne: {
    flex: 1,
  },
  scribeNoteModalContentInput: {
    flex: 1,
    color: '#5f7584',
    height: '90%',
    borderColor: '#ABBDCA',
    fontSize: 16,
    fontWeight: 400,
    resize: 'none',
  },
  modalContentStyles: {
    border: 'none',
    width: '414px',
    height: '523px',
    padding: '21px',
    boxSizing: 'border-box',
    borderRadius: 15,
  },
};
