import { useEffect, useMemo } from 'react';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { clinicalEditorPageState } from '../../../stores/clinicalEditorPageState.store';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';

const CLINICAL_NOTE_AUTOSAVE_DEFAULT_INTERVAL = 60;

interface IProps {
  saveNote: (autoSave?: boolean, override?: boolean) => Promise<any>;
  hasLoadLocal: boolean;
}

export const useNoteEditorAutoSave = ({ saveNote, hasLoadLocal }: IProps) => {
  const ldClient = useLDClient();

  const autosaveEnabled = clinicalNoteStore.autosaveEnabled;
  const clinicalNoteTitle = clinicalNoteStore.editableNoteTitle;
  const { autoSaveTimeout, hasBeenModified, previousTitle } =
    clinicalEditorPageState;

  const clinicalNoteUpdatedVersion = ldClient?.variation(
    FEATURE_FLAGS.CLINICAL_NOTE_UPDATED_VERSION,
    false
  );

  const configAutosaveInterval = ldClient?.variation(
    FEATURE_FLAGS.AUTOSAVE_INTERVAL_CONFIG,
    false
  );

  const autosaveInterval =
    (configAutosaveInterval
      ? ldClient?.variation(
          FEATURE_FLAGS.AUTOSAVE_INTERVAL_SECONDS,
          CLINICAL_NOTE_AUTOSAVE_DEFAULT_INTERVAL
        )
      : CLINICAL_NOTE_AUTOSAVE_DEFAULT_INTERVAL) * 1000;

  const hasNewVersion =
    clinicalNoteUpdatedVersion && clinicalNoteStore.hasNewVersion;

  const hasUnsavedModifications = useMemo(() => {
    if (!hasLoadLocal) {
      return false;
    }
    return hasBeenModified || clinicalNoteTitle !== previousTitle;
  }, [clinicalNoteTitle, hasBeenModified, hasLoadLocal, previousTitle]);

  useEffect(() => {
    // Autosaves data after a minute has passed
    if (autosaveEnabled && hasUnsavedModifications && !hasNewVersion) {
      clinicalEditorPageState.setShouldBlockNavigation(true);
      if (!autoSaveTimeout && hasBeenModified) {
        const newAutoSaveTimeoutId = setTimeout(() => {
          clinicalEditorPageState.setPreviousTitle(clinicalNoteTitle);
          saveNote(true);
          clinicalEditorPageState.setHasBeenModified(false);
          clinicalEditorPageState.setAutoSaveTimeout(undefined);
        }, autosaveInterval);
        clinicalEditorPageState.setAutoSaveTimeout(newAutoSaveTimeoutId);
      }
    } else {
      clinicalEditorPageState.setShouldBlockNavigation(false);
    }

    if (!autosaveEnabled) {
      clinicalEditorPageState.clearAutoSaveTimeout();
    }

    return () => {
      clinicalEditorPageState.clearAutoSaveTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autosaveEnabled,
    hasUnsavedModifications,
    hasBeenModified,
    clinicalNoteTitle,
  ]);
};
