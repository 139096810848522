import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { SignInPage } from './SignIn';
import { TranscriptionsListPage } from './TranscriptionsList/TranscriptionsListPage';
import { ClinicalNotesEditorPageFromAkidoNoteId } from './ClinicalNotesEditor/ClinicalNotesEditorPageFromAkidoNoteId';
import authStore from '../stores/auth.store';
import { LoggedInLayout, PageLayout } from '../components';
import { RoutePaths } from '../constants/routes';
import { ClinicalNotePreview } from './ClinicalNotePreview/ClinicalNotePreview';
import { useAnalytics } from '../hooks/useAnalytics';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../config/launchdarkly';
import { oktaAuth } from '../config/okta';
import { LoginCallback, Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { storeAuthInfo } from '../utilities/auth';
import { ErrorPage } from './ErrorPage/ErrorPage';
import { ClinicalNoteEditorPageFromExternalEncounterId } from './ClinicalNotesEditor/ClinicalNoteEditorPageFromExternalEncounterId';
import { Schedule } from './Schedule/Schedule';
import { AnalyticsScreenNameMapping } from '../constants/analytics';
import { ClinicalNoteEditor } from './ClinicalNotesEditor/ClinicalNoteEditor';
import { AINoteLoad } from './AINote/AINoteLoad';
import { useZendesk } from '../hooks/useZendesk';
import Maintenance from './Maintenance/Maintenance';

export const AppRouter = observer(() => {
  const location = useLocation();
  const { sendScreenEvent } = useAnalytics();
  const navigate = useNavigate();
  const isRendered = useRef(false);

  const ldClient = useLDClient();
  const autosaveOnNavigation = true;

  const showNewEditorPage = ldClient?.variation(
    FEATURE_FLAGS.NEW_EDITOR,
    false
  );

  const maintenanceData = ldClient?.variation(FEATURE_FLAGS.MAINTENANCE, {});

  useZendesk(maintenanceData?.message ? undefined : location.pathname);

  useEffect(() => {
    oktaAuth.getUser()?.then((user) => storeAuthInfo(user, authStore));
    if (!isRendered.current) return;
    const screenLocation: string = location.pathname.split('/')[1];
    const screenName = AnalyticsScreenNameMapping[screenLocation];

    if (screenName) {
      sendScreenEvent({
        firebase_screen: screenName,
        firebase_screen_class: screenName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    isRendered.current = true;
  }, []);

  const handleRestoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    const user = await _oktaAuth.getUser();

    await storeAuthInfo(user, authStore);

    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const renderEditorRouteFromExternalEncounter = () => {
    // this will render nothing while ld is initialized instead of rendering an actual page
    // and then possibly switch to another after we get the ld variation
    if (showNewEditorPage === undefined)
      return <div data-testid='ld-not-initialized' />;
    if (showNewEditorPage) {
      return <ClinicalNoteEditor />;
    } else {
      return <ClinicalNoteEditorPageFromExternalEncounterId />;
    }
  };

  const renderEditorRouteFromAkidoNoteId = () => {
    // this will render while ld is initialized
    if (showNewEditorPage === undefined)
      return <div data-testid='ld-not-initialized' />;
    if (showNewEditorPage) {
      return <ClinicalNoteEditor />;
    } else {
      return <ClinicalNotesEditorPageFromAkidoNoteId />;
    }
  };

  if (maintenanceData?.message) {
    return (
      <Maintenance
        message={maintenanceData?.message}
        title={maintenanceData?.title}
      />
    );
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={handleRestoreOriginalUri}>
      <Routes>
        <Route path={RoutePaths.RedirectCallback} element={<LoginCallback />} />

        <Route path={RoutePaths.Login} element={<SignInPage />} />

        {/* Transcriptions */}
        <Route
          path={RoutePaths.Transcriptions}
          element={
            <LoggedInLayout>
              <TranscriptionsListPage />
            </LoggedInLayout>
          }
        />

        {/* AI Note Page */}
        <Route path={RoutePaths.AIClinicalNote} element={<AINoteLoad />} />

        <Route
          path={RoutePaths.AIClinicalNoteWithId}
          element={<AINoteLoad />}
        />

        {/* Clinical notes */}
        <Route
          path={RoutePaths.ClinicalNotes}
          element={
            <LoggedInLayout shouldAutoSaveOnLogout={autosaveOnNavigation}>
              {renderEditorRouteFromExternalEncounter()}
            </LoggedInLayout>
          }
        />

        {/* Clinical Note Preview */}
        <Route
          path={RoutePaths.ClinicalNotePreview}
          element={
            <LoggedInLayout>
              <ClinicalNotePreview />
            </LoggedInLayout>
          }
        />

        {/* Clinical Note with Id */}
        <Route
          path={RoutePaths.ClinicalNotesWithId}
          element={
            <LoggedInLayout shouldAutoSaveOnLogout={autosaveOnNavigation}>
              {renderEditorRouteFromAkidoNoteId()}
            </LoggedInLayout>
          }
        />

        {/* Schedule */}
        <Route
          path={RoutePaths.Schedule}
          element={
            <PageLayout shouldAutoSaveOnLogout={autosaveOnNavigation}>
              <Schedule />
            </PageLayout>
          }
        />

        {/* Error */}
        <Route
          path={RoutePaths.Error}
          element={
            <LoggedInLayout shouldAutoSaveOnLogout={autosaveOnNavigation}>
              <ErrorPage />
            </LoggedInLayout>
          }
        />

        {/* Others */}
        <Route path={RoutePaths.Others} element={<Navigate to='/login' />} />
      </Routes>
    </Security>
  );
});
