import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { useKeyPress } from '../../hooks/useKeyPress';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { transcriptionFilterStore } from '../../stores/transcriptionFilter.store';
import { IconButton } from '../AkidoUI/IconButton';
import { ClearFilters } from './ClearFilters';
import { PhysicianSelect } from './PhysicianSelect';
import { RangeSelector, RangeSelectorRef } from './RangeSelector';
import { Separator } from './Separator';
import { StatusSelect } from './StatusSelect';
import { SelectOption } from './Select/types';

const Container = styled.div`
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: absolute;
  top: 0;
  right: 110%;
  z-index: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  color: #0b1826;
  font-size: 1.2rem;
`;

const SectionSeparator = styled(Separator)`
  margin: 24px 0;
`;

interface FilterProps {
  isVisible?: boolean;
  onClose?: () => void;
  onAppointmentFilterChange?: (start: Date, end: Date) => void;
  onSubmissionFilterChange?: (start: Date, end: Date) => void;
  onStatusFilterChange?: (option: SelectOption, action: string) => void;
  onPractitionerFilterChange?: (option: SelectOption, action: string) => void;
}

export const FilterDisplay = observer((props: FilterProps) => {
  const {
    onClose,
    isVisible = false,
    onAppointmentFilterChange,
    onSubmissionFilterChange,
    onStatusFilterChange,
    onPractitionerFilterChange,
  } = props;

  const submittedRef = useRef<RangeSelectorRef | null>(null);
  const appointmentRef = useRef<RangeSelectorRef | null>(null);

  const isPressed = useKeyPress('Escape');
  const filterRef = useRef<HTMLDivElement | null>(null);

  const _onClose = useMemo(() => onClose ?? (() => {}), [onClose]);

  useOnClickOutside(filterRef, _onClose);

  useEffect(() => {
    if (isPressed) _onClose();
  }, [isPressed, _onClose]);

  const selectedPractitioner = transcriptionFilterStore.practitionerIds?.[0];
  const selectedStatus = transcriptionFilterStore.statusIds?.[0];

  const clearFilters = useCallback(() => {
    submittedRef.current?.clear();
    appointmentRef.current?.clear();
    transcriptionFilterStore.clear();
  }, []);

  return (
    <Container
      ref={filterRef}
      style={{ display: isVisible ? undefined : 'none' }}
    >
      <TitleSection>
        <Title>Filter By</Title>
        <IconButton icon={<CloseIcon />} callback={_onClose} />
      </TitleSection>
      <RangeSelector
        title='Date Submitted'
        startLabel='From'
        endLabel='To'
        ref={submittedRef}
        styles={{ container: { margin: '24px 0 0 0' } }}
        onChange={onSubmissionFilterChange}
      />
      <RangeSelector
        title='Date of Appointment'
        startLabel='From'
        endLabel='To'
        ref={appointmentRef}
        styles={{
          container: {
            margin: '24px 0 0 0',
          },
        }}
        onChange={onAppointmentFilterChange}
      />
      <SectionSeparator />
      <PhysicianSelect
        onChange={onPractitionerFilterChange}
        selectedOption={selectedPractitioner}
      />
      <StatusSelect
        onChange={onStatusFilterChange}
        selectedOption={selectedStatus}
      />
      <ClearFilters onClick={clearFilters} />
    </Container>
  );
});
