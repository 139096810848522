import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from 'styled-components';
import { StatusOptionId } from '../../../constants/transcriptionStatuses';
import { transcriptionModalStore } from '../../../stores/transcriptionModal.store';
import { Modal } from '../../../components/AkidoUI/Modal';
import { ModalCloseButton } from '../../../components/Modal/ModalCloseButton';
import { MetaData } from './MetaData';
import { NoteContent } from './NoteContent';
import { StatusDropdown } from './StatusDropdown';
import { Title } from './Title';

interface TranscriptionModalProps {}

const Container = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
`;

const Header = styled.div`
  margin-bottom: 12px;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Content = styled.div`
  overflow: hidden;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const TranscriptionModal: FC<TranscriptionModalProps> = observer(() => {
  const transcription = transcriptionModalStore.selectedTranscription;

  return (
    <Modal
      contentStyles={{
        border: 'none',
        width: '60%',
        height: '60%',
        padding: '28px 44px 44px 44px',
        boxSizing: 'border-box',
        borderRadius: '16px',
      }}
      isVisible={transcriptionModalStore.isVisible}
      onClose={() => transcriptionModalStore.hideModal()}
    >
      <Container>
        <Header>
          <ModalCloseButton
            onClick={() => transcriptionModalStore.hideModal()}
          />
          <Title>
            {transcription?.encounter?.encounterId
              ?.split('')
              .splice(0, 8)
              .join('')}
          </Title>
        </Header>
        <Content>
          <TitleSection>
            <TitleContainer>
              <MetaData
                name={transcriptionModalStore.patientName}
                age={transcription?.encounter?.patient?.age ?? 0}
                dateOfBirth={transcription?.encounter.patient.birthDate ?? ''}
              />
              <StatusDropdown
                selectedOption={transcription?.status as StatusOptionId}
              />
            </TitleContainer>
          </TitleSection>
          <NoteContent
            note={transcription?.encounter.note ?? ''}
            title={transcription?.encounter?.noteTitle ?? ''}
          />
        </Content>
      </Container>
    </Modal>
  );
});
