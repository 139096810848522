import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { RoutePathRegex, RoutePaths } from '../../constants/routes';
import { EditableNoteTitle } from '../../routes/ClinicalNotesEditor/EditableNoteTitle';
import { PreviewNoteTitle } from '../../routes/ClinicalNotesEditor/PreviewNoteTitle';

interface RouteAwareHeaderLeftProps {}

const Placeholder = styled.div`
  visibility: hidden;
`;

export const RouteAwareHeaderLeft: FC<RouteAwareHeaderLeftProps> = (props) => {
  const { pathname } = useLocation();

  const Component = useMemo(() => {
    switch (pathname) {
      case RoutePaths.Transcriptions:
        return Placeholder;
      case pathname.match(RoutePathRegex.ClinicalNotesPreview)?.input:
        return PreviewNoteTitle;
      case RoutePaths.ClinicalNotes:
      case pathname.match(RoutePathRegex.ClinicalNotesWithId)?.input:
        return EditableNoteTitle;
      default:
        return Placeholder;
    }
  }, [pathname]);

  return <Component />;
};
