import { forwardRef } from 'react';

export const AlertIcon = forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: any) => {
    return (
      <svg
        width='19'
        height='17'
        viewBox='0 0 19 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.59418 0.244792C9.11055 0.303409 8.63972 0.550672 8.31799 0.914912C8.08774 1.17561 1.16081 13.1674 1.05847 13.4825C0.676834 14.6577 1.41009 15.9221 2.61962 16.1743C2.94195 16.2415 16.786 16.2415 17.1083 16.1743C18.3159 15.9225 19.0506 14.6561 18.6696 13.4831C18.6305 13.3625 17.0234 10.5328 15.0983 7.19489C12.4506 2.60419 11.5434 1.06587 11.3734 0.879208C11.2124 0.702321 11.0523 0.584094 10.8083 0.461949C10.5062 0.310775 10.3855 0.277783 9.95011 0.22722C9.89089 0.220329 9.73071 0.228253 9.59418 0.244792ZM10.2427 4.4576C10.5168 4.65271 10.5139 4.61601 10.5005 7.74481C10.4886 10.5437 10.4875 10.5748 10.3978 10.6951C10.1197 11.0681 9.60822 11.0681 9.33012 10.6951C9.24037 10.5747 9.23933 10.5444 9.22783 7.72327L9.2162 4.87305L9.31397 4.68243C9.44056 4.43564 9.64109 4.3231 9.9123 4.3467C10.0234 4.35639 10.1694 4.4054 10.2427 4.4576ZM10.1655 11.7851C10.5459 11.8909 10.8261 12.284 10.8261 12.7117C10.8261 13.5372 9.87896 13.9813 9.24063 13.4552C8.77819 13.074 8.78224 12.347 9.24898 11.9609C9.49767 11.7552 9.82934 11.6916 10.1655 11.7851Z'
          fill={props.color ?? '#CC1E12'}
        />
      </svg>
    );
  }
);
