import { forwardRef } from 'react';

export const CalendarIcon = forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: any) => {
    return (
      <svg
        role={props.role}
        width='13'
        height='15'
        viewBox='0 0 13 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
        {...props}
      >
        <path
          d='M11.5 1.875H10.875V1.25C10.875 0.90625 10.5938 0.625 10.25 0.625C9.90625 0.625 9.625 0.90625 9.625 1.25V1.875H3.375V1.25C3.375 0.90625 3.09375 0.625 2.75 0.625C2.40625 0.625 2.125 0.90625 2.125 1.25V1.875H1.5C0.8125 1.875 0.25 2.4375 0.25 3.125V13.125C0.25 13.8125 0.8125 14.375 1.5 14.375H11.5C12.1875 14.375 12.75 13.8125 12.75 13.125V3.125C12.75 2.4375 12.1875 1.875 11.5 1.875ZM10.875 13.125H2.125C1.78125 13.125 1.5 12.8438 1.5 12.5V5H11.5V12.5C11.5 12.8438 11.2188 13.125 10.875 13.125Z'
          fill={props.color || 'black'}
        />
      </svg>
    );
  }
);
