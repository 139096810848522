import { forwardRef } from 'react';

export const CheckIcon = forwardRef(
  (props: React.SVGProps<SVGSVGElement>, ref: any) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        ref={ref}
        {...props}
      >
        <path
          d='M20 6L9 17L4 12'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }
);
