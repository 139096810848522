import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import authStore from '../stores/auth.store';
import {
  REACT_APP_PROVIDER_DESKTOP_BFFE_HOST,
  REACT_APP_PROVIDER_DESKTOP_BFFE_SUBSCRIPTION_URL,
} from './environment';
import { oktaAuth } from './okta';
import { getMainDefinition } from '@apollo/client/utilities';

const logout = async () => {
  oktaAuth.tokenManager.clear();
  await oktaAuth.signOut();
  await authStore.reset();
};

interface Error {
  message: string;
  extensions: Extensions;
}

interface Extensions {
  code: string;
  response: Response;
}

interface Response {
  statusCode: number;
  message: string;
  error: string;
}

interface Response {
  errors: Error[];
  data?: any;
}

const logoutLink = onError((error) => {
  const graphqlErrors = error.graphQLErrors ?? [];

  for (const err of graphqlErrors) {
    if (
      err?.extensions?.code === 'UNAUTHENTICATED' ||
      (err?.extensions?.response as any)?.statusCode === 401
    ) {
      logout();
      break;
    }
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = oktaAuth.getAccessToken();

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    await logout();
    return { headers };
  }
});

const httpLink = createHttpLink({
  uri: `${REACT_APP_PROVIDER_DESKTOP_BFFE_HOST}/graphql`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${REACT_APP_PROVIDER_DESKTOP_BFFE_SUBSCRIPTION_URL}/graphql`,
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: from([authLink, logoutLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      AWSAccessCredentials: {
        keyFields: ['accessKeyId'],
      },
    },
  }),
});

export default client;
