import { action, computed, makeObservable, observable } from 'mobx';
import { TranscriptionModel } from '@akido/provider-desktop-bffe-types';

export class TranscriptionModalStore {
  state = 'hidden';
  selectedTranscription: TranscriptionModel | null = null;

  constructor() {
    makeObservable(this, {
      state: observable,
      selectedTranscription: observable,
      patientName: computed,
      showModal: action,
      hideModal: action,
      isVisible: computed,
    });
  }

  showModal() {
    this.state = 'visible';
  }

  hideModal() {
    this.state = 'hidden';
  }

  get isVisible() {
    return this.state === 'visible';
  }

  setSelectedTranscription(transcription: TranscriptionModel) {
    this.selectedTranscription = transcription;
  }

  get patientName() {
    const givenName = this.selectedTranscription?.encounter?.patient?.givenName;
    const familyName =
      this.selectedTranscription?.encounter?.patient?.familyName;
    return `${givenName} ${familyName}`;
  }
}

export const transcriptionModalStore = new TranscriptionModalStore();
