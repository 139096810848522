import { DataTable } from '../../components/AkidoUI/DataTable';
import { PageClickFunc } from '../../components/AkidoUI/DataTable/DataTable.types';
import { useMutation, useQuery } from '@apollo/client';
import omit from 'lodash/omit';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useMemo } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useTranscriptionData } from './hooks/useTranscriptionData';
import {
  TranscriptionList,
  TranscriptionListVariables,
} from '../../services/graphql/transcriptionsList';
import { UpdateTranscriptionStatus } from '../../services/graphql/updateTranscriptionStatus';
import { scribeNoteModalStore } from '../../stores/scribeNoteModal.store';
import { transcriptionFilterStore } from '../../stores/transcriptionFilter.store';
import { UpdateTranscriptionInput } from '@akido/provider-desktop-bffe-types';
import { TranscriptionModel } from '@akido/provider-desktop-bffe-types';
import { formatDate } from '../../utilities/formatting';
import { EncounterIdButton } from './EncounterIdButton';
import { ScribeNoteIndicator } from './ScribeNoteIndicator';

import { TranscriptionStatusAction } from './TranscriptionStatusAction';
import { useSearchParams } from 'react-router-dom';
import { TestID } from '../../constants/testIds';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../config/launchdarkly';

interface TranscriptionsTableProps {
  onPageClick?: PageClickFunc;
  currentPage?: number;
}

interface TableHeader {
  value: string;
}

interface TableHeaders {
  [key: string]: TableHeader;
}

const TABLE_HEADERS: TableHeaders = {
  status: {
    value: 'Status',
  },
  note: {
    value: 'Note',
  },
  encounterId: {
    value: 'Encounter',
  },
  practitioner: {
    value: 'Physician',
  },
  dateOfSubmission: {
    value: 'Date Submitted',
  },
  dateOfEncounter: {
    value: 'Date of Appt.',
  },
  patient: {
    value: 'Patient',
  },
  birthDate: {
    value: 'DOB',
  },
  phone: {
    value: 'Phone Number',
  },
  sex: {
    value: 'Sex at Birth',
  },
  address: {
    value: 'Address',
  },
  region: {
    value: 'Region',
  },
  coverage: {
    value: 'Insurance Plan',
  },
  memberId: {
    value: 'MemberId',
  },
};

export const TranscriptionsTable: FC<TranscriptionsTableProps> = observer(
  (props) => {
    const { currentPage, onPageClick } = props;
    const pageSize = 10;

    const [updateTranscription] = useMutation<
      TranscriptionModel,
      UpdateTranscriptionInput
    >(UpdateTranscriptionStatus);
    const ldClient = useLDClient();

    const shouldShowPatientColumns = ldClient?.variation(
      FEATURE_FLAGS.NEW_PATIENT_COLUMNS,
      false
    );

    const { sendNoteStatusUpdateEvent } = useAnalytics();

    useTranscriptionData();

    // This may look weird, but the exhaustive dependencies linting rule
    // doesn't think mobx stores need to be included, but they do because they cause
    // changes. This is to eliminate the linting warning.
    const {
      practitionerIds,
      statusIds,
      submissionDateFilter,
      appointmentDateFilter,
    } = transcriptionFilterStore;

    const [searchValue] = useSearchParams();
    const searchQuery = useMemo(() => {
      return searchValue.get('search') || '';
    }, [searchValue]);

    const transcriptionVariables = useMemo(() => {
      return {
        pageNumber: currentPage ?? 1,
        pageSize: pageSize,
        practitionerIds: practitionerIds,
        statuses: statusIds,
        submissionDate: submissionDateFilter,
        appointmentDate: appointmentDateFilter,
        ...(searchQuery && { searchQuery: searchQuery }),
      };
    }, [
      searchQuery,
      currentPage,
      practitionerIds,
      statusIds,
      submissionDateFilter,
      appointmentDateFilter,
    ]);

    const { loading, data, refetch } = useQuery<
      {
        transcriptionsList: {
          transcriptions: TranscriptionModel[];
          totalCount: number;
        };
      },
      TranscriptionListVariables
    >(TranscriptionList, {
      variables: transcriptionVariables,
      fetchPolicy: 'network-only',
    });

    scribeNoteModalStore.refreshData = async () => {
      await refetch(transcriptionVariables);
    };

    const handleStatusChange = useCallback(
      async (id: string, newStatus: string) => {
        await updateTranscription({
          variables: {
            transcriptionId: id,
            status: newStatus,
          },
        });
        sendNoteStatusUpdateEvent(newStatus, id);
        await refetch(transcriptionVariables);
      },
      [
        updateTranscription,
        refetch,
        transcriptionVariables,
        sendNoteStatusUpdateEvent,
      ]
    );

    let featureFlaggedColumns = TABLE_HEADERS;
    if (!shouldShowPatientColumns) {
      const propertiesToRemove = [
        'sex',
        'phone',
        'address',
        'region',
        'coverage',
        'memberId',
      ];
      featureFlaggedColumns = omit(TABLE_HEADERS, propertiesToRemove);
    }

    const tableData = useMemo(() => {
      return {
        headers: featureFlaggedColumns,
        rows:
          data?.transcriptionsList?.transcriptions?.map((item) => {
            const transcriptionItem = {
              id: item.transcriptionId,
              value: {
                status: () => (
                  <TranscriptionStatusAction
                    id={item.transcriptionId}
                    status={item.status}
                    handleStatusChange={handleStatusChange}
                  />
                ),
                note: () => (
                  <ScribeNoteIndicator
                    transcriptionId={item?.transcriptionId}
                    encounterId={item?.encounter?.encounterId}
                    hasNote={item?.scribeNote?.length > 0}
                    scribeNoteId={item?.scribeNote?.[0]?.scribeNoteId}
                    scribeNote={item?.scribeNote?.[0]?.note}
                  />
                ),
                encounterId: () => (
                  <EncounterIdButton
                    transcription={item}
                    id={item.encounter?.encounterId}
                  />
                ),
                practitioner: `${item.encounter.practitioner.givenName} ${item.encounter.practitioner.familyName}`,
                dateOfSubmission: formatDate(item.dateSubmitted || ''),
                dateOfEncounter: formatDate(
                  item.encounter.dateOfEncounter || ''
                ),
                patient: `${item.encounter.patient.givenName} ${item.encounter.patient.familyName}`,
                birthDate: formatDate(
                  item.encounter.patient.birthDate || '',
                  false,
                  'utc'
                ),
                phone: shouldShowPatientColumns
                  ? item.encounter.patient.phone || ''
                  : undefined,
                sex: shouldShowPatientColumns
                  ? item.encounter.patient.sex || ''
                  : undefined,

                address: shouldShowPatientColumns
                  ? item.encounter.patient.address || ''
                  : undefined,
                region: () => {
                  return <div>{item?.encounter?.patient?.region}</div>;
                },
                coverage: () => {
                  return <div>{item?.encounter?.patient?.coverage}</div>;
                },
                memberId: () => {
                  return <div>{item?.encounter?.patient?.memberId}</div>;
                },
              },
            };

            if (!shouldShowPatientColumns) {
              delete transcriptionItem.value.sex;
              delete transcriptionItem.value.address;
              delete transcriptionItem.value.phone;
            }

            return transcriptionItem;
          }) ?? [],
      };
    }, [
      data?.transcriptionsList?.transcriptions,
      handleStatusChange,
      shouldShowPatientColumns,
      featureFlaggedColumns,
    ]);

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div style={{ width: '100%', overflow: 'auto' }}>
        <DataTable
          pagination
          pageSize={pageSize}
          onPageClick={onPageClick}
          selectedPage={currentPage}
          totalCount={data?.transcriptionsList?.totalCount}
          styles={{
            table: {
              overflow: 'auto',
              whiteSpace: 'nowrap',
              width: Object.keys(featureFlaggedColumns).length * 150,
            },
            tableHeaderRow: {
              fontSize: '1.1rem',
            },
            tableBody: {
              fontSize: '1rem',
            },
          }}
          data={tableData}
          testID={TestID.Transcriptions.Table}
        />
      </div>
    );
  }
);
