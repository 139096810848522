import { observer } from 'mobx-react-lite';
import { Tabs } from '../../../components/Tabs/Tabs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { dragonSettingsStore } from '../../../stores/dragonSettings.store';
import {
  AINoteSectionTypes,
  ClinicalNoteFieldValue,
} from '../../../types/clinical-notes';
import { SectionEditableAutofill } from './SectionEditableAutofill';
import { useClinicalNoteAI } from '../../../hooks/useClinicalNoteAI';
import { AIOutput } from './AIOutput';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { ActionsModal } from '../../../components/Modal/ActionsModal';
import { isEmpty } from 'lodash';

export const AIFields = observer(
  ({
    fields,
    noteId,
    updateCallback,
  }: {
    fields: ClinicalNoteFieldValue[];
    noteId: string;
    updateCallback: () => void;
  }) => {
    const [activeTab, setActiveTab] = useState('transcription');
    const isRecording = useMemo(() => dragonSettingsStore.isRecording, []);
    const [modalOpen, setModalOpen] = useState(false);
    const hasRun = useRef(false);

    useEffect(() => {
      if (isRecording) {
        setActiveTab('transcription');
      }
    }, [isRecording]);

    const transcriptionFieldObject = fields?.find(
      (f) => f.type === AINoteSectionTypes.Transcription
    );

    const aiSummaryFieldObject = useMemo(() => {
      return fields?.find((f) => f.type === AINoteSectionTypes.AISummary);
    }, [fields]);

    const transcriptionField =
      transcriptionFieldObject !== undefined ? (
        <SectionEditableAutofill
          noteId={noteId}
          field={{
            ...transcriptionFieldObject,
            sectionId:
              transcriptionFieldObject?.id ??
              transcriptionFieldObject?.sectionId ??
              '',
          }}
          updateCallback={updateCallback}
          containerStyles={{
            borderRadius: '8px',
          }}
          showTitle={false}
          minRows={15}
          maxRows={45}
        />
      ) : null;

    const { runAIAnalysis } = useClinicalNoteAI();

    const hasSummary = useMemo(() => {
      const text = aiSummaryFieldObject?.text ?? '';
      return text !== '';
    }, [aiSummaryFieldObject]);

    const runAiAction = async () => {
      if (hasRun.current || hasSummary) {
        setModalOpen(true);
      } else {
        setActiveTab('ai');
        if (runAIAnalysis) {
          clinicalNoteStore.clearAISummaryContent();
          await runAIAnalysis();
        }

        hasRun.current = true;
      }
    };

    const performAiAnalysis = async () => {
      clinicalNoteStore.clearAISummaryContent();
      setModalOpen(false);
      setActiveTab('ai');
      if (runAIAnalysis) {
        await runAIAnalysis();
      }
    };

    return (
      <>
        <ActionsModal
          testId='modal_ai_generate_replace'
          isVisible={modalOpen}
          onDecline={() => {
            setModalOpen(false);
          }}
          onAccept={performAiAnalysis}
          declineLabel={'Cancel'}
          acceptLabel={'Confirm'}
          title='Replace Summary Note'
        >
          <div>
            Previous summary output and edits will be overwritten by generating
            a new summary. <br /> <br />
            Select confirm to continue.
          </div>
        </ActionsModal>
        <Tabs
          sections={[
            {
              label: 'Transcript',
              value: 'transcription',
            },
            {
              label: 'Summary',
              value: 'ai',
            },
          ]}
          activeTab={activeTab}
          setActiveTab={(tab) => {
            setActiveTab(tab);
          }}
          additionalHeader={
            <button
              className='btn btn-primary px-4'
              type='button'
              onClick={runAiAction}
              disabled={isEmpty(transcriptionFieldObject?.text)}
            >
              Generate Summary
            </button>
          }
        >
          <>{transcriptionField}</>
          <AIOutput
            field={aiSummaryFieldObject}
            updateCallback={updateCallback}
          />
        </Tabs>
      </>
    );
  }
);
