export const getFrequencyData = (analyser?: AnalyserNode | null) => {
  if (!analyser) return new Uint8Array(0);

  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);
  analyser.getByteFrequencyData(dataArray);
  return dataArray;
};

export const calculateVolume = (frequencyData: Uint8Array) => {
  let sumOfSquares = 0;

  for (const dataValue of frequencyData) {
    const linearValue = dataValue / 255;
    sumOfSquares += linearValue * linearValue;
  }

  let rms = Math.sqrt(sumOfSquares / (frequencyData.length || 1));

  rms = Math.max(rms, 0.0001);

  const dB = 20 * Math.log10(rms); // -80 is the minimum value for dB

  if (dB <= -40) return 1;
  if (dB >= -10) return 10;

  return Math.pow(10, (dB + 40) / 30);
};

export const getVolume = (frequencyData: Uint8Array) => {
  return calculateVolume(frequencyData).toFixed(0);
};
