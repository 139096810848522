import React from 'react';
import { Dialog } from '@headlessui/react';
import akidoLogo from '../../../assets/images/akido_logo.png';
import { CircleAlertIcon } from '../Icon/CircleAlertIcon';
import ConditionalRender from '../../ConditionalRender/ConditionalRender';

type AlertModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  message?: string;
  // We can add more modal types here as designs are created
  // Eventually we might have warninig, success, and info as well
  type?: 'error';
  'data-testid'?: string;
  showLogo?: boolean;
  showCloseButton?: boolean;
};

export function AlertModal(props: AlertModalProps) {
  const {
    isOpen,
    onClose = () => {},
    title,
    message,
    type,
    'data-testid': testId,
    showLogo = false,
    showCloseButton = true,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className='relative z-50'
      data-testid={testId}
    >
      <div
        className='fixed inset-0 bg-gray-700/70 backdrop-blur-sm'
        aria-hidden='true'
      />
      <div className='fixed inset-0 overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4 text-center'>
          <Dialog.Panel className='w-full max-w-sm scale-100 transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle opacity-100 shadow-xl'>
            {type === 'error' && (
              <div className='boder-error-50 flex h-12 w-12 items-center justify-center rounded-full border-4 border-error-50 bg-error-100 outline outline-4 outline-error-50'>
                <CircleAlertIcon className='h-6 w-6 text-error-600' />
              </div>
            )}
            <ConditionalRender shouldRender={showLogo}>
              <img src={akidoLogo} alt='Akido Logo' />
            </ConditionalRender>
            <div className='mt-5'>
              <Dialog.Title className='mt-5 text-lg font-medium text-gray-900'>
                {title}
              </Dialog.Title>
              <Dialog.Description className='text-sm text-gray-500'>
                {message}
              </Dialog.Description>
            </div>
            <ConditionalRender shouldRender={showCloseButton}>
              <div className='mt-8'>
                <button
                  className='btn btn-md btn-white w-full'
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </ConditionalRender>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
