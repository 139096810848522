import { IconButton } from '../../../components/AkidoUI/IconButton';
import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as CircleCheck } from '../../../assets/icons/circle-check.svg';
import { ReactComponent as CircleError } from '../../../assets/icons/circle-error.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

const Container = styled.div`
  background-color: #daf0e3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 12px;
  min-width: 260px;
`;

const Label = styled.span`
  margin-left: 17px;
  font-size: 1rem;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

interface StatusProps {
  styles?: {
    container?: CSSProperties;
    label?: CSSProperties;
    leftIcon?: CSSProperties;
    rightIcon?: CSSProperties;
  };
  label: string;
  onClose: () => void;
  error?: boolean;
  testId: string;
  closeTestId: string;
}

export const Status: FC<StatusProps> = (props) => {
  const { styles, label, onClose, error, testId, closeTestId } = props;

  return (
    <Container
      style={
        styles?.container ?? { backgroundColor: error ? '#FAE6E6' : undefined }
      }
    >
      <LabelContainer>
        {error ? (
          <CircleError style={styles?.leftIcon} />
        ) : (
          <CircleCheck style={styles?.leftIcon} />
        )}
        <Label
          style={styles?.label ?? { color: error ? '#CC1E12' : '#2ba564' }}
          data-testid={testId}
        >
          {label}
        </Label>
      </LabelContainer>
      <IconButton
        testId={closeTestId}
        callback={onClose}
        icon={<CloseIcon fill={error ? '#CC1E12' : '#2ba564'} />}
      ></IconButton>
    </Container>
  );
};
