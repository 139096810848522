import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';
import { gql } from '@apollo/client';

export interface ClinicalNoteUpdatedVersionVariables {
  id: string;
  version: number;
}

export interface ClinicalNoteUpdatedVersionResponse {
  clinicalNoteVersionUpdated: ClinicalNoteModel;
}

export const clinicalNoteUpdatedVersionSubscription = gql`
  subscription getClinicalNoteVersionUpdated($id: String!, $version: Float!) {
    clinicalNoteVersionUpdated(id: $id, version: $version) {
      id
      version
    }
  }
`;
