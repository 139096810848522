import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

type SelectOptionsProps = {
  children: React.ReactNode;
};

export function SelectOptions(props: SelectOptionsProps) {
  const { children } = props;

  return (
    <Transition
      as={Fragment}
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg border border-gray-100 bg-white py-1 shadow-lg focus:outline-none'>
        {children}
      </Listbox.Options>
    </Transition>
  );
}
