import {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactSelect, { SelectInstance, ActionMeta } from 'react-select';
import { DropdownIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';
import styled from 'styled-components';
import { ReactComponent as ArrowDropdownDown } from '../../assets/icons/arrow-dropdown-down.svg';
import { randomId } from '../../utilities/helpers';
import {
  CustomFormatOptionLabel,
  SelectOption,
  SelectStyles,
} from './Select/types';

interface SelectProps {
  className?: string;
  id?: string;
  label?: string;
  name: string;
  onChange?: (value: SelectOption, action: string) => void;
  onInputChange?: (value: string) => void;
  customFormatOptionLabel?: CustomFormatOptionLabel;
  selectedOption?: string | undefined;
  options?: SelectOption[];
  placeholder?: string;
  styles?: SelectStyles;
}

const DropdownArrow: FunctionComponent<
  DropdownIndicatorProps & { isOpen: boolean }
> = (props) => {
  const { isOpen, getStyles } = props;

  const style = getStyles('dropdownIndicator', props) as CSSProperties;

  return (
    <ArrowDropdownDown
      style={{ ...style, transform: isOpen ? 'rotate(180deg)' : '' }}
    ></ArrowDropdownDown>
  );
};

const Label = styled.label`
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 12px;
  align-self: flex-start;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Select = (props: SelectProps) => {
  const {
    options,
    onChange,
    onInputChange,
    customFormatOptionLabel,
    placeholder,
    name,
    label,
    id,
    className,
    styles,
    selectedOption,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<SelectInstance | null>(null);

  const selectId = id ?? randomId(8);

  const onSelectOptionChange = useCallback(
    (value: SelectOption, actionMeta: ActionMeta<SelectOption>) => {
      onChange?.(value, actionMeta.action);
    },
    [onChange]
  );

  const onTextInputChange = useCallback(
    (value: string) => {
      onInputChange?.(value);
    },
    [onInputChange]
  );

  const selected = options?.find((option) => option.value === selectedOption);

  useEffect(() => {
    if (!selected) {
      ref.current?.clearValue();
    }
  }, [selected]);

  return (
    <Container className={className} style={styles?.container}>
      <Label htmlFor={selectId} style={styles?.label}>
        {label}
      </Label>
      <ReactSelect
        ref={ref}
        onChange={(value, actionMeta) =>
          onSelectOptionChange(
            value as SelectOption,
            actionMeta as ActionMeta<SelectOption>
          )
        }
        inputId={selectId}
        value={selected as unknown}
        name={name}
        escapeClearsValue
        options={options}
        onInputChange={onTextInputChange}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        components={{
          DropdownIndicator: (props) => DropdownArrow({ ...props, isOpen }),
        }}
        formatOptionLabel={customFormatOptionLabel}
        placeholder={placeholder}
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: '4px',
            border: '1px solid #C9D2DC',
          }),
          dropdownIndicator: (base) => ({
            ...base,
            margin: '0 12px 0 0',
            padding: 0,
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          placeholder: (base) => ({
            ...base,
            color: '#0B1826',
            fontSize: '1rem',
          }),
          singleValue: (base) => ({
            ...base,
            color: '#0B1826',
            fontSize: '1rem',
          }),
          valueContainer: (base) => ({ ...base, margin: '3.5px 0' }),
          menuList: (base) => ({
            ...base,
            fontSize: '1rem',
            padding: 0,
          }),
          option: (base, props) => {
            const styles = { ...base, color: '#0C1E2C' };
            if (props.isSelected || props.isFocused) {
              styles.backgroundColor = '#F4FAFB';
            } else {
              styles.backgroundColor = 'transparent';
            }
            return styles;
          },
          menu: (base) => ({
            ...base,
            overflow: 'hidden',
            margin: '1px 0 0 0',
            borderRadius: '4px',
            boxShadow:
              '0px 4px 7px rgba(9, 40, 66, 0.05), 0px 0px 1px rgba(9, 40, 66, 0.2)',
          }),
          ...styles?.select,
        }}
      />
    </Container>
  );
};
