import { observer } from 'mobx-react-lite';
import { CSSProperties, FC } from 'react';
import { AddendumInput } from '../Addendum/AddendumInput';
import { ClinicalNotePreviewMode } from '../ClinicalNotePreview';
import { StatusMessage } from '../StatusMessage/StatusMessage';
import { ClinicalNotePreviewAside } from './ClinicalNotePreviewAside';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../../config/launchdarkly';
import { BillingPreviewSection } from '../BillingPreview/BillingPreviewSection';
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { ClinicalNoteStatus } from '../../../types/clinical-notes';

interface RightSectionProps {
  mode?: string;
  error?: boolean;
  isBillingVisible: boolean;
  onClickAddAddendum: () => Promise<void> | void;
  onCancelSignAddendum: () => Promise<void> | void;
}

export const RightSection: FC<RightSectionProps> = observer(
  ({ mode, error, onCancelSignAddendum, isBillingVisible }) => {
    const { clinicalNote } = clinicalNoteStore;

    const ldClient = useLDClient();
    const isNewAddendumUI = ldClient?.variation(FEATURE_FLAGS.NEW_ADDENDUM_UI);
    const showAddendumInput = ClinicalNotePreviewMode.AddAddendum === mode;
    const style: CSSProperties = { flex: isBillingVisible ? 2 : 1 };

    switch (mode) {
      case ClinicalNotePreviewMode.AddAddendum:
        style.flex = 2;
        style.padding = isNewAddendumUI ? '27px 30px 30px 0px' : '';
        break;
      case ClinicalNotePreviewMode.UnsignedPreview:
        style.backgroundColor = 'white';
        style.paddingTop = 61;
        break;
      case ClinicalNotePreviewMode.SignedPreview:
      default:
        style.paddingRight = 25;
        style.paddingTop = isNewAddendumUI ? 26 : 61;
    }

    if (
      !isBillingVisible &&
      !showAddendumInput &&
      !clinicalNoteStore.isStatusMessageVisible
    ) {
      return null;
    }

    return (
      <ClinicalNotePreviewAside
        border={clinicalNote?.status === ClinicalNoteStatus.Draft}
        style={style}
      >
        <ConditionalRender shouldRender={!showAddendumInput}>
          <StatusMessage data-public error={error} />
        </ConditionalRender>
        <AddendumInput
          onCancelSignAddendum={onCancelSignAddendum}
          visible={showAddendumInput}
        />
        <BillingPreviewSection data-public isVisible={isBillingVisible} />
      </ClinicalNotePreviewAside>
    );
  }
);
