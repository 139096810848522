import { gql } from '@apollo/client';

export type GetCapellaUrlResponse = {
  credentials: {
    capellaConfig: {
      url: string;
    };
  };
};

export const GetCapellaUrlQuery = gql`
  query GetCapellaUrl {
    credentials {
      capellaConfig {
        url
      }
    }
  }
`;
