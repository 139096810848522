import { FC } from 'react';
import styled from 'styled-components';
import authStore from '../../stores/auth.store';
import { RouteAwareHeaderLeft } from './RouteAwareHeaderLeft';
import { RouteAwareHeaderButtons } from './RouteAwareHeaderButtons';
import { UserMenu } from './UserMenu';
import { observer } from 'mobx-react-lite';
import { useUserAuthorization } from '../../hooks/useUserAuthorization';
import ConditionalRender from '../ConditionalRender/ConditionalRender';

interface PageHeaderProps {
  shouldAutoSaveOnLogout: boolean;
}

const HeaderContainer = styled.header`
  height: 58.125px;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
`;

export const IconWrapper = styled.button`
  position: relative;
  padding: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const HeaderRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Spacer = styled.div`
  margin-right: 78px;
`;

export const PageHeader: FC<PageHeaderProps> = observer(
  ({ shouldAutoSaveOnLogout }) => {
    useUserAuthorization();
    return (
      <ConditionalRender shouldRender={authStore.isLoaded}>
        <HeaderContainer>
          <RouteAwareHeaderLeft />
          <HeaderRightContainer>
            <RouteAwareHeaderButtons />
            <Spacer />
            <UserMenu
              shouldAutoSaveOnLogout={shouldAutoSaveOnLogout}
              styles={{ container: { marginRight: '25px' } }}
            />
          </HeaderRightContainer>
        </HeaderContainer>
      </ConditionalRender>
    );
  }
);
