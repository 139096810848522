import { LoadingSpinner } from '../../../components/AkidoUI/LoadingSpinner';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ClinicalNotePreviewMode } from '../ClinicalNotePreview';
import { ClinicalNotePreviewMainContent } from '../ClinicalNotePreviewMainContent';
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';

const Center = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 0;
`;

interface CenterSectionProps {
  isLoadingTemplate: boolean;
  mode: ClinicalNotePreviewMode;
  printRef?: React.MutableRefObject<null>;
  error?: Error;
  isAITemplate?: boolean;
}

export const CenterSection: FC<CenterSectionProps> = (props) => {
  const {
    error,
    isLoadingTemplate,
    mode,
    printRef = undefined,
    isAITemplate = false,
  } = props;

  return (
    <Center>
      <ConditionalRender shouldRender={!!error}>
        <div data-testid='save-clinical-note-error-placeholder'>
          There was an error fetching the note: {JSON.stringify(error)}
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={!isLoadingTemplate}>
        <ClinicalNotePreviewMainContent
          mode={mode}
          printRef={printRef}
          isAITemplate={isAITemplate}
        />
      </ConditionalRender>
      <ConditionalRender shouldRender={isLoadingTemplate}>
        <LoadingSpinner testId='loading-spinner-test' />
      </ConditionalRender>
    </Center>
  );
};
