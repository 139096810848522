import React, { CSSProperties, ReactNode } from 'react';
import { DefaultTheme, Theme } from '../Themes';
import styled from 'styled-components';

export type MessageBubbleProps = {
  /**
   * a node to be rendered to the left of the text.
   */
  icon?: ReactNode;
  /**
   * Theme to be applied to the component.
   */
  theme?: Theme;
  /**
   * The type of the message bubble.
   */
  type?: 'info' | 'success' | 'warning' | 'error';
  /**
   * The text to be rendered in the message bubble.
   */
  text: string;
  /**
   * The style of the message bubble.
   */
  containerStyles?: CSSProperties;
  /**
   * The style of the message bubble text.
   */
  textStyles?: CSSProperties;
};

const Container = styled.div(
  ({ theme, type = 'info' }: MessageBubbleProps) => ({
    backgroundColor: theme?.colors?.states?.[type]?.secondary,
    padding: 10,
    borderRadius: 10,
  })
);

const TextContent = styled.span(
  ({ theme, type = 'info' }: MessageBubbleProps) => ({
    color: theme?.colors?.states?.[type]?.primary,
  })
);

export function MessageBubble({
  theme = DefaultTheme,
  type = 'info',
  text,
  icon,
  containerStyles = {},
  textStyles = {},
}: MessageBubbleProps) {
  return (
    <Container style={containerStyles} theme={theme} type={type} text={text}>
      <>
        {icon}
        <TextContent style={textStyles} theme={theme} type={type} text={text}>
          {text}
        </TextContent>
      </>
    </Container>
  );
}
