export const stopStreaming = async (
  mediaRecorder?: AudioWorkletNode,
  transcribeClient?: { destroy: () => void } | null
) => {
  if (!mediaRecorder || !transcribeClient) {
    return;
  }

  mediaRecorder.port.postMessage({
    message: 'UPDATE_RECORDING_STATE',
    setRecording: false,
  });
  mediaRecorder.port.close();
  mediaRecorder.disconnect();

  transcribeClient.destroy();
};
