import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from 'styled-components';
import { editScribeNoteModalStore } from '../../../stores/editScribeNoteModal.store';
import { truncateUUID } from '../../../utilities/helpers';
import { EditScribeNoteBackButton } from './EditScribeNoteBackButton';
import { EditScribeNoteButton } from './EditScribeNoteButton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleText = styled.span`
  font-size: 1.1rem;
  font-weight: 400;
  margin-left: 20px;
`;

const TitleContainer = styled.div``;

interface EditScribeNoteHeaderProps {
  id: string | null;
  canEdit?: boolean;
}

export const EditScribeNoteHeader: FC<EditScribeNoteHeaderProps> = observer(
  (props) => {
    const { id, canEdit } = props;

    const scribeNoteTitle = id ? `Note for ${truncateUUID(id)}` : '';

    return (
      <Container>
        <TitleContainer>
          <EditScribeNoteBackButton
            onClick={() => {
              editScribeNoteModalStore.hideModal();
            }}
          />
          <TitleText>{scribeNoteTitle}</TitleText>
        </TitleContainer>
        <EditScribeNoteButton
          onClick={() => {
            editScribeNoteModalStore.setMode('edit');
          }}
          isVisible={!editScribeNoteModalStore.isEditMode && canEdit}
        />
      </Container>
    );
  }
);
