import styled from 'styled-components';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { isUndefined } from 'lodash';
import { ChevronIcon } from '../Icon/ChevronIcon';
import { ChevronUpIcon } from '../Icon/ChevronUp';

export type DropdownElement = {
  value: any;
  label: string;
  backgroundColor?: string;
  textColor?: string;
};

export type DropdownProps = {
  /** Selected/Starting value for the dropdown (defaults to first element) */
  selectedValue?: any;
  options: DropdownElement[];
  onChange?: (selection: any) => void;
  /** Raw style object for the container element */
  dropdownContainerStyles?: any;
  headerStyles?: any;
  listItemStyles?: any;
  chevron?: boolean;
  /** Raw style object for the list element */
  listStyles?: any;
  onClick?: (isOpen: boolean) => void;
  onClose?: () => void;
  isOpen?: boolean;
  HeaderComponent?: React.FC<{ label: string; isOpen?: boolean }>;
};

const DropDownContainer = styled('div')`
  display: flex;
  position: relative;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
`;

const DropDownHeader = styled('div')`
  padding: 0.6em;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  width: fit-content;
  border-radius: 4px;
  cursor: pointer;
`;

const DropDownList = styled('ul')`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 0;
  margin-top: 2em;
  background-color: #fff;
  z-index: 100;
`;

const ListItem = styled('li')`
  padding: 0.6em;
  list-style: none;
  margin-top: 0.4em;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
`;

const ChevronContainer = styled.div`
  margin-left: 5px;
`;

const DEFAULT_ELEMENT: DropdownElement = { label: '', value: '' };

export function Dropdown({
  selectedValue,
  options,
  onChange,
  onClose,
  onClick,
  isOpen,
  dropdownContainerStyles,
  headerStyles,
  listStyles,
  listItemStyles,
  chevron = false,
  HeaderComponent,
}: DropdownProps) {
  const [selectedOption, setSelectedOption] =
    useState<DropdownElement>(DEFAULT_ELEMENT);
  const [internalIsOpen, setIsOpen] = useState(isOpen);

  const toggleMenu = useCallback(() => {
    if (!isUndefined(isOpen)) {
      onClick?.(isOpen);
    } else {
      setIsOpen((prev) => !prev);
    }
  }, [isOpen, setIsOpen, onClick]);

  const closeMenu: React.FocusEventHandler<HTMLDivElement> = () => {
    if (!isUndefined(isOpen)) {
      onClose?.();
      onClick?.(false);
    } else {
      setIsOpen(false);
    }
  };

  const shouldShowList = useMemo(() => {
    if (!isUndefined(isOpen)) {
      return isOpen;
    }

    return internalIsOpen;
  }, [isOpen, internalIsOpen]);

  const onOptionClicked = (option: DropdownElement) => {
    setSelectedOption(option);
    onChange?.(option.value);
  };

  useEffect(() => {
    setSelectedOption(
      options.find((option) => option.value === selectedValue) || options[0]
    );
  }, [selectedValue, options]);

  return (
    <DropDownContainer style={dropdownContainerStyles}>
      <DropDownHeader
        tabIndex={0}
        onClick={toggleMenu}
        onBlur={closeMenu}
        style={{
          backgroundColor: selectedOption?.backgroundColor,
          color: selectedOption?.textColor,
          display: 'flex',
          ...headerStyles,
        }}
      >
        {HeaderComponent ? (
          <HeaderComponent
            label={selectedOption?.label}
            isOpen={internalIsOpen}
          />
        ) : (
          <div>{selectedOption?.label}</div>
        )}
        {chevron ? (
          <ChevronContainer>
            {internalIsOpen ? <ChevronUpIcon /> : <ChevronIcon />}
          </ChevronContainer>
        ) : null}
      </DropDownHeader>
      {shouldShowList && (
        <DropDownList style={listStyles}>
          {options.map((option: DropdownElement) => (
            <ListItem
              onMouseDown={() => {
                onOptionClicked(option);
              }}
              key={`option_${option.value}`}
              style={{
                backgroundColor: option.backgroundColor,
                color: option.textColor,
                ...listItemStyles,
              }}
            >
              {option.label}
            </ListItem>
          ))}
        </DropDownList>
      )}
    </DropDownContainer>
  );
}
