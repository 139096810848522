import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { ClinicalNotePreviewMode } from '../ClinicalNotePreview';
import { ReactComponent as PrintIcon } from '../../../assets/icons/print.svg';
import ReactToPrint from 'react-to-print';
import { observer } from 'mobx-react-lite';
import { patientStore } from '../../../stores/patient.store';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { ClosePreviewButton } from '../ClosePreviewButton';
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';
import { ClinicalNoteStatus } from '../../../types/clinical-notes';
import { DateTime } from 'luxon';

export const makeDocumentTitle = (
  accountNumber: string | undefined,
  date: Date | undefined | null
) => {
  if (accountNumber && date) {
    return `${accountNumber}_${DateTime.fromJSDate(date).toFormat('yyyyMMdd')}`;
  } else if (accountNumber) {
    return accountNumber;
  } else if (date) {
    return DateTime.fromJSDate(date).toFormat('yyyyMMdd');
  } else {
    return '';
  }
};

export enum ClinicalNotePreviewTabs {
  NoteInfo = 'noteInfo',
  BillingInfo = 'billingInfo',
}

interface ClinicalNoteTopBarProps {
  mode?: ClinicalNotePreviewMode;
  currentTab?: ClinicalNotePreviewTabs;
  printContent: () => null | React.ReactInstance;
  changeTabCallback?: (newTab: ClinicalNotePreviewTabs) => void;
}

const TopBarContainer = styled.div`
  width: 100%;
  min-height: 35px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-top: 1px solid #dcdfe3;
  flex-shrink: 0;
  padding: 6px 40px 0px 40px;
`;

const PrintIconButton = styled(PrintIcon)`
  align-self: center;
  margin-left: auto;
  margin-right: 35px;
`;

export const ClinicalNoteTopBar: FC<ClinicalNoteTopBarProps> = observer(
  ({ mode, printContent = () => null }) => {
    const printTrigger = useCallback(() => {
      return <PrintIconButton />;
    }, []);

    const { accountNumber } = patientStore;
    const { dateOfService, clinicalNote } = clinicalNoteStore;

    if (
      mode === ClinicalNotePreviewMode.SignedPreview ||
      mode === ClinicalNotePreviewMode.UnsignedPreview
    ) {
      const documentTitle = makeDocumentTitle(
        accountNumber?.value,
        dateOfService
      );
      const canGoBackToEditor =
        ClinicalNotePreviewMode.UnsignedPreview &&
        clinicalNote?.canSignNote === true &&
        clinicalNote.status !== ClinicalNoteStatus.Signed;

      return (
        <TopBarContainer>
          <ConditionalRender shouldRender={canGoBackToEditor}>
            <ClosePreviewButton />
          </ConditionalRender>
          <ConditionalRender
            shouldRender={mode === ClinicalNotePreviewMode.SignedPreview}
          >
            <ReactToPrint
              content={printContent}
              documentTitle={documentTitle}
              removeAfterPrint
              trigger={printTrigger}
            />
          </ConditionalRender>
        </TopBarContainer>
      );
    }

    return null;
  }
);
