export const TestID = {
  Auth: {
    SignInEmailField: 'AuthSignInEmailField',
    SignInPasswordField: 'AuthSignInPasswordField',
    SignInSubmitButton: 'AuthSignInSubmitButton',
    SignInMicrosoft: 'AuthSignInMicrosoft',
  },
  AkidoUI: {
    Icon: {
      CircleErrorIcon: 'AkidoUICircleErrorIcon',
    },
  },
  Error: {
    ErrorPage: 'ErrorPage',
  },
  ClinicalNote: {
    OpenNotePreviewButton: 'ClinicalNoteOpenPreviewButton',
    NotePreviewPage: 'ClinicalNotePreviewPage',
    ClinicalNotesEditorContainer: 'ClinicalNotesEditorContainer',
    SaveButton: 'clinicalNoteSaveButton',
    NotePreviewHeader: 'ClinicalNotePreviewHeader',
    PreviewCloseButton: 'ClinicalNotePreviewCloseButton',
    PreviewHeaderReportTitleText: 'ClinicalNotePreviewHeaderReportTitleText',
    PreviewHeaderReportAccountNumber:
      'ClinicalNotePreviewHeaderAccountNumberHeader',
    PreviewHeaderReportSigned: 'PreviewHeaderReportSigned',
    PreviewLeftColumn: 'PreviewLeftColumn',
    PreviewMainContent: 'PreviewMainContent',
    PreviewRightColumn: 'PreviewRightColumn',
    PreviewDemographicsContainer: 'NotePreviewDemographicsContainer',
    PreviewPhysicianAndAccountNumber: 'NotePreviewPhysicianAndAccountNumber',
    PreviewNoteTitleText: 'NotePreviewNoteTitleText',
    PreviewServiceDateText: 'NotePreviewServiceDateText',
    PreviewBilling: 'NotePreviewBilling',
    PreviewAccountNumber: 'AccountNumberPreview',
    PreviewReferringPractitioner: 'ReferringPractitionerPreview',
    Loading: 'ClinicalNotesLoading',
    TemplateDropdownHeader: 'TemplateDropdownHeader',
    CopyClinicalNoteModal: 'CopyClinicalNoteModal',
    CopyClinicalNoteModalAcceptButton: 'CopyClinicalNoteModal-accept',
    CopyClinicalNoteModalDeclineButton: 'CopyClinicalNoteModal-button',
    ChangeTemplateModal: 'ChangeTemplateModal',
    ChangeTemplateModalAcceptButton: 'ChangeTemplateModal-accept',
    ChangeTemplateModalDeclineButton: 'ChangeTemplateModal-decline',
    TemplateDropdownTextDefault: 'TemplateDropdownTextDefault',
    ResultDialog: 'ClinicalPreviewResultDialog',
    CloseResultDialog: 'CloseResultDialogButton',
    EditorStatusBox: 'ClinicalNoteEditorStatusBox',
    PatientInfoContainer: 'ClinicalNotePatientInfoContainer',
    Error: {
      MissingIdError: 'ClinicalNoteMissingIdErrorBox',
      FetchErrorAkidoId: 'ClinicalNoteFetchErrorAkidoIdBox',
      FetchErrorExternalId: 'ClinicalNoteFetchErrorExternalIdBox',
      FetchTemplatesError: 'ClinicalNoteFetchTemplatesErrorBox',
    },
    VitalsItemsContainer: 'VitalsItemsContainer',
    VitalsSpacer: 'VitalsSpacer',
    RefreshButton: 'RefreshButton',
    CopyPreviousEncounterButton: 'CopyPreviousEncounterButton',
    CopyPreviousEncounterLink: 'CopyPreviousEncounterLink',
    CopyPreviousErrorModal: 'CopyPreviousErrorModal',
    RunAIButton: 'RunAIButton',
    EmptyAiOutput: 'EmptyAiOutput',
    AddendaPreviewContainer: 'AddendaPreviewContainer',
    TranscribeControl: {
      Container: 'ClinicalNoteTranscribeControlContainer',
      Button: 'ClinicalNoteTranscribeControlButton',
      Icon: 'ClinicalNoteTranscribeControlIcon',
    },
    DragonTranscribeControl: {
      Container: 'ClinicalNoteDragonTranscribeControlContainer',
      Button: 'ClinicalNoteDragonTranscribeControlButton',
    },
  },
  Calendar: {
    OpenCapellaSchedulerButton: 'OpenCapellaSchedulerButton',
    CalendarEvent: 'CalendarEvent',
    OpenNoteButton: 'CalendarOpenNote',
    OpenCreateEncounterButton: 'OpenCreateEncounterButton',
    CreateEncounterDrawer: 'CreateEncounterDrawer',
    CancelCreateEncounterButton: 'CancelCreateEncounterButton',
    SaveEncounterButton: 'SaveEncounterButton',
    CalendarMonthSelect: 'CalendarMonthSelect',
    CalendarYearSelect: 'CalendarYearSelect',
    ErrorAlertModal: 'CalendarErrorAlertModal',
  },
  CollapsibleSection: {
    ContentContainer: 'CollapsibleSectionContentContainer',
  },
  Addendum: {
    AddAddendumContainer: 'AddAddendumContainer',
    AddendumInputsContainer: 'AddendumInputsContainer',
    AddAddendumButton: 'AddAddendumButton',
    SignAddendumButtons: 'SignAddendumButtons',
    AddendumContent: 'AddendumContent',
    AddAddendumResultDialog: 'AddendumResultDialog',
    AddAddendumCloseResultDialog: 'AddendumCloseResultDialog',
  },
  Sign: {
    SignContainer: 'AddAddendumContainer',
    SignButton: 'ClinicalNoteSignButton',
  },
  Help: {
    HelpContainer: 'HelpContainer',
  },
  Hooks: {
    PatientClinicalDataNothingFound: 'PatientClinicalDataNothingFound',
    PatientClinicalDataFound: 'PatientClinicalDataFound',
  },
  Transcriptions: {
    FilterButtonDefault: 'TranscriptionsFilterButtonDefault',
    FilterDisplay: 'TranscriptionsFilterDisplay',
    SearchInput: 'TranscriptionsSearchInput',
    Table: 'TranscriptionsTable',
  },
  SideDrawer: {
    SetDateTitle: 'SideDrawerSetDateTitle',
    SetTimeTitle: 'SideDrawerSetTimeTitle',
    DatePicker: 'SideDrawerDatePicker',
    AmPmPicker: 'SideDrawerAmPmPicker',
    TimePickerContainer: 'SideDrawerTimePickerContainer',
    TimePickerDropDownArrow: 'SideDrawerTimePickerDropDownArrow',
    StartTimePicker: 'SideDrawerStartTimePicker',
    EndTimePicker: 'SideDrawerEndTimePicker',
    SelectedPatient: 'SideDrawerSelectedPatient',
    AddNewPatientButton: 'AddNewPatientButton',
    ErrorAlertModal: 'ErrorAlertModal',
  },
  Header: {
    UserMenu: 'HeaderUserMenu',
    CapellaMyQButton: 'HeaderCapellaMyQButton',
    MyTasksLink: 'MyTasksLink',
  },
  AIClinicalNote: {
    AIClinicalNoteHeaderButtons: {
      Save: 'SaveAIClinicalNote',
      Preview: 'PreviewAIClinicalNote',
      Container: 'AIClinicalNoteHeaderButtonsContainer',
      RefreshButtonLoading: 'RefreshButtonLoading',
      RefreshButtonIdle: 'RefreshButtonIdle',
    },
    AIClinicalNoteGlobalHeader: 'AIClinicalNoteGlobalHeader',
    AINotePageLayout: 'AINotePageLayout',
    AINoteTabs: {
      SummaryEmpty: 'AINoteSummaryTabEmpty',
      TranscriptEmpty: 'AINoteTranscriptTabEmpty',
      TabButton: 'AINoteTabButton',
    },
    TranscribeControl: {
      Container: 'TranscribeControlContainer',
      Button: 'TranscribeControlButton',
      Icon: 'TranscribeControlIcon',
    },
  },
  CreatePatientModal: {
    Dialog: 'CreatePatientModalDialog',
    FirstNameInput: 'CreatePatientModalFirstNameInput',
    LastNameInput: 'CreatePatientModalLastNameInput',
    DateOfBirthInput: 'CreatePatientModalDateOfBirthInput',
    PhoneNumberInput: 'CreatePatientModalPhoneNumberInput',
    SexAtBirthInput: 'CreatePatientModalSexAtBirthInput',
    StreetAddressInput1: 'CreatePatientModalStreetAddressInput1',
    StreetAddressInput2: 'CreatePatientModalStreetAddressInput2',
    CityInput: 'CreatePatientModalCityInput',
    StateInput: 'CreatePatientModalStateInput',
    ZipCodeInput: 'CreatePatientModalZipCodeInput',
    CancelButton: 'CreatePatientModalCancelButton',
    SubmitButton: 'CreatePatientModalSubmitButton',
    Region: 'CreatePatientModalRegionInput',
    InsurancePlan: 'CreatePatientModalInsurancePlanInput',
    MemberId: 'CreatePatientModalMemberIdInput',
  },
};
