import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { TestID } from '../../constants/testIds';
import { ReactComponent as BackArrow } from '../../assets/icons/arrow-back-ios.svg';

const ButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 19px;
`;

const ButtonText = styled.span`
  font-size: 0.88rem;
`;

export const ClosePreviewButton = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const clinicalNoteEditorUrl = `/clinical-notes/${id}`;

  return (
    <ButtonContainer
      data-testid={TestID.ClinicalNote.PreviewCloseButton}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
      onClick={() => {
        navigate(clinicalNoteEditorUrl);
      }}
    >
      <BackArrow height={24} width={24} fill='#1B4C73' />
      <ButtonText>Back to editor</ButtonText>
    </ButtonContainer>
  );
};
