import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHasLoaded } from './useHasLoaded';
import { escapeRegex } from '../helpers/escapeRegex';
import { loopingClamp } from '../helpers/loopingClamp';
import { isNil } from 'lodash';

export type UseTextMarkersArgs = {
  value: string;
  token: string;
  navigationKey: string;
  textArea: HTMLTextAreaElement | null | undefined;
};
export const useTextMarkers = (args: UseTextMarkersArgs) => {
  const { value, token, navigationKey, textArea } = args;

  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(0);
  const [markers, setMarkers] = useState<number[]>([]);
  const escapedToken = useMemo(() => escapeRegex(token), [token]);
  const hasLoaded = useHasLoaded();

  useEffect(() => {
    const markerPositions: number[] = [];
    const markerRegex = new RegExp(escapedToken, 'g');
    let match = markerRegex.exec(value);

    while (match !== null) {
      markerPositions.push(match.index);
      match = markerRegex.exec(value);
    }

    setMarkers(markerPositions);

    setCurrentMarkerIndex((prev) => {
      if (hasLoaded) {
        return loopingClamp(prev - 1, 0, markerPositions.length - 1);
      }

      return prev;
    });
  }, [value, escapedToken, hasLoaded]);

  const _setCurrentMarkerIndex = useCallback(
    (index: number, nextAvailableMarker: number) => {
      let nextMarkerIndex = loopingClamp(index, 0, markers.length - 1);

      if (nextAvailableMarker || nextAvailableMarker === 0) {
        nextMarkerIndex = markers.indexOf(nextAvailableMarker);
      }

      setCurrentMarkerIndex(nextMarkerIndex);
      if (isNil(textArea)) return;

      textArea.setSelectionRange(
        markers[nextMarkerIndex],
        markers[nextMarkerIndex] + token.length
      );
    },
    [markers, token.length, textArea]
  );

  const _onNavigationKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === navigationKey && markers.length !== 0) {
        e.preventDefault();

        if (e.shiftKey) {
          const firstLargestMarker =
            markers.find(
              (marker) => marker >= (textArea?.selectionStart ?? 0)
            ) ?? markers[markers.length - 1];

          const nextAvailableMarker =
            markers[
              loopingClamp(
                markers.indexOf(firstLargestMarker) - 1,
                0,
                markers.length - 1
              )
            ];

          _setCurrentMarkerIndex(currentMarkerIndex - 1, nextAvailableMarker);
        } else {
          const nextAvailableMarker =
            markers.find(
              (marker) => marker > (textArea?.selectionStart ?? 0)
            ) ?? markers[0];
          _setCurrentMarkerIndex(currentMarkerIndex + 1, nextAvailableMarker);
        }
      }
    },
    [
      navigationKey,
      markers,
      _setCurrentMarkerIndex,
      currentMarkerIndex,
      textArea?.selectionStart,
    ]
  );

  const _onFocus = useCallback(() => {
    if (isNil(textArea) || markers.length === 0) return;

    textArea.selectionStart = markers[currentMarkerIndex];
    textArea.selectionEnd = markers[currentMarkerIndex] + token.length;
  }, [textArea, markers, currentMarkerIndex, token]);

  return {
    onKeyDown: _onNavigationKeyDown,
    onFocus: _onFocus,
  };
};
