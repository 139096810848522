import { useEffect, useState } from 'react';

import {
  LanguageCode,
  TranscribeStreamingClient,
} from '@aws-sdk/client-transcribe-streaming';
import { InputElement, LiveTranscriptionArgs, Transcript } from './types';
import { startStreaming } from './utils/start-streaming';
import { stopStreaming } from './utils/stop-streaming';
import { updateTranscription } from './utils/update-transcription';

const sampleRate = 48000;
const language = 'en-US' as LanguageCode;

export const useLiveTranscriptions = (args: LiveTranscriptionArgs) => {
  const { currentCredentials } = args;

  const [_mediaRecorder, setMediaRecorder] = useState<AudioWorkletNode>();
  const [_transcript, setTranscript] = useState<Transcript>();
  const [_transcriptionClient, setTranscriptionClient] =
    useState<TranscribeStreamingClient>();
  const [isTranscribing, setIsTranscribing] = useState<boolean>(false);
  const [lines, setLines] = useState<Transcript[]>([]);
  const [currentLine, setCurrentLine] = useState<Transcript[]>([]);
  const [currentText, setCurrentText] = useState<string | null>(null);

  useEffect(() => {
    if (_transcript) {
      const focusedElement = window.AkidoTranscribe
        ?.focusedElement as InputElement;

      if (!focusedElement) return;

      _transcript.id = focusedElement.id;

      if (_transcript.partial) {
        setCurrentText((current) =>
          current === null ? focusedElement.value : current
        );
        setCurrentLine([_transcript]);
      } else {
        setLines((previousLines) => [...previousLines, _transcript]);
        setCurrentLine([]);
        setCurrentText(null);
      }
    }
  }, [_transcript]);

  useEffect(() => {
    updateTranscription(currentLine[0]?.text, currentText);
  }, [currentText, currentLine]);

  const onTranscriptionDataReceived = (
    data: string,
    partial: boolean,
    transcriptionClientFromWorklet: TranscribeStreamingClient,
    mediaRecorderFromWorklet: AudioWorkletNode
  ) => {
    setTranscript({
      channel: '0',
      partial: partial,
      text: data,
    });
    setMediaRecorder(mediaRecorderFromWorklet);
    setTranscriptionClient(transcriptionClientFromWorklet);
  };

  const startRecording = async () => {
    if (!currentCredentials) {
      console.error('No AWS credentials found.');
      setIsTranscribing(false);
      return;
    }

    try {
      await startStreaming({
        handleTranscribeOutput: onTranscriptionDataReceived,
        currentCredentials,
        language,
        sampleRateHz: sampleRate,
      });
    } catch (error) {
      alert(`An error occurred while recording: ${error}`);
      setIsTranscribing(false);
    }
  };

  async function toggleTranscribe() {
    if (isTranscribing) {
      await startRecording();
    } else {
      await stopStreaming(_mediaRecorder, _transcriptionClient);
    }
  }

  useEffect(() => {
    void toggleTranscribe();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTranscribing]);

  return {
    startTranscribing: () => {
      setIsTranscribing(true);
    },
    stopTranscribing: () => {
      setIsTranscribing(false);
    },
    isTranscribing,
    transcript: _transcript,
    lines,
    currentText,
    currentLine,
  };
};
