import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePathRegex, RoutePaths } from '../../constants/routes';
import { ClinicalNoteButtons } from './ClinicalNoteButtons';

export const RouteAwareHeaderButtons: FC = () => {
  const { pathname } = useLocation();

  const shouldShowClinicalNotesButtons = useMemo(() => {
    return (
      (pathname === RoutePaths.ClinicalNotes ||
        pathname.match(RoutePathRegex.ClinicalNotesWithId)?.input) &&
      !pathname.includes('preview')
    );
  }, [pathname]);

  if (!shouldShowClinicalNotesButtons) return null;

  return <ClinicalNoteButtons />;
};
