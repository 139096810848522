import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styled from 'styled-components';
import { ClinicalNotePreviewMode } from './ClinicalNotePreview';
import { PreviewSignCard } from './Sign/SignCard';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import { AddAddendum } from './Addendum/AddAddendum';

interface ClinicalNotePreviewBottomBarProps {
  mode: ClinicalNotePreviewMode;
  onClickSign: () => void;
  onClickAddAddendum: () => void;
}

const BottomBar = styled.footer`
  border-top: solid 2px #d0d7e4;
  background-color: #fafbfc;
  height: 85px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const ClinicalNotePreviewBottomBar: FC<ClinicalNotePreviewBottomBarProps> =
  observer(({ mode, onClickSign, onClickAddAddendum }) => {
    const { clinicalNote } = clinicalNoteStore;
    switch (mode) {
      case ClinicalNotePreviewMode.SignedPreview:
        return (
          <ConditionalRender
            shouldRender={clinicalNote?.canAddAddendum || false}
          >
            <BottomBar>
              <AddAddendum onAddAddendum={onClickAddAddendum} />
            </BottomBar>
          </ConditionalRender>
        );
      case ClinicalNotePreviewMode.UnsignedPreview:
        return (
          <ConditionalRender shouldRender={clinicalNote?.canSignNote || false}>
            <BottomBar>
              <PreviewSignCard onSign={onClickSign} />
            </BottomBar>
          </ConditionalRender>
        );
      default:
        return <BottomBar />;
    }
  });
