import React, { createContext, useCallback, useContext, useState } from 'react';
import { ToastProps } from './Toast';
import { uniqueId } from 'lodash';

type ToastProviderProps = {
  children: React.ReactNode;
};

// Remove id for toast data since that will be generated in the addToast function
export type ToastData = Omit<ToastProps, 'id'>;

type ToastContextType = {
  toasts: Map<string, ToastProps>;
  addToast: (toast: ToastData) => void;
  removeToast: (id: string) => void;
};

const ToastContext = createContext<ToastContextType>({} as ToastContextType);

export function ToastProvider(props: ToastProviderProps) {
  const { children } = props;
  // Use a ES6 Map, so we can preserve insertion order of the toasts
  const [toasts, setToast] = useState(new Map<string, ToastProps>());

  const addToast = useCallback((toast: ToastData) => {
    // Generate a random id for the toast. We can use this as a reference for removing the toasts later.
    const id = uniqueId('toast_');
    setToast((existingToasts) => {
      const updatedToasts = new Map(existingToasts);
      updatedToasts.set(id, { ...toast, id });
      return updatedToasts;
    });

    // Return the toast id in case the consumer would like to remove it later
    return id;
  }, []);

  const removeToast = useCallback((id: string) => {
    setToast((existingToasts) => {
      if (!existingToasts.has(id)) return existingToasts;

      const updatedToasts = new Map(existingToasts);
      updatedToasts.delete(id);
      return updatedToasts;
    });
  }, []);

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  );
}

export const useToast = () => useContext(ToastContext);
