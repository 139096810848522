import React, { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';

const DEFAULT_WIDTH = 'w-[400px]';
const ANIMATION_TIME = 200;

type SideDrawerProps = {
  ['data-testid']?: string;
  isOpen: boolean;
  unmountOnExit?: boolean;
  children?: React.ReactNode;
};

export function SideDrawer(props: SideDrawerProps) {
  const {
    'data-testid': testId,
    isOpen,
    unmountOnExit = true,
    children,
  } = props;
  // We want the drawer to initially be mounted if it is open, or if we never want to unmount it.
  const [shouldRender, setShouldRender] = useState(isOpen || !unmountOnExit);
  // Skip the first animation
  const shouldAnimate = useRef(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (unmountOnExit) {
      if (isOpen && !shouldRender) {
        setShouldRender(true);
      } else if (!isOpen && shouldRender) {
        // Delay the setShouldRender state update to wait for the animation to end
        timeoutId = setTimeout(() => setShouldRender(false), ANIMATION_TIME);
      }
    }

    return () => clearTimeout(timeoutId);
  }, [isOpen, shouldRender, unmountOnExit]);

  // Begin animations after the first render
  useEffect(() => {
    if (!shouldAnimate.current) shouldAnimate.current = true;
  }, [isOpen]);

  if (!shouldRender && unmountOnExit) return null;

  return (
    <Transition
      show={isOpen}
      unmount={unmountOnExit}
      appear={shouldAnimate.current}
      enter={`transition-all duration-200 ease-in`}
      leave={`transition-all duration-200 ease-in`}
      enterFrom='w-0'
      enterTo={DEFAULT_WIDTH}
      leaveFrom={DEFAULT_WIDTH}
      leaveTo='w-0'
      className={
        'flex-shrink-0 overflow-hidden whitespace-nowrap border-l border-gray-300 bg-white pb-20'
      }
      data-testId={testId}
    >
      {children}
    </Transition>
  );
}
