import { TestID } from '../../constants/testIds';
import { useFetchClinicalNoteTemplate } from '../../hooks/useFetchClinicalNoteTemplate';
import { useUserAuthorization } from '../../hooks/useUserAuthorization';
import { clinicalEditorPageState } from '../../stores/clinicalEditorPageState.store';
import { clinicalNoteTemplatesStore } from '../../stores/clinicalNoteTemplates.store';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { RoutePaths } from '../../constants/routes';
import { useClinicalNoteEditorPageNavigation } from './hooks/useClinicalNoteEditorPageNavigation';
import { useHandleOnClinicalNotePageSave } from './hooks/useHandleOnClinicalNotePageSave';
import { useNoteEditorAutoSave } from './hooks/useNoteEditorAutoSave';
import { localClinicalNotesStore } from '../../stores/localPersistentClinicalNotes.store';
import {
  getErrorCodeFromGraphqlCall,
  resourceIdTypeToResourceType,
} from '../../utilities/helpers';
import { useNoteEditorFromExternalEncounterIdPageLoad } from './hooks/useNoteEditorFromExternalEncounterIdPageLoad';
import { ClinicalNoteEditorPage } from './ClinicalNoteEditorPage';
import { useAnalytics } from '../../hooks/useAnalytics';
import useNUSAProcessingWatcher from '../../hooks/useNUSAProcessingWatcher';
import { ClinicalNoteAnalyticsEventName } from '../../constants/analytics';
import { ClinicalNoteScreens } from '../../types/screens';

const errorRoute = (errorCode?: string) => {
  return RoutePaths.Error + (errorCode ? `?code=${errorCode}` : '');
};

export const ClinicalNoteEditorPageFromExternalEncounterId = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sendClinicalNoteAnalyticsEvent } = useAnalytics();

  const [searchParams] = useSearchParams();
  const resourceIdType = searchParams.get('resourceIdType');
  const resourceType = resourceIdType
    ? resourceIdTypeToResourceType(resourceIdType)
    : undefined;

  const fetchTemplatesError = clinicalNoteTemplatesStore.templateErrors;
  const {
    hasSaved: hasSuccessfullySaved,
    isSaving,
    hasSaveError,
  } = clinicalEditorPageState;

  useFetchClinicalNoteTemplate({
    screen: ClinicalNoteScreens.EDITOR,
    resourceType,
  });
  useUserAuthorization();
  useNUSAProcessingWatcher();

  useEffect(() => {
    sendClinicalNoteAnalyticsEvent(ClinicalNoteAnalyticsEventName.StartNote, {
      capella_note_id: clinicalNote?.encounterId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    clinicalNote,
    externalEncounterId,
    hasLoadLocal,
    clinicalNoteData,
    fetchNoteByExternalIdError,
    isDataForEditorLoading,
    isPatientLoading,
  } = useNoteEditorFromExternalEncounterIdPageLoad();

  const { saveNote } = useHandleOnClinicalNotePageSave({
    akidoNoteId: undefined,
    currentId: null,
    externalEncounterId: externalEncounterId || undefined,
  });

  useNoteEditorAutoSave({ saveNote, hasLoadLocal });

  useClinicalNoteEditorPageNavigation({
    clinicalNoteData,
    saveNote,
    akidoNoteId: undefined,
    clinicalNote,
    isNoteSigned: false,
  });

  const onNoteSign = useCallback(async () => {
    const noteId = await saveNote();
    if (externalEncounterId) {
      localClinicalNotesStore.deleteClinicalNoteById(externalEncounterId);
    }

    sendClinicalNoteAnalyticsEvent(ClinicalNoteAnalyticsEventName.PreviewNote, {
      capella_note_id: clinicalNote?.encounterId,
      akido_note_id: noteId,
    });
    navigate(`${RoutePaths.ClinicalNotePreview.replace(':id', noteId)}`);
  }, [
    clinicalNote,
    externalEncounterId,
    navigate,
    saveNote,
    sendClinicalNoteAnalyticsEvent,
  ]);

  if (!externalEncounterId) {
    navigate(`${RoutePaths.Error}?code=404`, {
      state: { previousPath: location.pathname },
    });
    return (
      <div data-testid={TestID.ClinicalNote.Error.MissingIdError}>
        There is no id to fetch an encounter
      </div>
    );
  }

  if (fetchNoteByExternalIdError) {
    const errorCode = getErrorCodeFromGraphqlCall(fetchNoteByExternalIdError);
    navigate(errorRoute(errorCode), {
      state: { previousPath: location.pathname },
    });
    return (
      <div data-testid={TestID.ClinicalNote.Error.FetchErrorExternalId}>
        There was an error fetching the clinical note for encounter{' '}
        {externalEncounterId}
        {JSON.stringify(fetchNoteByExternalIdError)}
      </div>
    );
  }

  if (fetchTemplatesError) {
    const errorCode = getErrorCodeFromGraphqlCall(fetchTemplatesError);
    navigate(errorRoute(errorCode), {
      state: { previousPath: location.pathname },
    });
    return (
      <div data-testid={TestID.ClinicalNote.Error.FetchTemplatesError}>
        There was an error when loading the templates
        {JSON.stringify(fetchTemplatesError)}
      </div>
    );
  }

  return (
    <ClinicalNoteEditorPage
      pageTestId={'clinical-notes-editor-page-external-encounter-id'}
      noteId={externalEncounterId}
      isPatientLoading={isPatientLoading}
      clinicalNote={clinicalNote}
      saveNote={saveNote}
      onNoteSign={onNoteSign}
      isSaving={isSaving}
      hasSuccessfullySaved={hasSuccessfullySaved}
      hasSaveError={hasSaveError}
      isDataForEditorLoading={isDataForEditorLoading}
      isLoadingFromExternalId
    />
  );
});
