import { ReactNode } from 'react';
import styled from 'styled-components';

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const OptionsContainer = styled.div`
  border-left: 2px solid #eceef1;
`;

export function SidebarSection({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <>
      <Header data-public>{title}</Header>
      <OptionsContainer>{children}</OptionsContainer>
    </>
  );
}
