import styled, { CSSObject } from 'styled-components';

export const defaultHeaderStyle: CSSObject = {
  textAlign: 'left',
  padding: '8px',
  borderBottomWidth: '2px',
  borderBottomStyle: 'solid',
  borderBottomColor: '#d1e0e8',
};
export const TableHeader = styled.thead(defaultHeaderStyle);
