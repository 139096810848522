import { CSSProperties, FunctionComponent } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import './date-picker.css';

const Container = styled.div`
  position: relative;
`;

interface DatePickerStyles {
  container?: CSSProperties;
}

interface DatePickerProps {
  selectedDate?: Date;
  onChange: (date: Date) => void;
  styles?: DatePickerStyles;
  dateFormat?: string;
  className?: string;
}

export const DatePicker: FunctionComponent<DatePickerProps> = (props) => {
  const {
    styles,
    dateFormat,
    className,
    onChange,
    selectedDate: value,
  } = props;

  return (
    <Container className={className} style={styles?.container}>
      <ReactDatePicker
        showIcon
        icon={<CalendarIcon />}
        selected={value}
        placeholderText={'Jan 01, 20'}
        dateFormat={dateFormat ?? 'MMM dd, yy'}
        popperPlacement='bottom-start'
        showPopperArrow={false}
        timeClassName={() => 'time'}
        className='date_input'
        onChange={onChange}
      />
    </Container>
  );
};
