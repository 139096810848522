import { CopyPreviousEncounterButton } from './CopyPreviousEncounterButton';
import { TopRefreshClinicalNoteButton } from './TopRefreshClinicalNoteButton';
import { FEATURE_FLAGS } from '../../config/launchdarkly';
import FeatureFlaggedConditionalRender from '../FeatureFlaggedConditionalRender/FeatureFlaggedConditionalRender';

export const ClinicalNoteButtons = () => {
  return (
    <>
      <FeatureFlaggedConditionalRender featureFlag={FEATURE_FLAGS.COPY_BUTTON}>
        <CopyPreviousEncounterButton
          styles={{ buttonContainer: { marginRight: '20px' } }}
        />
      </FeatureFlaggedConditionalRender>
      <TopRefreshClinicalNoteButton />
    </>
  );
};
