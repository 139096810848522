export enum PatientTypes {
  CAPELLA_PATIENT_ID = 'capella_patient_id',
  FHIR_PATIENT_ID = 'fhir_patient_id',
}

export enum ClinicalNoteTypes {
  AKIDO_CLINICAL_NOTE_ID = 'akido_clinical_note_id',
}

export enum EncounterTypes {
  CAPELLA_ENCOUNTER_ID = 'capella_encounter_id',
}
