export const OptionColors: Record<string, string> = {
  not_started: '#F0F0F0',
  in_progress: '#D4BC63',
  completed: '#97BC59',
};

export enum StatusOptionId {
  NotStarted = 'not_started',
  Completed = 'completed',
  InProgress = 'in_progress',
}

export const statusOptionsById: Record<StatusOptionId, any> = {
  [StatusOptionId.NotStarted]: {
    value: StatusOptionId.NotStarted,
    label: 'Not Started',
    backgroundColor: OptionColors[StatusOptionId.NotStarted],
  },
  [StatusOptionId.InProgress]: {
    value: StatusOptionId.InProgress,
    label: 'In Progress',
    backgroundColor: OptionColors[StatusOptionId.InProgress],
  },
  [StatusOptionId.Completed]: {
    value: StatusOptionId.Completed,
    label: 'Completed',
    backgroundColor: OptionColors[StatusOptionId.Completed],
  },
};

export const defaultStatusOptions = Object.values(statusOptionsById);
