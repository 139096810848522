import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { ClinicalNoteStatus } from '../../types/clinical-notes';

interface NoteStatusBadgeProps {
  status?: ClinicalNoteStatus;
}

const Badge = styled.span`
  color: #000000;
  font-size: 0.775rem;
  line-height: 1rem;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 12px;
  margin-left: 35px;
`;

const statusMetadata: Record<
  ClinicalNoteStatus,
  { style: CSSProperties; label: string }
> = {
  [ClinicalNoteStatus.Signed]: {
    label: 'Signed',
    style: {
      backgroundColor: '#daf0e3',
      color: '#000000',
    },
  },
  [ClinicalNoteStatus.Draft]: {
    label: 'Unsigned',
    style: { backgroundColor: '#FFF2D9', color: '#00000' },
  },
};

export const NoteStatusBadge: FC<NoteStatusBadgeProps> = (props) => {
  const { status } = props;

  if (!status) {
    return null;
  }

  return (
    <Badge style={statusMetadata[status]?.style}>
      {statusMetadata[status]?.label}
    </Badge>
  );
};
