import { action, computed, makeObservable, observable } from 'mobx';

export class EditScribeNoteModalStore {
  isVisible: boolean = false;
  originalNote: string | null = null;
  editedNote: string | null = null;
  selectedEncounterId: string | null = null;
  mode: string = 'view';
  scribeNoteId: string | null = null;

  constructor() {
    makeObservable(this, {
      cancelEdit: action,
      editNote: action,
      hideModal: action,
      isEditMode: computed,
      isVisible: observable,
      mode: observable,
      note: computed,
      originalNote: observable,
      editedNote: observable,
      reset: action,
      scribeNoteId: observable,
      selectedEncounterId: observable,
      setEncounterId: action,
      setMode: action,
      setNote: action,
      showModal: action,
    });
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get note() {
    return this.editedNote ?? this.originalNote;
  }

  showModal() {
    this.isVisible = true;
  }
  hideModal() {
    this.reset();
  }

  setNote(note: string | null) {
    this.originalNote = note;
  }

  setScribeNoteId(id: string | null) {
    this.scribeNoteId = id;
  }

  editNote(note: string | null) {
    this.editedNote = note;
  }

  cancelEdit() {
    this.mode = 'view';
    this.editNote(null);
  }

  setEncounterId(id?: string | null) {
    if (id) {
      this.selectedEncounterId = id;
    }
  }

  reset() {
    this.setMode('view');
    this.isVisible = false;
    this.editedNote = null;
    this.originalNote = null;
    this.selectedEncounterId = null;
    this.scribeNoteId = null;
  }

  setMode(mode: 'view' | 'edit') {
    this.mode = mode;
  }
}

export const editScribeNoteModalStore = new EditScribeNoteModalStore();
