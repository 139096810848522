import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { clinicalNoteTemplatesStore } from '../../../stores/clinicalNoteTemplates.store';
import {
  LocalClinicalNoteSection,
  localClinicalNotesStore,
} from '../../../stores/localPersistentClinicalNotes.store';
import {
  addAutofillFromClinicalNoteValues,
  mergeClinicalNoteSectionsArray,
} from '../../../utilities/helpers';
import { ClinicalNoteModel } from '@akido/provider-desktop-bffe-types';
interface IProps {
  localStoreEnabled: boolean;
  currentId: string | null;
  clinicalNoteReturn?: {
    getClinicalNote: ClinicalNoteModel;
  };
  setClinicalNote: (newClinicalNote: any | undefined) => void;
}

export const useLoadClinicalNote = ({
  localStoreEnabled,
  currentId,
  clinicalNoteReturn,
  setClinicalNote,
}: IProps) => {
  const loadClinicalNote = () => {
    if (localStoreEnabled) {
      if (currentId) {
        // Loading data for the first time
        const locallySavedClinicalNote =
          localClinicalNotesStore.getClinicalNoteById(currentId);
        let remoteUpdatedAt;
        if (clinicalNoteReturn?.getClinicalNote.updatedAt) {
          remoteUpdatedAt = new Date(
            clinicalNoteReturn?.getClinicalNote.updatedAt
          ).getTime();
        }
        if (
          remoteUpdatedAt &&
          locallySavedClinicalNote?.localUpdatedAt &&
          locallySavedClinicalNote?.localUpdatedAt < remoteUpdatedAt
        ) {
          // Our local version is older than the one from the server. Delete local
          localClinicalNotesStore.deleteClinicalNoteById(currentId);
          setClinicalNote(clinicalNoteReturn?.getClinicalNote);
        } else {
          // We don't have a local version, or our local version is newer than remote
          if (locallySavedClinicalNote?.content.title) {
            clinicalNoteStore.setEditableNoteTitle(
              locallySavedClinicalNote.content.title
            );
          }
          if (clinicalNoteReturn) {
            // There is data from the server

            // Get template for this clinical note
            const templateId =
              clinicalNoteReturn.getClinicalNote.content.templateId;
            let template;
            if (templateId) {
              template = clinicalNoteTemplatesStore.getTemplateById(templateId);
            }

            let newClinicalNote = { ...clinicalNoteReturn.getClinicalNote };
            if (locallySavedClinicalNote) {
              // Load local data for the clinical note
              if (
                locallySavedClinicalNote.content.sections &&
                clinicalNoteReturn.getClinicalNote.content.sections
              ) {
                // Load content of local clinical note sections
                newClinicalNote.content.sections =
                  mergeClinicalNoteSectionsArray(
                    locallySavedClinicalNote.content.sections,
                    clinicalNoteReturn.getClinicalNote.content.sections
                  );
              }
              if (locallySavedClinicalNote?.content.title) {
                newClinicalNote.content.title =
                  locallySavedClinicalNote?.content.title;
              }
            }
            if (template) {
              // Add the autofillFrom property that comes from the template
              newClinicalNote.content.sections =
                addAutofillFromClinicalNoteValues(
                  newClinicalNote.content
                    .sections as LocalClinicalNoteSection[],
                  template
                );
            }
            setClinicalNote(newClinicalNote);
            localClinicalNotesStore.addNote(currentId, newClinicalNote);
          } else {
            // No data in server for this clinical note
            if (locallySavedClinicalNote) {
              clinicalNoteStore.loadLocalClinicalNote(currentId);
            } else {
              // No data in either server nor local for this clinical note
              localClinicalNotesStore.addNote(currentId);
            }
          }
        }
      } else {
        // No id for the clinical note
        setClinicalNote(undefined);
      }
    } else {
      // Feature flag for local storage is inactive
      setClinicalNote(clinicalNoteReturn?.getClinicalNote);
    }
  };

  return { loadClinicalNote };
};
