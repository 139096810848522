import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

interface PanelProps extends PropsWithChildren {
  visible: boolean;
  ['data-testid']?: string;
  className?: string;
}

export const Panel: FC<PanelProps> = (props: PanelProps) => {
  const { visible, children, className, 'data-testid': dataTestID } = props;

  const classes = clsx(
    'absolute right-0 h-full w-1/6 bg-yellow-200',
    className,
    {
      hidden: !visible,
    }
  );

  return (
    <div data-testid={dataTestID} className={classes}>
      {children}
    </div>
  );
};
