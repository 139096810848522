import { gql } from '@apollo/client';
import { DragonSpeechToTextSecrets } from '@akido/provider-desktop-bffe-types';

export interface GetDragonSecretsReturn {
  dragonSpeechToTextSecrets: DragonSpeechToTextSecrets;
}

export const getDragonSecrets = gql`
  query GetDragonSecrets {
    dragonSpeechToTextSecrets {
      partnerGUID
      applicationName
      organizationToken
    }
  }
`;
