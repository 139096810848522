import { observer } from 'mobx-react-lite';
import { ClinicalNoteFieldValue } from '../../../types/clinical-notes';
import ClinicalNotesAutoFillField from '../ClinicalNotesAutoFillFields';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import React, { useCallback, useEffect } from 'react';
import { localClinicalNotesStore } from '../../../stores/localPersistentClinicalNotes.store';
import { usePatientOrders } from '../hooks/usePatientOrders';
import { clinicalNoteRefreshableAutofillStore } from '../../../stores/clinicalNoteRefreshableAutofill.store';
import { get } from 'lodash';

interface IProps {
  noteId: string;
  sectionId: string;
  field: ClinicalNoteFieldValue;
  updateCallback: (newValue: string) => void;
  localStoreEnabled: boolean;
}

export const OrdersField: React.FC<IProps> = observer(
  ({ noteId, sectionId, field, updateCallback, localStoreEnabled }) => {
    const fullPageRefresh = clinicalNoteStore.refreshing;
    const externalEncounterId = clinicalNoteStore.clinicalNote?.encounterId;
    const orders = clinicalNoteRefreshableAutofillStore.orders?.items;
    const encounterIdentifier = get(
      clinicalNoteStore,
      'clinicalNote.encounter.encounterIdentifier'
    );
    delete encounterIdentifier?.__typename;

    const localNoteSection = localStoreEnabled
      ? localClinicalNotesStore.getSectionByIds(noteId, sectionId)
      : null;

    const { fetchOrders, isLoading } = usePatientOrders(
      externalEncounterId,
      encounterIdentifier
    );

    const refresh = useCallback(() => {
      fetchOrders();
    }, [fetchOrders]);

    useEffect(() => {
      if (fullPageRefresh) {
        refresh();
      }
    }, [fullPageRefresh, refresh]);

    return (
      <ClinicalNotesAutoFillField
        key={`orders_field_${sectionId}`}
        id={sectionId}
        title={field.title}
        showTopAction
        autofillData={orders}
        refreshCallback={refresh}
        isRefreshLoading={fullPageRefresh || isLoading}
        value={localNoteSection?.text || field.text}
        onChange={(newValue: string) => {
          clinicalNoteStore.setSectionValue(
            sectionId,
            newValue,
            field.sortOrder,
            field.type,
            field.uiSection
          );
          updateCallback(newValue);
        }}
        uiSection={field.uiSection ?? sectionId}
      />
    );
  }
);
