export enum RoutePaths {
  Login = '/login',
  RedirectCallback = '/login/callback',
  Transcriptions = '/transcriptions',
  ClinicalNotes = '/clinical-notes',
  ClinicalNotesWithId = '/clinical-notes/:id',
  ClinicalNotePreview = '/clinical-notes/:id/preview',
  AIClinicalNote = '/ai-clinical-note',
  AIClinicalNoteWithId = '/ai-clinical-note/:id',
  Error = '/error',
  Schedule = '/schedule',
  Others = '*',
}

export const RoutePathRegex = {
  ClinicalNotesWithId: /\/clinical-notes\/.+/,
  ClinicalNotesPreview: /\/clinical-notes\/.+\/preview/,
};
