import { FC } from 'react';
import clsx from 'clsx';
import { TestID } from '../../../../constants/testIds';

export interface TabButtonProps {
  label: string;
  className?: string;
}

export const TabButton: FC<TabButtonProps> = (props) => {
  const { label, className } = props;

  return (
    <div
      className={clsx(
        'rounded-md px-5 py-3 font-bold text-primary-500 ui-selected:bg-primary-200 ui-selected:drop-shadow-sm ui-not-selected:bg-white',
        className
      )}
      data-testid={TestID.AIClinicalNote.AINoteTabs.TabButton}
    >
      {label}
    </div>
  );
};
