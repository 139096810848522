import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import {
  getClinicalNoteQuery,
  GetClinicalNoteReturn,
  GetClinicalNoteVariables,
} from '../../../services/graphql/clinicalNote';
import { clinicalNoteStore } from '../../../stores/clinicalNote.store';
import { patientStore } from '../../../stores/patient.store';
import { ClinicalNoteScreens } from '../../../types/screens';

export const useClinicalNoteForPreview = ({
  id,
  screen,
}: {
  id?: string;
  screen: ClinicalNoteScreens;
}) => {
  const [getClinicalNote, { data, loading: isLoading, error }] = useLazyQuery<
    GetClinicalNoteReturn,
    GetClinicalNoteVariables
  >(getClinicalNoteQuery, {
    variables: { id, screen },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (id && data?.getClinicalNote?.id === id) {
      // yes, it was in the need of copy to prevent mobx from updating its reference
      const copyClinicalNoteStore = {
        ...clinicalNoteStore,
      };
      const wasAISummaryEdited = clinicalNoteStore.aiSummaryEdited;
      clinicalNoteStore.reset();
      clinicalNoteStore.setClinicalNote(data.getClinicalNote);
      clinicalNoteStore.setTemplateId(copyClinicalNoteStore.templateId);
      clinicalNoteStore.setTemplateName('');
      clinicalNoteStore.setAISummaryEdited(wasAISummaryEdited);
      if (data?.getClinicalNote?.content?.title) {
        clinicalNoteStore.setEditableNoteTitle(
          data.getClinicalNote.content.title
        );
      }

      if (data?.getClinicalNote?.encounter?.referringPractitioner) {
        clinicalNoteStore.setReferringPractitioner(
          data.getClinicalNote.encounter.referringPractitioner
        );
      }

      if (data?.getClinicalNote?.patient) {
        patientStore.setPatientData(data.getClinicalNote.patient);
      }

      if (data.getClinicalNote?.encounter?.dateOfEncounter) {
        clinicalNoteStore.setDateOfService(
          data.getClinicalNote.encounter.dateOfEncounter
        );
      }
    }
  }, [data, id]);

  useEffect(() => {
    getClinicalNote();
  }, [id, getClinicalNote]);

  return {
    isLoading,
    error,
    getClinicalNote,
  };
};
