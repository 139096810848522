import { FC } from 'react';
import clsx from 'clsx';
import { Microphone } from '../../../../components/AkidoUI/Icon/Microphone';
import { TestID } from '../../../../constants/testIds';
import { useMicrophone } from '../../utils/useMicrophone';
import { REACT_APP_TRANSCRIPTION_WS_URL } from '../../../../config/environment';

export interface TranscribeControlProps {
  clinicalNoteId: string;
}

export const TranscribeControl: FC<TranscribeControlProps> = (props) => {
  const { clinicalNoteId } = props;
  const { state, start, stop } = useMicrophone(
    clinicalNoteId,
    REACT_APP_TRANSCRIPTION_WS_URL
  );

  const onTranscribeClick = async () => {
    if (!state) {
      void start();
    }

    if (state === 'recording') {
      stop();
    }

    if (state === 'inactive') {
      void start();
    }
  };

  const isRecording = state === 'recording';

  return (
    <div
      className='flex h-10 items-center bg-white text-blue-500'
      data-testid={TestID.AIClinicalNote.TranscribeControl.Container}
    >
      <button
        className='color h-full rounded-lg border-2 border-gray-100 bg-white px-2.5 text-sm font-semibold'
        type='button'
        onClick={onTranscribeClick}
        data-testid={TestID.AIClinicalNote.TranscribeControl.Button}
      >
        Transcribe
        <Microphone
          className={clsx('ml-4 inline-block', { 'text-red-500': isRecording })}
          height={20}
          width={20}
          data-testid={TestID.AIClinicalNote.TranscribeControl.Icon}
        />
      </button>
    </div>
  );
};
