import styled from 'styled-components';
import { UserMenu } from '../../components/PageHeaders/UserMenu';
import { TestID } from '../../constants/testIds';

export const Container = styled.div`
  background: #f6f8fa;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
`;

export const AINoteGlobalHeader = () => {
  return (
    <div
      className='flex h-12 w-full justify-end bg-gray-50 align-middle'
      data-testid={TestID.AIClinicalNote.AIClinicalNoteGlobalHeader}
    >
      <UserMenu
        shouldAutoSaveOnLogout
        styles={{
          container: {
            marginRight: '25px',
            alignSelf: 'center',
          },
        }}
      />
    </div>
  );
};
