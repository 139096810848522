import React, {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';
import { setOnlyDate, setOnlyTime } from '../../utilities/helpers';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';

const Container = styled.div`
  padding-right: 24px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: bold;
  align-self: flex-start;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 0 0 24px;
`;

const RowLabel = styled.span`
  font-weight: 500;
  margin-right: 12px;
`;

const StyledDatePicker = styled(DatePicker)`
  margin-right: 12px;
`;

const StyledTimePicker = styled(TimePicker)``;

export type RangeSelectorRef = {
  clear: () => void;
};

interface RangeSelectorStyles {
  container?: CSSProperties;
}

interface RangeSelectorProps {
  title: string;
  startLabel: string;
  endLabel: string;
  styles?: RangeSelectorStyles;
  onChange?: (start: Date, end: Date) => void;
  ref?: React.ForwardedRef<RangeSelectorRef>;
}

enum DateKey {
  From = 'from',
  To = 'to',
}

export const RangeSelector: React.FC<RangeSelectorProps> = forwardRef(
  (props, ref) => {
    const { onChange, styles, title, startLabel, endLabel } = props;

    const [from, setFromDate] = useState<Date | undefined>();
    const [to, setToDate] = useState<Date | undefined>();

    useImperativeHandle(ref, () => ({
      clear: () => {
        setFromDate(undefined);
        setToDate(undefined);
      },
    }));

    const onChangeDate = useCallback((date: Date, key: string) => {
      if (key === DateKey.From) {
        setFromDate((prev) => {
          return setOnlyDate(prev, date);
        });
      } else if (key === DateKey.To) {
        setToDate((prev) => {
          return setOnlyDate(prev, date);
        });
      }
    }, []);

    const onChangeTime = useCallback((date: Date, key: string) => {
      if (key === DateKey.From) {
        setFromDate((prev) => {
          return setOnlyTime(prev, date);
        });
      } else if (key === DateKey.To) {
        setToDate((prev) => {
          return setOnlyTime(prev, date);
        });
      }
    }, []);

    useEffect(() => {
      if (from && to) {
        onChange?.(from, to);
      }
    }, [from, to, onChange]);

    return (
      <Container style={styles?.container}>
        <Title data-testid={'range-selector_title'}>{title}</Title>
        <RowContainer>
          <RowLabel data-testid='range-selector_start_label'>
            {startLabel}
          </RowLabel>
          <StyledDatePicker
            selectedDate={from}
            onChange={(date) => onChangeDate(date, DateKey.From)}
          />
          <StyledTimePicker
            selectedTime={from}
            onChange={(date) => onChangeTime(date, DateKey.From)}
          />
        </RowContainer>
        <RowContainer>
          <RowLabel data-testid='range-selector_end_label'>{endLabel}</RowLabel>
          <StyledDatePicker
            selectedDate={to}
            onChange={(date) => onChangeDate(date, DateKey.To)}
          />
          <StyledTimePicker
            selectedTime={to}
            onChange={(date) => onChangeTime(date, DateKey.To)}
          />
        </RowContainer>
      </Container>
    );
  }
);
