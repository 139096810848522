import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { AlertIcon } from '../../components';
import { ActionsModal } from '../../components/Modal/ActionsModal';
import { TestID } from '../../constants/testIds';
import { clinicalNoteStore } from '../../stores/clinicalNote.store';

const BottomBar = styled.div({
  width: '100%',
  backgroundColor: '#FAFBFC',
  borderTop: '2px solid #D0D7E4',
  display: 'flex',
  justifyContent: 'flex-end',
  height: '70.525px',
});
const HighlightedButton = styled.button({
  margin: '15px 50px 15px 0',
  padding: '10px 30px',
  backgroundColor: '#0078D8',
  fontWeight: '600',
  fontSize: '0.65rem',
  border: 'none',
  borderRadius: '8px',
  color: 'white',
  letterSpacing: '0.5px',
  height: '38px',
});
const MutedButton = styled.button({
  margin: '15px 15px 16px 0',
  padding: '10px 30px',
  backgroundColor: '#FFFFFF',
  fontWeight: '600',
  fontSize: '0.65rem',
  border: '2px solid #F0F2F7',
  borderRadius: '8px',
  color: '#405580',
  letterSpacing: '0.5px',
  height: '39px', //Bigger than the highlighted button to account for the 2px times 2 border size
  boxShadow: '0px 2px 5px rgba(38, 51, 77, 0.03)',
});

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const NewVersionTitle = styled.div`
  color: #0c1e2c;
  font-size: 18px;
  font-weight: 500;
`;

const Text = styled.div`
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
`;

export interface SaveBarProps {
  onSave?: (autoSave?: boolean, override?: boolean) => void;
  onSign?: () => void;
  onRunAI?: () => void;
}

export const SaveBar = observer((props: SaveBarProps) => {
  const { onSave, onSign } = props;

  const onSaveClinicalNote = () => {
    if (
      clinicalNoteStore.hasNewVersion &&
      clinicalNoteStore.newVersionInfo &&
      clinicalNoteStore.clinicalNote &&
      clinicalNoteStore?.newVersionInfo?.version >
        clinicalNoteStore.clinicalNote?.version
    ) {
      clinicalNoteStore.setModalOpen(true);
    } else {
      onSave?.();
    }
  };

  const onGoToPreview = () => {
    onSign?.();
  };

  return (
    <>
      <ActionsModal
        testId='override_note_content'
        isVisible={clinicalNoteStore.newVersionModalOpen}
        onDecline={() => {
          clinicalNoteStore.setAutosaveEnabled(true);
          clinicalNoteStore.overrideClinicaNoteVersion();
          clinicalNoteStore.resetVersionTracker();
          clinicalNoteStore.hideModal();
        }}
        onAccept={() => {
          if (clinicalNoteStore.hasNewVersion) {
            clinicalNoteStore.setModalOpen(false);
            window.location.reload();
          }
        }}
        declineLabel={'Continue'}
        acceptLabel={'View Latest Version'}
        contentStyles={{
          width: '500px',
        }}
        title=''
        showCloseButton={false}
      >
        <ModalWrapper>
          <AlertIcon height='24px' width='24px' />
          <NewVersionTitle>A newer version has been detected</NewVersionTitle>
          <Text>
            Select view latest version or select continue to overwrite and edit
            current version.
          </Text>
        </ModalWrapper>
      </ActionsModal>
      <BottomBar>
        <MutedButton
          data-testid={TestID.ClinicalNote.SaveButton}
          onClick={onSaveClinicalNote}
        >
          Save
        </MutedButton>
        <HighlightedButton
          data-testid={TestID.ClinicalNote.OpenNotePreviewButton}
          onClick={onGoToPreview}
        >
          Preview Note
        </HighlightedButton>
      </BottomBar>
    </>
  );
});
