import React from 'react';
import styled, { CSSProperties } from 'styled-components';

export type IconProps = {
  /**
   * The icon name.
   */
  name: string;
  /**
   * Icon's aria label.
   */
  ariaLabel?: string;
  /**
   * size of the icon.
   */
  size?: 'small' | 'medium' | 'large' | 'xLarge';
  /**
   * style of the icon.
   */
  iconStyle?: 'solid' | 'outline' | 'twoTone';
  /**
   *
   */
  style?: CSSProperties;
};

const iconSizes = {
  small: '16px',
  medium: '24px',
  large: '32px',
  xLarge: '48px',
};

const styleClassNames = {
  solid: 'material-icons',
  outline: 'material-icons-outlined',
  twoTone: 'material-icons-two-tone',
};

const StyledIcon = styled.span<{ size: IconProps['size'] }>`
  display: inline-block;
  font-size: ${({ size }) => iconSizes[size ?? 'medium']} !important;
  line-height: 1;
`;

export function Icon({
  name,
  size,
  ariaLabel,
  iconStyle = 'solid',
  style,
}: IconProps) {
  const description = ariaLabel || `${name} icon`;

  const className = styleClassNames[iconStyle] || 'material-icons'; // default to solid

  return (
    <StyledIcon
      style={style}
      className={className}
      aria-label={description}
      size={size}
    >
      {name}
    </StyledIcon>
  );
}

Icon.defaultProps = {
  size: 'medium',
  iconStyle: 'solid',
};
