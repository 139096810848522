import { gql } from '@apollo/client';

export type GetClinicalNoteIdReturn = {
  getClinicalNote?: {
    id: string;
    signedAt?: string;
  } | null;
};

export type GetClinicalNoteIdVariables = {
  id: string;
  client: string;
};

export const getClinicalNoteIdQuery = gql`
  query GetClinicalNoteId($client: String!, $id: String!) {
    getClinicalNote(externalEncounter: { system: $client, id: $id }) {
      id
      signedAt
    }
  }
`;
